import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { BankDetail } from '../../types/bankDetails';
import BankDetailsService from '../../services/bankDetails.service';
import { toast } from 'react-hot-toast';
import Button from '../common/Button';
import { useCategories } from '../../contexts/CategoryContext';

interface BankDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    bank?: BankDetail;
    isSharedBank?: boolean;
    permissionLevel?: string;
    sharedKey?: string;
    onSuccessfulUpdate?: () => Promise<void> | void;
}

const BankDetailsModal: React.FC<BankDetailsModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    bank,
    isSharedBank = false,
    permissionLevel = 'read',
    sharedKey,
    onSuccessfulUpdate
}) => {
    const [formData, setFormData] = useState({
        name: '',
        bank_name: '',
        account_type: '',
        encrypted_routing_number: '',
        encrypted_account_number: '',
        encrypted_swift_code: '',
        encrypted_iban_number: '',
        encrypted_pin: '',
        encrypted_branch_address: '',
        encrypted_branch_phone: '',
        encrypted_notes: '',
        category_id: '',
        favourite: false
    });

    const [loading, setLoading] = useState(false);
    const { categories, refreshCategories, createCategory } = useCategories();
    const [isCreatingCategory, setIsCreatingCategory] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [creatingCategoryLoading, setCreatingCategoryLoading] = useState(false);

    useEffect(() => {
        if (isOpen && bank) {
            setFormData({
                ...formData,
                name: bank.name || '',
                bank_name: bank.bank_name || '',
                account_type: bank.account_type || '',
                encrypted_routing_number: bank.encrypted_routing_number || '',
                encrypted_account_number: bank.encrypted_account_number || '',
                encrypted_swift_code: bank.encrypted_swift_code || '',
                encrypted_iban_number: bank.encrypted_iban_number || '',
                encrypted_pin: bank.encrypted_pin || '',
                encrypted_branch_address: bank.encrypted_branch_address || '',
                encrypted_branch_phone: bank.encrypted_branch_phone || '',
                encrypted_notes: bank.encrypted_notes || '',
                category_id: bank.category_id || '',
                favourite: bank.favourite || false
            });
        } else if (!isOpen) {
            setFormData({
                name: '',
                bank_name: '',
                account_type: '',
                encrypted_routing_number: '',
                encrypted_account_number: '',
                encrypted_swift_code: '',
                encrypted_iban_number: '',
                encrypted_pin: '',
                encrypted_branch_address: '',
                encrypted_branch_phone: '',
                encrypted_notes: '',
                category_id: '',
                favourite: false
            });
        }

        if (isOpen && !isSharedBank && bank?.itemType !== 'category') {
            refreshCategories();
        }
    }, [bank, isOpen]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCreateCategory = async () => {
        if (!newCategoryName.trim()) return;

        setCreatingCategoryLoading(true);
        try {
            await  createCategory({ name: newCategoryName });
            await refreshCategories();

            // Find the newly created category and select it
            const newCategory = categories.find(cat => cat.name === newCategoryName);
            if (newCategory) {
                setFormData(prev => ({
                    ...prev,
                    category_id: newCategory.id
                }));
            }

            // Reset the new category UI state
            setNewCategoryName('');
            setIsCreatingCategory(false);
        } catch (error) {
            console.error('Error creating category:', error);
            toast.error('Failed to create category');
        } finally {
            setCreatingCategoryLoading(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (bank) {
                await BankDetailsService.updateBankDetail(bank.id, {
                    ...formData,
                    favourite: formData.favourite,
                    isSharedUpdate: isSharedBank,
                    sharedKey,
                    itemType: bank.itemType || 'bank'
                });
                toast.success('Bank account updated successfully');
            } else {
                await BankDetailsService.createBankDetail({
                    ...formData,
                    favourite: formData.favourite
                });
                toast.success('Bank account created successfully');
            }
            if (onSuccessfulUpdate) {
                await onSuccessfulUpdate();
            } else {
                onSuccess();
            }
            onClose();
        } catch (error) {
            console.error('Error saving bank account:', error);
            toast.error(bank ? 'Failed to update bank account' : 'Failed to create bank account');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    const isReadOnly = isSharedBank && permissionLevel === 'read';

    return (
        <div className="fixed inset-0 z-50 overflow-hidden">
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className="relative bg-white rounded-lg w-full max-w-4xl max-h-[90vh] flex flex-col shadow-lg overflow-hidden">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h3 className="text-lg font-medium">
                            {bank ? 'Edit Bank Account' : 'Add Bank Account'}
                        </h3>
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit} className="flex-1 overflow-auto p-4">
                        <div className="h-full flex flex-col md:flex-row md:divide-x">
                            {/* Left Column - Basic Info */}
                            <div className="w-full md:w-1/3 p-4 overflow-y-auto">
                                <div className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                    {!isSharedBank && (
                                        <>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Vault</label>
                                                {!isCreatingCategory ? (
                                                    <select
                                                        name="category_id"
                                                        value={formData.category_id}
                                                        onChange={(e) => {
                                                            if (e.target.value === "create_new") {
                                                                setIsCreatingCategory(true);
                                                            } else {
                                                                handleChange(e);
                                                            }
                                                        }}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        disabled={isReadOnly}
                                                    >
                                                        <option value="">Select Vault</option>
                                                        {categories.map((category) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                        <option value="create_new">Create new Vault</option>
                                                    </select>
                                                ) : (
                                                    <div className="mt-1 space-x-2">
                                                        <input
                                                            type="text"
                                                            value={newCategoryName}
                                                            onChange={(e) => setNewCategoryName(e.target.value)}
                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                            placeholder="Vault name"
                                                            autoFocus
                                                        />
                                                        <div className="flex items-center justify-between mt-4">
                                                            <button
                                                                type="button"
                                                                onClick={handleCreateCategory}
                                                                disabled={creatingCategoryLoading || !newCategoryName.trim()}
                                                                className={`px-3 py-2 rounded-md text-sm font-medium ${
                                                                    creatingCategoryLoading || !newCategoryName.trim()
                                                                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                                        : 'bg-blue-600 text-white hover:bg-blue-700'
                                                                }`}
                                                            >
                                                                {creatingCategoryLoading ? 'Creating...' : 'Create'}
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setIsCreatingCategory(false);
                                                                    setNewCategoryName('');
                                                                }}
                                                                className="px-3 py-2 rounded-md text-sm font-medium bg-gray-200 text-gray-700 hover:bg-gray-300"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex items-center mt-4">
                                                <input
                                                    type="checkbox"
                                                    id="favourite"
                                                    name="favourite"
                                                    checked={formData.favourite}
                                                    onChange={(e) => setFormData({ ...formData, favourite: e.target.checked })}
                                                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                    disabled={isReadOnly}
                                                />
                                                <label htmlFor="favourite" className="ml-2 block text-sm text-gray-900">
                                                    Add to favourites
                                                </label>
                                            </div>
                                        </>
                                    )}

                                </div>
                            </div>

                            {/* Right Column - Bank Details */}
                            <div className="w-full md:w-2/3 p-4 overflow-y-auto">
                                <div className="space-y-6">
                                    {/* Bank Information Section */}
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="text-sm font-medium text-gray-900 mb-4">Bank Information</h4>
                                        <div className="space-y-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Bank Name</label>
                                                <input
                                                    type="text"
                                                    name="bank_name"
                                                    value={formData.bank_name}
                                                    onChange={handleChange}
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    disabled={isReadOnly}
                                                />
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Account Type</label>
                                                <input
                                                    type="text"
                                                    name="account_type"
                                                    value={formData.account_type}
                                                    onChange={handleChange}
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    disabled={isReadOnly}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Account Number</label>
                                                <input
                                                    type="text"
                                                    name="encrypted_account_number"
                                                    value={formData.encrypted_account_number}
                                                    onChange={handleChange}
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    disabled={isReadOnly}
                                                />
                                            </div>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">PIN</label>
                                                    <input
                                                        type="password"
                                                        name="encrypted_pin"
                                                        value={formData.encrypted_pin}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        disabled={isReadOnly}
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Routing Number</label>
                                                    <input
                                                        type="text"
                                                        name="encrypted_routing_number"
                                                        value={formData.encrypted_routing_number}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        disabled={isReadOnly}
                                                    />
                                                </div>
                                            </div>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">SWIFT Code</label>
                                                    <input
                                                        type="text"
                                                        name="encrypted_swift_code"
                                                        value={formData.encrypted_swift_code}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        disabled={isReadOnly}
                                                    />
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">IBAN Number</label>
                                                    <input
                                                        type="text"
                                                        name="encrypted_iban_number"
                                                        value={formData.encrypted_iban_number}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        disabled={isReadOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Branch Information Section */}
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="text-sm font-medium text-gray-900 mb-4">Branch Information</h4>
                                        <div className="space-y-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Branch Address</label>
                                                <textarea
                                                    name="encrypted_branch_address"
                                                    value={formData.encrypted_branch_address}
                                                    onChange={handleChange}
                                                    rows={2}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    disabled={isReadOnly}
                                                />
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Branch Phone</label>
                                                <input
                                                    type="text"
                                                    name="encrypted_branch_phone"
                                                    value={formData.encrypted_branch_phone}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    disabled={isReadOnly}
                                                />
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Notes</label>
                                                <textarea
                                                    name="encrypted_notes"
                                                    value={formData.encrypted_notes}
                                                    onChange={handleChange}
                                                    rows={3}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    disabled={isReadOnly}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Fixed Footer */}
                        <div className="sticky bottom-0 left-0 right-0 p-4 bg-white border-t flex justify-end space-x-2">
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            {!isReadOnly && (
                                <Button
                                    type="submit"
                                    loading={loading}
                                    disabled={loading}
                                >
                                    {bank ? 'Update Bank Account' : 'Save Bank Account'}
                                </Button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default BankDetailsModal; 