import React, { useEffect, useState, useCallback, useRef } from 'react';
import { KeyIcon, ClipboardDocumentIcon, PencilIcon, TrashIcon, ArrowTopRightOnSquareIcon, ShareIcon, XMarkIcon, EyeIcon, EyeSlashIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useVault } from '../../contexts/VaultContext';
import { IDecryptedPasswordEntry } from '../../types/vault.types';
import EditPasswordModal from './EditPasswordModal';
import { VaultService } from '../../services/vault.service';
import { toast } from 'react-hot-toast';
// import SharePasswordModal from './SharePasswordModal';
import { useLocation } from 'react-router-dom';
import { config } from '../../extension/config';

interface PasswordListProps {
    entries?: IDecryptedPasswordEntry[];
    categoryId?: string;
    loading?: boolean;
    error?: string | null;
    showCategoryBadge?: boolean;
    isShared?: boolean;
    permissionLevel?: string;
    onSuccessfulUpdate?: () => Promise<void>;
}

const EXTENSION_ID = config.EXTENSION_ID;

const PasswordList: React.FC<PasswordListProps> = ({ entries = [], categoryId, loading, error, showCategoryBadge, isShared = false, permissionLevel = 'write', onSuccessfulUpdate }) => {
    const { deleteEntry, refreshEntries, searchQuery } = useVault();
    const [selectedEntry, setSelectedEntry] = useState<IDecryptedPasswordEntry | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [shareEntry, setShareEntry] = useState<IDecryptedPasswordEntry | null>(null);
    const location = useLocation();
    const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>({});
    const [showDetailsPanel, setShowDetailsPanel] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);

    // Apply search filtering inside the component
    const filteredEntries = entries.filter(entry => {
        if (!searchQuery) return true;
        
        const query = searchQuery.toLowerCase();
        return (
            entry.title?.toLowerCase().includes(query) ||
            entry.username?.toLowerCase().includes(query) ||
            entry.website_url?.toLowerCase().includes(query) ||
            entry.notes?.toLowerCase().includes(query)
        );
    });

    // Detect screen size changes
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            if (window.innerWidth >= 768) {
                setShowDetailsPanel(!!selectedEntry);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [selectedEntry]);

    const getFaviconUrl = (websiteUrl: string, faviconUrl: string): string => {
        try {
            if (faviconUrl) {
                return faviconUrl;
            }

            const url = new URL(websiteUrl);
            return `https://www.google.com/s2/favicons?domain=${url.hostname}&sz=64`;
        } catch {
            return '/password-manager.png';
        }
    };

    const handleCopyItem = async (type: string, item: string) => {
        try {
            await navigator.clipboard.writeText(item);
            if (type === 'password') {
                toast.success('Password copied to clipboard');
            } else {
                toast.success('Username copied to clipboard');
            }
        } catch (err) {
            console.error('Failed to copy password:', err);
            toast.error('Failed to copy password');
        }
    };

    const handleEdit = async (entryId: string) => {
        try {
            if (isShared) {
                const entry = entries?.find(entry => entry.id === entryId);
                setSelectedEntry(entry || null);
                setIsEditModalOpen(true);
            } else {
                const entry = await VaultService.getEntryById(entryId);
                setSelectedEntry(entry);
                setIsEditModalOpen(true);
            }
        } catch (err) {
            console.error('Failed to fetch entry:', err);
            toast.error('Failed to fetch entry');
        }
    };

    const handleWebsiteLaunch = async (entry: IDecryptedPasswordEntry) => {
        try {
            // Store both the active entry and indicate it was launched
            if (chrome?.runtime && EXTENSION_ID) {
                chrome.runtime.sendMessage(
                    EXTENSION_ID,
                    {
                        type: 'SET_LAUNCH_DATA',
                        payload: {
                            launchedEntryId: entry.id,
                            url: entry.website_url,
                            timestamp: Date.now()
                        }
                    },
                    (response) => {
                        if (entry.website_url) {
                            window.open(entry.website_url, '_blank');
                            toast.success('Website opened');
                        } else {
                            toast.error('No website URL provided');
                        }
                    }
                );
            } else {
                // Fallback
                if (entry.website_url) {
                    window.open(entry.website_url, '_blank');
                }
            }
        } catch (err) {
            console.error('Failed to launch website:', err);
            toast.error('Failed to launch website');
        }
    };

    const handleShare = (entry: IDecryptedPasswordEntry) => {
        setShareEntry(entry);
        setIsShareModalOpen(true);
    };

    const handleRemoveFromCategory = async (entryId: string) => {
        try {
            if (window.confirm('Are you sure you want to remove this password from its category?')) {
                await VaultService.removeCategoryFromEntry(entryId);
                await refreshEntries();
                toast.success('Password removed from category');
            }
        } catch (error) {
            toast.error('Failed to remove password from category');
        }
    };

    const toggleDetailsPanel = (entryId: string) => {
        if (selectedEntry?.id === entryId) {
            setSelectedEntry(null);
            setShowDetailsPanel(false);
        } else {
            const entry = entries?.find(e => e.id === entryId);
            if (entry) {
                setSelectedEntry(entry);
                setShowDetailsPanel(true);
            }
        }
    };

    const closeDetailsPanel = () => {
        setShowDetailsPanel(false);
        if (isMobile) {
            setSelectedEntry(null);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            await deleteEntry(id);
            // toast.success('Password deleted successfully');
            if (onSuccessfulUpdate) {
                await onSuccessfulUpdate();
            }
        } catch (err) {
            console.error('Failed to delete entry:', err);
            toast.error('Failed to delete entry');
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center p-8">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-gray-100"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="bg-red-50 dark:bg-red-900/20 p-4 rounded-md">
                <p className="text-red-700 dark:text-red-400">Error loading passwords: {error}</p>
            </div>
        );
    }

    if (filteredEntries.length === 0) {
        return (
            <div className="text-center py-8">
                <KeyIcon className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500" />
                <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-200">No passwords</h3>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">Get started by creating a new password entry.</p>
            </div>
        );
    }

    // Mobile view - either show list or details
    if (isMobile) {
        return (
            <div className="h-full">
                {!showDetailsPanel ? (
                    // Mobile Password List
                    <div className="border-r border-gray-200 dark:border-gray-700 h-full">
                        <div className="p-2 space-y-2">
                            {filteredEntries?.map((entry) => (
                                <div
                                    key={entry.id}
                                    className={`p-3 rounded-lg cursor-pointer transition-all duration-200
                                        ${selectedEntry?.id === entry.id
                                            ? 'bg-blue-100 dark:bg-blue-900/30'
                                            : 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                                        }`}
                                    onClick={() => toggleDetailsPanel(entry.id)}
                                >
                                    <div className="flex items-center space-x-3">
                                        <div className="w-10 h-10 rounded-full bg-gray-100 dark:bg-gray-700 flex items-center justify-center overflow-hidden">
                                            {entry.website_url || entry.favicon_url ? (
                                                <img
                                                    src={getFaviconUrl(entry?.website_url || '', entry?.favicon_url || '')}
                                                    alt=""
                                                    className="w-6 h-6"
                                                    onError={(e) => {
                                                        const target = e.target as HTMLImageElement;
                                                        target.src = '/default-favicon.png';
                                                    }}
                                                />
                                            ) : (
                                                <KeyIcon className="w-5 h-5 text-gray-400" />
                                            )}
                                        </div>
                                        <div className="flex-1 min-w-0 my-2">
                                            <h3 className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate">
                                                {entry?.title}
                                            </h3>
                                            <div className="flex items-center space-x-1 text-xs text-gray-500 dark:text-gray-400">
                                                <span className="truncate">{entry?.username}</span>
                                                {entry?.category_name && (
                                                    <>
                                                        <span className="text-gray-400">•</span>
                                                        <span className="italic truncate">{entry.category_name}</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <ChevronRightIcon className="h-5 w-5 text-gray-400 dark:text-gray-500" />
                                    </div>
                                </div>
                            ))}
                            
                            {/* No results message */}
                            {filteredEntries.length === 0 && (
                                <div className="p-4 text-center">
                                    <p className="text-gray-500">No passwords found</p>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    // Mobile Password Details
                    <div className="h-full bg-white dark:bg-gray-800 p-4">
                        <div className="flex items-center mb-4">
                            <button
                                onClick={closeDetailsPanel}
                                className="p-2 mr-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                <ChevronLeftIcon className="h-5 w-5" />
                            </button>
                            <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                                {selectedEntry?.title}
                            </h2>
                        </div>

                        <div className="space-y-6">
                            {/* Username Field */}
                            <div className="space-y-2">
                                <label className="text-sm text-gray-500 dark:text-gray-400">Username</label>
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="text"
                                        value={selectedEntry?.username || ''}
                                        readOnly
                                        className="w-full bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600"
                                    />
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (selectedEntry) handleCopyItem('username', selectedEntry.username);
                                        }}
                                        className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >
                                        <ClipboardDocumentIcon className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>

                            {/* Password Field */}
                            <div className="space-y-2">
                                <label className="text-sm text-gray-500 dark:text-gray-400">Password</label>
                                <div className="flex items-center space-x-2">
                                    <input
                                        type={selectedEntry && showPassword[selectedEntry.id] ? 'text' : 'password'}
                                        value={selectedEntry?.password || ''}
                                        readOnly
                                        className="w-full bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600"
                                    />
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (selectedEntry) {
                                                setShowPassword(prev => ({
                                                    ...prev,
                                                    [selectedEntry.id]: !prev[selectedEntry.id]
                                                }));
                                            }
                                        }}
                                        className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >
                                        {selectedEntry && showPassword[selectedEntry.id] ? (
                                            <EyeSlashIcon className="w-5 h-5" />
                                        ) : (
                                            <EyeIcon className="w-5 h-5" />
                                        )}
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (selectedEntry) handleCopyItem('password', selectedEntry.password);
                                        }}
                                        className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >
                                        <ClipboardDocumentIcon className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>

                            {/* Website Field */}
                            {selectedEntry?.website_url && (
                                <div className="space-y-2">
                                    <label className="text-sm text-gray-500 dark:text-gray-400">Website</label>
                                    <div className="flex items-center space-x-2">
                                        <input
                                            type="text"
                                            value={selectedEntry.website_url}
                                            readOnly
                                            className="w-full bg-gray-50 dark:bg-gray-700 text-gray-800 dark:text-gray-200 px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600"
                                        />
                                        <button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (selectedEntry) handleWebsiteLaunch(selectedEntry);
                                            }}
                                            className="p-2 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                                        >
                                            <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                                        </button>
                                    </div>
                                </div>
                            )}

                            {/* Mobile Action Buttons */}
                            <div className="flex space-x-2 pt-4 md:hidden">
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit(selectedEntry?.id || '');
                                    }}
                                    className="flex-1 py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors flex items-center justify-center"
                                >
                                    <PencilIcon className="h-5 w-5 mr-2" />
                                    Edit
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (window.confirm('Are you sure you want to delete this password?')) {
                                            handleDelete(selectedEntry?.id || '');
                                        }
                                    }}
                                    className="flex-1 py-2 px-4 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors flex items-center justify-center"
                                >
                                    <TrashIcon className="h-5 w-5 mr-2" />
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {selectedEntry && isEditModalOpen && (
                    <EditPasswordModal
                        entry={selectedEntry}
                        isOpen={isEditModalOpen}
                        onClose={() => {
                            setIsEditModalOpen(false);
                            closeDetailsPanel();
                        }}
                        isSharedPassword={isShared}
                        permissionLevel={selectedEntry.permission_level || permissionLevel}
                        onSuccessfulUpdate={onSuccessfulUpdate}
                    />
                )}
            </div>
        );
    }

    // Desktop view - show both list and details side by side
    return (
        <div className="relative">
            {/* Mobile View */}
            <div className="block md:hidden">
                <div className="divide-y divide-gray-200">
                    {filteredEntries.map((entry) => (
                        <div
                            key={entry.id}
                            className={`p-3 rounded-lg cursor-pointer transition-all duration-200
                                ${selectedEntry?.id === entry.id
                                    ? 'bg-blue-100 dark:bg-blue-900/30'
                                    : 'bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700/50'
                                }`}
                            onClick={() => toggleDetailsPanel(entry.id)}
                        >
                            <div className="flex items-center space-x-3">
                                <div className="w-10 h-10 rounded-full bg-gray-100 dark:bg-gray-700 flex items-center justify-center overflow-hidden">
                                    {entry.website_url || entry.favicon_url ? (
                                        <img
                                            src={getFaviconUrl(entry?.website_url || '', entry?.favicon_url || '')}
                                            alt=""
                                            className="w-6 h-6"
                                            onError={(e) => {
                                                const target = e.target as HTMLImageElement;
                                                target.src = '/default-favicon.png';
                                            }}
                                        />
                                    ) : (
                                        <KeyIcon className="w-5 h-5 text-gray-400" />
                                    )}
                                </div>
                                <div className="flex-1 min-w-0">
                                    <h3 className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate">
                                        {entry.title}
                                    </h3>
                                    <div className="flex items-center space-x-1 text-xs text-gray-500 dark:text-gray-400">
                                        <span className="truncate">{entry.username}</span>
                                        {entry.category_name && (
                                            <>
                                                <span className="text-gray-400">•</span>
                                                <span className="italic truncate">{entry.category_name}</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <ChevronRightIcon className="h-5 w-5 text-gray-400 dark:text-gray-500" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Desktop View - Grid Layout */}
            <div className="hidden md:block">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
                    {filteredEntries.map((entry) => (
                        <div key={entry.id} className="relative">
                            <div
                                className={`bg-white dark:bg-gray-800 rounded-lg shadow-sm border 
                                    hover:shadow-md transition-all duration-200 cursor-pointer
                                    ${selectedEntry?.id === entry.id ? 'ring-2 ring-blue-500' : ''}`}
                                onClick={() => toggleDetailsPanel(entry.id)}
                            >
                                <div className="p-4">
                                    <div className="flex items-center space-x-3">
                                        <div className="flex-shrink-0">
                                            {entry.website_url || entry.favicon_url ? (
                                                
                                                <img
                                                    src={getFaviconUrl(entry?.website_url || '', entry?.favicon_url || '')}
                                                    alt=""
                                                    className="w-6 h-6"
                                                    onError={(e) => {
                                                        const target = e.target as HTMLImageElement;
                                                        target.src = '/default-favicon.png';
                                                    }}
                                                />
                                            ) : (
                                                <KeyIcon className="h-8 w-8 text-gray-400" />
                                            )}
                                        </div>
                                        <div className="flex-1 min-w-0">
                                            <p className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate">
                                                {entry.title}
                                            </p>
                                            <div className="flex items-center space-x-1 text-xs text-gray-500 dark:text-gray-400">
                                                <span className="truncate">{entry.username}</span>
                                                {entry.category_name && (
                                                    <>
                                                        <span className="text-gray-400">•</span>
                                                        <span className="italic truncate">{entry.category_name}</span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <ChevronRightIcon
                                            className={`h-5 w-5 text-gray-400 transition-transform duration-200
                                                ${selectedEntry?.id === entry.id ? 'transform rotate-90' : ''}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Centered Details Panel */}
            {selectedEntry && showDetailsPanel && (
                <div className="fixed inset-0 bg-gray-900/50 flex items-center justify-center z-50">
                    <div
                        className="bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-md mx-4"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="p-6">
                            {/* Header */}
                            <div className="flex items-center justify-between mb-6">
                                <div className="flex items-center space-x-3">
                                    <div className="flex-shrink-0">
                                        {selectedEntry.website_url ? (
                                            <img
                                                src={getFaviconUrl(selectedEntry.website_url, selectedEntry.favicon_url || '')}
                                                alt=""
                                                className="h-10 w-10 rounded-full"
                                                onError={(e) => {
                                                    const target = e.target as HTMLImageElement;
                                                    target.src = '/default-favicon.png';
                                                }}
                                            />
                                        ) : (
                                            <KeyIcon className="h-10 w-10 text-gray-400" />
                                        )}
                                    </div>
                                    <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                                        {selectedEntry.title}
                                    </h2>
                                </div>
                                <button
                                    onClick={() => toggleDetailsPanel(selectedEntry.id)}
                                    className="text-gray-400 hover:text-gray-600"
                                >
                                    <XMarkIcon className="h-6 w-6" />
                                </button>
                            </div>

                            {/* Content */}
                            <div className="space-y-6">
                                {/* Website URL Section */}
                                {selectedEntry.website_url && (
                                    <div>
                                        <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                            Website
                                        </label>
                                        <div className="mt-1 flex items-center">
                                            <input
                                                type="text"
                                                readOnly
                                                value={selectedEntry.website_url}
                                                className="flex-1 bg-gray-50 dark:bg-gray-700 px-3 py-2 rounded-lg text-sm"
                                            />
                                            <button
                                                onClick={() => window.open(selectedEntry.website_url, '_blank')}
                                                className="ml-2 p-2 text-gray-500 hover:text-gray-700"
                                                title="Open website"
                                            >
                                                <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {/* Username Section */}
                                <div>
                                    <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Username
                                    </label>
                                    <div className="mt-1 flex items-center">
                                        <input
                                            type="text"
                                            readOnly
                                            value={selectedEntry.username}
                                            className="flex-1 bg-gray-50 dark:bg-gray-700 px-3 py-2 rounded-lg text-sm"
                                        />
                                        <button
                                            onClick={() => navigator.clipboard.writeText(selectedEntry.username)}
                                            className="ml-2 p-2 text-gray-500 hover:text-gray-700"
                                        >
                                            <ClipboardDocumentIcon className="h-5 w-5" />
                                        </button>
                                    </div>
                                </div>

                                {/* Password Section */}
                                <div>
                                    <label className="text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Password
                                    </label>
                                    <div className="mt-1 flex items-center">
                                        <input
                                            type={showPassword[selectedEntry.id] ? 'text' : 'password'}
                                            readOnly
                                            value={selectedEntry.password}
                                            className="flex-1 bg-gray-50 dark:bg-gray-700 px-3 py-2 rounded-lg text-sm"
                                        />
                                        <button
                                            onClick={() => setShowPassword(prev => ({
                                                ...prev,
                                                [selectedEntry.id]: !prev[selectedEntry.id]
                                            }))}
                                            className="ml-2 p-2 text-gray-500 hover:text-gray-700"
                                        >
                                            {showPassword[selectedEntry.id] ? (
                                                <EyeSlashIcon className="h-5 w-5" />
                                            ) : (
                                                <EyeIcon className="h-5 w-5" />
                                            )}
                                        </button>
                                        <button
                                            onClick={() => navigator.clipboard.writeText(selectedEntry.password)}
                                            className="ml-2 p-2 text-gray-500 hover:text-gray-700"
                                        >
                                            <ClipboardDocumentIcon className="h-5 w-5" />
                                        </button>
                                    </div>
                                </div>

                                {/* Action Buttons */}
                                <div className="flex space-x-3 mt-6">
                                    <button
                                         onClick={(e) => {
                                            e.stopPropagation();
                                            handleEdit(selectedEntry?.id || '');
                                        }}
                                        className="flex-1 py-2 px-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg transition-colors flex items-center justify-center"
                                    >
                                        <PencilIcon className="h-5 w-5 mr-2" />
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => {
                                            if (window.confirm('Are you sure you want to delete this password?')) {
                                                handleDelete(selectedEntry.id);
                                            }
                                        }}
                                        className="flex-1 py-2 px-4 bg-red-600 hover:bg-red-700 text-white rounded-lg transition-colors flex items-center justify-center"
                                    >
                                        <TrashIcon className="h-5 w-5 mr-2" />
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Modal */}
            {selectedEntry && isEditModalOpen && (
                <EditPasswordModal
                    entry={selectedEntry}
                    isOpen={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        closeDetailsPanel();
                    }}
                    isSharedPassword={isShared}
                    permissionLevel={selectedEntry.permission_level || permissionLevel}
                    onSuccessfulUpdate={onSuccessfulUpdate}
                />
            )}
        </div>
    );
};

export default PasswordList;