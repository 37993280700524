import React, { useState, useRef } from 'react';
import { MagnifyingGlassIcon, PlusIcon, UserCircleIcon, ClipboardIcon, ArrowPathIcon, XMarkIcon, ArrowUpTrayIcon, Bars3Icon, KeyIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Button from '../common/Button';
import PasswordModal from './PasswordModal';
import AddItemModal from './AddItemModal';
import NotesModal from '../notes/CreateNoteModal';
import PersonalDetailsModal from '../personalDetails/PersonalDetailsModal';
import CardDetailsModal from '../cardDetails/CardDetailsModal';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { toast } from 'react-hot-toast';
import { useVault } from '../../contexts/VaultContext';
import BankDetailsModal from '../bankDetails/BankDetailsModal';
import DarkModeToggle from '../common/DarkModeToggle';
import { useSidebar } from '../../contexts/SidebarContext';

const VaultHeader: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isItemModalOpen, setIsItemModalOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [isPasswordGeneratorOpen, setIsPasswordGeneratorOpen] = useState(false);
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [includeLowercase, setIncludeLowercase] = useState(true);
    const [includeUppercase, setIncludeUppercase] = useState(true);
    const [includeNumbers, setIncludeNumbers] = useState(true);
    const [includeSymbols, setIncludeSymbols] = useState(true);
    const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
    const [isPersonalDetailsModalOpen, setIsPersonalDetailsModalOpen] = useState(false);
    const [isCardDetailsModalOpen, setIsCardDetailsModalOpen] = useState(false);
    const [isBankDetailsModalOpen, setIsBankDetailsModalOpen] = useState(false);
    const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

    const profileRef = useRef<HTMLDivElement>(null);
    const passwordPopoverRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { user, logout } = useAuth();
    const { refreshEntries, searchQuery, setSearchQuery } = useVault();
    const { toggleSidebar, isOpen } = useSidebar();

    useOnClickOutside(profileRef, () => setIsProfileOpen(false));
    useOnClickOutside(passwordPopoverRef, () => setIsPasswordGeneratorOpen(false));

    const handleLogout = () => {
        try {
            logout();
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Logout error:', error);
            toast.error('Failed to logout');
        }
    };

    const handleSuccess = async () => {
        setIsModalOpen(false);
        await refreshEntries();
        toast.success('Password added successfully');
    };

    const handleItemSelect = (itemType: string) => {
        if (itemType === 'password') {
            setIsModalOpen(true);
        } else if (itemType === 'notes') {
            setIsNotesModalOpen(true);
        } else if (itemType === 'personalDetails') {
            setIsPersonalDetailsModalOpen(true);
        } else if (itemType === 'cardDetails') {
            setIsCardDetailsModalOpen(true);
        } else if (itemType === 'bankDetails') {
            setIsBankDetailsModalOpen(true);
        }
        setIsItemModalOpen(false);
    };

    const generatePassword = () => {
        const chars = [];
        if (includeLowercase) chars.push(...Array.from('abcdefghijklmnopqrstuvwxyz'));
        if (includeUppercase) chars.push(...Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ'));
        if (includeNumbers) chars.push(...Array.from('0123456789'));
        if (includeSymbols) chars.push(...Array.from('!#$%^&*()_+'));

        if (chars.length === 0) return; // No characters selected

        let password = '';
        for (let i = 0; i < 16; i++) {
            password += chars[Math.floor(Math.random() * chars.length)];
        }
        setGeneratedPassword(password);
    };

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(generatedPassword);
        toast.success('Password copied to clipboard!');
    };

    React.useEffect(() => {
        generatePassword();
    }, [includeLowercase, includeUppercase, includeNumbers, includeSymbols]);

    return (
        <>
            <header className="w-full">
                <div className="relative z-10 flex-shrink-0 h-16 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 shadow-sm flex">
                    <div className="flex-1 flex justify-between px-2 sm:px-4 md:px-6">
                        {/* Mobile Menu Button - visible only on small screens */}
                        <div className="flex items-center lg:hidden">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                                onClick={() => {
                                    console.log("Header button clicked");
                                    toggleSidebar();
                                }}
                                aria-expanded={isOpen}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>

                        {/* Desktop Search Bar - Aligned to the left side */}
                        <div className="hidden md:flex flex-1 items-center justify-start">
                            <div className="max-w-lg w-full">
                                <label htmlFor="search" className="sr-only">Search</label>
                                <div className="relative">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <input
                                        id="search"
                                        name="search"
                                        className="block w-full rounded-md border-0 bg-white dark:bg-gray-700 py-2 pl-10 pr-3 text-gray-900 dark:text-gray-100 ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                                        placeholder="Search"
                                        type="search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Mobile Search Button */}
                        <div className="flex items-center md:hidden ml-2">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                                onClick={() => setIsMobileSearchOpen(!isMobileSearchOpen)}
                            >
                                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>

                        {/* Mobile Search - Hidden on desktop, shown on mobile when expanded */}
                        <div className={`${isMobileSearchOpen ? 'flex absolute top-16 left-0 right-0 bg-white dark:bg-gray-800 z-20 p-2 shadow-md' : 'hidden'} md:hidden`}>
                            <div className="w-full flex">
                                <label htmlFor="mobile-search" className="sr-only">Search</label>
                                <div className="relative w-full text-gray-400 focus-within:text-gray-600 dark:text-gray-300 dark:focus-within:text-gray-200">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                    </div>
                                    <input
                                        id="mobile-search"
                                        className="block w-full h-full pl-10 pr-10 py-2 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 placeholder-gray-500 dark:placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent sm:text-sm rounded-md"
                                        placeholder="Search"
                                        type="search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                    />
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                                        <button
                                            onClick={() => setIsMobileSearchOpen(false)}
                                            className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                                        >
                                            <XMarkIcon className="h-5 w-5" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Action buttons - optimized for all screen sizes */}
                        <div className="ml-auto flex items-center space-x-1 sm:space-x-2 md:space-x-3 lg:space-x-4">
                            {/* Password Generator Button - Text on larger screens, icon on small */}
                            <Button
                                onClick={() => setIsPasswordGeneratorOpen(!isPasswordGeneratorOpen)}
                                type="button"
                                className="hidden sm:flex items-center px-3 py-1.5 md:px-4 md:py-2"
                            >
                                <KeyIcon className="h-4 w-4 sm:mr-1 md:mr-2" />
                                <span className="hidden md:inline">Password Generator</span>
                            </Button>
                            <Button
                                onClick={() => setIsPasswordGeneratorOpen(!isPasswordGeneratorOpen)}
                                type="button"
                                className="sm:hidden p-2 min-w-0"
                                aria-label="Password Generator"
                            >
                                <KeyIcon className="h-5 w-5" />
                            </Button>

                            {/* Add Item Button - Text on larger screens, icon on small */}
                            <Button
                                onClick={() => setIsItemModalOpen(true)}
                                type="button"
                                className="hidden sm:flex items-center px-3 py-1.5 md:px-4 md:py-2"
                            >
                                <PlusIcon className="h-4 w-4 sm:mr-1 md:mr-2" />
                                <span className="hidden md:inline">Add Item</span>
                            </Button>
                            <Button
                                onClick={() => setIsItemModalOpen(true)}
                                type="button"
                                className="sm:hidden p-2 min-w-0"
                                aria-label="Add Item"
                            >
                                <PlusIcon className="h-5 w-5" />
                            </Button>

                            {/* Dark Mode Toggle - Between Add Item and Profile */}
                            <DarkModeToggle />

                            {/* Profile Dropdown - Responsive with email only showing on larger screens */}
                            <div className="relative" ref={profileRef}>
                                <button
                                    onClick={() => setIsProfileOpen(!isProfileOpen)}
                                    className="flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 p-2"
                                >
                                    <UserCircleIcon className="h-7 w-7 text-gray-400 dark:text-gray-300" />
                                    <span className="ml-2 text-gray-700 dark:text-gray-200 hidden md:block truncate max-w-[150px] lg:max-w-[200px]">
                                        {user?.email}
                                    </span>
                                </button>

                                {/* Dropdown Menu - Consistently positioned across devices */}
                                {isProfileOpen && (
                                    <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-700 z-50">
                                        <div className="px-4 py-3">
                                            <p className="text-sm text-gray-500 dark:text-gray-400">Signed in as</p>
                                            <p className="text-sm font-medium text-gray-900 dark:text-gray-100 truncate">
                                                {user?.email}
                                            </p>
                                        </div>
                                        <div className="py-1">
                                            <button
                                                onClick={() => {
                                                    setIsProfileOpen(false);
                                                    navigate('/mfa-setup');
                                                }}
                                                className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-blue-500"
                                            >
                                                Setup MFA
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setIsProfileOpen(false);
                                                    handleLogout();
                                                }}
                                                className="w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-red-500"
                                            >
                                                Logout
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* Password Generator Popover - Make it responsive */}
            {isPasswordGeneratorOpen && (
                <div
                    ref={passwordPopoverRef}
                    className="fixed sm:absolute z-20 sm:right-0 mt-2 w-[calc(100%-2rem)] sm:w-80 md:w-96 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 p-4"
                    style={{
                        top: window.innerWidth <= 640 ? '50%' : undefined,
                        left: window.innerWidth <= 640 ? '50%' : undefined,
                        transform: window.innerWidth <= 640 ? 'translate(-50%, -50%)' : undefined,
                        maxWidth: window.innerWidth <= 640 ? 'calc(100% - 2rem)' : undefined,
                        maxHeight: window.innerWidth <= 640 ? 'calc(100% - 6rem)' : undefined,
                        overflowY: 'auto'
                    }}
                >
                    <div className="flex justify-between items-center mb-2">
                        <div className="bg-gray-100 dark:bg-gray-700 p-2 rounded-md">
                            <p className="text-sm text-gray-700 dark:text-gray-300 font-semibold">
                                Security tip: Use generated passwords to keep your accounts safe.
                            </p>
                        </div>
                        <button
                            onClick={() => setIsPasswordGeneratorOpen(false)}
                            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200"
                        >
                            <XMarkIcon className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="flex flex-col mb-2">
                        <label className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                checked={includeLowercase}
                                onChange={() => setIncludeLowercase(!includeLowercase)}
                                className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            />
                            Include lowercase letters (a-z)
                        </label>
                        <label className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                checked={includeUppercase}
                                onChange={() => setIncludeUppercase(!includeUppercase)}
                                className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            />
                            Include uppercase letters (A-Z)
                        </label>
                        <label className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                checked={includeNumbers}
                                onChange={() => setIncludeNumbers(!includeNumbers)}
                                className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            />
                            Include numbers (0-9)
                        </label>
                        <label className="flex items-center mb-4">
                            <input
                                type="checkbox"
                                checked={includeSymbols}
                                onChange={() => setIncludeSymbols(!includeSymbols)}
                                className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            />
                            Include symbols (!#$%^&*()_+)
                        </label>
                    </div>

                    <div className="relative mb-4">
                        <input
                            type="text"
                            value={generatedPassword}
                            readOnly
                            className="block w-full py-2 px-3 border border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm text-gray-900 dark:text-gray-100 font-mono"
                        />
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                            <button
                                onClick={copyToClipboard}
                                className="p-1 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                <ClipboardIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                            </button>
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <button
                            onClick={generatePassword}
                            className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            <ArrowPathIcon className="h-4 w-4 mr-1" />
                            Regenerate
                        </button>
                        <button
                            onClick={copyToClipboard}
                            className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            <ClipboardIcon className="h-4 w-4 mr-1" />
                            Copy
                        </button>
                    </div>
                </div>
            )}

            {/* Item Selection Modal */}
            <AddItemModal
                isOpen={isItemModalOpen}
                onClose={() => setIsItemModalOpen(false)}
                onSelect={handleItemSelect}
            />

            {/* Password Modal */}
            <PasswordModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSuccess={handleSuccess}
            />

            {/* Notes Modal */}
            <NotesModal
                isOpen={isNotesModalOpen}
                onClose={() => setIsNotesModalOpen(false)}
                onSuccess={async () => {
                    setIsNotesModalOpen(false);
                    await refreshEntries();
                    toast.success('Note added successfully');
                }}
            />

            {/* Personal Details Modal */}
            <PersonalDetailsModal
                isOpen={isPersonalDetailsModalOpen}
                onClose={() => setIsPersonalDetailsModalOpen(false)}
                onSuccess={async () => {
                    setIsPersonalDetailsModalOpen(false);
                    await refreshEntries();
                    toast.success('Personal details added successfully');
                }}
            />

            {/* Card Details Modal */}
            <CardDetailsModal
                isOpen={isCardDetailsModalOpen}
                onClose={() => setIsCardDetailsModalOpen(false)}
                onSuccess={async () => {
                    setIsCardDetailsModalOpen(false);
                    await refreshEntries();
                    toast.success('Card details added successfully');
                }}
            />

            {/* Bank Details Modal */}
            <BankDetailsModal
                isOpen={isBankDetailsModalOpen}
                onClose={() => setIsBankDetailsModalOpen(false)}
                onSuccess={async () => {
                    setIsBankDetailsModalOpen(false);
                    await refreshEntries();
                    toast.success('Bank details added successfully');
                }}
            />
        </>
    );
};

export default VaultHeader;
