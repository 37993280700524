import { store } from '../store';
import { encryptData, decryptData } from '../utils/encryption';
import { secureStore } from '../utils/secureStore';
import { PersonalDetail, CreatePersonalDetailDto, UpdatePersonalDetailDto } from '../types/personalDetails';
import { config } from '../extension/config';

const API_URL = config.API_URL;

export class PersonalDetailsService {
    private static authToken: string | null = null;

    private static async request<T>(
        endpoint: string,
        method: string = 'GET',
        body?: any
    ): Promise<T> {
        const token = this.authToken || store.getState().auth.token;
        
        if (!token) {
            throw new Error('No authentication token found');
        }

        try {
            const response = await fetch(`${API_URL}/personal-details${endpoint}`, {
                method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: body ? JSON.stringify(body) : undefined,
            });

            const data = await response.json();

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Session expired. Please login again.');
                }
                throw new Error(data.message || 'An error occurred');
            }

            return data;
        } catch (error) {
            console.error('API request failed:', error);
            throw error;
        }
    }

    private static getMasterKey(): string {
        try {
            return secureStore.getVaultKey();
        } catch (error) {
            throw new Error('Vault key not found. Please login again.');
        }
    }

    static async getAllPersonalDetails(): Promise<PersonalDetail[]> {
        try {
            const details = await this.request<PersonalDetail[]>('');
            return details.map(detail => this.decryptPersonalDetail(detail));
        } catch (error) {
            console.error('Failed to fetch personal details:', error);
            throw error;
        }
    }

    static async createPersonalDetail(detail: CreatePersonalDetailDto): Promise<PersonalDetail> {
        try {
            const encryptedDetail = this.encryptPersonalDetail(detail);
            const response = await this.request<PersonalDetail>('', 'POST', encryptedDetail);
            return this.decryptPersonalDetail(response);
        } catch (error) {
            console.error('Create personal detail error:', error);
            throw error;
        }
    }

    private static decryptBufferData(encryptedBuffer: any, isShared: boolean = false, sharedKey?: string): string {
        if (!encryptedBuffer) return '';

        try {
            if (encryptedBuffer.data && Array.isArray(encryptedBuffer.data)) {
                const uint8Array = new Uint8Array(encryptedBuffer.data);
                const encryptedString = new TextDecoder().decode(uint8Array);
                return isShared && sharedKey ? decryptData(encryptedString, sharedKey) : decryptData(encryptedString, this.getMasterKey());
            }

            if (typeof encryptedBuffer === 'string') {
                return isShared && sharedKey ? decryptData(encryptedBuffer, sharedKey) : decryptData(encryptedBuffer, this.getMasterKey());
            }

            return '';
        } catch (error) {
            console.error('Error decrypting buffer:', error);
            return '';
        }
    }

    static async updatePersonalDetail(id: string, detail: UpdatePersonalDetailDto): Promise<PersonalDetail> {
        try {
            console.log("sharedKey", detail.sharedKey);
            const encryptedDetail = this.encryptPersonalDetail(detail, detail.sharedKey);
            const response = await this.request<PersonalDetail>(`/${id}`, 'PUT', encryptedDetail);
            return this.decryptPersonalDetail(response);
        } catch (error) {
            console.error('Update personal detail error:', error);
            throw error;
        }
    }

    static async deletePersonalDetail(id: string): Promise<void> {
        await this.request(`/${id}`, 'DELETE');
    }

    static async getPersonalDetailById(id: string, isShared: boolean = false, sharedKey?: string): Promise<PersonalDetail> {
        const response = await this.request<PersonalDetail>(`/${id}`);
        
        if (isShared && sharedKey) {
            return this.decryptPersonalDetail(response, isShared, sharedKey);
        }
        
        return this.decryptPersonalDetail(response);
    }

    private static encryptPersonalDetail(detail: Partial<UpdatePersonalDetailDto>, sharedKey?: string): any {
        const masterKey = sharedKey || this.getMasterKey();
        
        const encryptField = (value?: string) => value ? encryptData(value, masterKey) : null;

        return {
            title: detail.title,
            name: detail.name,
            encrypted_first_name: encryptField(detail.encrypted_first_name),
            encrypted_middle_name: encryptField(detail.encrypted_middle_name),
            encrypted_last_name: encryptField(detail.encrypted_last_name),
            encrypted_username: encryptField(detail.encrypted_username),
            gender: detail.gender,
            birthday: detail.birthday,
            encrypted_email_address: encryptField(detail.encrypted_email_address),
            encrypted_phone: encryptField(detail.encrypted_phone),
            encrypted_address_1: encryptField(detail.encrypted_address_1),
            encrypted_address_2: encryptField(detail.encrypted_address_2),
            encrypted_city_town: encryptField(detail.encrypted_city_town),
            encrypted_state: encryptField(detail.encrypted_state),
            encrypted_zip_postal_code: encryptField(detail.encrypted_zip_postal_code),
            encrypted_country: encryptField(detail.encrypted_country),
            encrypted_timezone: encryptField(detail.encrypted_timezone),
            encrypted_notes: encryptField(detail.encrypted_notes),
            category_id: detail.category_id,
            favourite: detail.favourite,
            isSharedUpdate: detail.isSharedUpdate || false,
            itemType: detail.itemType || 'personal'

        };
    }

    private static decryptPersonalDetail(detail: PersonalDetail, isShared: boolean = false, sharedKey?: string): PersonalDetail {
        const decryptField = (value?: string) => {
            if (!value) return '';
            return this.decryptBufferData(value, isShared, sharedKey);
        };

        return {
            ...detail,
            encrypted_first_name: decryptField(detail.encrypted_first_name),
            encrypted_middle_name: decryptField(detail.encrypted_middle_name),
            encrypted_last_name: decryptField(detail.encrypted_last_name),
            encrypted_username: decryptField(detail.encrypted_username),
            encrypted_email_address: decryptField(detail.encrypted_email_address),
            encrypted_phone: decryptField(detail.encrypted_phone),
            encrypted_address_1: decryptField(detail.encrypted_address_1),
            encrypted_address_2: decryptField(detail.encrypted_address_2),
            encrypted_city_town: decryptField(detail.encrypted_city_town),
            encrypted_state: decryptField(detail.encrypted_state),
            encrypted_zip_postal_code: decryptField(detail.encrypted_zip_postal_code),
            encrypted_country: decryptField(detail.encrypted_country),
            encrypted_timezone: decryptField(detail.encrypted_timezone),
            encrypted_notes: decryptField(detail.encrypted_notes),
            category_id: detail.category_id,
            favourite: detail.favourite
        };
    }
}

export default PersonalDetailsService; 