import React, { useEffect, useState } from 'react';
import { ICreatePasswordEntry, IDecryptedPasswordEntry } from '../../types/vault.types';
import Input from '../common/Input';
import Button from '../common/Button';
import { generateStrongPassword } from '../../utils/passwordGenerator';
import { useCategories } from '../../contexts/CategoryContext';
import PasswordStrengthMeter from '../common/PasswordStrengthMeter';
import { toast } from 'react-hot-toast';

interface PasswordFormProps {
    initialData?: IDecryptedPasswordEntry;
    onSubmit: (data: ICreatePasswordEntry) => Promise<void>;
    onCancel: () => void;
    isLoading?: boolean;
}

interface FormErrors {
    title?: string;
    username?: string;
    password?: string;
    website_url?: string;
}

const PasswordForm: React.FC<PasswordFormProps> = ({
    initialData = null,
    onSubmit,
    onCancel,
    isLoading = false
}) => {
    const [formData, setFormData] = useState<ICreatePasswordEntry>({
        title: initialData?.title || '',
        username: initialData?.username || '',
        password: initialData?.password || '',
        notes: initialData?.notes || '',
        website_url: initialData?.website_url || '',
        category_id: initialData?.category_id || '',
        favourite: initialData?.favourite || false,
        auto_login: initialData?.auto_login || false,
        favicon_url: initialData?.favicon_url || ''
    });

    const { categories, refreshCategories, createCategory } = useCategories();

    const [errors, setErrors] = useState<FormErrors>({});
    const [showPassword, setShowPassword] = useState(false);
    const [touched, setTouched] = useState<Record<string, boolean>>({});
    const [autoLogin, setAutoLogin] = useState(initialData?.auto_login || false);
    const [isCreatingCategory, setIsCreatingCategory] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [creatingCategoryLoading, setCreatingCategoryLoading] = useState(false);

    const validateForm = (): boolean => {
        const newErrors: FormErrors = {};

        // Title validation
        if (!formData.title.trim()) {
            newErrors.title = 'Title is required';
        } else if (formData.title.length < 3) {
            newErrors.title = 'Title must be at least 3 characters';
        }

        // Username validation
        if (!formData.username.trim()) {
            newErrors.username = 'Username is required';
        }

        // Password validation
        if (!formData.password) {
            newErrors.password = 'Password is required';
        }
        // Website URL validation (if provided)
        if (
            formData.website_url &&
            !formData.website_url.match(/^(https?:\/\/)?((localhost(:\d+)?)|([\da-z.-]+\.[a-z.]{2,6}))([\/\w .-]*)*\/?$/)
        ) {
            newErrors.website_url = 'Please enter a valid URL';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target;
        const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;

        setFormData(prev => ({
            ...prev,
            [name]: newValue
        }));

        // Clear error when user starts typing
        if (errors[name as keyof FormErrors]) {
            setErrors(prev => ({
                ...prev,
                [name]: undefined
            }));
        }
    };

    const handleBlur = (fieldName: string) => {
        setTouched(prev => ({
            ...prev,
            [fieldName]: true
        }));
        validateForm();
    };

    const handleGeneratePassword = () => {
        const newPassword = generateStrongPassword();
        setFormData(prev => ({ ...prev, password: newPassword }));
        setErrors(prev => ({
            ...prev,
            password: undefined
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (validateForm()) {
            await onSubmit({
                ...formData,
                auto_login: autoLogin
            });
        }
    };

    const handleCreateCategory = async () => {
        if (!newCategoryName.trim()) return;

        setCreatingCategoryLoading(true);
        try {
            await createCategory({ name: newCategoryName });
            await refreshCategories();

            // Find the newly created category and select it
            const newCategory = categories.find(cat => cat.name === newCategoryName);
            if (newCategory) {
                setFormData(prev => ({
                    ...prev,
                    category_id: newCategory.id
                }));
            }

            // Reset the new category UI state
            setNewCategoryName('');
            setIsCreatingCategory(false);
        } catch (error) {
            console.error('Error creating category:', error);
            toast.error('Failed to create category');
        } finally {
            setCreatingCategoryLoading(false);
        }
    };

    useEffect(() => {
        refreshCategories();
    }, []);

    return (
        <form onSubmit={handleSubmit} className="p-4">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                {/* Left sidebar - Basic Info (3 columns on medium+ screens) */}
                <div className="md:col-span-3">
                    <div className="space-y-4">
                        {/* Title field */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Title
                            </label>
                            <input
                                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                onBlur={() => handleBlur('title')}
                                className={`mt-1 block w-full rounded-md shadow-sm ${touched.title && errors.title
                                        ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                        : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                    }`}
                required
                placeholder="e.g., Gmail Account"
            />
                            {touched.title && errors.title && (
                                <p className="mt-1 text-sm text-red-600">{errors.title}</p>
                            )}
                        </div>

                        {/* Vault selection with create feature */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Vault
                            </label>
                            {!isCreatingCategory ? (
                                <select
                                    name="category_id"
                                    value={formData.category_id}
                                    onChange={(e) => {
                                        if (e.target.value === "create_new") {
                                            setIsCreatingCategory(true);
                                        } else {
                                            handleChange(e);
                                        }
                                    }}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                >
                                    <option value="">Select Vault</option>
                                    {categories.map(category => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                    <option value="create_new">Create new Vault</option>
                                </select>
                            ) : (
                                <div className="mt-1 space-x-2">
                                    <input
                                        type="text"
                                        value={newCategoryName}
                                        onChange={(e) => setNewCategoryName(e.target.value)}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Vault name"
                                        autoFocus
                                    />
                                    <div className="flex items-center justify-between mt-4">
                                        <button
                                            type="button"
                                            onClick={handleCreateCategory}
                                            disabled={creatingCategoryLoading || !newCategoryName.trim()}
                                            className={`px-3 py-2 rounded-md text-sm font-medium ${creatingCategoryLoading || !newCategoryName.trim()
                                                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                    : 'bg-blue-600 text-white hover:bg-blue-700'
                                                }`}
                                        >
                                            {creatingCategoryLoading ? 'Creating...' : 'Create'}
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setIsCreatingCategory(false);
                                                setNewCategoryName('');
                                            }}
                                            className="px-3 py-2 rounded-md text-sm font-medium bg-gray-200 text-gray-700 hover:bg-gray-300"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    
                                </div>
                            )}
                        </div>

                        {/* Add to favorites checkbox */}
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="favourite"
                                id="favourite"
                                checked={formData.favourite}
                                onChange={handleChange}
                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            />
                            <label htmlFor="favourite" className="ml-2 block text-sm text-gray-900">
                                Add to favorites
                            </label>
                        </div>

                        {/* Auto Login checkbox */}
                        <div className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                id="autoLogin"
                                checked={autoLogin}
                                onChange={(e) => setAutoLogin(e.target.checked)}
                                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                            />
                            <label
                                htmlFor="autoLogin"
                                className="text-sm text-gray-600 flex items-center"
                            >
                                <span>Enable Auto Login</span>
                                <button
                                    type="button"
                                    className="ml-1 text-gray-400 hover:text-gray-500"
                                    title="Automatically log in to this website when visiting"
                                >
                                    <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </button>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Main content area - Account details (9 columns on medium+ screens) */}
                <div className="md:col-span-9">
                    <div className="space-y-6">
                        {/* Account Information section */}
                        <div className="bg-gray-50 p-4 rounded-lg">
                            <h4 className="text-sm font-medium text-gray-900 mb-4">Account Information</h4>
                            <div className="space-y-4">
                                {/* Username field */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Username / Email
                                    </label>
                                    <input
                                        type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                onBlur={() => handleBlur('username')}
                                        className={`mt-1 block w-full rounded-md shadow-sm ${touched.username && errors.username
                                                ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                                : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                            }`}
                required
                placeholder="username@example.com"
            />
                                    {touched.username && errors.username && (
                                        <p className="mt-1 text-sm text-red-600">{errors.username}</p>
                                    )}
                                </div>

                                {/* Password field with generator */}
            <div>
                <div className="flex justify-between items-center mb-1">
                    <label className="block text-sm font-medium text-gray-700">
                        Password
                    </label>
                    <button
                        type="button"
                        onClick={handleGeneratePassword}
                        className="text-sm text-blue-600 hover:text-blue-500"
                    >
                        Generate Password
                    </button>
                </div>
                <Input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={() => handleBlur('password')}
                    error={touched.password ? errors.password : undefined}
                    required
                    showPasswordToggle
                />
                                <PasswordStrengthMeter
                                    password={formData.password}
                                    email={formData.username}
                                />
            </div>

                                {/* Website URL field */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Website URL
                                    </label>
                                    <input
                                        type="text"
                name="website_url"
                value={formData.website_url}
                onChange={handleChange}
                onBlur={() => handleBlur('website_url')}
                                        className={`mt-1 block w-full rounded-md shadow-sm ${touched.website_url && errors.website_url
                                                ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                                                : 'border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                            }`}
                placeholder="https://example.com"
                                    />
                                    {touched.website_url && errors.website_url && (
                                        <p className="mt-1 text-sm text-red-600">{errors.website_url}</p>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Additional Information section */}
                        <div className="bg-gray-50 p-4 rounded-lg">
                            <h4 className="text-sm font-medium text-gray-900 mb-4">Additional Information</h4>
            <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    Notes
                </label>
                <textarea
                    name="notes"
                    rows={3}
                    value={formData.notes}
                    onChange={handleChange}
                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
            </div>
                        </div>
            </div>
            </div>
            </div>

            {/* Footer with action buttons */}
            <div className="mt-5 flex justify-end space-x-3">
                <Button type="button" variant="secondary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button type="submit" loading={isLoading}>
                    {initialData ? 'Update' : 'Save'} Password
                </Button>
            </div>
        </form>
    );
};

export default PasswordForm;