import React from 'react';
import { RolesService } from '../../services/roles.service';
import { IRole } from '../../types/roles.types';
import { PencilIcon, TrashIcon, ShieldCheckIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';
import RoleModal from './RoleModal';
import { useVault } from '../../contexts/VaultContext';

interface RoleListProps {
    setSelectedRole: (role: IRole | null) => void;
    setIsModalOpen: (isOpen: boolean) => void;
    roles: IRole[];
    loading: boolean;
    error: string | null;
}

const RoleList: React.FC<RoleListProps> = ({ setSelectedRole, setIsModalOpen, roles, loading, error }) => {
    const { searchQuery } = useVault();

    const filteredRoles = roles.filter((role: IRole) => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        return (
            role.name?.toLowerCase().includes(searchLower) || 
            role.description?.toLowerCase().includes(searchLower) ||
            Object.values(role.permissions).some(permission => 
                Object.keys(permission).some(key => 
                    key.toLowerCase().includes(searchLower)
                )
            )
        );
    });

    const handleEdit = (role: IRole) => {
        setSelectedRole(role);
        setIsModalOpen(true);
    };

    const handleDelete = async (id: number) => {
        if (window.confirm('Are you sure you want to delete this role?')) {
            try {
                await RolesService.deleteRole(id);
                toast.success('Role deleted successfully');
            } catch (err) {
                // Handle error and show a toast notification
                const errorMessage = (err as any).response?.data?.message || 'Failed to delete role';
                toast.error(errorMessage);
            }
        }
    };

    if (loading) {
        return <div className="flex justify-center items-center p-8"><div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div></div>;
    }

    if (error) {
        return <div className="bg-red-50 p-4 rounded-md"><p className="text-red-700">Error loading roles: {error}</p></div>;
    }

    if (roles.length === 0) {
        return <div className="text-center py-8"><h3 className="mt-2 text-sm font-medium text-gray-900">No roles available</h3></div>;
    }

    

    // Format permission name for display
    const formatPermissionName = (permissionKey: string) => {
        return permissionKey.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-3">
            {/* Desktop view - Traditional table */}
            <div className="hidden md:block">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Role
                            </th>
                            
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Permissions
                            </th>
                            <th scope="col" className="relative px-6 py-3">
                                <span className="sr-only">Actions</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {filteredRoles.filter(role => role.id !== 1).map(role => (
                            <tr key={role.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 h-10 w-10 bg-gray-100 rounded-full flex items-center justify-center">
                                            {role.name.toLowerCase().includes('admin') ? (
                                                <ShieldCheckIcon className="h-6 w-6 text-purple-600" />
                                            ) : (
                                                <UserGroupIcon className="h-6 w-6 text-blue-600" />
                                            )}
                                        </div>
                                        <div className="ml-4">
                                            <div className="text-sm font-medium text-gray-900">{role.name}</div>
                                            <div className="text-xs text-gray-500 max-w-xs truncate">{role.description}</div>
                                        </div>
                                    </div>
                                </td>
                               
                                <td className="px-6 py-4">
                                    <div className="flex flex-wrap gap-1">
                                        {role.id === 1 ? (
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                All Permissions
                                            </span>
                                        ) : (
                                            Object.entries(role.permissions)
                                                .filter(([_, value]) => value === true)
                                                .map(([key]) => (
                                                    <span 
                                                        key={key} 
                                                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 me-2"
                                                    >
                                                        {formatPermissionName(key)}
                                                    </span>
                                                ))
                                        )}
                                        {role.id !== 1 && Object.values(role.permissions).filter(v => v).length === 0 && (
                                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                                No Permissions
                                            </span>
                                        )}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <div className="flex items-center justify-end space-x-3">
                                        <button
                                            onClick={() => handleEdit(role)}
                                            className="text-blue-600 hover:text-blue-900"
                                            title="Edit role"
                                        >
                                            <PencilIcon className="h-5 w-5" />
                                        </button>
                                        {role.id !== 1 && (
                                            <button
                                                onClick={() => handleDelete(role.id)}
                                                className="text-red-600 hover:text-red-900"
                                                title="Delete role"
                                            >
                                                <TrashIcon className="h-5 w-5" />
                                            </button>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Mobile view - Card-based layout */}
            <div className="block md:hidden">
                <ul className="divide-y divide-gray-200">
                    {filteredRoles.filter(role => role.id !== 1).map(role => (
                        <li key={role.id} className="py-4 px-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0 h-12 w-12 bg-gray-100 rounded-full flex items-center justify-center">
                                        {role.name.toLowerCase().includes('admin') ? (
                                            <ShieldCheckIcon className="h-7 w-7 text-purple-600" />
                                        ) : (
                                            <UserGroupIcon className="h-7 w-7 text-blue-600" />
                                        )}
                                    </div>
                                    <div className="ml-3">
                                        <div className="flex items-center">
                                            <p className="text-sm font-medium text-gray-900">{role.name}</p>
                                        </div>
                                        <p className="text-xs text-gray-500 max-w-xs truncate">{role.description}</p>
                                    </div>
                                </div>
                                <div className="flex space-x-2">
                                    <button
                                        onClick={() => handleEdit(role)}
                                        className="p-2 text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-50"
                                    >
                                        <PencilIcon className="h-5 w-5" />
                                    </button>
                                    {role.id !== 1 && (
                                        <button
                                            onClick={() => handleDelete(role.id)}
                                            className="p-2 text-red-600 hover:text-red-800 rounded-full hover:bg-red-50"
                                        >
                                            <TrashIcon className="h-5 w-5" />
                                        </button>
                                    )}
                                </div>
                            </div>
                            
                            {/* Permissions section */}
                            <div className="mt-3 ml-12">
                                <p className="text-xs font-medium text-gray-500 uppercase mb-1">Permissions</p>
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {role.id === 1 ? (
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                            All Permissions
                                        </span>
                                    ) : (
                                        Object.entries(role.permissions)
                                            .filter(([_, value]) => value === true)
                                            .map(([key]) => (
                                                <span 
                                                    key={key} 
                                                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 me-2"
                                                >
                                                    {formatPermissionName(key)}
                                                </span>
                                            ))
                                    )}
                                    {role.id !== 1 && Object.values(role.permissions).filter(v => v).length === 0 && (
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                            No Permissions
                                        </span>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default RoleList; 