import { store } from '../store';
import { encryptData, decryptData } from '../utils/encryption';
import { secureStore } from '../utils/secureStore';
import { Note, CreateNoteDto, UpdateNoteDto } from '../types/notes';
import { config } from '../extension/config';

const API_URL = config.API_URL;

export class NotesService {
    private static authToken: string | null = null;

    public static setAuthToken(token: string) {
        this.authToken = token;
    }

    private static async request<T>(
        endpoint: string,
        method: string = 'GET',
        body?: any
    ): Promise<T> {
        const token = this.authToken || store.getState().auth.token;
        
        if (!token) {
            throw new Error('No authentication token found');
        }

        try {
            const response = await fetch(`${API_URL}/notes${endpoint}`, {
                method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: body ? JSON.stringify(body) : undefined,
            });
            

            const data = await response.json();

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Session expired. Please login again.');
                }
                throw new Error(data.message || 'An error occurred');
            }

            return data;
        } catch (error) {
            console.error('API request failed:', error);
            throw error;
        }
    }

    private static getMasterKey(): string {
        try {
            return secureStore.getVaultKey();
        } catch (error) {
            throw new Error('Vault key not found. Please login again.');
        }
    }

    static async getAllNotes(): Promise<Note[]> {
        try {
            const notes = await this.request<Note[]>('');
            return notes.map(note => {
                try {
                    const encryptedNotesString = this.decryptBufferData(note.encrypted_notes);
                    const decryptedNotes = this.decryptNote(encryptedNotesString);
                    return {
                        ...note,
                        encrypted_notes: decryptedNotes,
                        category_id: note.category_id || '',
                        favourite: note.favourite || false
                    };
                } catch (error) {
                    console.error(`Failed to decrypt note ${note.id}:`, error);
                    return note;
                }
            });
        } catch (error) {
            console.error('Failed to fetch notes:', error);
            throw error;
        }
    }

    private static decryptBufferData(encryptedBuffer: any, isShared: boolean = false, sharedKey?: string): string {
        if (!encryptedBuffer) return '';

        try {
            if (encryptedBuffer.data && Array.isArray(encryptedBuffer.data)) {
                const uint8Array = new Uint8Array(encryptedBuffer.data);
                return new TextDecoder().decode(uint8Array);
            }

            if (typeof encryptedBuffer === 'string') {
                return isShared && sharedKey ? decryptData(encryptedBuffer, sharedKey) : decryptData(encryptedBuffer, this.getMasterKey());
            }

            return '';
        } catch (error) {
            console.error('Error decrypting buffer:', error);
            return '';
        }
    }

    static async createNote(note: CreateNoteDto): Promise<Note> {
        try {
            const encryptedNotes = this.encryptNote(note.encrypted_notes);
            const response = await this.request<Note>('', 'POST', {
                ...note,
                encrypted_notes: encryptedNotes
            });
            console.log('Response:', response);
            return {
                ...response,
                encrypted_notes: note.encrypted_notes // Return the original unencrypted notes
            };
        } catch (error) {
            console.error('Create note error:', error);
            throw error;
        }
    }

    static async updateNote(id: string, note: UpdateNoteDto): Promise<Note> {
        try {
            console.log("note", note);
            
            console.log("sharedKey", note.sharedKey);
            const encryptedNotes = this.encryptNote(note.encrypted_notes, note.sharedKey);
            const response = await this.request<Note>(`/${id}`, 'PUT', {
                ...note,
                encrypted_notes: encryptedNotes,
                itemType: note.itemType || 'note'
            });
            
            return {
                ...response,
                encrypted_notes: note.encrypted_notes // Return the original unencrypted notes
            };
        } catch (error) {
            console.error('Update note error:', error);
            throw error;
        }
    }

    static async deleteNote(id: string): Promise<void> {
        await this.request(`/${id}`, 'DELETE');
    }

    static async getNoteById(id: string, isShared: boolean = false, sharedKey?: string): Promise<Note> {
        const response = await this.request<Note>(`/${id}`);
        let decryptedNotes = '';
        
        if(isShared && sharedKey) {
            const encryptedNotesString = await this.decryptBufferData(response.encrypted_notes, isShared, sharedKey);
            console.log("encryptedNotesString", encryptedNotesString)
            decryptedNotes = await this.decryptNote(encryptedNotesString, isShared, sharedKey);
            console.log("decryptedNotes", decryptedNotes)
        } else {
            const encryptedNotesString = await this.decryptBufferData(response.encrypted_notes);
            decryptedNotes = await this.decryptNote(encryptedNotesString);
        }
        
        return {
            ...response,
            encrypted_notes: decryptedNotes
        };
    }

    private static encryptNote(notes: string, sharedKey?: string): string {
        if (!notes) return '';
        console.log("note sharedKey", sharedKey);
        const masterKey = sharedKey || this.getMasterKey();
        return encryptData(notes, masterKey);
    }

    private static decryptNote(encryptedNotes: string, isShared: boolean = false, sharedKey?: string): string {
        if (!encryptedNotes) return '';
        try {
            const masterKey = this.getMasterKey();
            return isShared && sharedKey ? decryptData(encryptedNotes, sharedKey) : decryptData(encryptedNotes, masterKey);
        } catch (error) {
            console.error('Failed to decrypt note:', error);
            return '(Decryption failed)';
        }
    }
}

export default NotesService; 