import React, { useEffect, useState } from 'react';
import Modal from '../common/Modal';
import Input from '../common/Input';
import Button from '../common/Button';
import { RolesService } from '../../services/roles.service';
import { Role } from '../../types/team.types';

interface InviteMemberModalProps {
  teamId: string;
  onClose: () => void;
  onInvite: (email: string, roleId: number, teamId: string) => void;
}

const InviteMemberModal: React.FC<InviteMemberModalProps> = ({ teamId, onClose, onInvite }) => {
  const [email, setEmail] = useState('');
  const [roleId, setRoleId] = useState(0); // Default to team member
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const response = await RolesService.getRoles();
      setRoles(response);
    };
    fetchRoles();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email.trim()) {
      setError('Email is required');
      return;
    }
    
    // Basic email validation
    if (!email.includes('@') || !email.includes('.')) {
      setError('Please enter a valid email address');
      return;
    }
    
    try {
      setLoading(true);
      setError('');
      
      onInvite(email, roleId, teamId);
    } catch (error: any) {
      console.error('Error inviting user:', error);
      setError(error.response?.data?.message || 'Failed to send invitation');
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Invite Team Member"
      onClose={onClose}
      size="md"
    >
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <Input
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            required
            autoFocus
          />
          
          <div>
            <label htmlFor="role" className="block text-sm font-medium text-gray-700">
              Role
            </label>
            <select
              id="role"
              name="role"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              value={roleId}
              onChange={(e) => setRoleId(Number(e.target.value))}
            >
              <option value={0}>Select Role</option>
              {roles.filter(role => role.id !== 1).map(role => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
          
          {error && (
            <div className="text-sm text-red-600">
              {error}
            </div>
          )}
        </div>
        
        <div className="mt-6 flex justify-end space-x-3">
          <Button
            type="button"
            variant="secondary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            loading={loading}
          >
            Send Invitation
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default InviteMemberModal;