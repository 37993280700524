import React, { useState, useEffect } from 'react';
import Modal from '../common/Modal';
import Input from '../common/Input';
import Button from '../common/Button';
import MultiSelect from '../common/MultiSelect';
import { toast } from 'react-hot-toast';
import { ShareService } from '../../services/share.service';
import { ItemTitle, SelectedItem, GroupedItems } from '../../types/share.types';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '../../hooks/useRedux';
interface ShareItemsModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess?: () => void;
}

const ShareItemsModal: React.FC<ShareItemsModalProps> = ({
    isOpen,
    onClose,
    onSuccess
}) => {
    const { team_id } = useAppSelector((state) => state.auth);
    const [shareType, setShareType] = useState<'individual' | 'team'>('individual');
    const [email, setEmail] = useState('');
    const [permissionLevel, setPermissionLevel] = useState<'read' | 'write' | 'manage'>('read');
    const [expiresIn, setExpiresIn] = useState('never');
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState<GroupedItems>({
        passwords: [],
        notes: [],
        cards: [],
        banks: [],
        personal: [],
        categories: []
    });
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [isLoadingItems, setIsLoadingItems] = useState(false);
    const [selectedItemTypes, setSelectedItemTypes] = useState({
        passwords: true,
        notes: true,
        cards: true,
        banks: true,
        personal: true,
        categories: true
    });

    // Fetch all items when modal opens
    useEffect(() => {
        if (isOpen) {
            fetchItems();
        }
    }, [isOpen]);

    const fetchItems = async () => {
        setIsLoadingItems(true);
        try {
            const data = await ShareService.getAllItemTitles();
            console.log("all data", data);
            setItems(data);
        } catch (error) {
            toast.error('Failed to fetch items');
            console.error('Error fetching items:', error);
        } finally {
            setIsLoadingItems(false);
        }
    };

    const handleItemSelect = (item: ItemTitle) => {
        // Check if item is already selected
        if (!selectedItems.some(selected => selected.id === item.id && selected.type === item.type)) {
            setSelectedItems([...selectedItems, { ...item, selected: true }]);
        }
    };

    const handleItemRemove = (item: ItemTitle) => {
        setSelectedItems(selectedItems.filter(
            selected => !(selected.id === item.id && selected.type === item.type)
        ));
    };

    const handleItemTypeToggle = (type: keyof typeof selectedItemTypes) => {
        setSelectedItemTypes(prev => ({
            ...prev,
            [type]: !prev[type]
        }));
    };

    const getFilteredItems = (): GroupedItems => {
        const filteredItems: GroupedItems = {
            passwords: [],
            notes: [],
            cards: [],
            banks: [],
            personal: [],
            categories: []
        };

        if (selectedItemTypes.passwords) filteredItems.passwords = items.passwords;
        if (selectedItemTypes.notes) filteredItems.notes = items.notes;
        if (selectedItemTypes.cards) filteredItems.cards = items.cards;
        if (selectedItemTypes.banks) filteredItems.banks = items.banks;
        if (selectedItemTypes.personal) filteredItems.personal = items.personal;
        if (selectedItemTypes.categories) filteredItems.categories = items.categories;

        return filteredItems;
    };

    const handleShare = async (e: React.FormEvent) => {
        e.preventDefault();

        if (selectedItems.length === 0) {
            toast.error('Please select at least one item to share');
            return;
        }

        if (shareType === 'individual' && !email) {
            toast.error('Please enter an email address');
            return;
        }
        console.log('team_id', team_id);

        setIsLoading(true);

        try {
            const expiresAt = expiresIn === 'never'
                ? undefined
                : new Date(Date.now() + parseInt(expiresIn) * 24 * 60 * 60 * 1000);

            const itemIds = selectedItems.map(item => ({
                id: item.id,
                type: item.type
            }));

            const response = await ShareService.shareItems({
                itemIds,
                sharedWithEmail: email,
                shareType,
                permissionLevel,
                expiresAt,
                sharedKey: '',
                teamId: team_id || ''
            });
            console.log('response', response);
            if(response[0].success) {
                toast.success('Items shared successfully');
                onSuccess?.();
                onClose();
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to share items';
            toast.error(errorMessage);
            console.error('Share error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={`fixed inset-0 z-50 overflow-hidden ${isOpen ? 'block' : 'hidden'}`}>
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className="relative bg-white rounded-lg w-full max-w-4xl max-h-[90vh] flex flex-col shadow-lg overflow-hidden">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h3 className="text-lg font-medium">
                            Share Items
                        </h3>
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>
                    <form onSubmit={handleShare} className="flex-1 overflow-auto p-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Left Column */}
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Share with
                                    </label>
                                    <div className="flex space-x-4">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                className="form-radio"
                                                value="individual"
                                                checked={shareType === 'individual'}
                                                onChange={() => setShareType('individual')}
                                            />
                                            <span className="ml-2">Individual</span>
                                        </label>
                                        <label className="inline-flex items-center">
                                            <input
                                                type="radio"
                                                className="form-radio"
                                                value="team"
                                                checked={shareType === 'team'}
                                                onChange={() => setShareType('team')}
                                            />
                                            <span className="ml-2">Team</span>
                                        </label>
                                    </div>
                                </div>

                                {shareType === 'individual' && (
                                    <Input
                                        label="Recipient Email Address"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                )}

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Permission Level
                                    </label>
                                    <select
                                        value={permissionLevel}
                                        onChange={(e) => setPermissionLevel(e.target.value as 'read' | 'write' | 'manage')}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    >
                                        <option value="read">View Only</option>
                                        <option value="write">View & Edit</option>
                                    </select>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Expires In
                                    </label>
                                    <select
                                        value={expiresIn}
                                        onChange={(e) => setExpiresIn(e.target.value)}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    >
                                        <option value="never">Never</option>
                                        <option value="1">24 hours</option>
                                        <option value="7">7 days</option>
                                        <option value="30">30 days</option>
                                        <option value="90">90 days</option>
                                    </select>
                                </div>
                                
                                
                            </div>
                            {/* Right Column */}
                            
                            <div>
                            <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-2">
                                        Select Item Types to Share
                                    </label>
                                    <div className="grid grid-cols-2 gap-2 sm:grid-cols-3">
                                        <label className="flex items-center p-2 border rounded-md hover:bg-gray-50">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 text-blue-600 rounded"
                                                checked={selectedItemTypes.passwords}
                                                onChange={() => handleItemTypeToggle('passwords')}
                                            />
                                            <span className="ml-2 text-sm">Passwords</span>
                                        </label>
                                        {/* <label className="flex items-center p-2 border rounded-md hover:bg-gray-50">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 text-blue-600 rounded"
                                                checked={selectedItemTypes.notes}
                                                onChange={() => handleItemTypeToggle('notes')}
                                            />
                                            <span className="ml-2 text-sm">Notes</span>
                                        </label> */}
                                        <label className="flex items-center p-2 border rounded-md hover:bg-gray-50">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 text-blue-600 rounded"
                                                checked={selectedItemTypes.cards}
                                                onChange={() => handleItemTypeToggle('cards')}
                                            />
                                            <span className="ml-2 text-sm">Cards</span>
                                        </label>
                                        <label className="flex items-center p-2 border rounded-md hover:bg-gray-50">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 text-blue-600 rounded"
                                                checked={selectedItemTypes.banks}
                                                onChange={() => handleItemTypeToggle('banks')}
                                            />
                                            <span className="ml-2 text-sm">Banks</span>
                                        </label>
                                        <label className="flex items-center p-2 border rounded-md hover:bg-gray-50">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 text-blue-600 rounded"
                                                checked={selectedItemTypes.personal}
                                                onChange={() => handleItemTypeToggle('personal')}
                                            />
                                            <span className="ml-2 text-sm">Personal</span>
                                        </label>
                                        <label className="flex items-center p-2 border rounded-md hover:bg-gray-50">
                                            <input
                                                type="checkbox"
                                                className="h-4 w-4 text-blue-600 rounded"
                                                checked={selectedItemTypes.categories}
                                                onChange={() => handleItemTypeToggle('categories')}
                                            />
                                            <span className="ml-2 text-sm">Vault</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="mt-4"> 
                                <label className="block text-sm font-medium text-gray-700 mb-2">
                                    Select Items to Share
                                </label>
                                {isLoadingItems ? (
                                    <div className="flex justify-center items-center h-40">
                                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                    </div>
                                ) : (
                                    <MultiSelect
                                        items={getFilteredItems()}
                                        selectedItems={selectedItems}
                                        onItemSelect={handleItemSelect}
                                        onItemRemove={handleItemRemove}
                                    />
                                )}
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-end space-x-3 mt-6">
                            <Button type="button" variant="secondary" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button type="submit" loading={isLoading}>
                                Share
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ShareItemsModal; 