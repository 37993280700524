import { store } from '../store';
import { IPasswordEntry, IDecryptedPasswordEntry, ICreatePasswordEntry, IVaultResponse } from '../types/vault.types';
import { encryptData, decryptData } from '../utils/encryption';
import { config } from '../extension/config';
import { secureStore } from '../utils/secureStore';
import { NotesService } from '../services/notes.service';
import { CardDetailsService } from '../services/cardDetails.service';
import { BankDetailsService } from '../services/bankDetails.service';
import { PersonalDetailsService } from '../services/personalDetails.service';
import { Note } from '../types/notes';
import { CardDetail } from '../types/cardDetails';
import { BankDetail } from '../types/bankDetails';
import { PersonalDetail } from '../types/personalDetails';

const API_URL = config.API_URL;
const EXTENSION_ID = config.EXTENSION_ID;

export class VaultService {
    private baseUrl: string;
    private static authToken: string | null = null;

    constructor() {
        this.baseUrl = API_URL;
    }

    public static setAuthToken(token: string) {
        this.authToken = token;
    }

    private static async request<T>(
        endpoint: string,
        method: string = 'GET',
        body?: any
    ): Promise<T> {
        // Try to get token from class property first, then from Redux store
        const token = this.authToken || store.getState().auth.token;
        
        if (!token) {
            throw new Error('No authentication token found');
        }

        try {
            const response = await fetch(`${API_URL}/vault${endpoint}`, {
                method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: body ? JSON.stringify(body) : undefined,
            });

            const data = await response.json();

            if (!response.ok) {
                if (response.status === 401) {
                    throw new Error('Session expired. Please login again.');
                }
                throw new Error(data.message || 'An error occurred');
            }

            return data;
        } catch (error) {
            console.error('API request failed:', error);
            throw error;
        }
    }

    static async getAllEntries(): Promise<IDecryptedPasswordEntry[]> {
        const entries = await this.request<IPasswordEntry[]>('/entries');
        const decryptedEntries = this.decryptEntries(entries);
        
        // Save to extension storage
        if (chrome.runtime && EXTENSION_ID) {
            chrome.runtime.sendMessage(
                EXTENSION_ID,
                { 
                    type: 'SAVE_ENTRIES',
                    payload: decryptedEntries 
                },
                (response) => {
                    if (chrome.runtime.lastError) {
                        console.error('Failed to save entries to extension:', chrome.runtime.lastError);
                    } else {
                        console.log('Entries saved to extension storage');
                    }
                }
            );
        }
        
        return decryptedEntries;
    }

    static async getFavoriteEntries(): Promise<IDecryptedPasswordEntry[]> {
        const entries = await this.request<IPasswordEntry[]>('/entries/favorites');
        return this.decryptEntries(entries);
    }

    static async createEntry(entry: ICreatePasswordEntry): Promise<IDecryptedPasswordEntry> {
        try {
            const encryptedEntry = this.encryptEntry(entry);
            const response = await this.request<IPasswordEntry>('/entries', 'POST', encryptedEntry);
            const decryptedEntry = this.decryptEntry(response);
            
            // Refresh entries in extension storage
            const allEntries = await this.getAllEntries();
            
            return decryptedEntry;
        } catch (error) {
            console.error('Create entry error:', error);
            throw error;
        }
    }

    static async updateEntry(
        id: string, 
        entry: Partial<ICreatePasswordEntry>
    ): Promise<IDecryptedPasswordEntry> {
        const encryptedEntry = this.encryptEntry(entry);

        const response = await this.request<IPasswordEntry>(`/entries/${id}`, 'PUT', encryptedEntry);
        return this.decryptEntry(response);
    }

    static async deleteEntry(id: string): Promise<void> {
        await this.request(`/entries/${id}`, 'DELETE');
    }

    static async searchEntries(query: string): Promise<IDecryptedPasswordEntry[]> {
        const entries = await this.request<IPasswordEntry[]>(`/entries/search?q=${encodeURIComponent(query)}`);
        return this.decryptEntries(entries);
    }

    static async getEntryById(id: string, sharedKey?: string): Promise<IDecryptedPasswordEntry> {
        const entry = await this.request<IPasswordEntry>(`/entries/${id}`);
        return this.decryptEntry(entry, sharedKey);
    }

    static async getAllItemsData(): Promise<{
        passwords: IDecryptedPasswordEntry[];
        notes: Note[];
        cards: CardDetail[];
        banks: BankDetail[];
        personals: PersonalDetail[];
    }> {
        const response = await this.request<IVaultResponse>('/all-items-data');
        
        console.log("API Response:", response);

        // Check if the response indicates success
        if (!response.success) {
            throw new Error('Failed to fetch items');
        }

        const data = response.data; // Access the nested data object

        try {
            // Check if passwords are defined and is an array
            const decryptedPasswords = Array.isArray(data.passwords) ? this.decryptEntries(data.passwords) : [];
            // Decrypt notes
            const decryptedNotes = Array.isArray(data.notes) ? data.notes.map(note => {
                return {
                    ...note,
                    encrypted_notes: this.decryptBufferData(note.encrypted_notes)
                };
            }) : [];
            
            // Decrypt cards
            const decryptedCards = Array.isArray(data.cards) ? data.cards.map(card => {
                return {
                    ...card,
                    encrypted_card_holder_name: this.decryptBufferData(card.encrypted_card_holder_name),
                    encrypted_card_number: this.decryptBufferData(card.encrypted_card_number),
                    encrypted_expiration_date: this.decryptBufferData(card.encrypted_expiration_date),
                    encrypted_cvv: this.decryptBufferData(card.encrypted_cvv),
                    encrypted_pin: card.encrypted_pin ? this.decryptBufferData(card.encrypted_pin) : null,
                    encrypted_postal_code: card.encrypted_postal_code ? this.decryptBufferData(card.encrypted_postal_code) : null,
                    encrypted_notes: card.encrypted_notes ? this.decryptBufferData(card.encrypted_notes) : null
                };
            }) : [];
            
            // Decrypt banks
            const decryptedBanks = Array.isArray(data.banks) ? data.banks.map(bank => {
                return {
                    ...bank,
                    encrypted_routing_number: bank.encrypted_routing_number ? this.decryptBufferData(bank.encrypted_routing_number) : null,
                    encrypted_account_number: bank.encrypted_account_number ? this.decryptBufferData(bank.encrypted_account_number) : null,
                    encrypted_swift_code: bank.encrypted_swift_code ? this.decryptBufferData(bank.encrypted_swift_code) : null,
                    encrypted_iban_number: bank.encrypted_iban_number ? this.decryptBufferData(bank.encrypted_iban_number) : null,
                    encrypted_pin: bank.encrypted_pin ? this.decryptBufferData(bank.encrypted_pin) : null,
                    encrypted_notes: bank.encrypted_notes ? this.decryptBufferData(bank.encrypted_notes) : null
                };
            }) : [];
            
            // Decrypt personals
            const decryptedPersonals = Array.isArray(data.personals) ? data.personals.map(personal => {
                return {
                    ...personal,
                    encrypted_first_name: this.decryptBufferData(personal.encrypted_first_name),
                    encrypted_middle_name: this.decryptBufferData(personal.encrypted_middle_name),
                    encrypted_last_name: this.decryptBufferData(personal.encrypted_last_name),
                    encrypted_username: this.decryptBufferData(personal.encrypted_username),
                    encrypted_email_address: this.decryptBufferData(personal.encrypted_email_address),
                    encrypted_phone: this.decryptBufferData(personal.encrypted_phone),
                    encrypted_notes: personal.encrypted_notes ? this.decryptBufferData(personal.encrypted_notes) : null
                };
            }) : [];

            return {
                passwords: decryptedPasswords,
                notes: decryptedNotes,
                cards: decryptedCards,
                banks: decryptedBanks,
                personals: decryptedPersonals as PersonalDetail[]
            };
        } catch (error) {
            console.error('Decryption error:', error);
            throw new Error('Failed to decrypt items');
        }
    }

    static async getFavouriteItemsData(): Promise<{
        passwords: IDecryptedPasswordEntry[];
        notes: Note[];
        cards: CardDetail[];
        banks: BankDetail[];
        personals: PersonalDetail[];
    }> {
        const response = await this.request<IVaultResponse>('/favourites-items-data');
        
        console.log("Favourites API Response:", response);

        // Check if the response indicates success
        if (!response.success) {
            throw new Error('Failed to fetch items');
        }

        const data = response.data; // Access the nested data object

        try {
            // Check if passwords are defined and is an array
            const decryptedPasswords = Array.isArray(data.passwords) ? this.decryptEntries(data.passwords) : [];
            // Decrypt notes
            const decryptedNotes = Array.isArray(data.notes) ? data.notes.map(note => {
                return {
                    ...note,
                    encrypted_notes: this.decryptBufferData(note.encrypted_notes)
                };
            }) : [];
            
            // Decrypt cards
            const decryptedCards = Array.isArray(data.cards) ? data.cards.map(card => {
                return {
                    ...card,
                    encrypted_card_holder_name: this.decryptBufferData(card.encrypted_card_holder_name),
                    encrypted_card_number: this.decryptBufferData(card.encrypted_card_number),
                    encrypted_expiration_date: this.decryptBufferData(card.encrypted_expiration_date),
                    encrypted_cvv: this.decryptBufferData(card.encrypted_cvv),
                    encrypted_pin: card.encrypted_pin ? this.decryptBufferData(card.encrypted_pin) : null,
                    encrypted_postal_code: card.encrypted_postal_code ? this.decryptBufferData(card.encrypted_postal_code) : null,
                    encrypted_notes: card.encrypted_notes ? this.decryptBufferData(card.encrypted_notes) : null
                };
            }) : [];
            
            // Decrypt banks
            const decryptedBanks = Array.isArray(data.banks) ? data.banks.map(bank => {
                return {
                    ...bank,
                    encrypted_routing_number: bank.encrypted_routing_number ? this.decryptBufferData(bank.encrypted_routing_number) : null,
                    encrypted_account_number: bank.encrypted_account_number ? this.decryptBufferData(bank.encrypted_account_number) : null,
                    encrypted_swift_code: bank.encrypted_swift_code ? this.decryptBufferData(bank.encrypted_swift_code) : null,
                    encrypted_iban_number: bank.encrypted_iban_number ? this.decryptBufferData(bank.encrypted_iban_number) : null,
                    encrypted_pin: bank.encrypted_pin ? this.decryptBufferData(bank.encrypted_pin) : null,
                    encrypted_notes: bank.encrypted_notes ? this.decryptBufferData(bank.encrypted_notes) : null
                };
            }) : [];
            
            // Decrypt personals
            const decryptedPersonals = Array.isArray(data.personals) ? data.personals.map(personal => {
                return {
                    ...personal,
                    encrypted_first_name: this.decryptBufferData(personal.encrypted_first_name),
                    encrypted_middle_name: this.decryptBufferData(personal.encrypted_middle_name),
                    encrypted_last_name: this.decryptBufferData(personal.encrypted_last_name),
                    encrypted_username: this.decryptBufferData(personal.encrypted_username),
                    encrypted_email_address: this.decryptBufferData(personal.encrypted_email_address),
                    encrypted_phone: this.decryptBufferData(personal.encrypted_phone),
                    encrypted_notes: personal.encrypted_notes ? this.decryptBufferData(personal.encrypted_notes) : null
                };
            }) : [];

            return {
                passwords: decryptedPasswords,
                notes: decryptedNotes,
                cards: decryptedCards,
                banks: decryptedBanks,
                personals: decryptedPersonals as PersonalDetail[]
            };
        } catch (error) {
            console.error('Decryption error:', error);
            throw new Error('Failed to decrypt items');
        }
    }
    private static getMasterKey(): string {
        try {
            return secureStore.getVaultKey();
        } catch (error) {
            throw new Error('Vault key not found. Please login again.');
        }
    }

    public static encryptEntry(entry: Partial<ICreatePasswordEntry>): any {
        const encryptionKey = entry.sharedKey || this.getMasterKey();
        try {
            if (!entry.title || !entry.username || !entry.password) {
                throw new Error('Missing required fields');
            }

            let encryptedUsername, encryptedPassword, encryptedNotes;
            
            try {
                encryptedUsername = encryptData(entry.username, encryptionKey);
            } catch (error) {
                console.error('Username encryption failed:', error);
                throw new Error('Failed to encrypt username');
            }

            try {
                encryptedPassword = encryptData(entry.password, encryptionKey);
            } catch (error) {
                console.error('Password encryption failed:', error);
                throw new Error('Failed to encrypt password');
            }

            if (entry.notes) {
                try {
                    encryptedNotes = encryptData(entry.notes, encryptionKey);
                } catch (error) {
                    console.error('Notes encryption failed:', error);
                    encryptedNotes = null;
                }
            }

            return {
                title: entry.title,
                encrypted_username: encryptedUsername,
                encrypted_password: encryptedPassword,
                encrypted_notes: encryptedNotes,
                website_url: entry.website_url || '',
                category_id: entry.category_id || '',
                favourite: entry.favourite || false,
                auto_login: entry.auto_login || false,
                isSharedUpdate: entry.isSharedUpdate || false,
                itemType: entry.itemType || 'password',
                favicon_url: entry.favicon_url || ''
            };
        } catch (error) {
            console.error('Encryption error:', error);
            throw new Error('Failed to encrypt password entry');
        }
    }

    public static decryptEntry(entry: IPasswordEntry,  sharedKey?: string): IDecryptedPasswordEntry {
        const decryptionKey = sharedKey || this.getMasterKey();
        
        try {
            const decryptBufferData = (encryptedBuffer: any): string => {
                if (!encryptedBuffer) return '';
                
                try {
                    if (encryptedBuffer.data && Array.isArray(encryptedBuffer.data)) {
                        const uint8Array = new Uint8Array(encryptedBuffer.data);
                        const encryptedString = new TextDecoder().decode(uint8Array);
                        return decryptData(encryptedString, decryptionKey);
                    }
                    
                    if (typeof encryptedBuffer === 'string') {
                        return decryptData(encryptedBuffer, decryptionKey);
                    }

                    return '';
                } catch (error) {
                    console.error('Error decrypting buffer:', error);
                    return '';
                }
            };

            const decryptedData: IDecryptedPasswordEntry = {
                id: entry.id,
                vault_id: entry.vault_id,
                title: entry.title,
                username: decryptBufferData(entry.encrypted_username),
                password: decryptBufferData(entry.encrypted_password),
                notes: entry.encrypted_notes ? 
                    decryptBufferData(entry.encrypted_notes) : undefined,
                website_url: entry.website_url || '',
                category_id: entry.category_id || '',
                category_name: entry.category_name || '',
                favourite: entry.favourite,
                last_used: entry.last_used ? new Date(entry.last_used) : undefined,
                password_strength: entry.password_strength || 0,
                created_at: new Date(entry.created_at),
                updated_at: new Date(entry.updated_at),
                auto_login: entry.auto_login || false,
                personal_vault_id: entry.personal_vault_id || '',
                favicon_url: entry.favicon_url || ''
            };
            
            return decryptedData;
        } catch (error) {
            console.error('Decryption error:', error);
            throw new Error('Failed to decrypt password entry');
        }
    }

    private static decryptEntries(entries: IPasswordEntry[]): IDecryptedPasswordEntry[] {
        return entries.map(entry => {
            try {
                return this.decryptEntry(entry);
            } catch (error) {
                console.error(`Failed to decrypt entry ${entry.id}:`, error);
                // Return a placeholder for failed entries
                return {
                    id: entry.id,
                    vault_id: entry.vault_id,
                    title: entry.title,
                    username: '(Decryption failed)',
                    password: '(Decryption failed)',
                    notes: undefined,
                    website_url: entry.website_url || '',
                    category_id: entry.category_id || '',
                    category_name: entry.category_name || '',
                    favourite: entry.favourite,
                    last_used: entry.last_used ? new Date(entry.last_used) : undefined,
                    password_strength: entry.password_strength || 0,
                    created_at: new Date(entry.created_at),
                    updated_at: new Date(entry.updated_at),
                    auto_login: false,
                    personal_vault_id: entry.personal_vault_id || '',
                    favicon_url: entry.favicon_url || ''
                };
            }
        });
    }

    static async toggleFavorite(entryId: string): Promise<void> {
        await this.request(`/entries/${entryId}/favourite`, 'POST');
    }

    static async addCategoryToEntry(entryId: string, categoryId: string): Promise<void> {
        await this.request(`/entry/${entryId}/category`, 'POST', { categoryId });
    }

    static async removeCategoryFromEntry(entryId: string): Promise<void> {
        await this.request(`/entry/${entryId}/category`, 'DELETE');
    }

    private static decryptBufferData(encryptedBuffer: any, isShared: boolean = false, sharedKey?: string): string {
        if (!encryptedBuffer) return '';

        try {
            if (encryptedBuffer.data && Array.isArray(encryptedBuffer.data)) {
                const uint8Array = new Uint8Array(encryptedBuffer.data);
                const encryptedString = new TextDecoder().decode(uint8Array);
                return isShared && sharedKey ? 
                    decryptData(encryptedString, sharedKey) : 
                    decryptData(encryptedString, this.getMasterKey());
            }

            if (typeof encryptedBuffer === 'string') {
                return isShared && sharedKey ? 
                    decryptData(encryptedBuffer, sharedKey) : 
                    decryptData(encryptedBuffer, this.getMasterKey());
            }

            return '';
        } catch (error) {
            console.error('Error decrypting buffer:', error);
            return '';
        }
    }
}

export const vaultService = new VaultService();