import React, { useState, useEffect } from 'react';
import { Team, TeamService } from '../../services/team.service';
import Modal from '../common/Modal';
import { useVault } from '../../contexts/VaultContext';
import { toast } from 'react-hot-toast';

interface CreateTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTeamCreated: () => void;
  editMode?: boolean;
  teamData?: Team;
  onUpdated?: () => void;
}

const CreateTeamModal: React.FC<CreateTeamModalProps> = ({ 
  isOpen, 
  onClose, 
  onTeamCreated, 
  editMode = false,
  teamData
}) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { generateEncryptionKey, encryptWithVaultKey } = useVault();

  // Set initial values when editing
  useEffect(() => {
    if (editMode && teamData) {
      setName(teamData.name || '');
      setDescription(teamData.description || '');
    } else {
      // Reset form when opening in create mode
      setName('');
      setDescription('');
    }
    setError('');
  }, [editMode, teamData, isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!name.trim()) {
      setError('Team name is required');
      return;
    }
    
    try {
      setLoading(true);
      setError('');
      
      if (editMode && teamData) {
        // Update existing team
        await TeamService.updateTeam(teamData.id, {
          name,
          description
        });
        toast.success('Team updated successfully');
      } else {
        // Create new team
        // Generate a new encryption key for the team
        const teamKey = generateEncryptionKey();
        
        // Encrypt the team key with the user's vault key
        const encryptedTeamKey = encryptWithVaultKey(teamKey);
        
        await TeamService.createTeam(name, description, encryptedTeamKey);
        toast.success('Team created successfully');
      }
      
      onTeamCreated();
    } catch (error: any) {
      console.error(`Error ${editMode ? 'updating' : 'creating'} team:`, error);
      setError(error.response?.data?.message || `Failed to ${editMode ? 'update' : 'create'} team`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      title={editMode ? "Edit Team" : "Create Team"}
    >
      <div className="p-6">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Team Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            />
          </div>
          {error && (
            <div className="text-sm text-red-600 mb-4">
              {error}
            </div>
          )}
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              disabled={loading}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {loading ? (editMode ? 'Updating...' : 'Creating...') : (editMode ? 'Update Team' : 'Create Team')}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateTeamModal;