import { ImportSource, ImportHistory, DuplicateGroup } from '../types/import';
import { store } from '../store';
import { config } from '../extension/config';
import { encryptData } from '../utils/encryption';
import { secureStore } from '../utils/secureStore';

const API_URL = config.API_URL;

export class ImportService {
    private static async request<T>(endpoint: string, method: string = 'GET', body?: any): Promise<T> {
        const token = store.getState().auth.token;
        
        if (!token) {
            throw new Error('No authentication token found');
        }

        const response = await fetch(`${API_URL}${endpoint}`, {
            method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: body ? JSON.stringify(body) : undefined,
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || 'An error occurred');
        }

        return data;
    }

    private static getMasterKey(): string {
        return secureStore.getVaultKey();
    }

    private static encryptField(value: string | null): string | null {
        const masterKey = this.getMasterKey();
        return value ? encryptData(value, masterKey) : null;
    }

    static async getSupportedSources(): Promise<ImportSource[]> {
        return this.request<ImportSource[]>('/import/sources');
    }

    static async initializeImport(sourceType: string): Promise<ImportHistory> {
        return this.request<ImportHistory>('/import/initialize', 'POST', { source_type: sourceType });
    }

    static async uploadFile(file: File, importId: string, sourceType: string): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('importId', importId);
        formData.append('sourceType', sourceType);

        const token = store.getState().auth.token;
        
        const response = await fetch(`${API_URL}/import/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || 'Upload failed');
        }

        const parsedData = await response.json();

        // Add duplicate detection after getting the parsed data
        const duplicateGroups = this.findDuplicates(parsedData.items);
        parsedData.duplicateGroups = duplicateGroups;
        parsedData.duplicate = duplicateGroups.reduce(
            (acc, group) => acc + group.duplicates.length, 
            0
        );

        return parsedData;
    }

    // Add new method for duplicate detection
    private static findDuplicates(items: Array<any>): DuplicateGroup[] {
        const duplicateGroups: DuplicateGroup[] = [];
        const seen = new Map();

        items.forEach((item) => {
            // Create a unique key from the item's properties
            const key = `${item.name}|${item.username}|${item.password}|${item.url}`.toLowerCase();
            
            if (seen.has(key)) {
                const existingGroup = duplicateGroups.find(
                    group => group.original === seen.get(key)
                );
                
                if (existingGroup) {
                    existingGroup.duplicates.push(item.id);
                } else {
                    duplicateGroups.push({
                        original: seen.get(key),
                        duplicates: [item.id]
                    });
                }
            } else {
                seen.set(key, item.id);
            }
        });

        return duplicateGroups;
    }

    static async getImportProgress(importId: string): Promise<any> {
        return this.request<any>(`/import/progress/${importId}`);
    }

    static async storePassword(data: any) {
        const encryptedData = {
            title: data.title,
            encrypted_username: this.encryptField(data.username),
            encrypted_password: this.encryptField(data.password),
            encrypted_notes: this.encryptField(data.notes),
            website_url: data.website_url,
            category_id: data.category_id,
            favorite: data.favorite
        };

        return this.request('/vault/entries', 'POST', encryptedData);
    }

    static async storeBankAccount(data: any) {
        const encryptedData = {
            name: data.name,
            bank_name: data.bank_name,
            account_type: data.account_type,
            encrypted_account_number: this.encryptField(data.account_number),
            encrypted_routing_number: this.encryptField(data.routing_number),
            encrypted_swift_code: this.encryptField(data.swift_code),
            encrypted_iban_number: this.encryptField(data.iban_number),
            encrypted_pin: this.encryptField(data.pin),
            encrypted_branch_address: this.encryptField(data.branch_address),
            encrypted_branch_phone: this.encryptField(data.branch_phone),
            encrypted_notes: this.encryptField(data.notes),
            category_id: data.category_id
        };

        return this.request('/bank-details', 'POST', encryptedData);
    }

    static async storeCardDetail(data: any) {
        const encryptedData = {
            name: data.name,
            card_type: data.card_type,
            encrypted_card_holder_name: this.encryptField(data.card_holder_name),
            encrypted_card_number: this.encryptField(data.card_number),
            encrypted_expiration_date: this.encryptField(data.expiration_date),
            encrypted_cvv: this.encryptField(data.cvv),
            encrypted_pin: this.encryptField(data.pin),
            encrypted_postal_code: this.encryptField(data.postal_code),
            encrypted_notes: this.encryptField(data.notes),
            category_id: data.category_id
        };

        return this.request('/card-details', 'POST', encryptedData);
    }

    static async storePersonalDetail(data: any) {
        console.log(data);
        const encryptedData = {
            name: data.name,
            title: data.title,
            encrypted_first_name: this.encryptField(data.first_name),
            encrypted_middle_name: this.encryptField(data.middle_name),
            encrypted_last_name: this.encryptField(data.last_name),
            encrypted_username: this.encryptField(data.username),
            gender: data.gender,
            birthday: data.birthday,
            encrypted_email_address: this.encryptField(data.email),
            encrypted_phone: this.encryptField(data.phone),
            encrypted_address_1: this.encryptField(data.address1),
            encrypted_address_2: this.encryptField(data.address2),
            encrypted_city_town: this.encryptField(data.city),
            encrypted_state: this.encryptField(data.state),
            encrypted_zip_postal_code: this.encryptField(data.zipCode),
            encrypted_country: this.encryptField(data.country),
            encrypted_timezone: this.encryptField(data.timezone),
            encrypted_notes: this.encryptField(data.notes),
            category_id: data.category_id
        };

        return this.request('/personal-details', 'POST', encryptedData);
    }

    // New method for batch import
    static async batchImport(items: any[], importId: string) {
        // First encrypt sensitive data
        const masterKey = this.getMasterKey();
        if (!masterKey) {
            throw new Error('Master key not found');
        }

        const processedItems = items.map(item => {
            // For passwords
            if (item.type === 'password' || (item.username && item.password)) {
                return {
                    type: 'password',
                    data: {
                        title: item.name || 'Imported Password',
                        encrypted_username: this.encryptField(item.username || ''),
                        encrypted_password: this.encryptField(item.password || ''),
                        encrypted_notes: this.encryptField(item.notes || ''),
                        website_url: item.url || '',
                        category_id: item.categoryId || null,
                        favorite: false
                    }
                };
            }
            // For bank accounts
            else if (item.type === 'Bank Account' || item.accountNumber) {
                return {
                    type: 'bankAccount',
                    data: {
                        name: item.name || 'Imported Bank Account',
                        bank_name: item.bankName || '',
                        account_type: item.accountType || 'checking',
                        encrypted_account_number: this.encryptField(item.accountNumber || ''),
                        encrypted_routing_number: this.encryptField(item.routingNumber || ''),
                        encrypted_swift_code: this.encryptField(item.swiftCode || ''),
                        encrypted_iban_number: this.encryptField(item.ibanNumber || ''),
                        encrypted_pin: this.encryptField(item.pin || ''),
                        encrypted_branch_address: this.encryptField(item.branchAddress || ''),
                        encrypted_branch_phone: this.encryptField(item.branchPhone || ''),
                        encrypted_notes: this.encryptField(item.notes || ''),
                        category_id: item.categoryId || null
                    }
                };
            }
            // For credit cards
            else if (item.type === 'Credit Card' || item.cardNumber) {
                return {
                    type: 'card',
                    data: {
                        name: item.name || 'Imported Card',
                        card_type: 'credit',
                        encrypted_card_holder_name: this.encryptField(item.cardHolderName || ''),
                        encrypted_card_number: this.encryptField(item.cardNumber || ''),
                        encrypted_expiration_date: this.encryptField(item.expirationDate || ''),
                        encrypted_cvv: this.encryptField(item.cvv || ''),
                        encrypted_pin: this.encryptField(item.pin || ''),
                        encrypted_postal_code: this.encryptField(item.postalCode || ''),
                        encrypted_notes: this.encryptField(item.notes || ''),
                        category_id: item.categoryId || null
                    }
                };
            }
            // For personal info
            else if (item.type === 'Address' || item.firstName || item.lastName) {
                return {
                    type: 'personal',
                    data: {
                        name: item.name || 'Imported Personal Info',
                        title: item.title || '',
                        encrypted_first_name: this.encryptField(item.firstName || ''),
                        encrypted_middle_name: this.encryptField(item.middleName || ''),
                        encrypted_last_name: this.encryptField(item.lastName || ''),
                        encrypted_username: this.encryptField(item.username || ''),
                        gender: item.gender || '',
                        birthday: item.birthday || null,
                        encrypted_email_address: this.encryptField(item.email || ''),
                        encrypted_phone: this.encryptField(item.phone || ''),
                        encrypted_address_1: this.encryptField(item.address1 || ''),
                        encrypted_address_2: this.encryptField(item.address2 || ''),
                        encrypted_city_town: this.encryptField(item.city || ''),
                        encrypted_state: this.encryptField(item.state || ''),
                        encrypted_zip_postal_code: this.encryptField(item.zipCode || ''),
                        encrypted_country: this.encryptField(item.country || ''),
                        encrypted_timezone: this.encryptField(item.timezone || ''),
                        encrypted_notes: this.encryptField(item.notes || ''),
                        category_id: item.categoryId || null
                    }
                };
            }
            // Default to secure note for anything else
            else {
                return {
                    type: 'note',
                    data: {
                        name: item.name || 'Imported item',
                        encrypted_notes: this.encryptField(item.notes || JSON.stringify(item)),
                        category_id: item.categoryId || null,
                        favourite: false
                    }
                };
            }
        });

        console.log("Processed items:", processedItems);

        return this.request('/import/batch', 'POST', { 
            items: processedItems,
            importId
        });
    }
}

export default ImportService; 