import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { CloudArrowUpIcon, ArrowDownTrayIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

interface FileUploadStepProps {
    onFileSelect: (file: File) => void;
    acceptedFormats: string[];
    sourceType: string;
    maxSizeBytes?: number;
}

const FileUploadStep: React.FC<FileUploadStepProps> = ({ 
    onFileSelect, 
    acceptedFormats, 
    sourceType,
    maxSizeBytes = 1024 * 1024 // Default to 1MB
}) => {
    const [fileError, setFileError] = useState<string | null>(null);
    
    const validateFile = (file: File): boolean => {
        // Check file size (default 1MB)
        if (file.size > maxSizeBytes) {
            setFileError(`File is too large. Maximum size is ${Math.round(maxSizeBytes/1024)}KB.`);
            return false;
        }
        
        // Check file type based on extension and mimetype
        const extension = file.name.split('.').pop()?.toLowerCase();
        const acceptedExtensions = ['csv', 'json', '1pif'];
        
        if (!acceptedExtensions.includes(extension || '')) {
            setFileError(`Invalid file type. Accepted formats: ${acceptedFormats.join(', ')}`);
            return false;
        }
        
        setFileError(null);
        return true;
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            
            if (validateFile(file)) {
                onFileSelect(file);
            }
        }
    }, [onFileSelect, maxSizeBytes]);

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
            'application/json': ['.json'],
            'application/x-1password-1pif': ['.1pif'],
            'application/csv': ['.csv']
        },
        multiple: false,
        maxSize: maxSizeBytes
    });

    const handleDownloadTemplate = () => {
        const link = document.createElement('a');
        link.href = '/assets/templates/LockdownPass_sample_import_format.csv';
        link.download = 'LockdownPass_sample_import_format.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="p-6">
            <h3 className="text-lg font-medium mb-4">Upload your file</h3>

            {sourceType === 'other' && (
                <div className="mb-6 bg-blue-50 p-4 rounded-lg">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            <ArrowDownTrayIcon className="h-5 w-5 text-blue-400" />
                        </div>
                        <div className="ml-3">
                            <h3 className="text-sm font-medium text-blue-800">
                                Download Sample Format
                            </h3>
                            <div className="mt-2 text-sm text-blue-700">
                                <p>Before uploading your CSV file:</p>
                                <ul className="list-disc pl-5 mt-1 space-y-1">
                                    <li>Download our sample CSV format for reference</li>
                                    <li>Open the downloaded file in Google Sheets</li>
                                    <li>Do not modify column names to ensure successful import</li>
                                    <li>Use only the specified types: password, note, personal_details, card_details, bank_details</li>
                                    <li>Fill in the relevant fields based on the type of entry</li>
                                    <li>Download the updated file in .csv format</li>
                                    <li>Upload the newly downloaded CSV file for import.</li>
                                </ul>
                                <button
                                    onClick={handleDownloadTemplate}
                                    className="mt-3 text-blue-800 hover:text-blue-900 font-medium flex items-center"
                                >
                                    <ArrowDownTrayIcon className="h-4 w-4 mr-1" />
                                    Download Template
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            
            {/* File size info */}
            <div className="mb-3 text-sm text-gray-600">
                <p>Maximum file size: 1MB</p>
                <p>Supported formats: {acceptedFormats.join(', ')}</p>
            </div>

            <div
                {...getRootProps()}
                className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors
                    ${isDragActive && !isDragReject ? 'border-blue-500 bg-blue-50' : 
                    isDragReject || fileError ? 'border-red-500 bg-red-50' : 
                    'border-gray-300 hover:border-blue-400'}`}
            >
                <input {...getInputProps()} />
                {isDragReject || fileError ? (
                    <div>
                        <ExclamationCircleIcon className="mx-auto h-12 w-12 text-red-400" />
                        <p className="mt-2 text-sm text-red-600">
                            {fileError || "Invalid file type or size"}
                        </p>
                    </div>
                ) : (
                    <>
                        <CloudArrowUpIcon className="mx-auto h-12 w-12 text-gray-400" />
                        <p className="mt-2 text-sm text-gray-600">
                            {isDragActive
                                ? 'Drop the file here'
                                : 'Drag and drop your file here, or click to select'}
                        </p>
                    </>
                )}
            </div>
            
            {/* Error message outside the dropzone */}
            {fileError && (
                <div className="mt-2 text-sm text-red-600">
                    {fileError}
                </div>
            )}
        </div>
    );
};

export default FileUploadStep; 