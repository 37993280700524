import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import VaultPage from './pages/vault/VaultPage';
import ProtectedRoute from './components/auth/ProtectedRoute';
import { VaultProvider } from './contexts/VaultContext';
import { useAppSelector } from './hooks/useRedux';
import { Toaster } from 'react-hot-toast';
import FavoritePage from './pages/vault/FavoritePage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import RecoveryPage from './pages/auth/RecoveryPage';
import SharingCenterPage from './pages/vault/SharingCenterPage';
import CategoryPage from './pages/vault/CategoriesPage';
import { CategoryProvider } from './contexts/CategoryContext';
import FeaturesPage from './pages/FeaturesPage';
import LandingPage from './pages/LandingPage';
import VaultHeader from './components/vault/VaultHeader';
import MfaSetup from './components/settings/MfaSetup';
import NotesPage from './pages/notes/NotesPage';
import PersonalDetailsPage from './pages/personalDetails/PersonalDetailsPage';
import CardDetailsPage from './pages/cardDetails/CardDetailsPage';
import PasswordsPage from './pages/passwords/PasswordsPage';
import BankDetailsPage from './pages/bankDetails/BankDetailsPage';
import ImportPage from './pages/import/ImportPage';
import RolesPage from './pages/roles/RolesPage';
import TeamMembersPage from './pages/team/TeamMembersPage';
import { SidebarProvider } from './contexts/SidebarContext';
import { AuthProvider } from './contexts/AuthContext';
import RegisterGuard from './components/auth/RegisterGuard';
import PermissionProtectedRoute from './components/auth/PermissionProtectedRoute';
import NotAuthorizedPage from './pages/errors/NotAuthorizedPage';
import TeamsPage from './pages/team/TeamsPage';
import TeamDetailsPage from './pages/team/TeamDetailsPage';
import AcceptInvitation from './pages/AcceptInvitation';
// Separate component for routes that uses Redux hooks
const AppRoutes: React.FC = () => {
    const { user } = useAppSelector((state) => state.auth);

    return (
        <AuthProvider>
            <VaultProvider>
                <SidebarProvider>
                    <CategoryProvider>
                        <Routes>
                            {/* Public Routes */}
                            <Route path="/" element={<LandingPage />} />
                            <Route path="/features" element={<FeaturesPage />} />
                            <Route path="/not-authorized" element={<NotAuthorizedPage />} /> 

                            <Route
                                path="/login"
                                element={user ? <Navigate to="/vaults" replace /> : <LoginPage />}
                            />
                            <Route
                                path="/register"
                                element={
                                    <RegisterGuard>
                                        <RegisterPage />
                                    </RegisterGuard>
                                }
                            />


                            {/* Protected Routes */}
                            <Route path="/all-items" element={
                                <ProtectedRoute>
                                    <VaultPage />
                                </ProtectedRoute>
                            } />
                            <Route path="/passwords" element={
                                <ProtectedRoute>
                                    <PasswordsPage />
                                </ProtectedRoute>
                            } />

                            <Route path="/favorites" element={
                                <ProtectedRoute>
                                    <FavoritePage />
                                </ProtectedRoute>
                            } />

                            <Route path="/vaults" element={
                                <ProtectedRoute>
                                    <CategoryPage />
                                </ProtectedRoute>
                            } />

                            <Route path="/sharing-center" element={
                                <ProtectedRoute>
                                    <SharingCenterPage />
                                </ProtectedRoute>
                            } />

                            <Route path="/roles" element={
                                <PermissionProtectedRoute requiredPermission="manage_roles">
                                    <RolesPage />
                                </PermissionProtectedRoute>
                            } />

                            <Route path="/manage-members" element={
                                <PermissionProtectedRoute requiredPermission="manage_team">
                                    <TeamMembersPage />
                                </PermissionProtectedRoute>
                            } />
                            <Route
                                path="/personal-details"
                                element={
                                    <ProtectedRoute>
                                        <PersonalDetailsPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/card-details"
                                element={
                                    <ProtectedRoute>
                                        <CardDetailsPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/bank-details"
                                element={
                                    <ProtectedRoute>
                                        <BankDetailsPage />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/import"
                                element={
                                    <ProtectedRoute >
                                        <ImportPage />
                                    </ProtectedRoute>
                                }
                            />
                            {/* <Route path="/notes" element={
                                <ProtectedRoute>
                                    <NotesPage />
                                </ProtectedRoute>
                            } /> */}

                            <Route path="/mfa-setup" element={
                                <ProtectedRoute>
                                    <MfaSetup />
                                </ProtectedRoute>
                            } />

                            <Route 
                                path="/teams" 
                                element={
                                    <PermissionProtectedRoute requiredPermission="manage_teams">
                                        <TeamsPage />
                                    </PermissionProtectedRoute>
                                } 
                            />

                            <Route 
                                path="/team/:teamId" 
                                element={
                                    <ProtectedRoute>
                                        <TeamDetailsPage />
                                    </ProtectedRoute>
                                } 
                            />

                            {/* Redirect root to appropriate page */}
                            <Route
                                path="/"
                                element={<Navigate to={user ? "/vaults" : "/"} replace />}
                            />

                            {/* 404 Page */}
                            <Route path="*" element={
                                <div className="min-h-screen flex items-center justify-center">
                                    <div className="text-center">
                                        <h1 className="text-4xl font-bold text-gray-900">404</h1>
                                        <p className="text-gray-600">Page not found</p>
                                    </div>
                                </div>
                            } />
                            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                            <Route path="/account-recovery" element={<RecoveryPage />} />
                            <Route path="/not-authorized" element={<NotAuthorizedPage />} />
                            <Route path="/accept-invitation" element={<AcceptInvitation />} />
                        </Routes>
                    </CategoryProvider>
                </SidebarProvider>
            </VaultProvider>
        </AuthProvider>
    );
};

// Main App component that provides Redux store
const App: React.FC = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AuthProvider>

                    <VaultProvider>
                        <SidebarProvider>
                            <Toaster position="top-right" />
                            <Router>
                                <AppRoutes />
                            </Router>
                        </SidebarProvider>
                    </VaultProvider>
                </AuthProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;