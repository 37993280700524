import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TeamService } from '../../services/team.service';

interface RegisterGuardProps {
    children: React.ReactNode;
}

const RegisterGuard: React.FC<RegisterGuardProps> = ({ children }) => {
    const location = useLocation();
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const validateInvitation = async () => {
            setIsLoading(true);
            const queryParams = new URLSearchParams(location.search);
            const encodedData = queryParams.get('token');

            if (!encodedData) {
                setIsValid(false);
                setError('Direct registration is not allowed. Please ask your administrator for an invitation.');
                setIsLoading(false);
                return;
            }

            try {
                // First decode the token to get the raw token value
                const decodedString = atob(encodedData);
                const invitationData = JSON.parse(decodedString);
                
                if (!invitationData.token) {
                    setIsValid(false);
                    setError('Invalid invitation link format.');
                    setIsLoading(false);
                    return;
                }

                // Now validate with the backend
                await TeamService.validateInvitationToken(invitationData.token);
                
                // If we get here, the token is valid
                setIsValid(true);
            } catch (error: any) {
                setIsValid(false);
                // Use the error message from the API if available
                if (error.message) {
                    setError(error.message);
                } else {
                    setError('Invalid or expired invitation link. Please request a new invitation.');
                }
            } finally {
                setIsLoading(false);
            }
        };

        validateInvitation();
    }, [location]);

    if (isLoading) {
        return (
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="flex flex-col items-center">
                    <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-blue-600 mb-4"></div>
                    <p className="text-gray-600">Validating invitation...</p>
                </div>
            </div>
        );
    }

    if (!isValid) {
        return (
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <div className="text-center">
                            <h2 className="text-2xl font-bold text-gray-900 mb-4">
                                Registration Restricted
                            </h2>
                            <div className="rounded-md bg-red-50 p-4 mb-6">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-red-800">
                                            {error}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <a
                                href="/login"
                                className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                Back to Login
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return <>{children}</>;
};

export default RegisterGuard;
