import React, { useState, useMemo, useEffect } from 'react';
import { CheckCircleIcon, XCircleIcon, InformationCircleIcon, EyeIcon, EyeSlashIcon, TrashIcon } from '@heroicons/react/24/outline';
import { DuplicateGroup } from '../../types/import';
import { useVault } from '../../contexts/VaultContext';

interface ImportProgressProps {
    importData: {
        total: number;
        complete: number;
        incomplete: number;
        duplicate: number;
        existing?: number;
        items: Array<{
            id: string;
            name: string;
            username: string;
            password: string;
            url: string;
            folder: string;
            notes: string;
            status: 'complete' | 'incomplete';
            type: 'password' | 'Bank Account' | 'Credit Card' | 'Address' | 'note' | 'unknown';
            isExisting?: boolean;
        }>;
        duplicateGroups?: DuplicateGroup[];
    };
    onImport: (selectedItems: string[]) => void;
    importStatus?: {
        status: 'processing' | 'completed' | 'failed' | 'idle';
        progress: number;
        error?: string;
    };
    importId?: string;
    isImporting?: boolean;
    vaultEntries?: any[];
}

const ImportProgress: React.FC<ImportProgressProps> = ({ importData, onImport, importStatus, importId, isImporting, vaultEntries = [] }) => {
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [filter, setFilter] = useState<'all' | 'complete' | 'incomplete' | 'duplicate' | 'existing'>('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [visiblePasswords, setVisiblePasswords] = useState<string[]>([]);
    const [importing, setImporting] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const { entries, refreshEntries } = useVault();
    // Track import status
    useEffect(() => {
        if (importStatus?.status === 'completed') {
            setImporting(false);
        } else if (importStatus?.status === 'processing') {
            setImporting(true);
        } else if (importStatus?.status === 'failed') {
            setImporting(false);
        }

        if (isImporting) {
            setImporting(true);
        } else {
            setImporting(false);
        }
    }, [importStatus, isImporting]);

    useEffect(() => {
        refreshEntries();
    },[])

    // Update isMobile state on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (entries.length > 0 && importData.items.length > 0) {
            // Mark existing items
            importData.items = importData.items.map(item => ({
                ...item,
                isExisting: isExisting(item)
            }));
    
            updateCounts();
            console.log("entries", entries)
        }
    }, [entries, importData.items]);
    // Add function to check if item is duplicate
    const isDuplicate = (itemId: string) => {
        console.log("importData.duplicateGroups");
        return importData.duplicateGroups?.some(group => 
            group.duplicates.includes(itemId)
        );
    };

    // Function to check if item is existing
    const isExisting = (item: any) => {
        return entries.some(entry => 
            entry.title?.toLowerCase() === item.name?.toLowerCase() &&
            entry.website_url?.toLowerCase() === item.url?.toLowerCase() &&
            entry.username?.toLowerCase() === item.username?.toLowerCase() &&
            entry.password === item.password &&
            entry.category_name?.toLowerCase() === item.folder?.toLowerCase()
        );
    };


    // Update counts function to include existing
    const updateCounts = () => {
        let total = importData.items.length;
        let complete = 0, incomplete = 0, existingCount = 0;
    
        for (const item of importData.items) {
            if (item.isExisting) existingCount++;
            if (item.status === 'complete') complete++;
            if (item.status === 'incomplete') incomplete++;
        }
    
        importData.total = total;
        importData.complete = complete;
        importData.incomplete = incomplete;
        importData.existing = existingCount;
        importData.duplicate = importData.duplicateGroups?.reduce(
            (acc, group) => acc + group.duplicates.length, 0
        ) || 0;
    };

    // Update handleRemoveItem to use the new function
    const handleRemoveItem = (itemId: string) => {
        if (window.confirm("Are you sure you want to remove this item?")) {
            setSelectedItems(prev => prev.filter(id => id !== itemId));
            
            // Remove from importData
            const updatedItems = importData.items.filter(item => item.id !== itemId);
            importData.items = updatedItems;
            
            // Update duplicate groups
            if (importData.duplicateGroups) {
                importData.duplicateGroups = importData.duplicateGroups
                    .map(group => ({
                        ...group,
                        duplicates: group.duplicates.filter(id => id !== itemId)
                    }))
                    .filter(group => group.duplicates.length > 0); // Remove empty groups
            }

            // Update all counts
            updateCounts();
        }
    };

    const filteredItems = useMemo(() => {
        return importData.items.filter(item => {
            if (filter === 'complete') return item.status === 'complete';
            if (filter === 'incomplete') return item.status === 'incomplete';
            if (filter === 'duplicate') return isDuplicate(item.id);
            if (filter === 'existing') return isExisting(item);
            return true; // 'all' filter
        }).filter(item => 
            (item.name || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
            (item.username || "").toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [importData.items, filter, searchQuery, importData.duplicateGroups]);

    // Toggle password visibility for a specific item
    const togglePasswordVisibility = (itemName: string) => {
        if (visiblePasswords.includes(itemName)) {
            setVisiblePasswords(visiblePasswords.filter(name => name !== itemName));
        } else {
            setVisiblePasswords([...visiblePasswords, itemName]);
        }
    };
       // Function to truncate URLs
       const truncateUrl = (url: string, maxLength: number = 30) => {
        if (url?.length > maxLength) {
            return `${url.slice(0, maxLength)}...`;
        }
        return url;
    };

    // Update the checkbox handling to use IDs
    const handleCheckboxChange = (itemId: string) => {
        if (selectedItems.includes(itemId)) {
            setSelectedItems(selectedItems.filter(id => id !== itemId));
            console.log("selectedItems", selectedItems);
        } else {
            setSelectedItems([...selectedItems, itemId]);
        }
    };

    // Handle import with loading state
    const handleImport = () => {
        try {
            setImporting(true);
            
            // Filter out existing items from the selected items
            const itemsToImport = selectedItems.filter(itemId => {
                const item = importData.items.find(i => i.id === itemId);
                return item && !item.isExisting;
            });
            
            // Only proceed if there are non-existing items to import
            if (itemsToImport.length > 0) {
                onImport(itemsToImport);
            } else if (selectedItems.length > 0) {
                // All selected items are existing, show a message
                alert("All selected items already exist in your vault. No new items to import.");
                setImporting(false);
            } else {
                setImporting(false);
            }
        } catch (error) {
            console.error('Error during import:', error);
            setImporting(false);
        }
    };

    // Update handleRemoveSelected similarly
    const handleRemoveSelected = () => {
        if (selectedItems.length === 0) return;
        
        if (window.confirm(`Are you sure you want to remove ${selectedItems.length} selected items?`)) {
            // Remove all selected items from importData
            const updatedItems = importData.items.filter(item => !selectedItems.includes(item.id));
            importData.items = updatedItems;
            
            // Update duplicate groups
            if (importData.duplicateGroups) {
                importData.duplicateGroups = importData.duplicateGroups
                    .map(group => ({
                        ...group,
                        duplicates: group.duplicates.filter(id => !selectedItems.includes(id))
                    }))
                    .filter(group => group.duplicates.length > 0); // Remove empty groups
            }

            // Update all counts
            updateCounts();
            
            // Clear selection
            setSelectedItems([]);
        }
    };

    return (
        <div className="p-3 md:p-6">
            {/* Import Status and Progress */}
            {importStatus && importStatus.status !== 'idle' && (
                <div className={`mb-4 p-2 md:p-4 rounded-md text-sm md:text-base ${
                    importStatus.status === 'processing' ? 'bg-blue-50 text-blue-700' : 
                    importStatus.status === 'completed' ? 'bg-green-50 text-green-700' : 
                    'bg-red-50 text-red-700'
                }`}>
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            {importStatus.status === 'processing' && (
                                <span className="mr-2">
                                    <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                                        <circle 
                                            className="opacity-25" 
                                            cx="12" 
                                            cy="12" 
                                            r="10" 
                                            stroke="currentColor" 
                                            strokeWidth="4"
                                            fill="none" 
                                        />
                                        <path 
                                            className="opacity-75" 
                                            fill="currentColor" 
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        />
                                    </svg>
                                </span>
                            )}
                            {importStatus.status === 'completed' && <CheckCircleIcon className="h-5 w-5 mr-2" />}
                            {importStatus.status === 'failed' && <XCircleIcon className="h-5 w-5 mr-2" />}
                            <span>
                                {importStatus.status === 'processing' && 'Processing import...'}
                                {importStatus.status === 'completed' && 'Import completed successfully!'}
                                {importStatus.status === 'failed' && `Import failed: ${importStatus.error || 'Unknown error'}`}
                            </span>
                        </div>
                        <span className="text-sm font-medium">
                            {importStatus.progress}%
                        </span>
                    </div>
                    
                    {/* Progress Bar */}
                    {importStatus.status === 'processing' && (
                        <div className="mt-2 w-full bg-blue-200 rounded-full h-2.5">
                            <div 
                                className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
                                style={{ width: `${importStatus.progress}%` }}
                            />
                        </div>
                    )}
                </div>
            )}
            
            {/* File Size Warning */}
            {importStatus?.error?.includes('file size') && (
                <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md flex items-center">
                    <XCircleIcon className="h-5 w-5 mr-2" />
                    <span>The file exceeds the maximum size limit of 1MB. Please reduce the file size and try again.</span>
                </div>
            )}

            {/* Duplicate items warning */}
            {(importData.duplicate ?? 0) > 0 && (
                <div className="mb-4 p-2 md:p-4 bg-yellow-50 text-yellow-700 rounded-md flex items-center text-sm md:text-base">
                    <InformationCircleIcon className="h-4 w-4 md:h-5 md:w-5 mr-2 flex-shrink-0" />
                    <span>Duplicate items detected. Remove duplicates before importing or import them as a new item.</span>
                </div>
            )}

            {/* Warning Messages - made responsive */}
            {importData.incomplete > 0 && (
                <div className="mb-4 p-2 md:p-4 bg-blue-50 text-blue-700 rounded-md flex items-center text-sm md:text-base">
                    <InformationCircleIcon className="h-4 w-4 md:h-5 md:w-5 mr-2 flex-shrink-0" />
                    <span>Incomplete items detected.</span>
                </div>
            )}

            {/* Existing items warning */}
            {(importData.existing ?? 0) > 0 && (
                <div className="mb-4 p-2 md:p-4 bg-amber-50 text-amber-700 rounded-md flex items-center text-sm md:text-base">
                    <InformationCircleIcon className="h-4 w-4 md:h-5 md:w-5 mr-2 flex-shrink-0" />
                    <span>We found {importData.existing} items that already exist in your vault. These items will be skipped during import.</span>
                </div>
            )}

            {/* Filter Tabs - responsive design */}
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4">
                <div className="flex flex-wrap border-b border-gray-200 w-full mb-3 md:mb-0">
                    <button 
                        className={`py-1 px-2 md:py-2 md:px-4 -mb-px text-xs md:text-sm ${filter === 'all' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
                        onClick={() => setFilter('all')}
                    >
                        All ({importData.total})
                    </button>
                    <button 
                        className={`py-1 px-2 md:py-2 md:px-4 -mb-px text-xs md:text-sm ${filter === 'complete' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
                        onClick={() => setFilter('complete')}
                    >
                        Complete ({importData.complete})
                    </button>
                    <button 
                        className={`py-1 px-2 md:py-2 md:px-4 -mb-px text-xs md:text-sm ${filter === 'incomplete' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
                        onClick={() => setFilter('incomplete')}
                    >
                        Incomplete ({importData.incomplete})
                    </button>
                    <button 
                        className={`py-1 px-2 md:py-2 md:px-4 -mb-px text-xs md:text-sm ${filter === 'duplicate' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
                        onClick={() => setFilter('duplicate')}
                    >
                        Duplicate ({importData.duplicate || 0})
                    </button>
                    <button 
                        className={`py-1 px-2 md:py-2 md:px-4 -mb-px text-xs md:text-sm ${filter === 'existing' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500'}`}
                        onClick={() => setFilter('existing')}
                    >
                        Existing ({importData.existing || 0})
                    </button>
                </div>

                {/* Search */}
                <input
                    type="text"
                    placeholder="Search"
                    className="w-full md:w-auto px-3 py-1 md:px-4 md:py-2 text-sm border rounded-md"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            {/* Items Display - Table for desktop, Cards for mobile */}
            {!isMobile ? (
                // Desktop Table View
                <div className="border rounded-lg overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-3 md:px-6 py-2 md:py-3 text-left">
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setSelectedItems(filteredItems.map(item => item.id));
                                            } else {
                                                setSelectedItems([]);
                                            }
                                        }}
                                    />
                                </th>
                                <th className="px-3 md:px-6 py-2 md:py-3 text-left text-xs font-medium text-gray-500 uppercase">ID</th>
                                <th className="px-3 md:px-6 py-2 md:py-3 text-left text-xs font-medium text-gray-500 uppercase">Name</th>
                                <th className="px-3 md:px-6 py-2 md:py-3 text-left text-xs font-medium text-gray-500 uppercase">Username</th>
                                <th className="px-3 md:px-6 py-2 md:py-3 text-left text-xs font-medium text-gray-500 uppercase">Password</th>
                                <th className="px-3 md:px-6 py-2 md:py-3 text-left text-xs font-medium text-gray-500 uppercase">URL</th>
                                <th className="px-3 md:px-6 py-2 md:py-3 text-left text-xs font-medium text-gray-500 uppercase">Folder</th>
                                <th className="px-3 md:px-6 py-2 md:py-3 text-left text-xs font-medium text-gray-500 uppercase">Notes</th>
                                <th className="px-3 md:px-6 py-2 md:py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {filteredItems.map((item, index) => (
                                <tr key={item.id} className={`
                                    ${isDuplicate(item.id) ? 'bg-yellow-100' : ''}
                                    ${isExisting(item) ? 'bg-amber-50' : ''}
                                `}>
                                    <td className="px-3 md:px-6 py-2 md:py-4">
                                        <input
                                            type="checkbox"
                                            checked={selectedItems.includes(item.id)}
                                            onChange={() => handleCheckboxChange(item.id)}
                                        />
                                    </td>
                                    <td className="px-3 md:px-6 py-2 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-500">{index + 1}</td>
                                    <td className="px-3 md:px-6 py-2 md:py-4 whitespace-nowrap text-xs md:text-sm font-medium text-gray-900">{item.name || '-'}</td>
                                    <td className="px-3 md:px-6 py-2 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-500">{item.username || '-'}</td>
                                    <td className="px-3 md:px-6 py-2 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-500">
                                        <div className="flex items-center space-x-2">
                                            {item.password ? (
                                                <>
                                                    <span>
                                                        {visiblePasswords.includes(item.name) ? item.password : '•'.repeat(8)}
                                                    </span>
                                                    <button
                                                        onClick={() => togglePasswordVisibility(item.name)}
                                                        className="text-gray-400 hover:text-gray-600"
                                                    >
                                                        {visiblePasswords.includes(item.name) ? (
                                                            <EyeSlashIcon className="h-3 w-3 md:h-4 md:w-4" />
                                                        ) : (
                                                            <EyeIcon className="h-3 w-3 md:h-4 md:w-4" />
                                                        )}
                                                    </button>
                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                    </td>
                                    <td className="px-3 md:px-6 py-2 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-500">{truncateUrl(item.url, isMobile ? 15 : 30) || '-'}</td>
                                    <td className="px-3 md:px-6 py-2 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-500">{item.folder || '-'}</td>
                                    <td className="px-3 md:px-6 py-2 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-500">{item.notes ? '...' : '-'}</td>
                                    <td className="px-3 md:px-6 py-2 md:py-4 whitespace-nowrap text-xs md:text-sm text-gray-500">
                                        <button
                                            onClick={() => handleRemoveItem(item.id)}
                                            className="text-red-600 hover:text-red-800"
                                        >
                                            <TrashIcon className="h-4 w-4 md:h-5 md:w-5" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                // Mobile Card View
                <div className="space-y-4">
                    <div className="bg-gray-100 p-2 text-sm font-medium flex items-center">
                        <input
                            type="checkbox"
                            className="mr-2"
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedItems(filteredItems.map(item => item.id));
                                } else {
                                    setSelectedItems([]);
                                }
                            }}
                        />
                        <span>Select All</span>
                    </div>
                    
                    {filteredItems.map((item, index) => (
                        <div key={item.id} className={`border rounded-lg p-3 
                            ${isDuplicate(item.id) ? 'bg-yellow-50' : ''}
                            ${isExisting(item) ? 'bg-amber-50' : ''}
                        `}>
                            <div className="flex justify-between items-start mb-2">
                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={selectedItems.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                        className="mr-2"
                                    />
                                    <span className="text-sm font-medium">{index + 1}. {item.name || 'Unnamed Item'}</span>
                                </div>
                                <button
                                    onClick={() => handleRemoveItem(item.id)}
                                    className="text-red-600"
                                >
                                    <TrashIcon className="h-4 w-4" />
                                </button>
                            </div>
                            
                            <div className="grid grid-cols-2 gap-2 text-xs">
                                <div>
                                    <span className="font-medium text-gray-500">Username:</span>
                                    <div className="mt-1">{item.username || '-'}</div>
                                </div>
                                
                                <div>
                                    <span className="font-medium text-gray-500">Password:</span>
                                    <div className="mt-1 flex items-center">
                                        {item.password ? (
                                            <>
                                                <span className="mr-1">
                                                    {visiblePasswords.includes(item.name) ? item.password : '•'.repeat(6)}
                                                </span>
                                                <button
                                                    onClick={() => togglePasswordVisibility(item.name)}
                                                    className="text-gray-400"
                                                >
                                                    {visiblePasswords.includes(item.name) ? (
                                                        <EyeSlashIcon className="h-3 w-3" />
                                                    ) : (
                                                        <EyeIcon className="h-3 w-3" />
                                                    )}
                                                </button>
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </div>
                                
                                <div>
                                    <span className="font-medium text-gray-500">URL:</span>
                                    <div className="mt-1 truncate">{item.url || '-'}</div>
                                </div>
                                
                                <div>
                                    <span className="font-medium text-gray-500">Folder:</span>
                                    <div className="mt-1">{item.folder || '-'}</div>
                                </div>
                                
                                {item.notes && (
                                    <div className="col-span-2">
                                        <span className="font-medium text-gray-500">Notes:</span>
                                        <div className="mt-1 text-xs">...</div>
                                    </div>
                                )}
                            </div>
                            {isExisting(item) && (
                                <div className="col-span-2 mt-2 text-xs text-amber-700 flex items-center">
                                    <InformationCircleIcon className="h-3 w-3 mr-1" />
                                    Already exists in your vault
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {/* Action Buttons - made responsive */}
            <div className="mt-4 flex flex-col md:flex-row gap-2 md:justify-end md:space-x-4">
                {/* Remove Selected Button */}
                <button
                    className={`w-full md:w-auto px-4 py-2 rounded-md text-sm ${
                        selectedItems.length === 0 
                            ? 'bg-gray-400 cursor-not-allowed' 
                            : 'bg-red-600 hover:bg-red-700 text-white'
                    }`}
                    onClick={handleRemoveSelected}
                    disabled={selectedItems.length === 0}
                >
                    Remove Selected ({selectedItems.length})
                </button>

                {/* Import Button - Show count of new items to be imported */}
                <button
                    className={`w-full md:w-auto px-4 py-2 rounded-md text-sm ${
                        importing ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 text-white'
                    }`}
                    onClick={handleImport}
                    disabled={importing || selectedItems.length === 0}
                >
                    {importing ? (
                        <div className="flex items-center justify-center">
                            <span className="animate-spin h-4 w-4 mr-2 border-t-2 border-b-2 border-white rounded-full"></span>
                            Importing...
                        </div>
                    ) : (
                        `Import Selected (${selectedItems.filter(id => {
                            const item = importData.items.find(i => i.id === id);
                            return item && !item.isExisting;
                        }).length} new)`
                    )}
                </button>
            </div>
        </div>
    );
};

export default ImportProgress; 