import { ISharedPassword, ISharePasswordRequest } from '../types/share.types';
import { config } from '../extension/config';
import { store } from '../store';
import { secureStore } from '../utils/secureStore';
import { encryptKeyData } from '../utils/encryption';
import { ShareItemRequest, ShareCategoryRequest, GroupedItems } from '../types/share.types';
import { ISharedItem } from '../types/share.types';

const API_URL = config.API_URL;

export class ShareService {
    private static getToken(): string {
        const token = store.getState().auth.token;
        if (!token) {
            throw new Error('No authentication token found');
        }
        return token;
    }

    static async sharePassword(request: ISharePasswordRequest): Promise<void> {

        const vaultKey = secureStore.getVaultKey();
        const sharedKey = encryptKeyData(vaultKey);

        const response = await fetch(`${API_URL}/vault/share`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`
            },
            body: JSON.stringify({
                ...request,
                sharedKey
            })
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to share password');
        }
    }

    static async getSharedPasswords(): Promise<ISharedPassword[]> {
        const response = await fetch(`${API_URL}/vault/shared-passwords`, {
            headers: {
                'Authorization': `Bearer ${this.getToken()}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch shared passwords');
        }

        return response.json();
    }

    static async getSharedByMePasswords(): Promise<ISharedPassword[]> {
        const response = await fetch(`${API_URL}/vault/shared-by-me`, {
            headers: {
                'Authorization': `Bearer ${this.getToken()}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch shared by me passwords');
        }

        return response.json();
    }

    static async revokeAccess(shareId: string): Promise<void> {
        const response = await fetch(`${API_URL}/vault/share/${shareId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${this.getToken()}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to revoke access');
        }
    }

    // static async updatePermissionLevel(shareId: string, permissionLevel: string): Promise<void> {
    //     console.log('shareId', shareId);
    //     console.log('permissionLevel', permissionLevel);
    //     const response = await fetch(`${API_URL}/vault/share/${shareId}/permission`, {
    //         method: 'PUT',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${this.getToken()}`
    //         },
    //         body: JSON.stringify({ permission_level: permissionLevel })
    //     });

    //     if (!response.ok) {
    //         throw new Error('Failed to update permission level');
    //     }
    // }

    static async updateExpiry(shareId: string, expiresAt: Date | null): Promise<void> {
        const response = await fetch(`${API_URL}/vault/share/${shareId}/expiry`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`
            },
            body: JSON.stringify({ expires_at: expiresAt })
        });

        if (!response.ok) {
            throw new Error('Failed to update expiry');
        }
    }

    static async getAllItemTitles(): Promise<GroupedItems> {
        try {
            const token = this.getToken();
            const response = await fetch(`${API_URL}/share/items/all-titles`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch item titles');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching item titles:', error);
            throw error;
        }
    }

    static async shareItems(request: ShareItemRequest): Promise<any> {
        try {
            const token = this.getToken();

            const vaultKey = secureStore.getVaultKey();
            const sharedKey = encryptKeyData(vaultKey);
            console.log("vaultKey", vaultKey);
            // Process each item
            console.log("request", request);
            const promises = request.itemIds.map(async (item) => {
                console.log("sharedKey", sharedKey);

                const response = await fetch(`${API_URL}/share/item`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        itemId: item.id,
                        itemType: item.type,
                        shareType: request.shareType,
                        sharedWithEmail: request.sharedWithEmail,
                        permissionLevel: request.permissionLevel,
                        expiresAt: request.expiresAt,
                        sharedKey,
                        teamId: request.teamId
                    })
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Failed to share item');
                }

                return await response.json();
            });

            return await Promise.all(promises);
        } catch (error) {
            console.error('Error sharing items:', error);
            throw error;
        }
    }

    static async shareCategory(request: ShareCategoryRequest): Promise<any> {
        try {
            const token = this.getToken();

            // Generate a shared key for encryption
            const sharedKey = secureStore.getVaultKey();

            const response = await fetch(`${API_URL}/share/category`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    categoryId: request.categoryId,
                    sharedWithEmail: request.sharedWithEmail,
                    permissionLevel: request.permissionLevel,
                    expiresAt: request.expiresAt,
                    sharedKey
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to share category');
            }

            return await response.json();
        } catch (error) {
            console.error('Error sharing category:', error);
            throw error;
        }
    }

    static async getSharedItems(): Promise<ISharedItem[]> {
        try {
            const response = await fetch(`${API_URL}/share/items/shared-with-me`, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch shared items');
            }

            return response.json();
        } catch (error) {
            console.error('Error fetching shared items:', error);
            throw error;
        }
    }

    static async getItemsSharedByMe(): Promise<ISharedItem[]> {
        try {
            const response = await fetch(`${API_URL}/share/items/shared-by-me`, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch shared items');
            }

            return response.json();
        } catch (error) {
            console.error('Error fetching shared items:', error);
            throw error;
        }
    }

    static async revokeItemAccess(id: string): Promise<void> {
        try {
            const response = await fetch(`${API_URL}/share/item/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to revoke access');
            }
        } catch (error) {
            console.error('Error revoking access:', error);
            throw error;
        }
    }

    static async updateItemExpiry(id: string, expiresAt: Date | null): Promise<void> {
        try {
            const response = await fetch(`${API_URL}/share/item/${id}/expiry`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getToken()}`
                },
                body: JSON.stringify({ expires_at: expiresAt })
            });

            if (!response.ok) {
                throw new Error('Failed to update expiry');
            }
        } catch (error) {
            console.error('Error updating expiry:', error);
            throw error;
        }
    }

    static async updatePermissionLevel(id: string, permissionLevel: string): Promise<void> {
        try {
            const response = await fetch(`${API_URL}/share/item/${id}/permission`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getToken()}`
                },
                body: JSON.stringify({ permission_level: permissionLevel })
            });

            if (!response.ok) {
                throw new Error('Failed to update permission level');
            }
        } catch (error) {
            console.error('Error updating permission level:', error);
            throw error;
        }
    }
}