import React, { useState, useEffect } from 'react';
import { TeamService, Team } from '../../services/team.service';
import VaultSidebar from '../../components/vault/VaultSidebar';
import VaultHeader from '../../components/vault/VaultHeader';
import { PlusIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import TeamCard from '../../components/team/TeamCard';
import CreateTeamModal from '../../components/team/CreateTeamModal';
import { useAppSelector } from '../../hooks/useRedux';
import toast from 'react-hot-toast';

const TeamsPage: React.FC = () => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { permissions } = useAppSelector(state => state.auth);
  const canManageTeams = permissions?.manage_team;
  const [teamStorageData, setTeamStorageData] = useState<{[key: string]: any}>({});
  const [refreshingTeam, setRefreshingTeam] = useState<string | null>(null);

  const fetchTeams = async () => {
    try {
      setLoading(true);
      const response = await TeamService.getUserTeams();
      console.log('response', response.teams);
      setTeams(response.teams || []);
    } catch (error) {
      console.error('Error fetching teams:', error);
      toast.error('Failed to load teams');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  const handleTeamCreated = () => {
    fetchTeams();
    setIsModalOpen(false);
    // toast.success('Team created successfully');
  };

  const handleTeamDeleted = (teamId: string) => {
    setTeams(teams.filter(team => team.id !== teamId));
    // toast.success('Team deleted successfully');
  };

  const handleRefreshTeamStorage = async (teamId: string) => {
    try {
      setRefreshingTeam(teamId);
      const storageData = await TeamService.fetchTeamStorageUsage(teamId);
      console.log('storageData', storageData);
      setTeamStorageData(prev => ({
        ...prev,
        [teamId]: storageData
      }));
      fetchTeams();
      toast.success('Storage usage updated');
    } catch (error) {
      console.error('Error fetching team storage:', error);
      toast.error('Failed to refresh storage data');

    } finally {
      setRefreshingTeam(null);
    }
  };

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <VaultSidebar />
      <div className="flex flex-col flex-1 overflow-hidden">
        <VaultHeader />
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <h1 className="text-2xl font-semibold text-gray-900">Teams</h1>
            </div>
            <div className="w-full px-4 sm:px-6 md:px-8">
              {loading ? (
                <div className="mt-6 flex justify-center">
                  <div className="animate-pulse flex space-x-4">
                    <div className="flex-1 space-y-6 py-1">
                      <div className="h-40 bg-gray-200 rounded"></div>
                      <div className="space-y-3">
                        <div className="h-4 bg-gray-200 rounded"></div>
                        <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : teams.length === 0 ? (
                <div className="mt-6 text-center py-12 border-2 border-dashed border-gray-300 rounded-lg">
                  <p className="text-gray-500">No teams found</p>
                  {canManageTeams && (
                    <button
                      className="mt-4 bg-blue-600 hover:bg-blue-700 text-white rounded-md px-4 py-2"
                      onClick={() => setIsModalOpen(true)}
                    >
                      Create your first team
                    </button>
                  )}
                </div>
              ) : (
                <div className="mt-6 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
                  {teams.map((team) => (
                    <TeamCard 
                      key={team.id} 
                      team={team} 
                      onDeleted={() => handleTeamDeleted(team.id)}
                      onUpdated={fetchTeams}
                      onRefresh={() => handleRefreshTeamStorage(team.id)}
                      storageData={teamStorageData[team.id]}
                      isRefreshing={refreshingTeam === team.id}
                    />
                  ))}
                </div>
              )}
            </div>
            {canManageTeams && (
              <button
                onClick={() => setIsModalOpen(true)}
                className="fixed bottom-8 right-8 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                title="Create Team"
              >
                <PlusIcon className="h-6 w-6" />
              </button>
            )}
          </div>
        </main>
      </div>

      <CreateTeamModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onTeamCreated={handleTeamCreated}
      />
    </div>
  );
};

export default TeamsPage;