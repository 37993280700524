import { useAppDispatch, useAppSelector } from './useRedux';
import { AuthService } from '../services/auth.service';
import { setCredentials, logout, updateMfaStatus } from '../store/slices/authSlice';
import { ILoginCredentials, IRegisterCredentials } from '../types/auth.types';
import { deriveKeys, decryptVaultKey } from '../utils/encryption';
import { secureStore } from '../utils/secureStore';
import { getBrowserAPI } from '../utils/browser';
import { config } from '../extension/config';

interface IAuthContext {
    user: string;
    token: string;
    isAuthenticated: boolean;
    loading: boolean;
    error: string | null;
    verifyMasterPassword: (password: string) => Promise<boolean>;
    role_id: number | null;
    permissions: {
        manage_team: boolean;
        manage_roles: boolean;
        invite_members: boolean;
        remove_members: boolean;
        manage_categories: boolean;
    } | null;
    team_id: string | null;
}

export const useAuth = () => {
    const dispatch = useAppDispatch();
    const { user, token, loading, error, isAuthenticated, role_id, permissions, team_id } = useAppSelector(
        (state) => state.auth
    );

    const saveAuthToExtension = async (authData: any) => {
        try {
            const safeAuthData = {
                user: authData.user,
                token: authData.token,
                isAuthenticated: authData.isAuthenticated,
                vaultKey: secureStore.getVaultKey(),
                encryptionKey: secureStore.getEncryptionKey(),
                symmetricKey: secureStore.getSymmetricKey(),
                team_id: authData.team_id
            };

            // Try both methods of communication
            const results = await Promise.allSettled([
                // Method 1: External messaging
                new Promise((resolve, reject) => {
                    chrome.runtime.sendMessage(
                        config.EXTENSION_ID,
                        { 
                            type: 'SAVE_AUTH_DATA',
                            payload: safeAuthData 
                        },
                        (response) => {
                            if (chrome.runtime.lastError) {
                                reject(chrome.runtime.lastError);
                                return;
                            }
                            resolve(response);
                        }
                    );
                }),
                // Method 2: Direct storage
                new Promise((resolve, reject) => {
                    if (chrome?.storage?.local) {
                        chrome.storage.local.set({ auth: safeAuthData }, () => {
                            if (chrome.runtime.lastError) {
                                reject(chrome.runtime.lastError);
                            } else {
                                resolve(true);
                            }
                        });
                    } else {
                        reject(new Error('Chrome storage not available'));
                    }
                })
            ]);

            // Check if either method succeeded
            const succeeded = results.some(result => result.status === 'fulfilled');
            if (!succeeded) {
                throw new Error('Failed to save auth data through any method');
            }

            return { success: true };
        } catch (error) {
            console.error('Error saving auth data to extension:', error);
            return { success: false, error };
        }
    };

    const login = async (credentials: ILoginCredentials) => {
        try {
            // If MFA code is provided, verify MFA
            if (credentials.mfaCode && credentials.tempToken) {
                console.log("mfaCode credentials", credentials);
                const mfaResponse = await AuthService.verifyMfa({
                    code: credentials.mfaCode,
                    tempToken: credentials.tempToken
                });
                console.log("mfaResponse", mfaResponse);
                if (mfaResponse.success && mfaResponse.data) {
                    console.log("we should be here after success", mfaResponse);
                    // Derive keys again for MFA verification
                    const { encryptionKey, symmetricKey } = deriveKeys(
                        credentials.password,
                        credentials.email
                    );

                    // Decrypt vault key
                    const vaultKey = decryptVaultKey(
                        mfaResponse.data.encryptedVaultKey,
                        encryptionKey
                    );

                    // Store the keys
                    secureStore.setKeys(
                        encryptionKey,
                        symmetricKey,
                        vaultKey
                    );

                    const authData = {
                        user: mfaResponse.data.user,
                        token: mfaResponse.data.token,
                        isAuthenticated: true,
                        role_id: mfaResponse.data.role_id,
                        permissions: mfaResponse.data.permissions,
                        team_id: mfaResponse.data.team_id
                    };

                    dispatch(setCredentials(authData));
                    
                    dispatch(updateMfaStatus(true));

                    try {
                        await saveAuthToExtension(authData);
                    } catch (error) {
                        console.error('Failed to sync with extension, but login successful:', error);
                    }
                    console.log("we should be here after last try", mfaResponse);
                    return { success: true };
                }

                return {
                    success: false,
                    error: mfaResponse.message || 'MFA verification failed'
                };
            }

            // Regular login flow
            const { authKey, encryptionKey, symmetricKey } = deriveKeys(
                credentials.password,
                credentials.email
            );

            const response = await AuthService.login({
                email: credentials.email,
                authKey: authKey
            });

            if (response.requiresMfa) {
                return {
                    success: false,
                    requiresMfa: true,
                    tempToken: response.tempToken,
                    message: 'MFA verification required'
                };
            }

            if (response.success && response.data) {
                try {
                    if (!response.data.encryptedVaultKey) {
                        throw new Error('Server response missing encrypted vault key');
                    }

                    // Decrypt vault key
                    const vaultKey = decryptVaultKey(
                        response.data.encryptedVaultKey,
                        encryptionKey
                    );

                    // Store the keys
                    secureStore.setKeys(
                        encryptionKey,
                        symmetricKey,
                        vaultKey
                    );

                    const authData = {
                        user: response.data.user,
                        token: response.data.token,
                        isAuthenticated: true,
                        role_id: response.data.role_id,
                        permissions: response.data.permissions,
                        team_id: response.data.team_id
                    };

                    dispatch(setCredentials(authData));
                    
                    if(response.data?.user?.two_factor_enabled){
                        dispatch(updateMfaStatus(true));

                    }
                    try {
                        await saveAuthToExtension(authData);
                        console.log('Auth data successfully synced with extension');
                    } catch (error) {
                        console.error('Failed to sync with extension, but login successful:', error);
                    }
                    return { success: true };
                } catch (decryptError) {
                    console.error('Decryption error:', decryptError);
                    return {
                        success: false,
                        error: 'Failed to decrypt vault key'
                    };
                }
            }

            return {
                success: false,
                error: response.message || 'Login failed'
            };
        } catch (err: any) {
            console.error('Login error:', err);
            return {
                success: false,
                error: err.message || 'An error occurred during login'
            };
        }
    };

    const register = async (credentials: IRegisterCredentials) => {
        try {
            const response = await AuthService.register(credentials);
            
            if (response.success) {
                // return await login({
                //     email: credentials.email,
                //     password: credentials.password
                // });
            }
            return false;
        } catch (error) {
            console.error('Registration error:', error);
            return false;
        }
    };

    const logoutUser = async () => {
        try {
            // Clear sensitive data from memory
            secureStore.clearKeys();
            
            // Clear Redux state
            dispatch(logout());
            AuthService.logout();

            // Try direct extension API first
            const browser = getBrowserAPI();
            if (browser) {
                await browser.storage.local.remove('auth');
                // Also send a message to notify the extension
                chrome.runtime?.sendMessage({
                    type: 'AUTH_STATE_CHANGED',
                    payload: null
                });
                console.log('Auth data cleared from extension storage');
                return;
            }

            // If not in extension context, try to communicate with the extension
            if (window.chrome && chrome.runtime && config.EXTENSION_ID) {
                return new Promise((resolve, reject) => {
                    const timeout = setTimeout(() => {
                        reject(new Error('Communication timeout'));
                    }, 5000);

                    chrome.runtime?.sendMessage(
                        config.EXTENSION_ID,
                        { 
                            type: 'CLEAR_AUTH_DATA'
                        },
                        (response) => {
                            clearTimeout(timeout);
                            
                            if (chrome.runtime.lastError) {
                                console.error('Extension communication failed:', chrome.runtime.lastError);
                                reject(chrome.runtime.lastError);
                                return;
                            }
                            
                            if (response && response.success) {
                                console.log('Auth data cleared from extension');
                                // Notify about state change
                                chrome.runtime?.sendMessage({
                                    type: 'AUTH_STATE_CHANGED',
                                    payload: null
                                });
                                resolve(response);
                            } else {
                                reject(new Error('Failed to clear auth data'));
                            }
                        }
                    );
                });
            } else {
                console.log('No extension communication available');
            }
        } catch (error) {
            console.error('Failed to clear extension auth data:', error);
        }
    };

    const verifyMasterPassword = async (password: string): Promise<boolean> => {
        try {
            // Get email from secure store
            if (!user) {
                throw new Error('No user found in secure store');
            }

            // Derive auth key using the same process as login
            const { authKey } = await deriveKeys(password, user.email);

            const response = await fetch(`${config.API_URL}/export/verify-master-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ authKey })
            });

            const data = await response.json();
            
            if (!data.success) {
                throw new Error(data.message);
            }

            return data.isValid;
        } catch (error) {
            console.error('Password verification failed:', error);
            return false;
        }
    };

    return {
        user,
        token,
        isAuthenticated,
        loading,
        error,
        login,
        register,
        logout: logoutUser,
        verifyMasterPassword,
        role_id,
        permissions,
        team_id
    };
};