import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { PersonalDetail } from '../../types/personalDetails';
import PersonalDetailsService from '../../services/personalDetails.service';
import { toast } from 'react-hot-toast';
import Button from '../common/Button';
import { useCategories } from '../../contexts/CategoryContext';

interface PersonalDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    personal?: PersonalDetail;
    isSharedPersonal?: boolean;
    permissionLevel?: string;
    sharedKey?: string;
    onSuccessfulUpdate?: () => Promise<void> | void;
}

const PersonalDetailsModal: React.FC<PersonalDetailsModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    personal,
    isSharedPersonal = false,
    permissionLevel = 'read',
    sharedKey,
    onSuccessfulUpdate
}) => {
    const [formData, setFormData] = useState({
        name: '',
        title: '',
        encrypted_first_name: '',
        encrypted_middle_name: '',
        encrypted_last_name: '',
        encrypted_username: '',
        gender: '',
        birthday: '',
        encrypted_email_address: '',
        encrypted_phone: '',
        encrypted_address_1: '',
        encrypted_address_2: '',
        encrypted_city_town: '',
        encrypted_state: '',
        encrypted_zip_postal_code: '',
        encrypted_country: '',
        encrypted_timezone: '',
        encrypted_notes: '',
        category_id: '',
        favourite: false
    });

    const [loading, setLoading] = useState(false);
    const { categories, refreshCategories, createCategory } = useCategories();
    const [isCreatingCategory, setIsCreatingCategory] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [creatingCategoryLoading, setCreatingCategoryLoading] = useState(false);

    useEffect(() => {
        if (isOpen && personal) {
            setFormData({
                title: personal.title || '',
                encrypted_first_name: personal.encrypted_first_name,
                encrypted_middle_name: personal.encrypted_middle_name || '',
                encrypted_last_name: personal.encrypted_last_name,
                encrypted_username: personal.encrypted_username || '',
                gender: personal.gender || '',
                birthday: personal.birthday ? personal.birthday.split('T')[0] : '',
                encrypted_email_address: personal.encrypted_email_address || '',
                encrypted_phone: personal.encrypted_phone || '',
                encrypted_address_1: personal.encrypted_address_1 || '',
                encrypted_address_2: personal.encrypted_address_2 || '',
                encrypted_city_town: personal.encrypted_city_town || '',
                encrypted_state: personal.encrypted_state || '',
                encrypted_zip_postal_code: personal.encrypted_zip_postal_code || '',
                encrypted_country: personal.encrypted_country || '',
                encrypted_timezone: personal.encrypted_timezone || '',
                encrypted_notes: personal.encrypted_notes || '',
                category_id: personal.category_id || '',
                name: personal.name || '',
                favourite: personal.favourite || false
            });
        } else if (!isOpen) {
            setFormData({
                title: '',
                encrypted_first_name: '',
                encrypted_middle_name: '',
                encrypted_last_name: '',
                encrypted_username: '',
                gender: '',
                birthday: '',
                encrypted_email_address: '',
                encrypted_phone: '',
                encrypted_address_1: '',
                encrypted_address_2: '',
                encrypted_city_town: '',
                encrypted_state: '',
                encrypted_zip_postal_code: '',
                encrypted_country: '',
                encrypted_timezone: '',
                encrypted_notes: '',
                category_id: '',
                name: '',
                favourite: false
            });
        }

        if (isOpen && !isSharedPersonal && personal?.itemType !== 'category') {
            refreshCategories();
        }
    }, [personal, isOpen]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCreateCategory = async () => {
        if (!newCategoryName.trim()) return;

        setCreatingCategoryLoading(true);
        try {
            await createCategory({ name: newCategoryName });
            await refreshCategories();

            // Find the newly created category and select it
            const newCategory = categories.find(cat => cat.name === newCategoryName);
            if (newCategory) {
                setFormData(prev => ({
                    ...prev,
                    category_id: newCategory.id
                }));
            }

            // Reset the new category UI state
            setNewCategoryName('');
            setIsCreatingCategory(false);
        } catch (error) {
            console.error('Error creating category:', error);
            toast.error('Failed to create category');
        } finally {
            setCreatingCategoryLoading(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (personal) {
                console.log("personal details before update", personal);
                await PersonalDetailsService.updatePersonalDetail(personal.id, {
                    ...formData,
                    id: personal.id,
                    favourite: formData.favourite,
                    isSharedUpdate: isSharedPersonal,
                    sharedKey,
                    itemType: personal.itemType || 'personal'
                });
                toast.success('Personal details updated successfully');
            } else {
                await PersonalDetailsService.createPersonalDetail({
                    ...formData,
                    favourite: formData.favourite
                });
                toast.success('Personal details created successfully');
            }
            if (onSuccessfulUpdate) {
                await onSuccessfulUpdate();
            } else {
                onSuccess();
            }
            onClose();
        } catch (error) {
            console.error('Error saving personal details:', error);
            toast.error(personal ? 'Failed to update personal details' : 'Failed to create personal details');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    const isReadOnly = isSharedPersonal && permissionLevel === 'read';

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

            <div className="flex min-h-full items-center justify-center p-4">
                <div className="relative bg-white rounded-lg w-full max-w-4xl mx-4">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h3 className="text-lg font-medium">
                            {personal ? 
                            <>
                            {isReadOnly ? 'View Personal Details' : 'Edit Personal Details'}
                            </>
                            : 
                            'Create New Personal Details'
                            }
                        </h3>
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit} className="p-4">
                        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                            <div className="md:col-span-3">
                                <div className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            required
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                    {!isSharedPersonal && (
                                        <>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Vault</label>
                                                {!isCreatingCategory ? (
                                                    <select
                                                        name="category_id"
                                                        value={formData.category_id}
                                                        onChange={(e) => {
                                                            if (e.target.value === "create_new") {
                                                                setIsCreatingCategory(true);
                                                            } else {
                                                                handleChange(e);
                                                            }
                                                        }}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    >
                                                        <option value="">Select Vault</option>
                                                        {categories.map((category) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                        <option value="create_new">Create new Vault</option>
                                                    </select>
                                                ) : (
                                                    <div className="mt-1 space-x-2">
                                                        <input
                                                            type="text"
                                                            value={newCategoryName}
                                                            onChange={(e) => setNewCategoryName(e.target.value)}
                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                            placeholder="Vault name"
                                                            autoFocus
                                                        />
                                                        <div className="flex items-center justify-between mt-4">
                                                            <button
                                                                type="button"
                                                                onClick={handleCreateCategory}
                                                                disabled={creatingCategoryLoading || !newCategoryName.trim()}
                                                                className={`px-3 py-2 rounded-md text-sm font-medium ${
                                                                    creatingCategoryLoading || !newCategoryName.trim()
                                                                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                                        : 'bg-blue-600 text-white hover:bg-blue-700'
                                                                }`}
                                                            >
                                                                {creatingCategoryLoading ? 'Creating...' : 'Create'}
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setIsCreatingCategory(false);
                                                                    setNewCategoryName('');
                                                                }}
                                                                className="px-3 py-2 rounded-md text-sm font-medium bg-gray-200 text-gray-700 hover:bg-gray-300"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex items-center mt-4">
                                                <input
                                                    type="checkbox"
                                                    id="favourite"
                                                    name="favourite"
                                                    checked={formData.favourite}
                                                    onChange={(e) => setFormData({ ...formData, favourite: e.target.checked })}
                                                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                />
                                                <label htmlFor="favourite" className="ml-2 block text-sm text-gray-900">
                                                    Add to favourites
                                                </label>
                                            </div>
                                        </>
                                    )}

                                </div>
                            </div>
                            <div className="md:col-span-9">
                                <div className="space-y-6">
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="text-sm font-medium text-gray-900 mb-4">Personal Information</h4>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Title</label>
                                                <select
                                                    name="title"
                                                    value={formData.title}
                                                    onChange={handleChange}
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                >
                                                    <option value="">Select title</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Dr">Dr</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">First Name</label>
                                                <input
                                                    type="text"
                                                    name="encrypted_first_name"
                                                    value={formData.encrypted_first_name}
                                                    onChange={handleChange}
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Middle Name</label>
                                                <input
                                                    type="text"
                                                    name="encrypted_middle_name"
                                                    value={formData.encrypted_middle_name}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                                                <input
                                                    type="text"
                                                    name="encrypted_last_name"
                                                    value={formData.encrypted_last_name}
                                                    onChange={handleChange}
                                                    required
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Gender</label>
                                                <select
                                                    name="gender"
                                                    value={formData.gender}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                >
                                                    <option value="">Select gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Birthday</label>
                                                <input
                                                    type="date"
                                                    name="birthday"
                                                    value={formData.birthday}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="text-sm font-medium text-gray-900 mb-4">Contact Information</h4>
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Email</label>
                                                <input
                                                    type="email"
                                                    name="encrypted_email_address"
                                                    value={formData.encrypted_email_address}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Phone</label>
                                                <input
                                                    type="tel"
                                                    name="encrypted_phone"
                                                    value={formData.encrypted_phone}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="text-sm font-medium text-gray-900 mb-4">Address</h4>
                                        <div className="space-y-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Address Line 1</label>
                                                <input
                                                    type="text"
                                                    name="encrypted_address_1"
                                                    value={formData.encrypted_address_1}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Address Line 2</label>
                                                <input
                                                    type="text"
                                                    name="encrypted_address_2"
                                                    value={formData.encrypted_address_2}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                />
                                            </div>
                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">City/Town</label>
                                                    <input
                                                        type="text"
                                                        name="encrypted_city_town"
                                                        value={formData.encrypted_city_town}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">State/Province</label>
                                                    <input
                                                        type="text"
                                                        name="encrypted_state"
                                                        value={formData.encrypted_state}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">ZIP/Postal Code</label>
                                                    <input
                                                        type="text"
                                                        name="encrypted_zip_postal_code"
                                                        value={formData.encrypted_zip_postal_code}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Country</label>
                                                    <input
                                                        type="text"
                                                        name="encrypted_country"
                                                        value={formData.encrypted_country}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 flex justify-end space-x-2">
                            <Button type="button" variant="secondary" onClick={onClose}>
                                Cancel
                            </Button>
                            {!isReadOnly && (
                                <Button type="submit" loading={loading}>
                                    {personal ? 'Update Personal Details' : 'Create Personal Details'}
                                </Button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PersonalDetailsModal; 