import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TeamService, Team } from '../../services/team.service';
import VaultSidebar from '../../components/vault/VaultSidebar';
import VaultHeader from '../../components/vault/VaultHeader';
import Button from '../../components/common/Button';
import { PlusIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { useAppSelector } from '../../hooks/useRedux';
import AddMemberModal from '../../components/team/AddMemberModal';
import InviteMemberModal from '../../components/team/InviteMemberModal';
import ActiveUsersList from '../../components/team/ActiveUsersList';
import PendingUsersList from '../../components/team/PendingUsersList';
import { RolesService } from '../../services/roles.service';
import { Role } from '../../types/team.types';
const TeamDetailsPage: React.FC = () => {
  const { teamId } = useParams<{ teamId: string }>();
  const navigate = useNavigate();
  const [team, setTeam] = useState<Team | null>(null);
  const [activeTab, setActiveTab] = useState('active');
  const [loading, setLoading] = useState(true);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showInviteMemberModal, setShowInviteMemberModal] = useState(false);
  const { permissions } = useAppSelector(state => state.auth);
  const [members, setMembers] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [roles, setRoles] = useState<Role[]>([]);

  const fetchTeamData = async () => {
    if (!teamId) return;
    
    try {
      setLoading(true);
      
      // Fetch team details
      const teamResponse = await TeamService.getTeamById(teamId);
      setTeam(teamResponse.team);
      
      // Fetch team members and invitations in a single call
      const membersResponse = await TeamService.getTeamMembers(teamId);
      setMembers(membersResponse.activeMembers || []);
      setInvitations(membersResponse.pendingInvitations || []);
      setRoles(membersResponse.roles || []);
    } catch (error) {
      console.error('Error fetching team data:', error);
      toast.error('Failed to load team data');
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = async (userId: string, newRoleId: number) => {
    const confirm = window.confirm('Are you sure you want to update the role?');
    if (!confirm) return;

    try {
        const response = await TeamService.updateUserRole(userId, newRoleId);
        if (response.success) {
            toast.success('Role updated successfully');
            fetchTeamData(); // Refresh the team members after a successful update
        } else {
            toast.error('Failed to update role: ' + response.message);
        }
    } catch (error) {
        console.error('Error updating role:', error);
        toast.error('Error updating role');
    }
};

  useEffect(() => {
    fetchTeamData();
  }, [teamId]);

  const handleAddMember = async (userId: string, roleId: number, teamId: string) => {
    if (!teamId || !team) return;
    
    try {
      await TeamService.addMemberToTeam(userId, roleId, teamId);
      fetchTeamData();
      setShowAddMemberModal(false);
      toast.success('Member added successfully');
    } catch (error) {
      console.error('Error adding member:', error);
      toast.error('Failed to add member');
    }
  };

  const handleInviteMember = async (email: string, roleId: number, teamId: string) => {
    if (!teamId || !team) return;
    
    try {
      await TeamService.inviteUser(email, roleId, teamId);
      fetchTeamData();
      setShowInviteMemberModal(false);
      toast.success('Invitation sent successfully');
    } catch (error) {
      console.error('Error inviting member:', error);
      toast.error('Failed to send invitation');
    }
  };

  const isTeamAdmin = team?.role_id === 2;
  const canManageTeam = permissions?.manage_teams || isTeamAdmin;

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <VaultSidebar />
      <div className="flex flex-col flex-1 overflow-hidden">
        <VaultHeader />
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full mx-auto px-4 sm:px-6 md:px-8">
              {/* Header with back button and team name */}
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center">
                  <button
                    onClick={() => navigate(-1)}
                    className="mr-4 p-2 rounded-full hover:bg-gray-200"
                  >
                    <ArrowLeftIcon className="h-5 w-5 text-gray-600" />
                  </button>
                  <h1 className="text-2xl font-semibold text-gray-900">
                    {loading ? 'Loading...' : team?.name}
                  </h1>
                </div>
                {canManageTeam && (
                  <div className="flex space-x-3">
                    <Button
                      variant="secondary"
                      onClick={() => setShowAddMemberModal(true)}
                    >
                      Add Member
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => setShowInviteMemberModal(true)}
                    >
                      Invite Member
                    </Button>
                  </div>
                )}
              </div>

              {loading ? (
                <div className="animate-pulse">
                  <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
                  <div className="h-64 bg-gray-200 rounded"></div>
                </div>
              ) : (
                <>
                  {/* Tab Navigation */}
                  <div className="border-b border-gray-200 mb-6">
                    <nav className="-mb-px flex space-x-8">
                      <button
                        onClick={() => setActiveTab('active')}
                        className={`${
                          activeTab === 'active'
                            ? 'border-blue-500 text-blue-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                      >
                        Active Members ({members.length})
                      </button>
                      <button
                        onClick={() => setActiveTab('pending')}
                        className={`${
                          activeTab === 'pending'
                            ? 'border-blue-500 text-blue-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                      >
                        Pending Invitations ({invitations.length})
                      </button>
                    </nav>
                  </div>

                  {/* Content Area */}
                  <div className="mt-6">
                    {activeTab === 'active' ? (
                      <ActiveUsersList 
                        members={members}
                        roles={roles}
                        onRoleChange={handleRoleChange}
                        onRevoke={fetchTeamData}
                      />
                    ) : (
                      <PendingUsersList
                        invitations={invitations}
                        onRevoke={fetchTeamData}
                        onOpenInviteModal={() => setShowInviteMemberModal(true)}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      </div>

      {/* Modals */}
      {showAddMemberModal && (
        <AddMemberModal 
          teamId={teamId || ''}
          onClose={() => setShowAddMemberModal(false)}
          onAddMember={handleAddMember}
        />
      )}

      {showInviteMemberModal && (
        <InviteMemberModal 
          teamId={teamId || ''}
          onClose={() => setShowInviteMemberModal(false)}
          onInvite={handleInviteMember}
        />
      )}
    </div>
  );
};

export default TeamDetailsPage;