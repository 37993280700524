import React from 'react';
import Modal from '../common/Modal';
import { KeyIcon, DocumentTextIcon, IdentificationIcon, CreditCardIcon, BuildingLibraryIcon } from '@heroicons/react/24/outline';

interface AddItemModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (itemType: string) => void;
}

const AddItemModal: React.FC<AddItemModalProps> = ({ isOpen, onClose, onSelect }) => {
    const items = [
        { type: 'password', label: 'Password', icon: <KeyIcon className="h-8 w-8 text-blue-500" /> },
        // { type: 'notes', label: 'Notes', icon: <DocumentTextIcon className="h-8 w-8 text-green-500" /> },
        { type: 'personalDetails', label: 'Personal Details', icon: <IdentificationIcon className="h-8 w-8 text-purple-500" /> },
        { type: 'cardDetails', label: 'Card Details', icon: <CreditCardIcon className="h-8 w-8 text-red-500" /> },
        { type: 'bankDetails', label: 'Bank Details', icon: <BuildingLibraryIcon className="h-8 w-8 text-pink-500" /> },
    ];

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Add Item">
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 px-2 sm:px-4 mb-5">
                {items.map((item) => (
                    <button
                        key={item.type}
                        onClick={() => {
                            onSelect(item.type);
                            onClose();
                        }}
                        className="flex flex-col items-center justify-center p-3 sm:p-4 bg-gray-100 rounded-lg hover:bg-gray-200 transition-all"
                    >
                        <div className="h-6 w-6 sm:h-8 sm:w-8">{item.icon}</div>
                        <span className="mt-2 text-xs sm:text-sm font-medium text-gray-700 text-center">{item.label}</span>
                    </button>
                ))}
            </div>
        </Modal>
    );
};

export default AddItemModal;
