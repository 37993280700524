import React, { useEffect, useState } from 'react';
import VaultSidebar from '../../components/vault/VaultSidebar';
import VaultHeader from '../../components/vault/VaultHeader';
import { useCategories } from '../../contexts/CategoryContext';
import { useVault } from '../../contexts/VaultContext';
import CategoryModal from '../../components/vault/CategoryModal';
import { ICategory } from '../../types/category.types';
import { toast } from 'react-hot-toast';
import { PlusIcon, PencilIcon, TrashIcon, ChevronDownIcon, ChevronRightIcon, KeyIcon, DocumentTextIcon, CreditCardIcon, BuildingLibraryIcon, UserIcon, BriefcaseIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import PasswordList from '../../components/vault/PasswordList';
import { IDecryptedPasswordEntry } from '../../types/vault.types';
import QuickAddItem from '../../components/vault/QuickAddItem';
import { VaultService } from '../../services/vault.service';
import { CategoryService } from '../../services/category.service';
import NotesList from '../../components/notes/NotesList';
import CardDetailsList from '../../components/cardDetails/CardDetailsList';
import BankDetailsList from '../../components/bankDetails/BankDetailsList';
import PersonalDetailsList from '../../components/personalDetails/PersonalDetailsList';
import { Note } from '../../types/notes';
import { CardDetail } from '../../types/cardDetails';
import { BankDetail } from '../../types/bankDetails';
import { PersonalDetail } from '../../types/personalDetails';
import { secureStore } from '../../utils/secureStore';
import { decryptData } from '../../utils/encryption';
import CreateNoteModal from '../../components/notes/CreateNoteModal';
import CardDetailsModal from '../../components/cardDetails/CardDetailsModal';
import BankDetailsModal from '../../components/bankDetails/BankDetailsModal';
import PersonalDetailsModal from '../../components/personalDetails/PersonalDetailsModal';
import DeleteCategoryModal from '../../components/vault/DeleteCategoryModal';

interface CategoryWithEntries {
    category_id: string;
    category_name: string;
    category_description: string;
    category_color: string;
    category_icon: string | null;
    id: string;
    name: string;
    description: string;
    color: string;
    icon: string | null;
    vault_id: string;
    created_at: Date;
    updated_at: Date;
    passwords?: IDecryptedPasswordEntry[];
    notes?: Note[];
    cards?: CardDetail[];
    bank_details?: BankDetail[];
    personal_details?: PersonalDetail[];
}

// Define navigation levels and item types
type NavigationLevel = 'root' | 'category' | 'type';
type ItemType = 'password' | 'note' | 'card' | 'bank' | 'personal';

const CategoriesPage: React.FC = () => {
    const { createCategory, updateCategory, deleteCategory, getCategoryEntries } = useCategories();
    const { searchQuery } = useVault();
    const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [categoriesWithEntries, setCategoriesWithEntries] = useState<CategoryWithEntries[]>([]);
    const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());
    const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
    const [selectedNote, setSelectedNote] = useState<Note | null>(null);
    const [isCardModalOpen, setIsCardModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState<CardDetail | null>(null);
    const [isBankModalOpen, setIsBankModalOpen] = useState(false);
    const [selectedBank, setSelectedBank] = useState<BankDetail | null>(null);
    const [isPersonalModalOpen, setIsPersonalModalOpen] = useState(false);
    const [selectedPersonal, setSelectedPersonal] = useState<PersonalDetail | null>(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState<{ id: string, name: string } | null>(null);

    // Navigation state
    const [currentLevel, setCurrentLevel] = useState<NavigationLevel>('root');
    const [selectedType, setSelectedType] = useState<ItemType | null>(null);
    const [activeCategoryId, setActiveCategoryId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleCreateCategory = async (data: { name: string; description?: string; color?: string }) => {
        try {
            await createCategory(data);
            toast.success('Vault created successfully');
            setIsCreateModalOpen(false);
            getCategoryWithEntries();
        } catch (error) {
            toast.error('Failed to create vault');
        }
    };

    const handleUpdateCategory = async (data: { name: string; description?: string; color?: string }) => {
        if (!selectedCategory) return;
        try {
            await updateCategory(selectedCategory.id, data);
            toast.success('Vault updated successfully');
            setIsEditModalOpen(false);
            setSelectedCategory(null);
            getCategoryWithEntries();
        } catch (error) {
            toast.error('Failed to update vault');
        }
    };

    const handleDeleteCategoryClick = (categoryId: string, categoryName: string) => {
        setCategoryToDelete({ id: categoryId, name: categoryName });
        setIsDeleteModalOpen(true);
    };

    // Navigation handlers
    const handleCategoryClick = (categoryId: string | null) => {
        setActiveCategoryId(categoryId);
        setCurrentLevel('category');
    };

    const handleTypeClick = (type: ItemType) => {
        setSelectedType(type);
        setCurrentLevel('type');
    };

    const handleBackClick = () => {
        if (currentLevel === 'type') {
            setCurrentLevel('category');
            setSelectedType(null);
        } else if (currentLevel === 'category') {
            setCurrentLevel('root');
            setActiveCategoryId(null);
        }
    };

    // Modal handlers
    const handleNoteModalClose = () => {
        setIsNoteModalOpen(false);
        setSelectedNote(null);
    };

    const handleCardModalClose = () => {
        setIsCardModalOpen(false);
        setSelectedCard(null);
    };

    const handleBankModalClose = () => {
        setIsBankModalOpen(false);
        setSelectedBank(null);
    };

    const handlePersonalModalClose = () => {
        setIsPersonalModalOpen(false);
        setSelectedPersonal(null);
    };

    // Decryption functions
    const decryptBufferData = (encryptedBuffer: any): string => {
        if (!encryptedBuffer) return '';

        try {
            if (encryptedBuffer.data && Array.isArray(encryptedBuffer.data)) {
                const uint8Array = new Uint8Array(encryptedBuffer.data);
                return new TextDecoder().decode(uint8Array);
            }

            if (typeof encryptedBuffer === 'string') {
                return encryptedBuffer;
            }

            return '';
        } catch (error) {
            console.error('Error decrypting buffer:', error);
            return '';
        }
    };

    const decryptCardDetails = (card: any) => {
        const vaultKey = secureStore.getVaultKey();
        try {
            return {
                ...card,
                encrypted_card_holder_name: card.encrypted_card_holder_name
                    ? decryptData(decryptBufferData(card.encrypted_card_holder_name), vaultKey)
                    : '',
                encrypted_card_number: card.encrypted_card_number
                    ? decryptData(decryptBufferData(card.encrypted_card_number), vaultKey)
                    : '',
                encrypted_expiration_date: card.encrypted_expiration_date
                    ? decryptData(decryptBufferData(card.encrypted_expiration_date), vaultKey)
                    : '',
                cvv: card.encrypted_cvv
                    ? decryptData(decryptBufferData(card.encrypted_cvv), vaultKey)
                    : ''
            };
        } catch (error) {
            console.error('Error decrypting card details:', error);
            return card;
        }
    };
    const decryptPasswordDetails = (password: any) => {
        const vaultKey = secureStore.getVaultKey();
        try {
            return {
                ...password,
                username: password.encrypted_username
                    ? decryptData(decryptBufferData(password.encrypted_username), vaultKey)
                    : '',
                password: password.encrypted_password
                    ? decryptData(decryptBufferData(password.encrypted_password), vaultKey)
                    : ''
            };
        } catch (error) {
            console.error('Error decrypting password details:', error);
            return password;
        }
    };

    const decryptBankDetails = (bank: any) => {
        const vaultKey = secureStore.getVaultKey();
        try {
            return {
                ...bank,
                encrypted_account_number: bank.encrypted_account_number
                    ? decryptData(decryptBufferData(bank.encrypted_account_number), vaultKey)
                    : '',
            };
        } catch (error) {
            console.error('Error decrypting bank details:', error);
            return bank;
        }
    };

    const decryptPersonalDetails = (personal: any) => {
        const vaultKey = secureStore.getVaultKey();
        try {
            return {
                ...personal,
                encrypted_first_name: personal.encrypted_first_name
                    ? decryptData(decryptBufferData(personal.encrypted_first_name), vaultKey)
                    : '',
                encrypted_last_name: personal.encrypted_last_name
                    ? decryptData(decryptBufferData(personal.encrypted_last_name), vaultKey)
                    : '',
                encrypted_email_address: personal.encrypted_email_address
                    ? decryptData(decryptBufferData(personal.encrypted_email_address), vaultKey)
                    : '',
                encrypted_phone: personal.encrypted_phone
                    ? decryptData(decryptBufferData(personal.encrypted_phone), vaultKey)
                    : ''
            };
        } catch (error) {
            console.error('Error decrypting personal details:', error);
            return personal;
        }
    };

    const decryptNotes = (note: any) => {
        const vaultKey = secureStore.getVaultKey();
        try {
            const encryptedNotesString = decryptBufferData(note.encrypted_notes);
            const decryptedNotes = decryptData(encryptedNotesString, vaultKey);
            return {
                ...note,
                encrypted_notes: decryptedNotes || ''
            };
        } catch (error) {
            console.error('Error decrypting notes:', error);
            return {
                ...note,
                encrypted_notes: '(Decryption failed)'
            };
        }
    };

    // Data fetching
    const getCategoryWithEntries = async () => {
        setLoading(true);
        try {
            const data = await CategoryService.fetchCategoriesWithEntries();
            console.log("All Categories:", data);
            const categoriesData = data as unknown as CategoryWithEntries[];

            // Process and decrypt data
            const processedCategories = categoriesData.map(category => {
                return {
                    ...category,
                    passwords: category.passwords?.map(decryptPasswordDetails) || [],
                    notes: category.notes?.map(decryptNotes) || [],
                    cards: category.cards?.map(decryptCardDetails) || [],
                    bank_details: category.bank_details?.map(decryptBankDetails) || [],
                    personal_details: category.personal_details?.map(decryptPersonalDetails) || []
                };
            });

            setCategoriesWithEntries(processedCategories);

            // Get uncategorized entries safely
            const uncategorizedCategory = processedCategories.find(category => category.category_id === null);
            if (uncategorizedCategory) {
                // Safely set card details
                if (uncategorizedCategory.cards && uncategorizedCategory.cards.length > 0) {
                    setSelectedCard(uncategorizedCategory.cards[0]);
                }

                // Safely set bank details
                if (uncategorizedCategory.bank_details && uncategorizedCategory.bank_details.length > 0) {
                    setSelectedBank(uncategorizedCategory.bank_details[0]);
                }

                // Safely set personal details
                if (uncategorizedCategory.personal_details && uncategorizedCategory.personal_details.length > 0) {
                    setSelectedPersonal(uncategorizedCategory.personal_details[0]);
                }
            }
        } catch (error) {
            console.error("Error fetching vaults:", error);
            toast.error("Failed to fetch vaults");
        } finally {
            setLoading(false);
        }
    };

    // Filter functions
    const filterEntries = (entries: IDecryptedPasswordEntry[]) => {
        if (!searchQuery || !entries) return entries || [];

        return entries.filter(entry =>
            entry.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            entry.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            entry.website_url?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    const filterNotes = (notes: Note[]) => {
        if (!searchQuery || !notes) return notes || [];

        return notes.filter(note =>
            note.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            note.encrypted_notes?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    const filterDetails = (details: any[]) => {
        if (!searchQuery || !details) return details || [];

        return details.filter(detail =>
            Object.values(detail).some(
                value => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    };

    useEffect(() => {
        getCategoryWithEntries();
    }, []);

    // Helper function to get counts
    const getItemCount = (type: ItemType, categoryId: string | null) => {
        const category = categoriesWithEntries.find(c => c.category_id === categoryId);
        if (!category) return 0;

        switch (type) {
            case 'password':
                return category.passwords?.length || 0;
            case 'note':
                return category.notes?.length || 0;
            case 'card':
                return category.cards?.length || 0;
            case 'bank':
                return category.bank_details?.length || 0;
            case 'personal':
                return category.personal_details?.length || 0;
            default:
                return 0;
        }
    };

    // Get total count for a category
    const getTotalItemCount = (categoryId: string | null) => {
        const category = categoriesWithEntries.find(c => c.category_id === categoryId);
        if (!category) return 0;

        return (
            (category.passwords?.length || 0) +
            (category.notes?.length || 0) +
            (category.cards?.length || 0) +
            (category.bank_details?.length || 0) +
            (category.personal_details?.length || 0)
        );
    };

    // Item types with icons and colors
    const typeOptions = [
        { id: 'password' as ItemType, name: 'Passwords', icon: <KeyIcon className="h-10 w-10" />, color: 'text-blue-500' },
        { id: 'note' as ItemType, name: 'Secure Notes', icon: <DocumentTextIcon className="h-10 w-10" />, color: 'text-green-500' },
        { id: 'card' as ItemType, name: 'Cards', icon: <CreditCardIcon className="h-10 w-10" />, color: 'text-purple-500' },
        { id: 'bank' as ItemType, name: 'Bank Accounts', icon: <BuildingLibraryIcon className="h-10 w-10" />, color: 'text-yellow-500' },
        { id: 'personal' as ItemType, name: 'Personal Info', icon: <UserIcon className="h-10 w-10" />, color: 'text-red-500' }
    ];

    // Render breadcrumbs
    const renderBreadcrumbs = () => {
        const activeCategory = categoriesWithEntries.find(c => c.category_id === activeCategoryId);
        const categoryName = activeCategory ? activeCategory.category_name : 'Unassigned';
        const typeName = typeOptions.find(t => t.id === selectedType)?.name || '';

        return (
            <nav className="mb-6 flex items-center space-x-2 text-sm text-gray-500">
                <button
                    onClick={() => setCurrentLevel('root')}
                    className={`hover:text-gray-900 ${currentLevel === 'root' ? 'font-medium text-gray-900' : ''}`}
                >
                    Vaults
                </button>

                {currentLevel !== 'root' && (
                    <>
                        <span>/</span>
                        <button
                            onClick={() => {
                                setCurrentLevel('category');
                                setSelectedType(null);
                            }}
                            className={`hover:text-gray-900 ${currentLevel === 'category' ? 'font-medium text-gray-900' : ''}`}
                        >
                            {categoryName == 'Uncategorized' ? 'Unassigned' : categoryName}
                        </button>
                    </>
                )}

                {currentLevel === 'type' && (
                    <>
                        <span>/</span>
                        <span className="font-medium text-gray-900">{typeName}</span>
                    </>
                )}
            </nav>
        );
    };

    // Render root level (all vaults)
    const renderRootLevel = () => {
        // Separate uncategorized from categorized items
        const uncategorized = categoriesWithEntries.find(cat => cat.category_id === null);
        const categorized = categoriesWithEntries.filter(cat => cat.category_id !== null);

        // Filter vaults based on search query
        const filteredCategorized = searchQuery ? categorized.filter(category => {
            // Filter by category name
            if (category.category_name?.toLowerCase().includes(searchQuery.toLowerCase())) {
                return true;
            }

            // Filter by items inside vaults
            const hasMatchingPasswords = category.passwords?.some(pwd =>
                pwd.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                pwd.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                pwd.website_url?.toLowerCase().includes(searchQuery.toLowerCase())
            );

            const hasMatchingNotes = category.notes?.some(note =>
                note.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                note.encrypted_notes?.toLowerCase().includes(searchQuery.toLowerCase())
            );

            const hasMatchingCards = category.cards?.some(card =>
                Object.values(card).some(
                    value => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );

            const hasMatchingBankDetails = category.bank_details?.some(bank =>
                Object.values(bank).some(
                    value => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );

            const hasMatchingPersonalDetails = category.personal_details?.some(personal =>
                Object.values(personal).some(
                    value => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );

            return hasMatchingPasswords || hasMatchingNotes || hasMatchingCards ||
                hasMatchingBankDetails || hasMatchingPersonalDetails;
        }) : categorized;

        // Filter uncategorized items
        const shouldShowUncategorized = !searchQuery || (uncategorized && (
            // Check if any items in uncategorized match the search
            (uncategorized.passwords && uncategorized.passwords.some(pwd =>
                pwd.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                pwd.username?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                pwd.website_url?.toLowerCase().includes(searchQuery.toLowerCase())
            )) ||
            (uncategorized.notes && uncategorized.notes.some(note =>
                note.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                note.encrypted_notes?.toLowerCase().includes(searchQuery.toLowerCase())
            )) ||
            (uncategorized.cards && uncategorized.cards.some(card =>
                Object.values(card).some(
                    value => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
                )
            )) ||
            (uncategorized.bank_details && uncategorized.bank_details.some(bank =>
                Object.values(bank).some(
                    value => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
                )
            )) ||
            (uncategorized.personal_details && uncategorized.personal_details.some(personal =>
                Object.values(personal).some(
                    value => typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
                )
            ))
        ));

        return (
            <div>
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-2xl font-bold text-gray-900">All Vaults</h1>
                    {/* Search results indicator */}
                    {/* {searchQuery && (
                        <p className="text-sm text-gray-500">
                            Search results for: "{searchQuery}"
                        </p>
                    )} */}
                </div>

                {loading && (
                    <div className="flex justify-center items-center p-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 dark:border-gray-100"></div>
                    </div>
                )}

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {/* Unassigned folder */}
                    {uncategorized && shouldShowUncategorized && getTotalItemCount(null) > 0 && (
                        <div
                            onClick={() => handleCategoryClick(null)}
                            className="bg-white rounded-lg shadow p-6 hover:shadow-md transition-shadow cursor-pointer border border-gray-200"
                        >
                            <div className="flex items-center">
                                <div className="h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-md bg-gray-100 text-gray-400">
                                    <BriefcaseIcon className="h-8 w-8" />
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-lg font-medium text-gray-900">Unassigned</h3>
                                    <p className="text-sm text-gray-500">{getTotalItemCount(null)} items</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Categorized folders */}
                    {filteredCategorized.map((category) => (
                        <div
                            key={category.category_id}
                            onClick={() => handleCategoryClick(category.category_id)}
                            className="bg-white rounded-lg shadow p-6 hover:shadow-md transition-shadow cursor-pointer relative group border border-gray-200"
                        >
                            <div className="flex items-center">
                                <div className="h-12 w-12 flex-shrink-0 flex items-center justify-center rounded-md bg-blue-50 text-blue-500">
                                    <BriefcaseIcon className="h-8 w-8" />
                                </div>
                                <div className="ml-4">
                                    <h3 className="text-lg font-medium text-gray-900">{category.category_name}</h3>
                                    <p className="text-sm text-gray-500">{getTotalItemCount(category.category_id)} items</p>
                                </div>
                            </div>

                            {/* Edit/Delete buttons that appear on hover */}
                            <div className="absolute top-4 right-4 hidden group-hover:flex space-x-2">
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    className="cursor-pointer"
                                >
                                    <QuickAddItem
                                        categoryId={category.category_id}
                                        onUpdate={getCategoryWithEntries}
                                    />
                                </div>
                                <div>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedCategory({
                                                id: category.category_id,
                                                name: category.category_name,
                                                description: category.category_description,
                                                color: category.category_color,
                                                icon: category.category_icon || undefined,
                                                vault_id: category.vault_id,
                                                created_at: category.created_at,
                                                updated_at: category.updated_at
                                            });
                                            setIsEditModalOpen(true);
                                        }}
                                        className="p-1 rounded-full bg-gray-100 hover:bg-gray-200 text-gray-500"
                                    >
                                        <PencilIcon className="h-4 w-4" />
                                    </button>
                                </div>
                                <div>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteCategoryClick(category.category_id, category.category_name);
                                        }}
                                        className="p-1 rounded-full bg-gray-100 hover:bg-red-100 text-gray-500 hover:text-red-500"
                                    >
                                        <TrashIcon className="h-4 w-4" />
                                    </button>
                                </div>

                            </div>
                        </div>
                    ))}

                    {/* No results message */}
                    {searchQuery && filteredCategorized.length === 0 && !shouldShowUncategorized && (
                        <div className="col-span-full text-center py-10">
                            <p className="text-gray-500">No vaults or items match your search.</p>
                        </div>
                    )}
                </div>

                <button
                    onClick={() => setIsCreateModalOpen(true)}
                    className="fixed bottom-6 right-6 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <PlusIcon className="h-6 w-6" />
                </button>
            </div>
        );
    };

    // Render category level (item types)
    const renderCategoryLevel = () => {
        const activeCategory = categoriesWithEntries.find(c => c.category_id === activeCategoryId);
        if (!activeCategory) return null;

        return (
            <div>
                <div className="flex items-center mb-6">
                    <button
                        onClick={handleBackClick}
                        className="mr-4 p-2 rounded-md hover:bg-gray-100"
                    >
                        <ArrowLeftIcon className="h-5 w-5 text-gray-500" />
                    </button>
                    <h2 className="text-xl font-semibold">
                        {activeCategory.category_name == 'Uncategorized' ? 'Unassigned' : activeCategory.category_name}
                    </h2>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {typeOptions.map(type => {
                        const count = getItemCount(type.id, activeCategoryId);
                        if (count === 0) return null; // Hide empty folders

                        return (
                            <div
                                key={type.id}
                                onClick={() => handleTypeClick(type.id)}
                                className="relative bg-white rounded-lg shadow hover:shadow-md transition-shadow duration-200 p-6 cursor-pointer"
                            >
                                <div className={`mb-4 ${type.color}`}>
                                    {type.icon}
                                </div>
                                <h3 className="text-lg font-medium text-gray-900">
                                    {type.name}
                                </h3>
                                <span className="text-sm text-gray-500">{count} items</span>
                            </div>
                        );
                    })}
                </div>
                <button
                    onClick={() => setIsCreateModalOpen(true)}
                    className="fixed bottom-6 right-6 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <PlusIcon className="h-6 w-6" />
                </button>
            </div>
        );
    };

    // Render type level (entries of selected type)
    const renderTypeLevel = () => {
        if (!selectedType) return null;

        const activeCategory = categoriesWithEntries.find(c => c.category_id === activeCategoryId);
        if (!activeCategory) return null;

        const typeName = typeOptions.find(t => t.id === selectedType)?.name || '';

        return (
            <div>
                <div className="flex items-center mb-6">
                    <button
                        onClick={handleBackClick}
                        className="mr-4 p-2 rounded-md hover:bg-gray-100"
                    >
                        <ArrowLeftIcon className="h-5 w-5 text-gray-500" />
                    </button>
                    <div>
                        <h2 className="text-xl font-semibold">{typeName}</h2>
                        <p className="text-sm text-gray-500">in {activeCategory.category_name == 'Uncategorized' ? 'Unassigned' : activeCategory.category_name}</p>
                    </div>
                </div>

                <div className="bg-white shadow rounded-lg p-6">
                    {selectedType === 'password' && activeCategory.passwords && (
                        <PasswordList
                            entries={filterEntries(activeCategory.passwords)}
                            showCategoryBadge={false}
                        />
                    )}

                    {selectedType === 'note' && activeCategory.notes && (
                        <NotesList
                            notes={filterNotes(activeCategory.notes)}
                            onUpdate={getCategoryWithEntries}
                            onEdit={(note) => {
                                setSelectedNote(note);
                                setIsNoteModalOpen(true);
                            }}
                        />
                    )}

                    {selectedType === 'card' && activeCategory.cards && (
                        <CardDetailsList
                            details={filterDetails(activeCategory.cards)}
                            onUpdate={getCategoryWithEntries}
                            onEdit={(card) => {
                                setSelectedCard(card);
                                setIsCardModalOpen(true);
                            }}
                        />
                    )}

                    {selectedType === 'bank' && activeCategory.bank_details && (
                        <BankDetailsList
                            details={filterDetails(activeCategory.bank_details)}
                            onUpdate={getCategoryWithEntries}
                            onEdit={(bank) => {
                                setSelectedBank(bank);
                                setIsBankModalOpen(true);
                            }}
                        />
                    )}

                    {selectedType === 'personal' && activeCategory.personal_details && (
                        <PersonalDetailsList
                            details={filterDetails(activeCategory.personal_details)}
                            onUpdate={getCategoryWithEntries}
                            onEdit={(personal) => {
                                setSelectedPersonal(personal);
                                setIsPersonalModalOpen(true);
                            }}
                        />
                    )}

                    {getItemCount(selectedType, activeCategoryId) === 0 && (
                        <div className="flex flex-col items-center justify-center py-12">
                            <div className="h-16 w-16 text-gray-300 mb-4">
                                {typeOptions.find(t => t.id === selectedType)?.icon}
                            </div>
                            <h3 className="text-lg font-medium text-gray-900">No items found</h3>
                            <p className="text-sm text-gray-500 mt-1">
                                There are no {typeName.toLowerCase()} in this category
                            </p>
                        </div>
                    )}
                </div>
                <button
                    onClick={() => setIsCreateModalOpen(true)}
                    className="fixed bottom-6 right-6 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    <PlusIcon className="h-6 w-6" />
                </button>
            </div>
        );
    };

    return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
            <VaultSidebar />
            <div className="flex flex-col flex-1 overflow-hidden">
                <VaultHeader />

                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="w-full mx-auto px-4 sm:px-6 md:px-8">
                            {renderBreadcrumbs()}

                            {currentLevel === 'root' && renderRootLevel()}
                            {currentLevel === 'category' && renderCategoryLevel()}
                            {currentLevel === 'type' && renderTypeLevel()}
                        </div>
                    </div>
                </main>
            </div>

            {/* Modals */}
            <CategoryModal
                isOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                onSubmit={handleCreateCategory}
                title="Create New Vault"
            />

            <CategoryModal
                isOpen={isEditModalOpen}
                onClose={() => {
                    setIsEditModalOpen(false);
                    setSelectedCategory(null);
                }}
                onSubmit={handleUpdateCategory}
                category={selectedCategory || undefined}
                title="Edit Vault"
            />

            <CreateNoteModal
                isOpen={isNoteModalOpen}
                onClose={handleNoteModalClose}
                onSuccess={() => {
                    getCategoryWithEntries();
                    handleNoteModalClose();
                }}
                note={selectedNote || undefined}
            />

            <CardDetailsModal
                isOpen={isCardModalOpen}
                onClose={handleCardModalClose}
                onSuccess={() => {
                    getCategoryWithEntries();
                    handleCardModalClose();
                }}
                card={selectedCard || undefined}
            />

            <BankDetailsModal
                isOpen={isBankModalOpen}
                onClose={handleBankModalClose}
                onSuccess={() => {
                    getCategoryWithEntries();
                    handleBankModalClose();
                }}
                bank={selectedBank || undefined}
            />

            <PersonalDetailsModal
                isOpen={isPersonalModalOpen}
                onClose={handlePersonalModalClose}
                onSuccess={() => {
                    getCategoryWithEntries();
                    handlePersonalModalClose();
                }}
                personal={selectedPersonal || undefined}
            />

            <DeleteCategoryModal
                isOpen={isDeleteModalOpen}
                onClose={() => {
                    setIsDeleteModalOpen(false);
                    setCategoryToDelete(null);
                }}
                categoryId={categoryToDelete?.id || ''}
                categoryName={categoryToDelete?.name || ''}
                onDeleted={() => {
                    getCategoryWithEntries();
                    setIsDeleteModalOpen(false);
                    setCategoryToDelete(null);
                }}
            />
        </div>
    );
};

export default CategoriesPage;