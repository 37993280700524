import React, { useEffect, useState } from 'react';
import { ShareService } from '../../services/share.service';
import { ISharedPassword, ISharedItem } from '../../types/share.types';
import { ArrowTopRightOnSquareIcon, ClipboardIcon, PencilIcon, TrashIcon, BuildingLibraryIcon, KeyIcon, DocumentTextIcon, CreditCardIcon, UserIcon, XMarkIcon, ShareIcon, FolderIcon, BriefcaseIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';
import { decryptData, decryptKeyData } from '../../utils/encryption';
import { secureStore } from '../../utils/secureStore';
import { IDecryptedPasswordEntry } from '../../types/vault.types';
import { VaultService } from '../../services/vault.service';
import EditPasswordModal from './EditPasswordModal';

import { Note } from '../../types/notes';
import { CardDetail } from '../../types/cardDetails';
import { PersonalDetail } from '../../types/personalDetails';
import { BankDetail } from '../../types/bankDetails';

import BankDetailsModal from '../../components/bankDetails/BankDetailsModal';
import CardDetailsModal from '../../components/cardDetails/CardDetailsModal';
import PersonalDetailsModal from '../../components/personalDetails/PersonalDetailsModal';
import CreateNoteModal from '../../components/notes/CreateNoteModal';
import CardDetailsService from '../../services/cardDetails.service';
import BankDetailsService from '../../services/bankDetails.service';
import PersonalDetailsService from '../../services/personalDetails.service';
import NotesService from '../../services/notes.service';
import { useVault } from '../../contexts/VaultContext';

const SharedByMeList: React.FC = () => {
    const [sharedItems, setSharedItems] = useState<ISharedItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedEntry, setSelectedEntry] = useState<IDecryptedPasswordEntry | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [permissionLevel, setPermissionLevel] = useState<string>('read');
    const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
    const [selectedNote, setSelectedNote] = useState<Note | null>(null);
    const [isCardModalOpen, setIsCardModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState<CardDetail | null>(null);
    const [isBankModalOpen, setIsBankModalOpen] = useState(false);
    const [selectedBank, setSelectedBank] = useState<BankDetail | null>(null);
    const [isPersonalModalOpen, setIsPersonalModalOpen] = useState(false);
    const [selectedPersonal, setSelectedPersonal] = useState<PersonalDetail | null>(null);
    const { searchQuery } = useVault();

    const filteredSharedItems = sharedItems.filter((item) => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        return item.item_name?.toLowerCase().includes(searchLower) ||
               item.shared_with_email?.toLowerCase().includes(searchLower) ||
               item.item_type?.toLowerCase().includes(searchLower);
    });
    useEffect(() => {
        loadSharedItems();
    }, []);

    const loadSharedItems = async () => {
        try {
            const items = await ShareService.getItemsSharedByMe();
            setSharedItems(items);
            console.log('sharedItems', items);
        } catch (error) {
            toast.error('Failed to load shared items');
        } finally {
            setLoading(false);
        }
    };
    const handleUpdate = async () => {
        await loadSharedItems();
    };

    const handleCopyPassword = async (encryptedPassword: { type: 'Buffer'; data: number[] }) => {
        try {
            const passwordBuffer = new TextDecoder().decode(new Uint8Array(encryptedPassword.data));
            const vaultKey = secureStore.getVaultKey();
            const decryptedPassword = decryptData(passwordBuffer, vaultKey);

            await navigator.clipboard.writeText(decryptedPassword);
            toast.success('Password copied to clipboard');
        } catch (err) {
            console.error('Failed to copy password:', err);
            toast.error('Failed to copy password');
        }
    };

    const handleRevokeAccess = async (id: string, sharedWithEmail: string) => {
        try {
            if (window.confirm(`Are you sure you want to revoke access? They'll lose access to this item`)) {
                await ShareService.revokeItemAccess(id);
                toast.success('Access revoked successfully');
                loadSharedItems();
            }
        } catch (error) {
            toast.error('Failed to revoke access');
        }
    };
    const getFaviconUrl = (websiteUrl: string): string => {
        try {
            const url = new URL(websiteUrl);
            return `https://www.google.com/s2/favicons?domain=${url.hostname}&sz=32`;
        } catch {
            return '/password-manager.png';
        }
    };
    const handleEditNote = (note: Note) => {
        setSelectedNote(note);
        setIsNoteModalOpen(true);
    };

    const handleNoteModalClose = () => {
        setIsNoteModalOpen(false);
        setSelectedNote(null);
    };

    const handleCardModalClose = () => {
        setIsCardModalOpen(false);
        setSelectedCard(null);
    };

    const handleBankModalClose = () => {
        setIsBankModalOpen(false);
        setSelectedBank(null);
    };
    const handlePersonalModalClose = () => {
        setIsPersonalModalOpen(false);
        setSelectedPersonal(null);
    };
    const handleEdit = async (itemId: string, shared: ISharedItem) => {
        try {
            setPermissionLevel(shared.permission_level);
            console.log("sharedKey", shared);
            const sharedKey = decryptKeyData(shared.shared_key);

            switch (shared.item_type) {

                case 'password':
                    const entry = await VaultService.getEntryById(shared.item_id, sharedKey);


                    console.log("entry", entry)
                    setSelectedEntry(entry);
                    setIsEditModalOpen(true);
                    break;

                case 'card':
                    try {
                        // Get card data with shared key
                        const cardData = await CardDetailsService.getCardDetailById(shared.item_id, true, sharedKey);
                        setSelectedCard(cardData);
                        setIsCardModalOpen(true);
                    } catch (error) {
                        console.error('Failed to decrypt card data:', error);
                        toast.error('Failed to decrypt card data');
                    }
                    break;

                case 'bank':
                    try {
                        // Get card data with shared key
                        const bankData = await BankDetailsService.getBankDetailById(shared.item_id, true, sharedKey);
                        setSelectedBank(bankData);
                        setIsBankModalOpen(true);
                    } catch (error) {
                        console.error('Failed to decrypt card data:', error);
                        toast.error('Failed to decrypt card data');
                    }
                    break;

                case 'personal':
                    try {
                        // Get personal data with shared key
                        const personalData = await PersonalDetailsService.getPersonalDetailById(shared.item_id, true, sharedKey);
                        setSelectedPersonal(personalData);
                        setIsPersonalModalOpen(true);
                    } catch (error) {
                        console.error('Failed to decrypt personal data:', error);
                        toast.error('Failed to decrypt personal data');
                    }
                    break;

                case 'note':
                    try {
                        // Get note data with shared key
                        const noteData = await NotesService.getNoteById(shared.item_id, true, sharedKey);
                        setSelectedNote(noteData);
                        setIsNoteModalOpen(true);

                    } catch (error) {
                        console.error('Failed to decrypt note data:', error);
                        toast.error('Failed to decrypt note data');
                    }
                    break;

                // Add other cases for different item types
                default:
                    console.warn('Unsupported item type:', shared.item_type);
                    break;
            }
        } catch (err) {
            console.error('Failed to prepare entry for editing:', err);
            toast.error('Failed to prepare entry for editing');
        }
    };
    const handleWebsiteLaunch = async (entry: IDecryptedPasswordEntry) => {
        try {
            // Copy credentials to clipboard
            const credentials = `Username: ${entry.username}\nPassword: ${entry.password}`;
            await navigator.clipboard.writeText(credentials);

            // Open website in new tab
            if (entry.website_url) {
                window.open(entry.website_url, '_blank');
                toast.success('Credentials copied and website opened');
            } else {
                toast.error('No website URL provided');
            }
        } catch (err) {
            console.error('Failed to launch website:', err);
            toast.error('Failed to launch website');
        }
    };

    const handlePermissionChange = async (shareId: string, newPermission: string) => {
        try {
            if (window.confirm(`Are you sure you want to update the permission level to ${newPermission}?`)) {
                await ShareService.updatePermissionLevel(shareId, newPermission);
                toast.success('Permission updated successfully');
                loadSharedItems();
            }
        } catch (error) {
            console.error('Failed to update permission:', error);
            toast.error('Failed to update permission');
        }
    };

    const getExpiryValue = (expiryDate: Date | null): string => {
        if (!expiryDate) return 'never';

        const now = new Date();
        const expiry = new Date(expiryDate);

        if (expiry <= now) {
            return 'expired';
        }

        const diffDays = Math.ceil((expiry.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));

        if (diffDays <= 1) return '1';
        if (diffDays <= 7) return '7';
        if (diffDays <= 30) return '30';
        if (diffDays <= 90) return '90';
        return '90';
    };

    const handleExpiryChange = async (shareId: string, value: string) => {
        try {
            let expiresAt: Date | null = null;

            if (value !== 'never') {
                const days = parseInt(value);
                expiresAt = new Date();
                expiresAt.setDate(expiresAt.getDate() + days);
            }

            if (window.confirm(`Are you sure you want to update the expiry?`)) {
                await ShareService.updateItemExpiry(shareId, expiresAt);
                toast.success('Expiry updated successfully');
                loadSharedItems();
            }
        } catch (error) {
            console.error('Failed to update expiry:', error);
            toast.error('Failed to update expiry');
        }
    };

    const getIconForType = (type: string) => {
        switch (type) {
            case 'password':
                return <KeyIcon className="h-5 w-5 text-gray-400" />;
            case 'note':
                return <DocumentTextIcon className="h-5 w-5 text-purple-400" />;
            case 'card':
                return <CreditCardIcon className="h-5 w-5 text-blue-400" />;
            case 'bank':
                return <BuildingLibraryIcon className="h-5 w-5 text-green-400" />;
            case 'personal':
                return <UserIcon className="h-5 w-5 text-orange-400" />;
            case 'category':
                return <BriefcaseIcon className="h-5 w-5 text-red-400" />;
            default:
                return null;
        }
    };

    if (loading) {
        return <div className="flex justify-center items-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>;
    }

    return (
        <div>
            {loading ? (
                <div className="flex justify-center items-center p-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                </div>
            ) : (
                <>
                    {filteredSharedItems.length === 0 ? (
                        <div className="text-center py-12 px-4">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
                                <ShareIcon className="h-6 w-6 text-blue-600" />
                            </div>
                            <h3 className="mt-2 text-sm font-medium text-gray-900">No items shared</h3>
                            <p className="mt-1 text-sm text-gray-500">
                                You haven't shared any items with other users yet.
                            </p>
                        </div>
                    ) : (
                        <>
                            {/* Desktop View */}
                            <div className="hidden md:block">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Item Details
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Shared With
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Access Level
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Expiration
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {filteredSharedItems.map((shared) => (
                                            <tr key={shared.id} >
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center rounded-md bg-gray-100">
                                                            {getIconForType(shared.item_type)}
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">{shared.item_name}</div>
                                                            <div className="text-sm text-gray-500 capitalize">{shared.item_type == 'category' ? 'Vault' : shared.item_type}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">{shared.shared_with_email || 'Team members'}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <select
                                                        value={shared.permission_level}
                                                        onChange={(e) => handlePermissionChange(shared.id, e.target.value)}
                                                        className={`px-3 py-1 rounded-full text-sm font-medium border-gray-300
                                                            ${shared.permission_level === 'write' ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800'}`}
                                                    >
                                                        <option value="read">Read</option>
                                                        <option value="write">Write</option>
                                                    </select>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <select
                                                        value={getExpiryValue(shared.expires_at ? new Date(shared.expires_at) : null)}
                                                        onChange={(e) => handleExpiryChange(shared.id, e.target.value)}
                                                        className={`px-3 py-1 rounded-full text-sm font-medium border-gray-300 
                                                        ${shared.expires_at && new Date(shared.expires_at) <= new Date()
                                                                ? 'bg-red-100 text-red-800'
                                                                : 'bg-gray-100 text-gray-800'}`}
                                                    >
                                                        <option value="1">1 day</option>
                                                        <option value="7">7 days</option>
                                                        <option value="30">30 days</option>
                                                        <option value="90">90 days</option>
                                                        <option value="never">Never expires</option>
                                                        {shared.expires_at && new Date(shared.expires_at) <= new Date() && (
                                                            <option value="expired" disabled>Expired</option>
                                                        )}
                                                    </select>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <div className="flex items-center justify-end space-x-3">
                                                        {shared.item_type == 'category' ? 
                                                            // <button
                                                            //     onClick={() => handleEdit(shared.item_id, shared)}
                                                            //     className="p-1.5 rounded-full text-gray-400 hover:text-blue-600 hover:bg-blue-50"
                                                            //     title="Edit item"
                                                            // >
                                                            //     <PencilIcon className="h-5 w-5" />
                                                            // </button>
                                                            <>
                                                            </>
                                                        :

                                                            <button
                                                                onClick={() => handleEdit(shared.item_id, shared)}
                                                                className="p-1.5 rounded-full text-gray-400 hover:text-blue-600 hover:bg-blue-50"
                                                                title="Edit item"
                                                            >
                                                                <PencilIcon className="h-5 w-5" />
                                                            </button>
                                                        }
                                                        <button
                                                            onClick={() => handleRevokeAccess(shared.id, shared.shared_with_email)}
                                                            className="p-1.5 rounded-full text-gray-400 hover:text-red-600 hover:bg-red-50"
                                                            title="Revoke access"
                                                        >
                                                            <XMarkIcon className="h-5 w-5" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            {/* Mobile View */}
                            <div className="block md:hidden">
                                <div className="space-y-3">
                                    {filteredSharedItems.map((shared) => (
                                        <div key={shared.id} className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                                            <div className="p-4">
                                                <div className="flex items-center">
                                                    <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center rounded-md bg-gray-100">
                                                        {getIconForType(shared.item_type)}
                                                    </div>
                                                    <div className="ml-3">
                                                        <h3 className="text-sm font-medium text-gray-900 truncate">{shared.item_name}</h3>
                                                        <p className="text-xs text-gray-500 capitalize">{shared.item_type == 'category' ? 'Vault' : shared.item_type}</p>
                                                    </div>
                                                    <div className="ml-auto">
                                                        <button
                                                            onClick={() => handleEdit(shared.item_id, shared)}
                                                            className="p-2 rounded-full text-gray-400 hover:text-blue-600"
                                                            title="Edit item"
                                                        >
                                                            <PencilIcon className="h-5 w-5" />
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="mt-4 space-y-3">
                                                    <div className="flex items-center justify-between">
                                                        <span className="text-xs font-medium text-gray-500">Shared with</span>
                                                        <span className="text-sm text-gray-900">{shared.shared_with_email || 'Team members'}</span>
                                                    </div>

                                                    <div className="flex items-center justify-between">
                                                        <span className="text-xs font-medium text-gray-500">Access level</span>
                                                        <select
                                                            value={shared.permission_level}
                                                            onChange={(e) => handlePermissionChange(shared.id, e.target.value)}
                                                            className={`px-2 py-1 text-xs rounded-full font-medium border-gray-300
                                                                ${shared.permission_level === 'write' ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800'}`}
                                                        >
                                                            <option value="read">Read</option>
                                                            <option value="write">Write</option>
                                                        </select>
                                                    </div>

                                                    <div className="flex items-center justify-between">
                                                        <span className="text-xs font-medium text-gray-500">Expiration</span>
                                                        <select
                                                            value={getExpiryValue(shared.expires_at ? new Date(shared.expires_at) : null)}
                                                            onChange={(e) => handleExpiryChange(shared.id, e.target.value)}
                                                            className={`px-2 py-1 text-xs rounded-full font-medium border-gray-300 
                                                            ${shared.expires_at && new Date(shared.expires_at) <= new Date()
                                                                    ? 'bg-red-100 text-red-800'
                                                                    : 'bg-gray-100 text-gray-800'}`}
                                                        >
                                                            <option value="1">1 day</option>
                                                            <option value="7">7 days</option>
                                                            <option value="30">30 days</option>
                                                            <option value="90">90 days</option>
                                                            <option value="never">Never expires</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="mt-4 pt-3 border-t border-gray-200">
                                                    <button
                                                        onClick={() => handleRevokeAccess(shared.id, shared.shared_with_email)}
                                                        className="w-full flex items-center justify-center px-4 py-2 border border-red-300 text-sm font-medium rounded-md text-red-700 bg-white hover:bg-red-50"
                                                    >
                                                        <XMarkIcon className="h-4 w-4 mr-1" />
                                                        Revoke Access
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}

            {selectedEntry && (
                <EditPasswordModal
                    entry={selectedEntry}
                    isOpen={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        setSelectedEntry(null);
                    }}
                    onSuccessfulUpdate={handleUpdate}
                />
            )}
            <CreateNoteModal
                isOpen={isNoteModalOpen}
                onClose={handleNoteModalClose}
                onSuccess={() => {
                    handleNoteModalClose();
                }}
                note={selectedNote || undefined}
                onSuccessfulUpdate={handleUpdate}
            />
            <BankDetailsModal
                isOpen={isBankModalOpen}
                onClose={handleBankModalClose}
                onSuccess={() => {
                    handleBankModalClose();
                }}
                bank={selectedBank || undefined}
                onSuccessfulUpdate={handleUpdate}
            />
            <CardDetailsModal
                isOpen={isCardModalOpen}
                onClose={handleCardModalClose}
                onSuccess={() => {
                    handleCardModalClose();
                    loadSharedItems();
                }}
                card={selectedCard || undefined}
                onSuccessfulUpdate={handleUpdate}
            />
            <PersonalDetailsModal
                isOpen={isPersonalModalOpen}
                onClose={handlePersonalModalClose}
                onSuccess={() => {
                    handlePersonalModalClose();
                }}
                personal={selectedPersonal || undefined}
                onSuccessfulUpdate={handleUpdate}
            />
        </div>
    );
};

export default SharedByMeList;