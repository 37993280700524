import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import RegisterForm from '../../components/auth/RegisterForm';
import Navbar from '../../components/common/Navbar';

interface InvitationData {
    token: string;
    email: string;
}

const RegisterPage: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const encodedData = queryParams.get('token');

    let invitationData: InvitationData | null = null;

    if (encodedData) {
        try {
            const decodedString = atob(encodedData);
            invitationData = JSON.parse(decodedString);
        } catch (error) {
            console.error('Error decoding invitation data:', error);
        }
    }

    return (
        <div className="bg-white">
            <Navbar />
            <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Create your account
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                    Your secure password manager
                </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <RegisterForm 
                        invitationData={invitationData}
                    />

                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">
                                    Already have an account?
                                </span>
                            </div>
                        </div>

                        <div className="mt-6">
                            <Link
                                to="/login"
                                className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                Sign in
                            </Link>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;