import React, { useState, useEffect } from 'react';
import { ICreateRole, IUpdateRole } from '../../types/roles.types';
import { RolesService } from '../../services/roles.service';
import { toast } from 'react-hot-toast';

interface RoleModalProps {
    isOpen: boolean;
    onClose: () => void;
    role?: IUpdateRole | null; // Optional for editing
    onRoleChange: () => void; // Callback to refresh roles
}

const RoleModal: React.FC<RoleModalProps> = ({ isOpen, onClose, role, onRoleChange }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [permissions, setPermissions] = useState({
        manage_team: false,
        manage_roles: false,
        invite_members: false,
        remove_members: false,
        // manage_categories: false,
    });

    useEffect(() => {
        if (role) {
            setName(role.name);
            setDescription(role.description || '');
            setPermissions(role.permissions);
        } else {
            setName('');
            setDescription('');
            setPermissions({
                manage_team: false,
                manage_roles: false,
                invite_members: false,
                remove_members: false,
                // manage_categories: false,
            });
        }
    }, [role]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            if (role) {
                // Update existing role
                await RolesService.updateRole({ id: role.id, name, description, permissions });
                toast.success('Role updated successfully');
                
            } else {
                // Create new role
                await RolesService.createRole({ name, description, permissions });
                toast.success('Role created successfully');
            }
            onRoleChange(); // Call the callback to refresh roles
            onClose(); // Close the modal after success
        } catch (error) {
            toast.error('Failed to save role');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                    <h2 className="text-lg font-semibold mb-4">{role ? 'Edit Role' : 'Create Role'}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Role Name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Description</label>
                            <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-500"
                            />
                        </div>
                        <div className="mb-4">
                            <h3 className="text-sm font-medium text-gray-700 mb-2">Permissions</h3>
                            {Object.keys(permissions).map((key) => (
                                <div key={key} className="flex items-center">
                                    <input
                                        type="checkbox"
                                        checked={permissions[key as keyof typeof permissions]}
                                        onChange={() => setPermissions({ ...permissions, [key]: !permissions[key as keyof typeof permissions] })}
                                        className="mr-2"
                                    />
                                    <label className="text-sm text-gray-600">{key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</label>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="button"
                                onClick={onClose}
                                className="mr-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-md"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="bg-blue-600 text-white px-4 py-2 rounded-md"
                            >
                                {role ? 'Update Role' : 'Create Role'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RoleModal; 