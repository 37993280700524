import { TeamResponse } from '../types/team.types';
import { config } from '../extension/config';
import { store } from '../store';

const API_URL = config.API_URL;

export interface Team {
  id: string;
  name: string;
  description?: string;
  created_at: string;
  updated_at?: string;
  status: string;
  member_count: number;
  role_id: number;
  role_name: string;
  encrypted_team_key?: string;
  storage_data?: any;
}

export interface TeamMember {
  id: string;
  user_id: string;
  email: string;
  role_id: number;
  role_name: string;
  added_by_email: string;
  team_role_id?: number;
  team_role_name?: string;
  created_at: string;
  two_factor_enabled: boolean;
}

export interface TeamInvitation {
  id: string;
  email: string;
  role_id: number;
  role_name: string;
  expires_at: string;
  created_at: string;
  invited_by_email: string;
}

export class TeamService {
    private static getToken(): string {
        const token = store.getState().auth.token;
        if (!token) {
            throw new Error('No authentication token found');
        }
        return token;
    }

  static async getTeamMembers(teamId?: string): Promise<any> {
    const url = teamId ? `${API_URL}/teams/${teamId}/members` : `${API_URL}/team/members`;

    const response = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${this.getToken()}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch team members');
        }

        return response.json();
    }

  static async inviteUser(email: string, roleId: number, teamId?: string): Promise<void> {
    const body: any = { email, roleId, teamId };

        const response = await fetch(`${API_URL}/team/invite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.getToken()}`
            },
      body: JSON.stringify(body)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to invite user');
        }
    }

    static async revokeInvitation(invitationId: string): Promise<void> {
        const response = await fetch(`${API_URL}/team/invite/${invitationId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${this.getToken()}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to revoke invitation');
        }
    }

    static async updateUserRole(userId: string, roleId: number): Promise<any> {
        try {
            const response = await fetch(`${API_URL}/team/update-role/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.getToken()}`
                },
                body: JSON.stringify({ roleId }),
            });

            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to update user role');
        }
    }

  static async removeMemberFromTeam(userId: string): Promise<void> {
    const response = await fetch(`${API_URL}/teams/remove-member/${userId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${this.getToken()}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to remove team member');
        }
    }

  static async deleteUser(userId: string): Promise<void> {
    const response = await fetch(`${API_URL}/team/delete-user/${userId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${this.getToken()}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to remove team member');
    }
  }
    static async validateInvitationToken(token: string) {
        try {
          const response = await fetch(`${API_URL}/team/validate-invitation`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });
          
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to validate invitation');
          }
          
          return await response.json();
        } catch (error) {
          console.error('Error validating invitation token:', error);
          throw error;
        }
      }
    
  static async createTeam(name: string, description: string, encrypted_team_key: string): Promise<any> {
    const response = await fetch(`${API_URL}/teams`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getToken()}`
      },
      body: JSON.stringify({ name, description, encrypted_team_key })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to create team');
    }

    return response.json();
  }

  static async getUserTeams(): Promise<{ teams: Team[] }> {
    const response = await fetch(`${API_URL}/teams`, {
      headers: {
        'Authorization': `Bearer ${this.getToken()}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch teams');
    }

    return response.json();
  }

  static async getTeamById(teamId: string): Promise<{ team: Team }> {
    const response = await fetch(`${API_URL}/teams/${teamId}`, {
      headers: {
        'Authorization': `Bearer ${this.getToken()}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch team details');
    }

    return response.json();
  }

  static async getTeamInvitations(teamId: string): Promise<{ invitations: TeamInvitation[] }> {
    const response = await fetch(`${API_URL}/teams/${teamId}/invitations`, {
      headers: {
        'Authorization': `Bearer ${this.getToken()}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch team invitations');
    }

    return response.json();
  }

  static async getUsersWithoutTeam(): Promise<{ users: any[] }> {
    const response = await fetch(`${API_URL}/teams/users/available`, {
      headers: {
        'Authorization': `Bearer ${this.getToken()}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch available users');
    }

    return response.json();
  }

  static async addMemberToTeam(userId: string, roleId: number, teamId: string): Promise<any> {
    const response = await fetch(`${API_URL}/teams/${teamId}/members`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getToken()}`
      },
      body: JSON.stringify({
        memberUserId: userId,
        roleId: roleId,
        teamId: teamId
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to add member to team');
    }

    return response.json();
  }

  static async removeMember(teamId: string, memberId: string): Promise<any> {
    const response = await fetch(`${API_URL}/teams/${teamId}/members/${memberId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${this.getToken()}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to remove team member');
    }

    return response.json();
  }

  static async deleteTeam(teamId: string, deleteUsers: boolean = false): Promise<any> {
    try {
        const token = store.getState().auth.token;
        
        if (!token) {
            throw new Error('Authentication token not found');
        }
        
        const response = await fetch(`${API_URL}/teams/${teamId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ deleteUsers })
        });
        
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to delete team');
        }
        
        return await response.json();
    } catch (error) {
        console.error('Delete team error:', error);
        throw error;
    }
  }

  static async acceptInvitation(token: string): Promise<any> {
    try {
      const response = await fetch(`${API_URL}/team/accept-invitation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to accept invitation');
      }
      
      return data;
    } catch (error) {
      console.error('Accept invitation error:', error);
      throw error;
    }
  }

  static async updateTeam(teamId: string, teamData: { name: string; description: string }): Promise<any> {
    try {
        const token = store.getState().auth.token;
        
        if (!token) {
            throw new Error('Authentication token not found');
        }
        
        const response = await fetch(`${API_URL}/teams/${teamId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(teamData)
        });
        
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to update team');
        }
        
        return await response.json();
    } catch (error) {
        console.error('Update team error:', error);
        throw error;
    }
  }

  static async fetchTeamStorageUsage(teamId: string): Promise<any> {
    const response = await fetch(`${API_URL}/teams/${teamId}/fetch-team-storage-usage`, {
      headers: {
        'Authorization': `Bearer ${this.getToken()}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch team storage usage');
    }

    return response.json();
  }

  static async getTeamStorageData(teamId: string): Promise<any> {
    const response = await fetch(`${API_URL}/teams/${teamId}/storage-data`, {
      headers: {
        'Authorization': `Bearer ${this.getToken()}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch team storage data');
    }

    return response.json();
  }
};

export default TeamService;
