import React, { useState } from 'react';
import Modal from '../common/Modal';
import { CategoryService } from '../../services/category.service';
import { toast } from 'react-hot-toast';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

interface DeleteCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  categoryId: string;
  categoryName: string;
  onDeleted: () => void;
}

const DeleteCategoryModal: React.FC<DeleteCategoryModalProps> = ({ 
  isOpen, 
  onClose, 
  categoryId,
  categoryName,
  onDeleted 
}) => {
  const [deleteOption, setDeleteOption] = useState<'keep-items' | 'delete-items'>('keep-items');
  const [isProcessing, setIsProcessing] = useState(false);
  
  const handleDeleteCategory = async () => {
    setIsProcessing(true);
    
    try {
      await CategoryService.deleteCategory(categoryId, deleteOption === 'delete-items');
      toast.success(
        deleteOption === 'delete-items' 
          ? 'Vault and all contained items have been deleted' 
          : 'Vault deleted and items moved to unassigned'
      );
      onDeleted();
    } catch (error) {
      console.error('Error deleting vault:', error);
      toast.error('Failed to delete vault');
    } finally {
      setIsProcessing(false);
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Vault"
    >
      <div className="p-6">
        <div className="flex items-center mb-5">
          <div className="flex-shrink-0 bg-red-100 rounded-full p-2">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
          </div>
          <div className="ml-3">
            <h3 className="text-lg font-medium text-gray-900">Delete "{categoryName}"</h3>
          </div>
        </div>
        
        <p className="text-sm text-gray-500 mb-6">
          This action cannot be undone. Please select how you want to handle items in this vault:
        </p>
        
        <div className="space-y-4 mb-6">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="keep-items"
                name="delete-option"
                type="radio"
                checked={deleteOption === 'keep-items'}
                onChange={() => setDeleteOption('keep-items')}
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="keep-items" className="font-medium text-gray-700">Keep items as unassigned</label>
              <p className="text-gray-500">Items will be moved to the unassigned vault.</p>
            </div>
          </div>
          
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="delete-items"
                name="delete-option"
                type="radio"
                checked={deleteOption === 'delete-items'}
                onChange={() => setDeleteOption('delete-items')}
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="delete-items" className="font-medium text-gray-700">Delete all items</label>
              <p className="text-gray-500">
                <span className="text-red-500 font-semibold">WARNING:</span> This will permanently delete all items in this vault.
              </p>
            </div>
          </div>
        </div>
        
        <div className="flex justify-end gap-3">
          <button
            type="button"
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onClose}
            disabled={isProcessing}
          >
            Cancel
          </button>
          <button
            type="button"
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
            onClick={handleDeleteCategory}
            disabled={isProcessing}
          >
            {isProcessing ? 'Deleting...' : 'Delete Vault'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteCategoryModal; 