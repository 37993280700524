import { store } from '../store';
import { logout } from '../store/slices/authSlice';
import { isTokenExpired } from '../utils/tokenUtils';

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const api = {
    async post(endpoint: string, data: any) {
        const token = store.getState().auth.token;
        
        // Check token expiration before making the request
        if (token && isTokenExpired(token)) {
            store.dispatch(logout());
            window.location.href = '/login';
            throw new Error('Session expired. Please login again.');
        }

        const response = await fetch(`${apiUrl}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            if (response.status === 401) {
                store.dispatch(logout());
                window.location.href = '/login';
            }
            const errorData = await response.json();
            throw new Error(errorData.message || 'An error occurred');
        }

        return response.json();
    },

    async get(endpoint: string) {
        const token = store.getState().auth.token;

        // Check token expiration before making the request
        if (token && isTokenExpired(token)) {
            store.dispatch(logout());
            window.location.href = '/login';
            throw new Error('Session expired. Please login again.');
        }

        const response = await fetch(`${apiUrl}${endpoint}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                store.dispatch(logout());
                window.location.href = '/login';
            }
            const errorData = await response.json();
            throw new Error(errorData.message || 'An error occurred');
        }

        return response.json();
    },
};

export default api;