import React, { useEffect, useState } from 'react';
import { ShareService } from '../../services/share.service';
import { ISharedItem } from '../../types/share.types';
import {
    EyeIcon,
    PencilIcon,
    BuildingLibraryIcon,
    KeyIcon,
    DocumentTextIcon,
    CreditCardIcon,
    UserIcon,
    ShareIcon,
    FolderIcon,
    ArrowLeftIcon,
    ChevronRightIcon
} from '@heroicons/react/24/outline';
import { toast } from 'react-hot-toast';
import { decryptData, decryptKeyData } from '../../utils/encryption';
import { VaultService } from '../../services/vault.service';
import EditPasswordModal from './EditPasswordModal';
import { IDecryptedPasswordEntry } from '../../types/vault.types';
import { Note } from '../../types/notes';
import { CardDetail } from '../../types/cardDetails';
import { PersonalDetail } from '../../types/personalDetails';
import { BankDetail } from '../../types/bankDetails';

import BankDetailsModal from '../bankDetails/BankDetailsModal';
import CardDetailsModal from '../cardDetails/CardDetailsModal';
import PersonalDetailsModal from '../personalDetails/PersonalDetailsModal';
import CreateNoteModal from '../notes/CreateNoteModal';
import PasswordList from '../vault/PasswordList';
import NotesList from '../notes/NotesList';
import CardDetailsList from '../cardDetails/CardDetailsList';
import BankDetailsList from '../bankDetails/BankDetailsList';
import PersonalDetailsList from '../personalDetails/PersonalDetailsList';
import { CategoryService } from '../../services/category.service';
import CardDetailsService from '../../services/cardDetails.service';
import BankDetailsService from '../../services/bankDetails.service';
import PersonalDetailsService from '../../services/personalDetails.service';
import NotesService from '../../services/notes.service';
import { CardDetailsService as CardDetailsServiceAlias } from '../../services/cardDetails.service';
import { BankDetailsService as BankDetailsServiceAlias } from '../../services/bankDetails.service';
import { PersonalDetailsService as PersonalDetailsServiceAlias } from '../../services/personalDetails.service';
import { NotesService as NotesServiceAlias } from '../../services/notes.service';
import { secureStore } from '../../utils/secureStore';
import { useVault } from '../../contexts/VaultContext';
const SharedPasswordsList: React.FC = () => {
    const { searchQuery } = useVault();
    const [sharedItems, setSharedItems] = useState<ISharedItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedEntry, setSelectedEntry] = useState<IDecryptedPasswordEntry | null>(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [permissionLevel, setPermissionLevel] = useState<string>('');
    const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
    const [selectedNote, setSelectedNote] = useState<Note | null>(null);
    const [isCardModalOpen, setIsCardModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState<CardDetail | null>(null);
    const [isBankModalOpen, setIsBankModalOpen] = useState(false);
    const [selectedBank, setSelectedBank] = useState<BankDetail | null>(null);
    const [isPersonalModalOpen, setIsPersonalModalOpen] = useState(false);
    const [selectedPersonal, setSelectedPersonal] = useState<PersonalDetail | null>(null);
    const [selctedSharedKey, setSelctedSharedKey] = useState<string>('');
    // For category navigation
    const [currentView, setCurrentView] = useState<'list' | 'category' | 'type'>('list');
    const [activeCategoryId, setActiveCategoryId] = useState<string | null>(null);
    const [activeCategoryName, setActiveCategoryName] = useState<string>('');
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [categorySharedKey, setCategorySharedKey] = useState<string>('');
    const [categoryItems, setCategoryItems] = useState<any>({
        passwords: [],
        notes: [],
        cards: [],
        bank_details: [],
        personal_details: []
    });

    // Type options for the category view
    const typeOptions = [
        { id: 'password', name: 'Passwords', icon: <KeyIcon className="h-6 w-6" />, color: 'text-blue-500' },
        { id: 'note', name: 'Secure Notes', icon: <DocumentTextIcon className="h-6 w-6" />, color: 'text-yellow-500' },
        { id: 'card', name: 'Cards', icon: <CreditCardIcon className="h-6 w-6" />, color: 'text-green-500' },
        { id: 'bank', name: 'Bank Accounts', icon: <BuildingLibraryIcon className="h-6 w-6" />, color: 'text-purple-500' },
        { id: 'personal', name: 'Personal Info', icon: <UserIcon className="h-6 w-6" />, color: 'text-red-500' }
    ];

    useEffect(() => {
        loadSharedItems();
    }, []);

    const filteredSharedItems = sharedItems.filter((item) => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        return item.item_name?.toLowerCase().includes(searchLower) ||
            item.shared_with_email?.toLowerCase().includes(searchLower) ||
            item.item_type?.toLowerCase().includes(searchLower) ||
            item.permission_level?.toLowerCase().includes(searchLower);
    });

    const loadSharedItems = async () => {
        try {
            setLoading(true);
            const sharedItems = await ShareService.getSharedItems();
            console.log("Shared items:", sharedItems);
            setSharedItems(sharedItems);
            setCurrentView('list');

        } catch (error) {
            console.error('Failed to load shared items:', error);
            toast.error('Failed to load shared items');
        } finally {
            setLoading(false);
        }
    };

    // Add these decryption functions
    const decryptBufferData = (encryptedBuffer: any): string => {
        if (!encryptedBuffer) return '';

        try {
            if (encryptedBuffer.data && Array.isArray(encryptedBuffer.data)) {
                const uint8Array = new Uint8Array(encryptedBuffer.data);
                return new TextDecoder().decode(uint8Array);
            }

            if (typeof encryptedBuffer === 'string') {
                return encryptedBuffer;
            }

            return '';
        } catch (error) {
            console.error('Error decrypting buffer:', error);
            return '';
        }
    };
    // const decryptPasswordDetails = (password: any) => {
    //     const sharedKey = categorySharedKey ?? decryptKeyData(password.shared_key);
    //     console.log("sharedKey", sharedKey);
    //     try {
    //         return {
    //             ...password,
    //             title: password.name,
    //             username: password.encrypted_username 
    //                 ? decryptData(decryptBufferData(password.encrypted_username), sharedKey)
    //                 : '',
    //             password: password.encrypted_password
    //                 ? decryptData(decryptBufferData(password.encrypted_password), sharedKey)
    //                 : '',
    //             is_shared: true,
    //             shared_key: sharedKey
    //         };
    //     } catch (error) {
    //         console.error('Error decrypting password details:', error);
    //         return password;
    //     }
    // };


    // const decryptCardDetails = (card: any) => {
    //     const sharedKey = categorySharedKey ?? decryptKeyData(card.shared_key);
    //     try {
    //         return {
    //             ...card,
    //             encrypted_card_holder_name: card.encrypted_card_holder_name
    //                 ? decryptData(decryptBufferData(card.encrypted_card_holder_name), sharedKey)
    //                 : '',
    //             encrypted_card_number: card.encrypted_card_number
    //                 ? decryptData(decryptBufferData(card.encrypted_card_number), sharedKey)
    //                 : '',
    //             encrypted_expiration_date: card.encrypted_expiration_date
    //                 ? decryptData(decryptBufferData(card.encrypted_expiration_date), sharedKey)
    //                 : '',
    //             cvv: card.encrypted_cvv
    //                 ? decryptData(decryptBufferData(card.encrypted_cvv), sharedKey)
    //                 : '',
    //             is_shared: true,
    //             shared_key: sharedKey
    //         };
    //     } catch (error) {
    //         console.error('Error decrypting card details:', error);
    //         return card;
    //     }
    // };

    // const decryptBankDetails = (bank: any) => {
    //     const sharedKey = categorySharedKey ?? decryptKeyData(bank.shared_key);
    //     try {
    //         return {
    //             ...bank,
    //             encrypted_account_number: bank.encrypted_account_number
    //                 ? decryptData(decryptBufferData(bank.encrypted_account_number), sharedKey)
    //                 : '',
    //             is_shared: true,
    //             shared_key: sharedKey
    //         };
    //     } catch (error) {
    //         console.error('Error decrypting bank details:', error);
    //         return bank;
    //     }
    // };

    // const decryptPersonalDetails = (personal: any) => {
    //     const sharedKey = categorySharedKey ?? decryptKeyData(personal.shared_key);
    //     try {
    //         return {
    //             ...personal,
    //             encrypted_first_name: personal.encrypted_first_name
    //                 ? decryptData(decryptBufferData(personal.encrypted_first_name), sharedKey)
    //                 : '',
    //             encrypted_last_name: personal.encrypted_last_name
    //                 ? decryptData(decryptBufferData(personal.encrypted_last_name), sharedKey)
    //                 : '',
    //             encrypted_email_address: personal.encrypted_email_address
    //                 ? decryptData(decryptBufferData(personal.encrypted_email_address), sharedKey)
    //                 : '',
    //             encrypted_phone: personal.encrypted_phone
    //                 ? decryptData(decryptBufferData(personal.encrypted_phone), sharedKey)
    //                 : '',
    //             is_shared: true,
    //             shared_key: sharedKey
    //         };
    //     } catch (error) {
    //         console.error('Error decrypting personal details:', error);
    //         return personal;
    //     }
    // };

    // const decryptNotes = (note: any) => {
    //     const sharedKey = categorySharedKey ?? decryptKeyData(note.shared_key);
    //     console.log("Shared key:", sharedKey);
    //     try {
    //         const encryptedNotesString = decryptBufferData(note.encrypted_notes);
    //         const decryptedNotes = decryptData(encryptedNotesString, sharedKey);
    //         return {
    //             ...note,
    //             encrypted_notes: decryptedNotes || '',
    //             is_shared: true,
    //             shared_key: sharedKey
    //         };
    //     } catch (error) {
    //         console.error('Error decrypting notes:', error);
    //         return {
    //             ...note,
    //             encrypted_notes: '(Decryption failed)'
    //         };
    //     }
    // };

    // Update the handleCategoryClick function to process and decrypt the data
    const handleCategoryClick = async (category: ISharedItem) => {
        try {
            setLoading(true);

            // Decrypt the category shared key
            const sharedKey = await decryptKeyData(category?.shared_key);


            // Set state variables
            setActiveCategoryId(category.item_id);
            setActiveCategoryName(category.item_name);
            setCategorySharedKey(sharedKey);
            setSelctedSharedKey(sharedKey);

            // Fetch all items in this category
            const response = await CategoryService.getCategoryEntries(category.item_id);
            if (response && response.data) {
                const items = response.data;
                setPermissionLevel(category.permission_level);
                const decryptPasswordDetailsWithKey = (password: any) => {
                    try {
                        return {
                            ...password,
                            title: password.name,
                            username: password.encrypted_username
                                ? decryptData(decryptBufferData(password.encrypted_username), sharedKey)
                                : '',
                            password: password.encrypted_password
                                ? decryptData(decryptBufferData(password.encrypted_password), sharedKey)
                                : '',
                            is_shared: true,
                            shared_key: sharedKey,
                            permission_level: category.permission_level,
                            itemType: 'category' as 'category',
                            category_id: category.item_id
                        };
                    } catch (error) {
                        console.error('Error decrypting password details:', error);
                        return password;
                    }
                };

                const decryptNotesWithKey = (note: any) => {
                    try {
                        const encryptedNotesString = decryptBufferData(note.encrypted_notes);
                        const decryptedNotes = decryptData(encryptedNotesString, sharedKey);
                        return {
                            ...note,
                            encrypted_notes: decryptedNotes || '',
                            is_shared: true,
                            shared_key: sharedKey,
                            permission_level: category.permission_level,
                            itemType: 'category' as 'category'
                        };
                    } catch (error) {
                        console.error('Error decrypting notes:', error);
                        return {
                            ...note,
                            encrypted_notes: '(Decryption failed)'
                        };
                    }
                };

                const decryptCardDetailsWithKey = (card: any) => {
                    try {
                        return {
                            ...card,
                            encrypted_card_holder_name: card.encrypted_card_holder_name
                                ? decryptData(decryptBufferData(card.encrypted_card_holder_name), sharedKey)
                                : '',
                            encrypted_card_number: card.encrypted_card_number
                                ? decryptData(decryptBufferData(card.encrypted_card_number), sharedKey)
                                : '',
                            encrypted_expiration_date: card.encrypted_expiration_date
                                ? decryptData(decryptBufferData(card.encrypted_expiration_date), sharedKey)
                                : '',
                            cvv: card.encrypted_cvv
                                ? decryptData(decryptBufferData(card.encrypted_cvv), sharedKey)
                                : '',
                            is_shared: true,
                            shared_key: sharedKey,
                            permission_level: category.permission_level,
                            itemType: 'category' as 'category'
                        };
                    } catch (error) {
                        console.error('Error decrypting card details:', error);
                        return card;
                    }
                };

                const decryptBankDetailsWithKey = (bank: any) => {
                    try {
                        return {
                            ...bank,
                            encrypted_account_number: bank.encrypted_account_number
                                ? decryptData(decryptBufferData(bank.encrypted_account_number), sharedKey)
                                : '',
                            is_shared: true,
                            shared_key: sharedKey,
                            permission_level: category.permission_level,
                            itemType: 'category' as 'category'
                        };
                    } catch (error) {
                        console.error('Error decrypting bank details:', error);
                        return bank;
                    }
                };

                const decryptPersonalDetailsWithKey = (personal: any) => {
                    try {
                        return {
                            ...personal,
                            encrypted_first_name: personal.encrypted_first_name
                                ? decryptData(decryptBufferData(personal.encrypted_first_name), sharedKey)
                                : '',
                            encrypted_last_name: personal.encrypted_last_name
                                ? decryptData(decryptBufferData(personal.encrypted_last_name), sharedKey)
                                : '',
                            encrypted_email_address: personal.encrypted_email_address
                                ? decryptData(decryptBufferData(personal.encrypted_email_address), sharedKey)
                                : '',
                            encrypted_phone: personal.encrypted_phone
                                ? decryptData(decryptBufferData(personal.encrypted_phone), sharedKey)
                                : '',
                            is_shared: true,
                            shared_key: sharedKey,
                            permission_level: category.permission_level,
                            itemType: 'category' as 'category'
                        };
                    } catch (error) {
                        console.error('Error decrypting personal details:', error);
                        return personal;
                    }
                };

                // Organize and decrypt items by type using the local functions
                const organizedItems = {
                    passwords: items.filter((item: any) => item.type === 'password').map(decryptPasswordDetailsWithKey),
                    notes: items.filter((item: any) => item.type === 'note').map(decryptNotesWithKey),
                    cards: items.filter((item: any) => item.type === 'card_detail' || item.card_type).map(decryptCardDetailsWithKey),
                    bank_details: items.filter((item: any) => item.type === 'bank_detail' || item.bank_name).map(decryptBankDetailsWithKey),
                    personal_details: items.filter((item: any) => item.type === 'personal_detail' || item.title).map(decryptPersonalDetailsWithKey)
                };

                setCategoryItems(organizedItems);
                console.log("Organized items:", organizedItems);
            }

            // Change to category view - showing types instead of direct items
            setCurrentView('category');
        } catch (error) {
            console.error('Error fetching category items:', error);
            toast.error('Failed to load category items');
        } finally {
            setLoading(false);
        }
    };

    const handleTypeClick = (type: string) => {
        setSelectedType(type);
        setCurrentView('type');
    };

    const handleBackClick = () => {
        if (currentView === 'type') {
            setCurrentView('category');
            setSelectedType(null);
        } else if (currentView === 'category') {
            setCurrentView('list');
            setActiveCategoryId(null);
            setActiveCategoryName('');
        }
    };

    const getItemCount = (type: string) => {
        if (!categoryItems) return 0;

        switch (type) {
            case 'password':
                return categoryItems.passwords?.length || 0;
            case 'note':
                return categoryItems.notes?.length || 0;
            case 'card':
                return categoryItems.cards?.length || 0;
            case 'bank':
                return categoryItems.bank_details?.length || 0;
            case 'personal':
                return categoryItems.personal_details?.length || 0;
            default:
                return 0;
        }
    };

    const handleItemEdit = async (itemId: string, shared: ISharedItem) => {
        try {
            setPermissionLevel(shared.permission_level);
            const sharedKey = decryptKeyData(shared.shared_key);

            switch (shared.item_type) {
                case 'password':
                    const entry = await VaultService.getEntryById(shared.item_id, sharedKey);
                    setSelectedEntry(entry);
                    setIsEditModalOpen(true);
                    setSelctedSharedKey(sharedKey);
                    break;

                case 'card':
                    try {
                        const cardData = await CardDetailsService.getCardDetailById(shared.item_id, true, sharedKey);
                        setSelectedCard(cardData);
                        setIsCardModalOpen(true);
                        setSelctedSharedKey(sharedKey);

                    } catch (error) {
                        console.error('Failed to decrypt card data:', error);
                        toast.error('Failed to decrypt card data');
                    }
                    break;

                case 'bank':
                    try {
                        const bankData = await BankDetailsService.getBankDetailById(shared.item_id, true, sharedKey);
                        setSelectedBank(bankData);
                        setIsBankModalOpen(true);
                        setSelctedSharedKey(sharedKey);
                    } catch (error) {
                        console.error('Failed to decrypt bank data:', error);
                        toast.error('Failed to decrypt bank data');
                    }
                    break;

                case 'personal':
                    try {
                        const personalData = await PersonalDetailsService.getPersonalDetailById(shared.item_id, true, sharedKey);
                        setSelectedPersonal(personalData);
                        setIsPersonalModalOpen(true);
                        setSelctedSharedKey(sharedKey);
                    } catch (error) {
                        console.error('Failed to decrypt personal data:', error);
                        toast.error('Failed to decrypt personal data');
                    }
                    break;

                case 'note':
                    try {
                        const noteData = await NotesService.getNoteById(shared.item_id, true, sharedKey);
                        // Ensure these properties are set with correct typing
                        noteData.is_shared = true;
                        noteData.permission_level = shared.permission_level;
                        noteData.shared_key = sharedKey;
                        noteData.itemType = 'note' as 'note'; // Type assertion to match the literal type

                        console.log("Setting note with itemType:", noteData);
                        setSelectedNote(noteData);
                        setIsNoteModalOpen(true);
                        setSelctedSharedKey(sharedKey);
                    } catch (error) {
                        console.error('Failed to decrypt note data:', error);
                        toast.error('Failed to decrypt note data');
                    }
                    break;

                default:
                    toast.error('Unknown item type');
                    break;
            }
        } catch (error) {
            console.error('Failed to decrypt data:', error);
            toast.error('Failed to decrypt data');
        }
    };

    const getItemIcon = (type: string) => {
        switch (type) {
            case 'password':
                return <KeyIcon className="h-5 w-5 text-blue-500" />;
            case 'note':
                return <DocumentTextIcon className="h-5 w-5 text-yellow-500" />;
            case 'card':
                return <CreditCardIcon className="h-5 w-5 text-green-500" />;
            case 'bank':
                return <BuildingLibraryIcon className="h-5 w-5 text-purple-500" />;
            case 'personal':
                return <UserIcon className="h-5 w-5 text-red-500" />;
            case 'category':
                return <FolderIcon className="h-5 w-5 text-gray-500" />;
            default:
                return <KeyIcon className="h-5 w-5 text-blue-500" />;
        }
    };

    const handleNoteModalClose = () => {
        setIsNoteModalOpen(false);
        setSelectedNote(null);
    };

    const handleCardModalClose = () => {
        setIsCardModalOpen(false);
        setSelectedCard(null);
    };

    const handleBankModalClose = () => {
        setIsBankModalOpen(false);
        setSelectedBank(null);
    };

    const handlePersonalModalClose = () => {
        setIsPersonalModalOpen(false);
        setSelectedPersonal(null);
    };

    const isShareExpired = (expiresAt: Date | null | undefined): boolean => {
        if (!expiresAt) return false;
        return new Date(expiresAt) < new Date();
    };

    const getExpiryStatus = (expiresAt: Date) => {
        const now = new Date();
        const expiryDate = new Date(expiresAt);
        const diffDays = Math.ceil((expiryDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));

        if (expiryDate < now) {
            return {
                status: 'Expired',
                className: 'text-red-600 font-medium'
            };
        } else if (diffDays <= 7) {
            return {
                status: `Expires in ${diffDays} day${diffDays === 1 ? '' : 's'}`,
                className: 'text-yellow-600 font-medium'
            };
        } else {
            return {
                status: expiryDate.toLocaleDateString(),
                className: 'text-gray-500'
            };
        }
    };

    // Main items view
    const renderItemsList = () => {
        return (
            <>
                {filteredSharedItems.length === 0 ? (
                    <div className="flex flex-col items-center justify-center py-12 px-4">
                        <div className="p-4 rounded-full bg-green-50 text-green-500 mb-4">
                            <ShareIcon className="h-8 w-8" />
                        </div>
                        <h3 className="text-lg font-medium text-gray-900">No shared items</h3>
                        <p className="text-gray-500 text-center mt-2">
                            No one has shared any passwords or other items with you yet.
                        </p>
                    </div>
                ) : (
                    <>
                        <h2 className="text-xl font-semibold mb-6 p-2">Items Shared With You</h2>

                        {/* Desktop Table */}
                        <div className="hidden md:block">
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Item Details
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Shared By
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Access Level
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Expires
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {filteredSharedItems.map((shared) => (
                                            <tr key={shared.id} className={isShareExpired(shared.expires_at) ? 'bg-red-50' : ''}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center bg-gray-100 rounded-full">
                                                            {getItemIcon(shared.item_type)}
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">
                                                                {shared.item_name}
                                                            </div>
                                                            <div className="text-sm text-gray-500 capitalize">
                                                                {shared.item_type == 'category' ? 'Vault' : shared.item_type}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">{shared.shared_by_email || 'Team member'}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                                                        ${shared.permission_level === 'write' ? 'bg-blue-100 text-blue-800' : 'bg-gray-100 text-gray-800'}`}>
                                                        {shared.permission_level === 'write' ? 'Edit' : 'View'} Access
                                                    </span>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {shared.expires_at && (
                                                        <span className={getExpiryStatus(shared.expires_at as Date).className}>
                                                            {getExpiryStatus(shared.expires_at as Date).status}
                                                        </span>
                                                    )}
                                                    {!shared.expires_at && (
                                                        <span className="text-gray-500">Never</span>
                                                    )}
                                                </td>
                                                <td className="px-2 py-4 text-right text-sm font-medium space-x-2 flex justify-end mr-4">
                                                    {!isShareExpired(shared.expires_at) && (
                                                        <>

                                                            {shared.item_type === 'category' ? (
                                                                <ChevronRightIcon
                                                                    className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700"
                                                                    onClick={() => handleCategoryClick(shared)}
                                                                    title="Open Category"
                                                                />
                                                            ) : (
                                                                <>
                                                                    {shared.permission_level === 'write' ? (
                                                                        <PencilIcon
                                                                            className="h-5 w-5 cursor-pointer text-blue-500 hover:text-blue-700"
                                                                            onClick={() => handleItemEdit(shared.item_id, shared)}
                                                                            title="Edit"
                                                                        />
                                                                    ) : (
                                                                        <EyeIcon
                                                                            className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700"
                                                                            onClick={() => handleItemEdit(shared.item_id, shared)}
                                                                            title="View"
                                                                        />
                                                                    )}
                                                                </>
                                                            )}
                                                        </>

                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Updated Mobile View */}
                        <div className="md:hidden">
                            <div className="space-y-4">
                                {filteredSharedItems.map((shared) => (
                                    <div
                                        key={shared.id}
                                        className={`bg-white rounded-lg shadow border border-gray-200 p-4 ${isShareExpired(shared.expires_at) ? 'border-l-4 border-red-500' : ''
                                            }`}
                                    >
                                        {/* Item Header */}
                                        <div className="flex items-center justify-between mb-3">
                                            <div className="flex items-center space-x-3">
                                                <div className="flex-shrink-0 h-10 w-10 flex items-center justify-center bg-gray-100 rounded-full">
                                                    {getItemIcon(shared.item_type)}
                                                </div>
                                                <div>
                                                    <h3 className="text-sm font-medium text-gray-900">
                                                        {shared.item_name}
                                                    </h3>
                                                    <p className="text-xs text-gray-500 capitalize">
                                                        {shared.item_type == 'category' ? 'Vault' : shared.item_type}
                                                    </p>
                                                </div>
                                            </div>
                                            {!isShareExpired(shared.expires_at) && (
                                            <div>
                                                {shared.item_type === 'category' ? (
                                                    <button
                                                        onClick={() => handleCategoryClick(shared)}
                                                        className="p-2 text-gray-400 hover:text-gray-600"
                                                    >
                                                        <ChevronRightIcon className="h-5 w-5" />
                                                    </button>
                                                ) : (
                                                    <button
                                                        onClick={() => handleItemEdit(shared.item_id, shared)}
                                                        className="p-2 text-gray-400 hover:text-gray-600"
                                                    >
                                                        {shared.permission_level === 'write' ? (
                                                            <PencilIcon className="h-5 w-5" />
                                                        ) : (
                                                            <EyeIcon className="h-5 w-5" />
                                                        )}
                                                    </button>
                                                )}
                                            </div>
                                            )}
                                        </div>

                                        {/* Item Details */}
                                        <div>
                                            <p className="text-xs text-gray-500">Shared by</p>
                                            <p className="text-sm text-muted">
                                                {shared.shared_by_email || 'Team member'}
                                            </p>
                                        </div>
                                        <div className="mt-2 grid grid-cols-2 gap-4">
                                            <div className='mt-2'>
                                                <p className="text-xs text-gray-500 mb-2">Access Level</p>
                                                <span className={`inline-flex text-xs font-medium rounded-full px-2 py-1 ${shared.permission_level === 'write'
                                                        ? 'bg-blue-100 text-blue-800'
                                                        : 'bg-gray-100 text-gray-800'
                                                    }`}>
                                                    {shared.permission_level === 'write' ? 'Edit' : 'View'} Access
                                                </span>
                                            </div>
                                            <div className='mt-2'>
                                                <p className="text-xs text-gray-500 mb-2">Expires</p>
                                                {shared.expires_at ? (
                                                    <p className={`text-xs font-medium ${isShareExpired(shared.expires_at)
                                                            ? 'text-red-600'
                                                            : 'text-gray-900'
                                                        }`}>
                                                        {isShareExpired(shared.expires_at)
                                                            ? 'Expired'
                                                            : new Date(shared.expires_at).toLocaleDateString()
                                                        }
                                                    </p>
                                                ) : (
                                                    <span className="inline-flex text-xs font-medium rounded-full px-2 py-1 bg-gray-100 text-gray-800">
                                                        Never
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };

    const renderCategoryLevel = () => {
        return (
            <div>
                <div className="flex items-center mb-6">
                    <button
                        onClick={handleBackClick}
                        className="mr-4 p-2 rounded-md hover:bg-gray-100"
                    >
                        <ArrowLeftIcon className="h-5 w-5 text-gray-500" />
                    </button>
                    <h2 className="text-xl font-semibold">{activeCategoryName}</h2>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                    {typeOptions.map(type => {
                        const count = getItemCount(type.id);
                        if (count === 0) return null; // Hide empty types

                        return (
                            <div
                                key={type.id}
                                onClick={() => handleTypeClick(type.id)}
                                className="relative bg-white rounded-lg shadow hover:shadow-md transition-shadow duration-200 p-6 cursor-pointer"
                            >
                                <div className={`mb-4 ${type.color}`}>
                                    {type.icon}
                                </div>
                                <h3 className="text-lg font-medium text-gray-900">
                                    {type.name}
                                </h3>
                                <span className="text-sm text-gray-500">{count} items</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    // Type level view - shows entries of the selected type
    const renderTypeLevel = () => {
        if (!selectedType || !activeCategoryId) return null;

        return (
            <div>
                <div className="flex items-center mb-6">
                    <button
                        onClick={handleBackClick}
                        className="mr-4 p-2 rounded-md hover:bg-gray-100"
                    >
                        <ArrowLeftIcon className="h-5 w-5 text-gray-500" />
                    </button>
                    <div>
                        <h2 className="text-xl font-semibold">
                            {typeOptions.find(t => t.id === selectedType)?.name || ''}
                        </h2>
                        <p className="text-sm text-gray-500">in {activeCategoryName}</p>
                    </div>
                </div>

                <div className="bg-white shadow rounded-lg p-6">
                    {selectedType === 'password' && categoryItems.passwords && (
                        <PasswordList
                            entries={categoryItems.passwords.map((password: any) => ({
                                ...password,
                                category_id: activeCategoryId,
                                permission_level: permissionLevel,
                                itemType: 'category'
                            }))}
                            showCategoryBadge={false}
                            isShared={true}
                            permissionLevel={permissionLevel}
                            onSuccessfulUpdate={loadSharedItems}
                        />
                    )}

                    {selectedType === 'note' && categoryItems.notes && (
                        <NotesList
                            notes={categoryItems.notes}
                            onUpdate={() => { }}
                            onEdit={(note) => {
                                // Add the itemType here with the correct type annotation
                                const noteWithType = {
                                    ...note,
                                    itemType: 'category' as 'category' // Use type assertion to match the literal type
                                };
                                setSelectedNote(noteWithType);
                                setIsNoteModalOpen(true);
                                setSelctedSharedKey(note.shared_key);
                            }}
                        />
                    )}

                    {selectedType === 'card' && categoryItems.cards && (
                        <CardDetailsList
                            details={categoryItems.cards}
                            onUpdate={() => { }}

                            onEdit={(card) => {

                                const cardWithType = {
                                    ...card,
                                    sharedKey: selctedSharedKey,
                                    itemType: 'category' as 'category' // Use type assertion to match the literal type
                                };
                                setSelectedCard(cardWithType);
                                setIsCardModalOpen(true);
                            }}
                        />
                    )}

                    {selectedType === 'bank' && categoryItems.bank_details && (
                        <BankDetailsList
                            details={categoryItems.bank_details}
                            onUpdate={() => { }}
                            onEdit={(bank) => {
                                const bankWithType = {
                                    ...bank,
                                    sharedKey: selctedSharedKey,
                                    itemType: 'category' as 'category' // Use type assertion to match the literal type
                                };
                                setSelectedBank(bankWithType);
                                setIsBankModalOpen(true);
                            }}
                        />
                    )}

                    {selectedType === 'personal' && categoryItems.personal_details && (
                        <PersonalDetailsList
                            details={categoryItems.personal_details}
                            onUpdate={() => { }}
                            onEdit={(personal) => {
                                const personalWithType = {
                                    ...personal,
                                    sharedKey: selctedSharedKey,
                                    itemType: 'category' as 'category' // Use type assertion to match the literal type
                                };
                                setSelectedPersonal(personalWithType);
                                setIsPersonalModalOpen(true);
                            }}
                        />
                    )}

                    {selectedType && getItemCount(selectedType) === 0 && (
                        <div className="flex flex-col items-center justify-center py-12">
                            <div className="h-16 w-16 text-gray-300 mb-4">
                                {typeOptions.find(t => t.id === selectedType)?.icon}
                            </div>
                            <h3 className="text-lg font-medium text-gray-900">No items found</h3>
                            <p className="text-sm text-gray-500 mt-1">
                                There are no {typeOptions.find(t => t.id === selectedType)?.name.toLowerCase() || ''} in this category
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    // Render only the modal that's currently needed
    const renderActiveModal = () => {
        if (selectedEntry && isEditModalOpen) {
            return (
                <EditPasswordModal
                    entry={selectedEntry}
                    isOpen={isEditModalOpen}
                    onClose={() => {
                        setIsEditModalOpen(false);
                        setSelectedEntry(null);
                    }}
                    isSharedPassword={true}
                    permissionLevel={permissionLevel}
                    sharedKey={selctedSharedKey}
                    onSuccessfulUpdate={loadSharedItems}
                />
            );
        }

        if (selectedNote && isNoteModalOpen) {
            return (
                <CreateNoteModal
                    isOpen={isNoteModalOpen}
                    onClose={() => {
                        setIsNoteModalOpen(false);
                        setSelectedNote(null);
                    }}
                    onSuccess={() => {
                        setIsNoteModalOpen(false);
                        setSelectedNote(null);
                    }}
                    note={selectedNote}
                    isSharedNote={true}
                    permissionLevel={permissionLevel}
                    sharedKey={selctedSharedKey}
                    onSuccessfulUpdate={loadSharedItems}
                />
            );
        }

        if (selectedCard && isCardModalOpen) {
            return (
                <CardDetailsModal
                    isOpen={isCardModalOpen}
                    onClose={() => {
                        setIsCardModalOpen(false);
                        setSelectedCard(null);
                    }}
                    onSuccess={() => {
                        setIsCardModalOpen(false);
                        setSelectedCard(null);
                    }}
                    card={selectedCard}
                    isSharedCard={true}
                    permissionLevel={permissionLevel}
                    sharedKey={selctedSharedKey}
                    onSuccessfulUpdate={loadSharedItems}
                />
            );
        }

        if (selectedBank && isBankModalOpen) {
            return (
                <BankDetailsModal
                    isOpen={isBankModalOpen}
                    onClose={() => {
                        setIsBankModalOpen(false);
                        setSelectedBank(null);
                    }}
                    onSuccess={() => {
                        setIsBankModalOpen(false);
                        setSelectedBank(null);
                    }}
                    bank={selectedBank}
                    isSharedBank={true}
                    permissionLevel={permissionLevel}
                    sharedKey={selctedSharedKey}
                    onSuccessfulUpdate={loadSharedItems}
                />
            );
        }

        if (selectedPersonal && isPersonalModalOpen) {
            return (
                <PersonalDetailsModal
                    isOpen={isPersonalModalOpen}
                    onClose={() => {
                        setIsPersonalModalOpen(false);
                        setSelectedPersonal(null);
                    }}
                    onSuccess={() => {
                        setIsPersonalModalOpen(false);
                        setSelectedPersonal(null);
                    }}
                    personal={selectedPersonal}
                    isSharedPersonal={true}
                    permissionLevel={permissionLevel}
                    sharedKey={selctedSharedKey}
                    onSuccessfulUpdate={loadSharedItems}
                />
            );
        }

        return null;
    };

    return (
        <div className="w-full">
            {loading ? (
                <div className="flex justify-center items-center p-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                </div>
            ) : (
                <>
                    {currentView === 'list' && renderItemsList()}
                    {currentView === 'category' && renderCategoryLevel()}
                    {currentView === 'type' && renderTypeLevel()}
                </>
            )}

            {renderActiveModal()}
        </div>
    );
};

export default SharedPasswordsList;