import React, { useState } from 'react';
import Modal from '../common/Modal';
import { TeamService } from '../../services/team.service';
import { toast } from 'react-hot-toast';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

interface DeleteTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  teamId: string;
  teamName: string;
  onDeleted: () => void;
}

const DeleteTeamModal: React.FC<DeleteTeamModalProps> = ({ 
  isOpen, 
  onClose, 
  teamId,
  teamName,
  onDeleted 
}) => {
  const [deleteOption, setDeleteOption] = useState<'keep-users' | 'delete-users'>('keep-users');
  const [isProcessing, setIsProcessing] = useState(false);
  
  const handleDeleteTeam = async () => {
    setIsProcessing(true);
    
    try {
      await TeamService.deleteTeam(teamId, deleteOption === 'delete-users');
      toast.success(
        deleteOption === 'delete-users' 
          ? 'Team and all team members have been deleted' 
          : 'Team deleted and members converted to individual users'
      );
      onDeleted();
    } catch (error) {
      console.error('Error deleting team:', error);
      toast.error('Failed to delete team');
    } finally {
      setIsProcessing(false);
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Team"
    >
      <div className="p-6">
        <div className="flex items-center mb-5">
          <div className="flex-shrink-0 bg-red-100 rounded-full p-2">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
          </div>
          <div className="ml-3">
            <h3 className="text-lg font-medium text-gray-900">Delete "{teamName}"</h3>
          </div>
        </div>
        
        <p className="text-sm text-gray-500 mb-6">
          This action cannot be undone. Please select how you want to handle team members:
        </p>
        
        <div className="space-y-4 mb-6">
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="keep-users"
                name="delete-option"
                type="radio"
                checked={deleteOption === 'keep-users'}
                onChange={() => setDeleteOption('keep-users')}
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="keep-users" className="font-medium text-gray-700">Keep users as individuals</label>
              <p className="text-gray-500">Users will be removed from the team but their accounts and data will be preserved.</p>
            </div>
          </div>
          
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="delete-users"
                name="delete-option"
                type="radio"
                checked={deleteOption === 'delete-users'}
                onChange={() => setDeleteOption('delete-users')}
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="delete-users" className="font-medium text-gray-700">Delete all team members</label>
              <p className="text-gray-500">
                <span className="text-red-500 font-semibold">WARNING:</span> This will permanently delete all team members and their data.
              </p>
            </div>
          </div>
        </div>
        
        <div className="flex justify-end gap-3">
          <button
            type="button"
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={onClose}
            disabled={isProcessing}
          >
            Cancel
          </button>
          <button
            type="button"
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
            onClick={handleDeleteTeam}
            disabled={isProcessing}
          >
            {isProcessing ? 'Deleting...' : 'Delete Team'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTeamModal; 