import React, { useState, useEffect } from 'react';
import Modal from '../common/Modal';
import Button from '../common/Button';
import { TeamService } from '../../services/team.service';
import toast from 'react-hot-toast';
import { Role } from '../../types/team.types';
import { RolesService } from '../../services/roles.service';

interface User {
  id: string;
  email: string;
  role_name: string;
  created_at: string;
}

interface AddMemberModalProps {
  teamId: string;
  onClose: () => void;
  onAddMember: (userId: string, roleId: number, teamId: string) => void;
}

const AddMemberModal: React.FC<AddMemberModalProps> = ({ teamId, onClose, onAddMember }) => {
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const [selectedRoleId, setSelectedRoleId] = useState<number>(0); // Default to regular team member
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);

  useEffect(() => {
    const fetchAvailableUsers = async () => {
      try {
        setLoading(true);
        const response = await TeamService.getUsersWithoutTeam();
        setAvailableUsers(response.users || []);
      } catch (error) {
        console.error('Error fetching available users:', error);
        toast.error('Failed to load available users');
      } finally {
        setLoading(false);
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await RolesService.getRoles();
        setRoles(response);
      } catch (error) {
        console.error('Error fetching roles:', error);
        toast.error('Failed to load roles');
      }
    };

    fetchAvailableUsers();
    fetchRoles();
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!selectedUserId) {
      toast.error('Please select a user');
      return;
    }
    console.log(selectedUserId, selectedRoleId, teamId)
    
    setSubmitting(true);
    onAddMember(selectedUserId, selectedRoleId, teamId);
  };

  return (
    <Modal
      title="Add Team Member"
      onClose={onClose}
      size="md"
    >
      {loading ? (
        <div className="flex justify-center py-6">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            {availableUsers.length === 0 ? (
              <div className="text-center py-4 text-gray-500">
                No available users found. All users are already in teams.
              </div>
            ) : (
              <>
                <div>
                  <label htmlFor="user" className="block text-sm font-medium text-gray-700">
                    Select User
                  </label>
                  <select
                    id="user"
                    name="user"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    value={selectedUserId}
                    onChange={(e) => setSelectedUserId(e.target.value)}
                    required
                  >
                    <option value="">-- Select a user --</option>
                    {availableUsers.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.email}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="role" className="block text-sm font-medium text-gray-700">
                    Role
                  </label>
                  <select
                    id="role"
                    name="role"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    value={selectedRoleId}
                    onChange={(e) => setSelectedRoleId(Number(e.target.value))}
                  >
                    <option value={0}>Select Role</option>
                      {roles.filter(role => role.id !== 1).map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                  </select>
                </div>
              </>
            )}
          </div>
          
          <div className="mt-6 flex justify-end space-x-3">
            <Button
              type="button"
              variant="secondary"
              onClick={onClose}
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              loading={submitting}
              disabled={availableUsers.length === 0 || !selectedUserId}
            >
              Add Member
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default AddMemberModal;