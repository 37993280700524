import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useVault } from '../../contexts/VaultContext';
import VaultSidebar from '../../components/vault/VaultSidebar';
import VaultHeader from '../../components/vault/VaultHeader';
import PasswordList from '../../components/vault/PasswordList';
import NotesList from '../../components/notes/NotesList';
import CardDetailsList from '../../components/cardDetails/CardDetailsList';
import BankDetailsList from '../../components/bankDetails/BankDetailsList';
import PersonalDetailsList from '../../components/personalDetails/PersonalDetailsList';
import { IDecryptedPasswordEntry } from '../../types/vault.types';
import { Note } from '../../types/notes';
import { CardDetail } from '../../types/cardDetails';
import { PersonalDetail } from '../../types/personalDetails';
import { BankDetail } from '../../types/bankDetails';
import { VaultService } from '../../services/vault.service';
import NotesService from '../../services/notes.service';
import CardDetailsService from '../../services/cardDetails.service';
import PersonalDetailsService from '../../services/personalDetails.service';
import BankDetailsService from '../../services/bankDetails.service';
import NotesModal from '../../components/notes/CreateNoteModal';
import CardDetailsModal from '../../components/cardDetails/CardDetailsModal';
import BankDetailsModal from '../../components/bankDetails/BankDetailsModal';
import PersonalDetailsModal from '../../components/personalDetails/PersonalDetailsModal';
import PasswordModal from '../../components/vault/PasswordModal';

// Tab type definition
type TabType = 'passwords' | 'notes' | 'cardDetails' | 'bankDetails' | 'personalDetails';

const VaultPage: React.FC = () => {
    const navigate = useNavigate();
    const { searchQuery } = useVault();

    // State variables for all items
    const [entries, setEntries] = useState<IDecryptedPasswordEntry[]>([]);
    const [notes, setNotes] = useState<Note[]>([]);
    const [cardDetails, setCardDetails] = useState<CardDetail[]>([]);
    const [personalDetails, setPersonalDetails] = useState<PersonalDetail[]>([]);
    const [bankDetails, setBankDetails] = useState<BankDetail[]>([]);

    // Tab state
    const [activeTab, setActiveTab] = useState<TabType>('passwords');

    // Loading states for each tab
    const [loadingPasswords, setLoadingPasswords] = useState<boolean>(true);
    const [loadingNotes, setLoadingNotes] = useState<boolean>(false);
    const [loadingCards, setLoadingCards] = useState<boolean>(false);
    const [loadingBanks, setLoadingBanks] = useState<boolean>(false);
    const [loadingPersonals, setLoadingPersonals] = useState<boolean>(false);

    // Modal states
    const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
    const [selectedNote, setSelectedNote] = useState<Note | null>(null);
    const [isCardModalOpen, setIsCardModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState<CardDetail | null>(null);
    const [isBankModalOpen, setIsBankModalOpen] = useState(false);
    const [selectedBank, setSelectedBank] = useState<BankDetail | null>(null);
    const [isPersonalModalOpen, setIsPersonalModalOpen] = useState(false);
    const [selectedPersonal, setSelectedPersonal] = useState<PersonalDetail | null>(null);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

    // Fetch passwords data
    const fetchPasswordsData = async () => {
        setLoadingPasswords(true);
        try {
            const passwordEntries = await VaultService.getAllEntries();
            setEntries(passwordEntries);
        } catch (error) {
            console.error('Error fetching passwords:', error);
        } finally {
            setLoadingPasswords(false);
        }
    };

    // Fetch notes data
    const fetchNotesData = async () => {
        setLoadingNotes(true);
        try {
            const notesData = await NotesService.getAllNotes();
            setNotes(notesData);
        } catch (error) {
            console.error('Error fetching notes:', error);
        } finally {
            setLoadingNotes(false);
        }
    };

    // Fetch card details data
    const fetchCardDetailsData = async () => {
        setLoadingCards(true);
        try {
            const cardsData = await CardDetailsService.getAllCardDetails();
            setCardDetails(cardsData);
        } catch (error) {
            console.error('Error fetching card details:', error);
        } finally {
            setLoadingCards(false);
        }
    };

    // Fetch bank details data
    const fetchBankDetailsData = async () => {
        setLoadingBanks(true);
        try {
            const banksData = await BankDetailsService.getAllBankDetails();
            setBankDetails(banksData);
        } catch (error) {
            console.error('Error fetching bank details:', error);
        } finally {
            setLoadingBanks(false);
        }
    };

    // Fetch personal details data
    const fetchPersonalDetailsData = async () => {
        setLoadingPersonals(true);
        try {
            const personalsData = await PersonalDetailsService.getAllPersonalDetails();
            setPersonalDetails(personalsData);
        } catch (error) {
            console.error('Error fetching personal details:', error);
        } finally {
            setLoadingPersonals(false);
        }
    };

    // Handle tab change
    const handleTabChange = (tab: TabType) => {
        setActiveTab(tab);

        // Load data for the selected tab if not already loaded
        switch (tab) {
            case 'passwords':
                if (entries.length === 0) fetchPasswordsData();
                break;
            // case 'notes':
            //     if (notes.length === 0) fetchNotesData();
            //     break;
            case 'cardDetails':
                if (cardDetails.length === 0) fetchCardDetailsData();
                break;
            case 'bankDetails':
                if (bankDetails.length === 0) fetchBankDetailsData();
                break;
            case 'personalDetails':
                if (personalDetails.length === 0) fetchPersonalDetailsData();
                break;
        }
    };

    // Initial data load for passwords tab
    useEffect(() => {
        fetchPasswordsData();
    }, []);

    // Add this function to handle new password creation
    const handleNewPassword = () => {
        setIsPasswordModalOpen(true);
    };

    // Filter items based on search query
    const filteredEntries = entries.filter(entry => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();
        return (
            entry.title?.toLowerCase().includes(query) ||
            entry.username?.toLowerCase().includes(query) ||
            entry.website_url?.toLowerCase().includes(query)
        );
    });

    const filteredNotes = notes.filter(note => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();
        return (
            note.name?.toLowerCase().includes(query) ||
            note.encrypted_notes?.toLowerCase().includes(query)
        );
    });

    const filteredCardDetails = cardDetails.filter(detail => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();
        return (
            detail.name?.toLowerCase().includes(query) ||
            detail.encrypted_card_holder_name?.toLowerCase().includes(query)
        );
    });

    const filteredBankDetails = bankDetails.filter(detail => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();
        return (
            detail.name?.toLowerCase().includes(query) ||
            detail.bank_name?.toLowerCase().includes(query)
        );
    });

    const filteredPersonalDetails = personalDetails.filter(detail => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();
        return (
            detail.name?.toLowerCase().includes(query) ||
            detail.encrypted_first_name?.toLowerCase().includes(query) ||
            detail.encrypted_last_name?.toLowerCase().includes(query) ||
            detail.encrypted_email_address?.toLowerCase().includes(query)
        );
    });

    return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
            <VaultSidebar />
            <div className="flex flex-col flex-1 overflow-hidden">
                <VaultHeader />
                <main className="flex-1 overflow-y-auto bg-gray-50 dark:bg-gray-900">
                    <div className="container mx-auto px-4 py-6">
                        <div className="mb-6">
                            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">All Items</h1>
                            <p className="text-gray-600 dark:text-gray-400 text-sm">Manage all your secure items in one place</p>
                        </div>

                        {/* Tabs Navigation */}
                        <div className="border-b border-gray-200 dark:border-gray-700 mb-6">
                            <nav className="-mb-px flex space-x-8">
                                <button
                                    onClick={() => handleTabChange('passwords')}
                                    className={`${activeTab === 'passwords'
                                            ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
                                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                >
                                    Passwords
                                </button>
                                {/* <button
                                    onClick={() => handleTabChange('notes')}
                                    className={`${activeTab === 'notes'
                                            ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
                                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                >
                                    Notes
                                </button> */}
                                <button
                                    onClick={() => handleTabChange('cardDetails')}
                                    className={`${activeTab === 'cardDetails'
                                            ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
                                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                >
                                    Cards
                                </button>
                                <button
                                    onClick={() => handleTabChange('bankDetails')}
                                    className={`${activeTab === 'bankDetails'
                                            ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
                                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                >
                                    Bank Accounts
                                </button>
                                <button
                                    onClick={() => handleTabChange('personalDetails')}
                                    className={`${activeTab === 'personalDetails'
                                            ? 'border-blue-500 text-blue-600 dark:text-blue-400'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 dark:text-gray-400 dark:hover:text-gray-300'
                                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                >
                                    Personal Info
                                </button>
                            </nav>
                        </div>

                        {/* Tab Content */}
                        <div className="space-y-6">
                            {/* Passwords Tab */}
                            {activeTab === 'passwords' && (
                                <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                                    <div className="px-4 py-5">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Passwords</h3>
                                    </div>
                                    {loadingPasswords ? (
                                        <div className="flex justify-center items-center py-12">
                                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                        </div>
                                    ) : (
                                        <>
                                            <PasswordList 
                                                entries={filteredEntries} 
                                                onSuccessfulUpdate={fetchPasswordsData}
                                            />
                                        </>
                                    )}
                                </div>
                            )}

                            {/* Notes Tab */}
                            {activeTab === 'notes' && (
                                <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                                    <div className="px-4 py-5">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Notes</h3>
                                    </div>
                                    {loadingNotes ? (
                                        <div className="flex justify-center items-center py-12">
                                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                        </div>
                                    ) : (
                                        <>
                                            <NotesList
                                                notes={filteredNotes}
                                                onUpdate={fetchNotesData}
                                                onEdit={(note) => {
                                                    setSelectedNote(note);
                                                    setIsNoteModalOpen(true);
                                                }}
                                            />

                                        </>
                                    )}
                                </div>
                            )}

                            {/* Card Details Tab */}
                            {activeTab === 'cardDetails' && (
                                <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                                    <div className="px-4 py-5">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Card Details</h3>
                                    </div>
                                    {loadingCards ? (
                                        <div className="flex justify-center items-center py-12">
                                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                        </div>
                                    ) : (
                                        <>
                                            <CardDetailsList
                                                details={filteredCardDetails}
                                                onUpdate={fetchCardDetailsData}
                                                onEdit={(card) => {
                                                    setSelectedCard(card);
                                                    setIsCardModalOpen(true);
                                                }}
                                            />

                                        </>
                                    )}
                                </div>
                            )}

                            {/* Bank Details Tab */}
                            {activeTab === 'bankDetails' && (
                                <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                                    <div className="px-4 py-5">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Bank Details</h3>
                                    </div>
                                    {loadingBanks ? (
                                        <div className="flex justify-center items-center py-12">
                                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                        </div>
                                    ) : (
                                        <>
                                            <BankDetailsList
                                                details={filteredBankDetails}
                                                onUpdate={fetchBankDetailsData}
                                                onEdit={(bank) => {
                                                    setSelectedBank(bank);
                                                    setIsBankModalOpen(true);
                                                }}
                                            />

                                        </>
                                    )}
                                </div>
                            )}

                            {/* Personal Details Tab */}
                            {activeTab === 'personalDetails' && (
                                <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                                    <div className="px-4 py-5">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">Personal Details</h3>
                                    </div>
                                    {loadingPersonals ? (
                                        <div className="flex justify-center items-center py-12">
                                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                        </div>
                                    ) : (
                                        <>
                                            <PersonalDetailsList
                                                details={filteredPersonalDetails}
                                                onUpdate={fetchPersonalDetailsData}
                                                onEdit={(personal) => {
                                                    setSelectedPersonal(personal);
                                                    setIsPersonalModalOpen(true);
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </div>

            {/* Modals */}
            <NotesModal
                isOpen={isNoteModalOpen}
                onClose={() => setIsNoteModalOpen(false)}
                onSuccess={fetchNotesData}
                note={selectedNote || undefined}
            />

            <CardDetailsModal
                isOpen={isCardModalOpen}
                onClose={() => setIsCardModalOpen(false)}
                onSuccess={fetchCardDetailsData}
                card={selectedCard || undefined}
            />

            <BankDetailsModal
                isOpen={isBankModalOpen}
                onClose={() => setIsBankModalOpen(false)}
                onSuccess={fetchBankDetailsData}
                bank={selectedBank || undefined}
            />

            <PersonalDetailsModal
                isOpen={isPersonalModalOpen}
                onClose={() => setIsPersonalModalOpen(false)}
                onSuccess={fetchPersonalDetailsData}
                personal={selectedPersonal || undefined}
            />

            {/* Add Password Modal */}
            <PasswordModal
                isOpen={isPasswordModalOpen}
                onClose={() => setIsPasswordModalOpen(false)}
                onSuccess={fetchPasswordsData}
            />
        </div>
    );
};

export default VaultPage;