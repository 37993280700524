import React, { useState, useEffect } from 'react';
import { UserIcon, PencilIcon, TrashIcon, IdentificationIcon, EllipsisVerticalIcon, XMarkIcon, EyeIcon } from '@heroicons/react/24/outline';
import { PersonalDetail } from '../../types/personalDetails';
import { toast } from 'react-hot-toast';
import PersonalDetailsService from '../../services/personalDetails.service';
import { useVault } from '../../contexts/VaultContext';

interface PersonalDetailsListProps {
    details: PersonalDetail[];
    onUpdate: () => void;
    onEdit: (detail: PersonalDetail, isShared?: boolean, sharedKey?: string) => void;
}

const PersonalDetailsList: React.FC<PersonalDetailsListProps> = ({ details, onUpdate, onEdit }) => {
    const [hoveredCard, setHoveredCard] = useState<string | null>(null);
    const [activeCard, setActiveCard] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
    const { searchQuery } = useVault();

    // Detect screen size changes for responsive behavior
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDelete = async (id: string) => {
        try {
            if (window.confirm('Are you sure you want to delete this personal detail?')) {
                await PersonalDetailsService.deletePersonalDetail(id);
                toast.success('Personal detail deleted successfully');
                onUpdate();
                setActiveCard(null);
            }
        } catch (error) {
            toast.error('Failed to delete personal detail');
        }
    };

    const handleEdit = async (entryId: string, isShared?: boolean, sharedKey?: string) => {
        try {
            const detail = await PersonalDetailsService.getPersonalDetailById(entryId, isShared, sharedKey);
            onEdit(detail, isShared ?? false, sharedKey ?? '');
            setActiveCard(null);
        } catch (err) {
            console.error('Failed to fetch entry:', err);
            toast.error('Failed to fetch entry');
        }
    };

    // Toggle active card on mobile
    const toggleCardOptions = (id: string, e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent bubbling
        if (isMobile) {
            setActiveCard(activeCard === id ? null : id);
        }
    };

    // Close card options when clicking outside
    useEffect(() => {
        const handleClickOutside = () => {
            if (activeCard) {
                setActiveCard(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [activeCard]);

    const cardColor = 'from-green-400 to-green-600';

    const filteredPersonalDetails = details.filter((detail: PersonalDetail) => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        return (
            detail.name?.toLowerCase().includes(searchLower) || 
            detail.encrypted_first_name?.toLowerCase().includes(searchLower) ||
            detail.encrypted_last_name?.toLowerCase().includes(searchLower) ||
            detail.encrypted_email_address?.toLowerCase().includes(searchLower) ||
            detail.encrypted_phone?.toLowerCase().includes(searchLower)
        );
    });

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 p-4 sm:p-6">
            {filteredPersonalDetails.map((detail) => {
                const fullName = `${detail.title || ''} ${detail.encrypted_first_name || ''} ${detail.encrypted_last_name || ''}`.trim();

                return (
                    <div
                        key={detail.id}
                        className="relative h-64 rounded-xl shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700"
                        onMouseEnter={() => !isMobile && setHoveredCard(detail.id)}
                        onMouseLeave={() => !isMobile && setHoveredCard(null)}
                        onClick={(e) => isMobile && toggleCardOptions(detail.id, e)}
                    >
                        {/* Front of ID Card */}
                        <div className={`absolute inset-0 w-full h-full bg-white dark:bg-gray-800 transition-all duration-300 ${hoveredCard === detail.id && !isMobile ? 'opacity-0' : 'opacity-100'}`}>
                            {/* Card Header with gradient */}
                            <div className={`h-20 bg-gradient-to-r ${cardColor} flex items-center justify-between px-4`}>
                                <div className="flex items-center">
                                    <div className="w-10 h-10 bg-white rounded-full flex items-center justify-center mr-3">
                                        <UserIcon className="h-6 w-6 text-gray-700" />
                                    </div>
                                    <div>
                                        <h2 className="text-white text-lg font-semibold">{detail.name}</h2>
                                        {detail.category_name && (
                                            <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-white/30 text-white">
                                                {detail.category_name}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    {detail.favourite && (
                                        <div className="text-yellow-300 mr-2">
                                            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                            </svg>
                                        </div>
                                    )}

                                    {/* Mobile menu icon */}
                                    {isMobile && (
                                        <button
                                            onClick={(e) => toggleCardOptions(detail.id, e)}
                                            className="text-white p-1 rounded-full hover:bg-white/10 focus:outline-none"
                                        >
                                            <EllipsisVerticalIcon className="h-5 w-5" />
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* ID Card Body */}
                            <div className="px-4 py-4">
                                <div className="space-y-2 text-sm mt-2">
                                    {detail.encrypted_first_name && (
                                        <div className="flex">
                                            <span className="text-gray-500 dark:text-gray-400 w-16">Name:</span>
                                            <span className="text-gray-800 dark:text-gray-200 truncate">{fullName || ''}</span>
                                        </div>
                                    )}

                                    {detail.encrypted_email_address && (
                                        <div className="flex">
                                            <span className="text-gray-500 dark:text-gray-400 w-16">Email:</span>
                                            <span className="text-gray-800 dark:text-gray-200 truncate">{detail.encrypted_email_address}</span>
                                        </div>
                                    )}
                                    {detail.encrypted_phone && (
                                        <div className="flex">
                                            <span className="text-gray-500 dark:text-gray-400 w-16">Phone:</span>
                                            <span className="text-gray-800 dark:text-gray-200">{detail.encrypted_phone}</span>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Simple footer with just the date */}
                            <div className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-gray-100 dark:bg-gray-700 flex justify-end items-center border-t border-gray-200 dark:border-gray-600">
                                <div className="text-xs text-gray-500 dark:text-gray-400">
                                    Last Updated:  <time dateTime={detail.updated_at}>
                                        {new Date(detail.updated_at).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric'
                                        })}
                                    </time>
                                </div>
                            </div>
                        </div>

                        {/* Desktop hover controls */}
                        {!isMobile && (
                            <>
                                {(detail.permission_level && detail.permission_level === 'read') ?

                                    <div className={`absolute inset-0 w-full h-full bg-gradient-to-r ${cardColor} flex flex-col items-center justify-center transition-all duration-300 ${hoveredCard === detail.id ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                                        <div className="absolute top-0 left-0 right-0 px-4 py-2 text-white">
                                            <h3 className="text-lg font-semibold">{fullName}</h3>
                                            <p className="text-xs opacity-80">Personal Information</p>
                                        </div>

                                        <button
                                            onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                            className="w-3/4 py-2 px-4 bg-white/20 hover:bg-white/30 text-white rounded-lg transition-colors flex items-center justify-center space-x-2 mb-3"
                                        >
                                            <EyeIcon className="h-5 w-5" />
                                            <span>View Details</span>
                                        </button>
                                    </div>

                                    :

                                    <div className={`absolute inset-0 w-full h-full bg-gradient-to-r ${cardColor} flex flex-col items-center justify-center transition-all duration-300 ${hoveredCard === detail.id ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                                        <div className="absolute top-0 left-0 right-0 px-4 py-2 text-white">
                                            <h3 className="text-lg font-semibold">{fullName}</h3>
                                            <p className="text-xs opacity-80">Personal Information</p>
                                        </div>

                                        <button
                                            onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                            className="w-3/4 py-2 px-4 bg-white/20 hover:bg-white/30 text-white rounded-lg transition-colors flex items-center justify-center space-x-2 mb-3"
                                        >
                                            <PencilIcon className="h-5 w-5" />
                                            <span>Edit Details</span>
                                        </button>
                                        <button
                                            onClick={() => handleDelete(detail.id)}
                                            className="w-3/4 py-2 px-4 bg-red-500/20 hover:bg-red-500/30 text-white rounded-lg transition-colors flex items-center justify-center space-x-2"
                                        >
                                            <TrashIcon className="h-5 w-5" />
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                }
                            </>

                        )}

                        {/* Mobile action menu */}
                        {isMobile && activeCard === detail.id && (
                            <div
                                className="absolute right-0 left-0 bottom-0 bg-white dark:bg-gray-800 backdrop-blur-sm rounded-b-lg z-10 p-3 shadow-lg"
                                onClick={(e) => e.stopPropagation()}
                                style={{ borderTop: '1px solid rgba(0,0,0,0.1)' }}
                            >
                                <div className="flex justify-between items-center mb-2">
                                    <h4 className="font-medium text-gray-800 dark:text-white">Personal options</h4>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActiveCard(null);
                                        }}
                                        className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >
                                        <XMarkIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                                    </button>
                                </div>
                                {(detail.permission_level && detail.permission_level === 'read') ?

                                    <div className="grid grid-cols-2 gap-2">
                                        <button
                                            onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                            className="py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors flex items-center justify-center space-x-1"
                                        >
                                            <PencilIcon className="h-4 w-4" />
                                            <span>Edit</span>
                                        </button>
                                        <button
                                            onClick={() => handleDelete(detail.id)}
                                            className="py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors flex items-center justify-center space-x-1"
                                        >
                                            <TrashIcon className="h-4 w-4" />
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                    :
                                    <div className="grid grid-cols-2 gap-2">
                                        <button
                                            onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                            className="py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors flex items-center justify-center space-x-1"
                                        >
                                            <PencilIcon className="h-4 w-4" />
                                            <span>Edit</span>
                                        </button>
                                        <button
                                            onClick={() => handleDelete(detail.id)}
                                            className="py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors flex items-center justify-center space-x-1"
                                        >
                                            <TrashIcon className="h-4 w-4" />
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                }
                            </div>

                        )}
                    </div>
                );
            })}

            {details.length === 0 && (
                <div className="col-span-full flex flex-col items-center justify-center py-12 text-gray-500 dark:text-gray-400">
                    <IdentificationIcon className="h-24 w-24 mb-4" />
                    <h3 className="text-xl font-medium mb-2">No personal details yet</h3>
                    <p className="text-sm text-gray-400 dark:text-gray-500">Add your first personal detail to get started</p>
                </div>
            )}
        </div>
    );
};

export default PersonalDetailsList; 