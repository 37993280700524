import { store } from '../store';
import { encryptData, decryptData } from '../utils/encryption';
import { secureStore } from '../utils/secureStore';
import { CardDetail, CreateCardDetailDto, UpdateCardDetailDto } from '../types/cardDetails';
import { config } from '../extension/config';

const API_URL = config.API_URL;

export class CardDetailsService {
    private static authToken: string | null = null;

    private static async request<T>(
        endpoint: string,
        method: string = 'GET',
        body?: any
    ): Promise<T> {
        const token = this.authToken || store.getState().auth.token;
        
        if (!token) {
            throw new Error('No authentication token found');
        }

        try {
            const response = await fetch(`${API_URL}/card-details${endpoint}`, {
                method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: body ? JSON.stringify(body) : undefined,
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'An error occurred');
            }

            return data;
        } catch (error) {
            console.error('API request failed:', error);
            throw error;
        }
    }

    private static getMasterKey(): string {
        try {
            return secureStore.getVaultKey();
        } catch (error) {
            throw new Error('Vault key not found. Please login again.');
        }
    }

    static async getAllCardDetails(): Promise<CardDetail[]> {
        try {
            const details = await this.request<CardDetail[]>('');
            return details.map(detail => this.decryptCardDetail(detail));
        } catch (error) {
            console.error('Failed to fetch card details:', error);
            throw error;
        }
    }

    static async createCardDetail(detail: CreateCardDetailDto): Promise<CardDetail> {
        try {
            const encryptedDetail = this.encryptCardDetail(detail);
            const response = await this.request<CardDetail>('', 'POST', encryptedDetail);
            return this.decryptCardDetail(response);
        } catch (error) {
            console.error('Create card detail error:', error);
            throw error;
        }
    }

    static async updateCardDetail(id: string, detail: UpdateCardDetailDto): Promise<CardDetail> {
        try {
            const encryptedDetail = this.encryptCardDetail(detail, detail.sharedKey);
            const response = await this.request<CardDetail>(`/${id}`, 'PUT', encryptedDetail);
            return this.decryptCardDetail(response);
        } catch (error) {
            console.error('Update card detail error:', error);
            throw error;
        }
    }

    static async deleteCardDetail(id: string): Promise<void> {
        await this.request(`/${id}`, 'DELETE');
    }

    static async getCardDetailById(id: string, isShared: boolean = false, sharedKey?: string): Promise<CardDetail> {
        const response = await this.request<CardDetail>(`/${id}`);
        if (isShared && sharedKey) {
            return this.decryptCardDetail(response, isShared, sharedKey);
        }
        
        return this.decryptCardDetail(response);
    }

    private static decryptBufferData(encryptedBuffer: any, isShared: boolean = false, sharedKey?: string): string {
        if (!encryptedBuffer) return '';

        try {
            if (encryptedBuffer.data && Array.isArray(encryptedBuffer.data)) {
                const uint8Array = new Uint8Array(encryptedBuffer.data);
                const encryptedString = new TextDecoder().decode(uint8Array);
                return isShared && sharedKey ? decryptData(encryptedString, sharedKey) : decryptData(encryptedString, this.getMasterKey());
            }

            if (typeof encryptedBuffer === 'string') {
                return isShared && sharedKey ? decryptData(encryptedBuffer, sharedKey) : decryptData(encryptedBuffer, this.getMasterKey());
            }

            return '';
        } catch (error) {
            console.error('Error decrypting buffer:', error);
            return '';
        }
    }

    private static decryptCardDetail(detail: CardDetail, isShared: boolean = false, sharedKey?: string): CardDetail {
        const decryptField = (value?: string | null) => {
            if (!value) return null;
            try {
                return this.decryptBufferData(value, isShared, sharedKey);
            } catch (error) {
                console.error('Failed to decrypt field:', error);
                return '(Decryption failed)';
            }
        };

        return {
            ...detail,
            encrypted_card_holder_name: decryptField(detail.encrypted_card_holder_name),
            encrypted_card_number: decryptField(detail.encrypted_card_number),
            encrypted_expiration_date: decryptField(detail.encrypted_expiration_date),
            encrypted_cvv: decryptField(detail.encrypted_cvv),
            encrypted_pin: decryptField(detail.encrypted_pin),
            encrypted_postal_code: decryptField(detail.encrypted_postal_code),
            encrypted_notes: decryptField(detail.encrypted_notes),
            category_id: detail.category_id || '',
            favourite: detail.favourite || false
        };
    }

    private static encryptCardDetail(detail: Partial<UpdateCardDetailDto>, sharedKey?: string): any {
        const masterKey = sharedKey || this.getMasterKey();
        const encryptField = (value?: string) => value ? encryptData(value, masterKey) : null;

        return {
            name: detail.name || null,
            category_id: detail.category_id || null,
            card_type: detail.card_type || null,
            encrypted_card_holder_name: encryptField(detail.encrypted_card_holder_name),
            encrypted_card_number: encryptField(detail.encrypted_card_number),
            encrypted_expiration_date: encryptField(detail.encrypted_expiration_date),
            encrypted_cvv: encryptField(detail.encrypted_cvv),
            encrypted_pin: encryptField(detail.encrypted_pin),
            encrypted_postal_code: encryptField(detail.encrypted_postal_code),
            encrypted_notes: encryptField(detail.encrypted_notes),
            favourite: detail.favourite || false,
            isSharedUpdate: detail.isSharedUpdate || false,
            itemType: detail.itemType || 'card'
        };
    }
}

export default CardDetailsService; 