import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import QuickAddItemModal from './QuickAddItemModal';
import { NotesService } from '../../services/notes.service';
import { CardDetailsService } from '../../services/cardDetails.service';
import { BankDetailsService } from '../../services/bankDetails.service';
import { PersonalDetailsService } from '../../services/personalDetails.service';
import { VaultService } from '../../services/vault.service';
import { Note } from '../../types/notes';
import { CardDetail } from '../../types/cardDetails';
import { BankDetail } from '../../types/bankDetails';
import { PersonalDetail } from '../../types/personalDetails';
import { IDecryptedPasswordEntry } from '../../types/vault.types';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

interface QuickAddItemProps {
    categoryId: string;
    onUpdate: () => void;
    
}

const QuickAddItem: React.FC<QuickAddItemProps> = ({ categoryId, onUpdate }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
    const [uncategorizedItems, setUncategorizedItems] = useState<any[]>([]);

    useEffect(() => {
        if (selectedType) {
            fetchUncategorizedItems(selectedType);
        }
    }, [selectedType]);

    const fetchUncategorizedItems = async (type: string) => {
        try {
            let items: any[] = [];
            switch (type) {
                case 'password':
                    const entries = await VaultService.getAllEntries();
                    items = entries.filter(entry => !entry.category_id);
                    break;
                case 'notes':
                    const notes = await NotesService.getAllNotes();
                    items = notes.filter(note => !note.category_id);
                    break;
                case 'cardDetails':
                    const cards = await CardDetailsService.getAllCardDetails();
                    items = cards.filter(card => !card.category_id);
                    break;
                case 'bankDetails':
                    const banks = await BankDetailsService.getAllBankDetails();
                    items = banks.filter(bank => !bank.category_id);
                    break;
                case 'personalDetails':
                    const personal = await PersonalDetailsService.getAllPersonalDetails();
                    items = personal.filter(detail => !detail.category_id);
                    break;
            }
            setUncategorizedItems(items);
        } catch (error) {
            console.error('Error fetching unassigned items:', error);
            toast.error('Failed to fetch items');
        }
    };

    const handleItemTypeSelect = (type: string) => {
        setSelectedType(type);
    };

    const handleItemSelect = async () => {
        if (!selectedItemId || !selectedType) return;

        try {
            const selectedItem = uncategorizedItems.find(item => item.id === selectedItemId);
            if (!selectedItem) return;

            switch (selectedType) {
                case 'password':
                    await VaultService.updateEntry(selectedItemId, {
                        ...selectedItem,
                        category_id: categoryId
                    });
                    break;
                case 'notes':
                    await NotesService.updateNote(selectedItemId, {
                        id: selectedItem.id,
                        name: selectedItem.name,
                        encrypted_notes: selectedItem.encrypted_notes,
                        category_id: categoryId,
                        favourite: selectedItem.favourite
                    });
                    break;
                case 'cardDetails':
                    await CardDetailsService.updateCardDetail(selectedItemId, {
                        ...selectedItem,
                        category_id: categoryId
                    });
                    break;
                case 'bankDetails':
                    await BankDetailsService.updateBankDetail(selectedItemId, {
                        ...selectedItem,
                        category_id: categoryId
                    });
                    break;
                case 'personalDetails':
                    await PersonalDetailsService.updatePersonalDetail(selectedItemId, {
                        ...selectedItem,
                        category_id: categoryId
                    });
                    break;
            }

            toast.success('Item added to vault');
            onUpdate();
            setIsOpen(false);
            setSelectedType(null);
            setSelectedItemId(null);
        } catch (error) {
            console.error('Error updating item:', error);
            toast.error('Failed to add item to vault');
        }
    };

    return (
        <>
            <button
                onClick={() => setIsOpen(true)}
                className="text-sm text-blue-600 hover:text-blue-500"
            >
                <PlusCircleIcon className="h-6 w-6" />
            </button>

            <QuickAddItemModal
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                    setSelectedType(null);
                    setSelectedItemId(null);
                }}
                onSelect={handleItemTypeSelect}
                selectedType={selectedType}
                items={uncategorizedItems}
                onItemSelect={(id: string) => setSelectedItemId(id)}
                onSubmit={handleItemSelect}
            />
        </>
    );
};

export default QuickAddItem; 