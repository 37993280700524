import { store } from '../store';
import { encryptData, decryptData } from '../utils/encryption';
import { secureStore } from '../utils/secureStore';
import { BankDetail, BankDetailFormData } from '../types/bankDetails';
import { config } from '../extension/config';

const API_URL = config.API_URL;

export class BankDetailsService {
    private static authToken: string | null = null;

    private static async request<T>(
        endpoint: string,
        method: string = 'GET',
        body?: any
    ): Promise<T> {
        const token = this.authToken || store.getState().auth.token;

        if (!token) {
            throw new Error('No authentication token found');
        }

        try {
            const response = await fetch(`${API_URL}/bank-details${endpoint}`, {
                method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: body ? JSON.stringify(body) : undefined,
            });

            const data = await response.json();

            if (!response.ok) {
                console.error('API Error:', data);
                throw new Error(data.message || 'An error occurred');
            }

            return data;
        } catch (error) {
            console.error('API request failed:', error);
            throw error;
        }
    }

    private static getMasterKey(): string {
        try {
            return secureStore.getVaultKey();
        } catch (error) {
            throw new Error('Vault key not found. Please login again.');
        }
    }

    static async getAllBankDetails(): Promise<BankDetail[]> {
        try {
            const details = await this.request<BankDetail[]>('');
            return details.map(detail => this.decryptBankDetail(detail));
        } catch (error) {
            console.error('Failed to fetch bank details:', error);
            throw error;
        }
    }

    static async createBankDetail(detail: BankDetailFormData): Promise<BankDetail> {
        try {
            const encryptedDetail = this.encryptBankDetail(detail);
            const response = await this.request<BankDetail>('', 'POST', encryptedDetail);
            return this.decryptBankDetail(response);
        } catch (error) {
            console.error('Create bank detail error:', error);
            throw error;
        }
    }

    static async updateBankDetail(id: string, detail: BankDetailFormData): Promise<BankDetail> {
        try {
            const encryptedDetail = this.encryptBankDetail(detail, detail.sharedKey);
            const response = await this.request<BankDetail>(`/${id}`, 'PUT', encryptedDetail);
            return this.decryptBankDetail(response);
        } catch (error) {
            console.error('Update bank detail error:', error);
            throw error;
        }
    }

    static async deleteBankDetail(id: string): Promise<void> {
        await this.request(`/${id}`, 'DELETE');
    }

    static async getBankDetailById(id: string, isShared: boolean = false, sharedKey?: string): Promise<BankDetail> {
        const response = await this.request<BankDetail>(`/${id}`);

        if (isShared && sharedKey) {
            return this.decryptBankDetail(response, isShared, sharedKey);
        }
        
        return this.decryptBankDetail(response);
    }

    private static decryptBufferData(encryptedBuffer: any, isShared: boolean = false, sharedKey?: string): string {
        if (!encryptedBuffer) return '';

        try {
            if (encryptedBuffer.data && Array.isArray(encryptedBuffer.data)) {
                const uint8Array = new Uint8Array(encryptedBuffer.data);
                const encryptedString = new TextDecoder().decode(uint8Array);
                return isShared && sharedKey ? decryptData(encryptedString, sharedKey) : decryptData(encryptedString, this.getMasterKey());
            }

            if (typeof encryptedBuffer === 'string') {
                return isShared && sharedKey ? decryptData(encryptedBuffer, sharedKey) : decryptData(encryptedBuffer, this.getMasterKey());
            }

            return '';
        } catch (error) {
            console.error('Error decrypting buffer:', error);
            return '';
        }
    }

    private static decryptBankDetail(detail: BankDetail, isShared: boolean = false, sharedKey?: string): BankDetail {
        const decryptField = (value?: string | null) => {
            if (!value) return '';
            try {
                return this.decryptBufferData(value, isShared, sharedKey);
            } catch (error) {
                console.error('Failed to decrypt field:', error);
                return '(Decryption failed)';
            }
        };

        return {
            ...detail,
            encrypted_routing_number: decryptField(detail.encrypted_routing_number) || '',
            encrypted_account_number: decryptField(detail.encrypted_account_number) || '',
            encrypted_swift_code: decryptField(detail.encrypted_swift_code) || '',
            encrypted_iban_number: decryptField(detail.encrypted_iban_number) || '',
            encrypted_pin: decryptField(detail.encrypted_pin) || '',
            encrypted_branch_address: decryptField(detail.encrypted_branch_address) || '',
            encrypted_branch_phone: decryptField(detail.encrypted_branch_phone) || '',
            encrypted_notes: decryptField(detail.encrypted_notes) || '',
            category_id: detail.category_id || '',
            favourite: detail.favourite || false
        };
    }

    private static encryptBankDetail(detail: Partial<BankDetailFormData>, sharedKey?: string): any {
        const masterKey = sharedKey || this.getMasterKey();

        const encryptField = (value?: string) => value ? encryptData(value, masterKey) : null;

        return {
            name: detail.name || null,
            bank_name: detail.bank_name || null,
            account_type: detail.account_type || null,
            encrypted_routing_number: encryptField(detail.encrypted_routing_number),
            encrypted_account_number: encryptField(detail.encrypted_account_number),
            encrypted_swift_code: encryptField(detail.encrypted_swift_code),
            encrypted_iban_number: encryptField(detail.encrypted_iban_number),
            encrypted_pin: encryptField(detail.encrypted_pin),
            encrypted_branch_address: encryptField(detail.encrypted_branch_address),
            encrypted_branch_phone: encryptField(detail.encrypted_branch_phone),
            encrypted_notes: encryptField(detail.encrypted_notes),
            category_id: detail.category_id || null,
            favourite: detail.favourite || false,
            isSharedUpdate: detail.isSharedUpdate || false,
            itemType: detail.itemType || 'bank'
        };
    }
}

export default BankDetailsService; 