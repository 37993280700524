import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Note, CreateNoteDto } from '../../types/notes';
import NotesService from '../../services/notes.service';
import { toast } from 'react-hot-toast';
import Button from '../common/Button';
import { useCategories } from '../../contexts/CategoryContext';

interface CreateNoteModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    note?: Note;
    isSharedNote?: boolean;
    permissionLevel?: string;
    sharedKey?: string;
    onSuccessfulUpdate?: () => Promise<void> | void;
}

const CreateNoteModal: React.FC<CreateNoteModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    note,
    isSharedNote = false,
    permissionLevel = 'read',
    sharedKey,
    onSuccessfulUpdate
}) => {
    const [name, setName] = useState('');
    const [notes, setNotes] = useState('');
    const [categoryId, setCategoryId] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [favourite, setFavourite] = useState(false);
    const { categories, refreshCategories, createCategory } = useCategories();
    const [isCreatingCategory, setIsCreatingCategory] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [creatingCategoryLoading, setCreatingCategoryLoading] = useState(false);

    useEffect(() => {
        if (isOpen && note) {
            console.log('Setting note values:', note);
            setName(note.name);
            setNotes(note.encrypted_notes);
            setCategoryId(note.category_id || '');
            setFavourite(note.favourite);
            console.log("note.itemType", note.itemType);
        } else if (!isOpen) {
            setName('');
            setNotes('');
            setCategoryId('');
            setFavourite(false);
        }

        if (isOpen && !isSharedNote && note?.itemType !== 'category') {
            refreshCategories();
        }
    }, [note, isOpen]);

    const handleCreateCategory = async () => {
        if (!newCategoryName.trim()) return;

        setCreatingCategoryLoading(true);
        try {
            await createCategory({ name: newCategoryName });
            await refreshCategories();

            // Find the newly created category and select it
            const newCategory = categories.find(cat => cat.name === newCategoryName);
            if (newCategory) {
                setCategoryId(newCategory.id);
            }

            // Reset the new category UI state
            setNewCategoryName('');
            setIsCreatingCategory(false);
        } catch (error) {
            console.error('Error creating category:', error);
            toast.error('Failed to create category');
        } finally {
            setCreatingCategoryLoading(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        console.log('Submitting form:', { name, notes, categoryId, isSharedNote, sharedKey });

        try {
            if (note) {
                await NotesService.updateNote(note.id, {
                    id: note.id,
                    name,
                    favourite,
                    encrypted_notes: notes,
                    category_id: categoryId || undefined,
                    isSharedUpdate: isSharedNote,
                    sharedKey,
                    itemType: note.itemType || 'note'
                });
                toast.success('Note updated successfully');
                if (onSuccessfulUpdate) {
                    await onSuccessfulUpdate();
                } else {
                    onSuccess();
                }
                onClose();
            } else {
                await NotesService.createNote({
                    name,
                    favourite,
                    encrypted_notes: notes,
                    category_id: categoryId || undefined
                });
                toast.success('Note created successfully');
                onClose();
                onSuccess();
            }

        } catch (error) {
            console.error('Error saving note:', error);
            toast.error(note ? 'Failed to update note' : 'Failed to create note');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    const isReadOnly = isSharedNote && permissionLevel === 'read';

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

            <div className="flex min-h-full items-center justify-center p-4">
                <div className="relative bg-white rounded-lg w-full max-w-4xl mx-4">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h3 className="text-lg font-medium">
                            {note ? 'Edit Note' : 'Create New Note'}
                        </h3>
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit} className="p-4">
                        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                            <div className="md:col-span-3">
                                <div className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            required
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                    {!isSharedNote && (
                                        <>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Vault
                                                </label>
                                                {!isCreatingCategory ? (
                                                    <select
                                                        value={categoryId}
                                                        onChange={(e) => {
                                                            if (e.target.value === "create_new") {
                                                                setIsCreatingCategory(true);
                                                            } else {
                                                                setCategoryId(e.target.value);
                                                            }
                                                        }}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        disabled={isReadOnly}
                                                    >
                                                        <option value="">Select Vault</option>
                                                        {categories.map((category) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                        <option value="create_new">Create new Vault</option>
                                                    </select>
                                                ) : (
                                                    <div className="mt-1 space-x-2">
                                                        <input
                                                            type="text"
                                                            value={newCategoryName}
                                                            onChange={(e) => setNewCategoryName(e.target.value)}
                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                            placeholder="Vault name"
                                                            autoFocus
                                                        />
                                                        <div className="flex items-center justify-between mt-4">
                                                            <button
                                                                type="button"
                                                                onClick={handleCreateCategory}
                                                                disabled={creatingCategoryLoading || !newCategoryName.trim()}
                                                                className={`px-3 py-2 rounded-md text-sm font-medium ${creatingCategoryLoading || !newCategoryName.trim()
                                                                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                                        : 'bg-blue-600 text-white hover:bg-blue-700'
                                                                    }`}
                                                            >
                                                                {creatingCategoryLoading ? 'Creating...' : 'Create'}
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setIsCreatingCategory(false);
                                                                    setNewCategoryName('');
                                                                }}
                                                                className="px-3 py-2 rounded-md text-sm font-medium bg-gray-200 text-gray-700 hover:bg-gray-300"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    name="favourite"
                                                    checked={favourite}
                                                    onChange={(e) => setFavourite(e.target.checked)}
                                                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                    disabled={isReadOnly}
                                                />
                                                <label className="ml-2 block text-sm text-gray-900">
                                                    Add to favourites
                                                </label>
                                            </div>
                                        </>
                                    )}



                                </div>
                            </div>
                            <div className="md:col-span-9">
                                <label className="block text-sm font-medium text-gray-700">
                                    Notes
                                </label>
                                <textarea
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    rows={12}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                    required
                                    disabled={isReadOnly}
                                />
                            </div>
                        </div>

                        <div className="mt-4 flex justify-end space-x-2">
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            {!isReadOnly && (
                                <Button type="submit" loading={loading}>
                                    {note ? 'Update Note' : 'Create Note'}
                                </Button>
                            )}

                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CreateNoteModal; 