import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ILoginCredentials } from '../../types';
import { useAuth } from '../../hooks/useAuth';
import Button from '../common/Button';
import Input from '../common/Input';
import toast from 'react-hot-toast';
import api from '../../services/api';
import { AuthService } from '../../services/auth.service';

const LoginForm: React.FC = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState<ILoginCredentials>({
        email: '',
        password: ''
    });
    const [error, setError] = useState<string>('');
    const [mfaCode, setMfaCode] = useState<string>(''); // State for MFA code
    const [requiresMfa, setRequiresMfa] = useState<boolean>(false); // State to track MFA requirement
    const [step, setStep] = useState<number>(1); // State to track the current step
    const [tempToken, setTempToken] = useState<string>('');
    const [showMfaHelp, setShowMfaHelp] = useState<boolean>(false);
    const [adminMessage, setAdminMessage] = useState<string>('');
    const [sendingRequest, setSendingRequest] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCredentials(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
        // Clear error when user starts typing
        if (error) setError('');
    };

    const handleMfaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMfaCode(e.target.value.replace(/\D/g, '').slice(0, 6)); // Allow only 6 digits
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        // Basic validation
        if (!credentials.email || !credentials.password) {
            setError('Please fill in all fields');
            setLoading(false);
            return;
        }

        try {
            // Login will handle the loading state internally
            const result = await login(credentials);
            console.log("Login result", result);
            if (result.success) {
                setLoading(false);
                navigate('/vaults');
            } else if (result.requiresMfa) {
                setLoading(false);
                setRequiresMfa(true);
                setStep(2);
                setTempToken(result.tempToken || '');
            } else {
                setLoading(false);
                setError(result.error || 'Invalid credentials');
            }
        } catch (err: any) {
            setLoading(false);
            setError(err.message || 'Login failed');
        }
    };

    const handleMfaSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        if (!mfaCode) {
            setError('Please enter the MFA code');
            setLoading(false);
            return;
        }

        try {
            console.log("MFA code", mfaCode);
            const result = await login({
                ...credentials,
                mfaCode,
                tempToken: tempToken // Use the stored tempToken
            });
            console.log("MFA result", result);

            if (result.success) {
                setLoading(false);
                navigate('/vaults');
            } else {
                setLoading(false);
                setError(result.error || 'Invalid MFA code');
            }
        } catch (err: any) {
            setLoading(false);
            setError(err.message || 'MFA verification failed');
        }
    };

    const handleSendAdminRequest = async (e: React.FormEvent) => {
        e.preventDefault();
        setSendingRequest(true);
        
        try {
            if (!adminMessage.trim()) {
                setError('Please provide a message for the administrator');
                setSendingRequest(false);
                return;
            }
            
            // Use AuthService instead of direct API call
            const response = await AuthService.requestMfaHelp(credentials.email, adminMessage);
            
            if (response.success) {
                toast.success('Your request has been sent to the administrator');
                setShowMfaHelp(false);
                setAdminMessage('');
            } else {
                toast.error(response.message || 'Failed to send request');
            }
        } catch (error: any) {
            console.error('Error sending admin request:', error);
            toast.error(error.message || 'An error occurred while sending your request');
        } finally {
            setSendingRequest(false);
        }
    };

    return (
        <form onSubmit={step === 1 ? handleSubmit : (showMfaHelp ? handleSendAdminRequest : handleMfaSubmit)} className="space-y-6">
            {step === 1 ? (
                <>
                    <Input
                        label="Email address"
                        type="email"
                        name="email"
                        value={credentials.email}
                        onChange={handleChange}
                        required
                        autoFocus
                        disabled={loading}
                    />

                    <Input
                        label="Master Password"
                        type="password"
                        name="password"
                        value={credentials.password}
                        onChange={handleChange}
                        required
                        showPasswordToggle
                        disabled={loading}
                    />
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <input
                                id="remember-me"
                                name="remember-me"
                                type="checkbox"
                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                disabled={loading}
                            />
                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                Remember me
                            </label>
                        </div>

                        <div className="text-sm">
                            <Link
                                to="/forgot-password"
                                className={`font-medium text-blue-600 hover:text-blue-500 ${loading ? 'pointer-events-none opacity-50' : ''}`}
                            >
                                Forgot your password?
                            </Link>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {!showMfaHelp ? (
                        <>
                            <h3 className="text-lg font-medium mb-2">Two-factor authentication</h3>
                            <p className="text-sm text-gray-600 mb-2">
                                Open your two-factor authenticator (TOTP) app or browser extension to view your authentication code.
                            </p>
                            <Input
                                label="Authentication code"
                                type="text"
                                value={mfaCode}
                                onChange={handleMfaChange}
                                placeholder="XXXXXX"
                                maxLength={6}
                                required
                            />
                            <div className="text-sm mt-4">
                                <button 
                                    type="button"
                                    onClick={() => setShowMfaHelp(true)}
                                    className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none"
                                >
                                    Having trouble with your authenticator app?
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <h3 className="text-lg font-medium mb-2">Request MFA Help</h3>
                            <p className="text-sm text-gray-600 mb-4">
                                If you've lost access to your authenticator app or need help with your two-factor authentication, 
                                please describe your issue below. An administrator will review your request.
                            </p>
                            <div className="mt-4">
                                <label htmlFor="admin-message" className="block text-sm font-medium text-gray-700 mb-1">
                                    Your message to the administrator
                                </label>
                                <textarea
                                    id="admin-message"
                                    rows={4}
                                    value={adminMessage}
                                    onChange={(e) => setAdminMessage(e.target.value)}
                                    placeholder="Please explain your situation and why you need help with MFA..."
                                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    required
                                />
                            </div>
                            
                            <div className="text-sm mt-4">
                                <button 
                                    type="button"
                                    onClick={() => setShowMfaHelp(false)}
                                    className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none"
                                >
                                    Back to Authentication Code
                                </button>
                            </div>
                        </>
                    )}
                </>
            )}

            {error && (
                <div className="bg-red-50 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <span className="block sm:inline">
                        {error}
                    </span>
                </div>
            )}

            <Button
                type="submit"
                fullWidth={true}
                loading={loading || sendingRequest}
                disabled={loading || sendingRequest}
            >
                {loading || sendingRequest ? 
                    (step === 1 ? 'Signing in...' : 
                     (showMfaHelp ? 'Sending request...' : 'Verifying...')) : 
                    (step === 1 ? 'Sign in' : 
                     (showMfaHelp ? 'Send Request to Admin' : 'Verify Code'))}
            </Button>
        </form>
    );
};

export default LoginForm;