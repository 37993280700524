import React, { useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-hot-toast';
import Button from '../common/Button';
import { ExportService } from '../../services/export.service';

interface ExportModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ExportModal: React.FC<ExportModalProps> = ({ isOpen, onClose }) => {
    const [masterPassword, setMasterPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const { verifyMasterPassword } = useAuth();

    const handleExport = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        try {
            const isValid = await verifyMasterPassword(masterPassword);
            
            if (!isValid) {
                toast.error('Invalid master password');
                return;
            }

            const exportData = await ExportService.exportData(masterPassword);
            
            // Create and download CSV file
            const blob = new Blob([exportData], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `lockdownpass_export_${new Date().toISOString().split('T')[0]}.csv`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            toast.success('Data exported successfully');
            onClose();
        } catch (error) {
            console.error('Export error:', error);
            toast.error('Failed to export data');
        } finally {
            setLoading(false);
            setMasterPassword('');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="relative bg-white rounded-lg max-w-md w-full p-6">
                    <div className="absolute top-4 right-4">
                        <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>

                    <div className="mb-4">
                        <h2 className="text-xl font-semibold">Export Vault</h2>
                        <div className="mt-2 p-3 bg-yellow-50 rounded-md">
                            <p className="text-sm text-yellow-800">
                                Warning: When you export, your passwords are saved to a file with readable text. 
                                When you're done using the file, we recommend deleting it so others who use this 
                                device can't see your passwords.
                            </p>
                        </div>
                    </div>

                    <form onSubmit={handleExport}>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Confirm Master Password
                            </label>
                            <input
                                type="password"
                                value={masterPassword}
                                onChange={(e) => setMasterPassword(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                required
                            />
                        </div>

                        <div className="flex justify-end space-x-3">
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                loading={loading}
                            >
                                Export
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ExportModal; 