import React, { useState } from 'react';
import VaultSidebar from '../../components/vault/VaultSidebar';
import VaultHeader from '../../components/vault/VaultHeader';
import SharedPasswordsList from '../../components/vault/SharedPasswordsList';
import SharedByMeList from '../../components/vault/SharedByMeList';
import { PlusIcon, ShareIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import ShareItemsModal from '../../components/vault/ShareItemsModal';

const SharingCenterPage: React.FC = () => {
    const [activeTab, setActiveTab] = useState('shared-by-me');
    const [shareItemsModalOpen, setShareItemsModalOpen] = useState(false);

    return (
        <div className="h-screen flex overflow-hidden bg-gray-50">
            <VaultSidebar />

            <div className="flex flex-col flex-1 overflow-hidden">
                <VaultHeader />

                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    <div className="py-4 sm:py-6">
                        <div className="w-full mx-auto px-2 sm:px-6 md:px-8">
                            {/* Header - Made responsive */}
                            <div className="mb-4 sm:mb-6 flex flex-col sm:flex-row sm:items-center justify-between p-2">
                                <div className="flex items-center mb-3 sm:mb-0">
                                    <ShareIcon className="h-6 w-6 sm:h-7 sm:w-7 text-blue-600 mr-2 sm:mr-3" />
                                    <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Sharing Center</h1>
                                </div>
                                <p className="text-sm sm:text-base text-gray-500 max-w-2xl">
                                    Manage items you've shared with others and items shared with you.
                                </p>
                            </div>

                            {/* Tabs - Made responsive */}
                            <div className="mb-4 sm:mb-6 border-b border-gray-200">
                                <div className="sm:flex sm:items-baseline">
                                    <nav className="-mb-px flex space-x-4 sm:space-x-6 overflow-x-auto" aria-label="Tabs">
                                        <button
                                            onClick={() => setActiveTab('shared-by-me')}
                                            className={`${activeTab === 'shared-by-me'
                                                ? 'border-blue-500 text-blue-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                                } whitespace-nowrap py-3 sm:py-4 px-1 border-b-2 font-medium flex items-center text-sm sm:text-base`}
                                        >
                                            <UserGroupIcon className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2" />
                                            <span>Shared by Me</span>
                                            
                                        </button>

                                        <button
                                            onClick={() => setActiveTab('shared-with-me')}
                                            className={`${activeTab === 'shared-with-me'
                                                ? 'border-blue-500 text-blue-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                                } whitespace-nowrap py-3 sm:py-4 px-1 border-b-2 font-medium flex items-center text-sm sm:text-base`}
                                        >
                                            <ShareIcon className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5 sm:mr-2" />
                                            <span>Shared with Me</span>
                                            
                                        </button>
                                    </nav>
                                </div>
                            </div>

                            {/* Content Panel - Made responsive */}
                            <div className="bg-white rounded-lg shadow overflow-hidden">
                                <div className="p-2 sm:p-4">
                                    {activeTab === 'shared-with-me' && (
                                        <SharedPasswordsList />
                                    )}
                                    {activeTab === 'shared-by-me' && (
                                        <SharedByMeList />
                                    )}
                                </div>
                            </div>

                            {/* Share Button - Made responsive */}
                            <button
                                onClick={() => setShareItemsModalOpen(true)}
                                className="fixed bottom-4 right-4 sm:bottom-6 sm:right-6 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-3 sm:p-4 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center justify-center z-10"
                                aria-label="Share items"
                            >
                                <PlusIcon className="h-5 w-5 sm:h-6 sm:w-6" />
                                <span className="ml-2 hidden sm:inline">Share Item</span>
                            </button>
                        </div>
                    </div>
                </main>
            </div>
            <ShareItemsModal
                isOpen={shareItemsModalOpen}
                onClose={() => setShareItemsModalOpen(false)}
            />
        </div>
    );
};

export default SharingCenterPage;