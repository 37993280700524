import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useVault } from '../../contexts/VaultContext';
import { ICreatePasswordEntry, IDecryptedPasswordEntry } from '../../types/vault.types';
import PasswordStrengthMeter from '../common/PasswordStrengthMeter';
import { toast } from 'react-hot-toast';
import Input from '../common/Input';
import { decryptKeyData } from '../../utils/encryption';
import { useCategories } from '../../contexts/CategoryContext';
import { VaultService } from '../../services/vault.service';
import { CategoryService } from '../../services/category.service';
import { ICategory } from '../../types/category.types';

interface EditPasswordModalProps {
    entry?: IDecryptedPasswordEntry;
    isOpen: boolean;
    onClose: () => void;
    isSharedPassword?: boolean;
    permissionLevel?: string;
    sharedKey?: string;
    onSuccessfulUpdate?: () => Promise<void> | void;
}

interface FormErrors {
    title?: string;
    username?: string;
    password?: string;
    website_url?: string;
    favourite?: boolean;
}

const EditPasswordModal: React.FC<EditPasswordModalProps> = ({
    entry,
    isOpen,
    onClose,
    isSharedPassword = false,
    permissionLevel = 'read',
    onSuccessfulUpdate,
    sharedKey
}) => {
    const { updateEntry, refreshEntries } = useVault();
    const { categories, refreshCategories, createCategory } = useCategories();

    const [formData, setFormData] = useState({
        title: entry?.title || '',
        username: entry?.username || '',
        password: entry?.password || '',
        website_url: entry?.website_url || '',
        notes: entry?.notes || '',
        favourite: entry?.favourite || false,
        category_id: entry?.category_id || '',
        isSharedUpdate: isSharedPassword,
        sharedKey: entry?.shared_key || sharedKey,
        auto_login: entry?.auto_login || false,
        favicon_url: entry?.favicon_url || ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<FormErrors>({});
    const [autoLogin, setAutoLogin] = useState(entry?.auto_login || false);
    const [isCreatingCategory, setIsCreatingCategory] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [creatingCategoryLoading, setCreatingCategoryLoading] = useState(false);

    const isReadOnly = isSharedPassword && permissionLevel === 'read';

    const validateForm = (): boolean => {
        const newErrors: FormErrors = {};

        if (!formData.title.trim()) {
            newErrors.title = 'Title is required';
        }

        if (!formData.username.trim()) {
            newErrors.username = 'Username is required';
        }

        if (!formData.password) {
            newErrors.password = 'Password is required';
        }

        if (
            formData.website_url &&
            !formData.website_url.match(/^(https?:\/\/)?((localhost(:\d+)?)|([\da-z.-]+\.[a-z.]{2,6}))([\/\w .-]*)*\/?$/)
        ) {
            newErrors.website_url = 'Please enter a valid URL';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    useEffect(() => {
        if (isOpen && !isSharedPassword) {
            refreshCategories();
        }
    }, [entry, isOpen]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;

        setFormData(prev => ({
            ...prev,
            [name]: newValue === '' ? null : newValue
        }));
        if (errors[name as keyof FormErrors]) {
            setErrors(prev => ({
                ...prev,
                [name]: undefined
            }));
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm()) return;

        setLoading(true);
        
        try {
            const updatedEntry: ICreatePasswordEntry = {
                title: formData.title,
                username: formData.username,
                password: formData.password,
                website_url: formData.website_url,
                notes: formData.notes,
                favourite: formData.favourite,
                category_id: formData.category_id,
                auto_login: autoLogin,
                sharedKey: formData.sharedKey,
                isSharedUpdate: isSharedPassword,
                itemType: entry?.itemType || 'password',
                favicon_url: formData.favicon_url
            };

            await VaultService.updateEntry(entry?.id || '', updatedEntry);
            
            toast.success('Password updated successfully');

            if (onSuccessfulUpdate) {
                await onSuccessfulUpdate();
            }
            
            onClose();
        } catch (error) {
            console.error('Failed to update entry:', error);
            toast.error('Failed to update entry');
        } finally {
            setLoading(false);
        }
    };

    const handleCreateCategory = async () => {
        if (!newCategoryName.trim()) return;

        setCreatingCategoryLoading(true);
        try {
            await createCategory({ name: newCategoryName });
            await refreshCategories();

            const newCategory = categories.find(cat => cat.name === newCategoryName);
            if (newCategory) {
                setFormData(prev => ({
                    ...prev,
                    category_id: newCategory.id
                }));
            }

            setNewCategoryName('');
            setIsCreatingCategory(false);
        } catch (error) {
            console.error('Error creating category:', error);
            toast.error('Failed to create category');
        } finally {
            setCreatingCategoryLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50 overflow-y-auto">
            <div className="bg-white rounded-lg max-w-4xl w-full my-8">
                <div className="flex justify-between items-center p-4 border-b sticky top-0 bg-white z-10">
                    <h2 className="text-lg font-medium">
                        {isReadOnly ? 'View Shared Password' : 'Edit Password Entry'}
                    </h2>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-gray-500"
                    >
                        <XMarkIcon className="h-5 w-5" />
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="flex flex-col h-full" autoComplete="off">
                    <div className="flex-1 overflow-auto max-h-[calc(100vh-16rem)]">
                        <div className="h-full flex flex-col md:flex-row md:divide-x">
                            {/* Left Column - Basic Info */}
                            <div className="w-full md:w-1/3 p-4 overflow-y-auto">
                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    disabled={isReadOnly}
                                    className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${errors.title ? 'border-red-300' : 'border-gray-300'
                                        } ${isReadOnly ? 'bg-gray-100' : ''}`}
                                />
                                {errors.title && (
                                    <p className="mt-1 text-sm text-red-600">{errors.title}</p>
                                )}
                            </div>

                                    {!isSharedPassword && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                                Vault
                                </label>
                                            {!isCreatingCategory ? (
                                                <select
                                                    name="category_id"
                                                    value={formData.category_id}
                                                    onChange={(e) => {
                                                        if (e.target.value === "create_new") {
                                                            setIsCreatingCategory(true);
                                                        } else {
                                                            handleChange(e);
                                                        }
                                                    }}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                >
                                                    <option value="">Select Vault</option>
                                                    {categories.map(category => (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                    <option value="create_new">Create new Vault</option>
                                                </select>
                                            ) : (
                                                <div className="mt-1 space-x-2">
                                <input
                                    type="text"
                                                        value={newCategoryName}
                                                        onChange={(e) => setNewCategoryName(e.target.value)}
                                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                        placeholder="Vault name"
                                                        autoFocus
                                                    />
                                                    <div className="flex items-center justify-between mt-4">
                                                        <button
                                                            type="button"
                                                            onClick={handleCreateCategory}
                                                            disabled={creatingCategoryLoading || !newCategoryName.trim()}
                                                            className={`px-3 py-2 rounded-md text-sm font-medium ${
                                                                creatingCategoryLoading || !newCategoryName.trim()
                                                                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                                    : 'bg-blue-600 text-white hover:bg-blue-700'
                                                            }`}
                                                        >
                                                            {creatingCategoryLoading ? 'Creating...' : 'Create'}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                setIsCreatingCategory(false);
                                                                setNewCategoryName('');
                                                            }}
                                                            className="px-3 py-2 rounded-md text-sm font-medium bg-gray-200 text-gray-700 hover:bg-gray-300"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {!isSharedPassword && (
                                        <div className="flex items-center mt-4">
                                            <input
                                                type="checkbox"
                                                id="favourite"
                                                name="favourite"
                                                checked={formData.favourite}
                                                onChange={(e) => setFormData({ ...formData, favourite: e.target.checked })}
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                    disabled={isReadOnly}
                                            />
                                            <label htmlFor="favourite" className="ml-2 block text-sm text-gray-900">
                                                Add to favourites
                                            </label>
                                        </div>
                                    )}

                                    {!isSharedPassword && (
                                        <div className="flex items-center space-x-2 mt-4">
                                            <input
                                                type="checkbox"
                                                id="autoLogin"
                                                checked={autoLogin}
                                                onChange={(e) => setAutoLogin(e.target.checked)}
                                                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                disabled={isSharedPassword && permissionLevel === 'read'}
                                            />
                                            <label
                                                htmlFor="autoLogin"
                                                className={`text-sm ${isSharedPassword && permissionLevel === 'read' ? 'text-gray-400' : 'text-gray-600'} flex items-center`}
                                            >
                                                <span>Enable Auto Login</span>
                                                <button
                                                    type="button"
                                                    className="ml-1 text-gray-400 hover:text-gray-500"
                                                    title="Automatically log in to this website when visiting"
                                                >
                                                    <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </button>
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Right Column - Password Details */}
                            <div className="w-full md:w-2/3 p-4 overflow-y-auto">
                                <div className="space-y-6">
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="text-sm font-medium text-gray-900 mb-4">Account Information</h4>
                                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Username
                                </label>
                                <input
                                    type="text"
                                    name="username"
                                    value={formData.username}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    spellCheck="false"
                                    disabled={isReadOnly}
                                    className={`mt-1 block w-full rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 ${errors.username ? 'border-red-300' : 'border-gray-300'
                                        } ${isReadOnly ? 'bg-gray-100' : ''}`}
                                />
                                {errors.username && (
                                    <p className="mt-1 text-sm text-red-600">{errors.username}</p>
                                )}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <Input
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        value={formData.password}
                                        onChange={handleChange}
                                        autoComplete="new-password"
                                        className={`block w-full pr-10 rounded-md focus:ring-blue-500 focus:border-blue-500 ${errors.password ? 'border-red-300' : 'border-gray-300'
                                            } ${isReadOnly ? 'bg-gray-100' : ''}`}
                                        required
                                        disabled={isReadOnly}
                                        showPasswordToggle
                                    />
                                </div>
                                {errors.password && (
                                    <p className="mt-1 text-sm text-red-600">{errors.password}</p>
                                )}
                                <PasswordStrengthMeter
                                    password={formData.password}
                                    email={formData.username}
                                />
                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">
                                                    Website URL
                                                </label>
                                                <input
                                                    type="text"
                                                    name="website_url"
                                                    value={formData.website_url}
                                                    onChange={handleChange}
                                                    disabled={isReadOnly}
                                                    className={`mt-1 block w-full rounded-md shadow-sm ${errors.website_url ? 'border-red-300' : 'border-gray-300'
                                                        } ${isReadOnly ? 'bg-gray-100' : ''}`}
                                                />
                                                {errors.website_url && (
                                                    <p className="mt-1 text-sm text-red-600">{errors.website_url}</p>
                                                )}
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Favicon URL</label>
                                                <input
                                                    type="text"
                                                    name="favicon_url"
                                                    value={formData.favicon_url}
                                                    onChange={handleChange}
                                                    disabled={isReadOnly}
                                                    className={`mt-1 block w-full rounded-md shadow-sm border-gray-300 focus:ring-blue-500 focus:border-blue-500 ${isReadOnly ? 'bg-gray-100' : ''}`}
                                                    placeholder="https://example.com/favicon.ico"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="text-sm font-medium text-gray-900 mb-4">Additional Information</h4>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Notes
                                </label>
                                <textarea
                                    name="notes"
                                    value={formData.notes}
                                    onChange={handleChange}
                                    rows={3}
                                    disabled={isReadOnly}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border-t bg-white p-4 sticky bottom-0">
                        <div className="flex justify-end space-x-3">
                            <button
                                type="button"
                                onClick={onClose}
                                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                            >
                                {isReadOnly ? 'Close' : 'Cancel'}
                            </button>
                            {!isReadOnly && (
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                                >
                                    {loading ? 'Saving...' : 'Save Changes'}
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditPasswordModal;