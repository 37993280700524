import React, { useState, useEffect } from 'react';
import { DocumentTextIcon, PencilIcon, TrashIcon, EllipsisVerticalIcon, XMarkIcon, EyeIcon } from '@heroicons/react/24/outline';
import { Note } from '../../types/notes';
import { toast } from 'react-hot-toast';
import NotesService from '../../services/notes.service';
import { VaultService } from '../../services/vault.service';
import { useVault } from '../../contexts/VaultContext';

interface NotesListProps {
    notes: Note[];
    onUpdate: () => void;
    onEdit: (note: Note, isShared?: boolean, sharedKey?: string) => void;
}

const NotesList: React.FC<NotesListProps> = ({ notes, onUpdate, onEdit }) => {
    const [hoveredNote, setHoveredNote] = useState<string | null>(null);
    const [activeNote, setActiveNote] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
    const { searchQuery } = useVault();

    // Detect screen size changes for responsive behavior
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDelete = async (id: string) => {
        try {
            if (window.confirm('Are you sure you want to delete this note?')) {
                await NotesService.deleteNote(id);
                toast.success('Note deleted successfully');
                onUpdate();
                setActiveNote(null);
            }
        } catch (error) {
            toast.error('Failed to delete note');
        }
    };

    const handleEdit = async (entryId: string, isShared?: boolean, sharedKey?: string) => {
        try {
            const note = await NotesService.getNoteById(entryId, isShared, sharedKey);
            note.shared_key = sharedKey || '';
            note.is_shared = isShared || false;
            
            // If itemType is missing, set it based on context
            // For notes within a category, this should be 'category'
            if (!note.itemType) {
                // Default to 'note' if not specified
                note.itemType = note.category_id ? 'category' : 'note';
            }
            
            onEdit(note, isShared, sharedKey);
            setActiveNote(null);
        } catch (err) {
            console.error('Failed to fetch entry:', err);
            toast.error('Failed to fetch entry');
        }
    };

    // Toggle active note on mobile
    const toggleNoteOptions = (id: string, e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent bubbling
        if (isMobile) {
            setActiveNote(activeNote === id ? null : id);
        }
    };

    // Close note options when clicking outside
    useEffect(() => {
        const handleClickOutside = () => {
            if (activeNote) {
                setActiveNote(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [activeNote]);

    const filteredNotes = notes.filter(note => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        return (
            note.name.toLowerCase().includes(searchLower) || 
            note.encrypted_notes?.toLowerCase().includes(searchLower)
        );
    });

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4 sm:gap-6 p-4 sm:p-6">
            {filteredNotes.map((note) => (
                <div
                    key={note.id}
                    className="relative bg-yellow-200 p-5 rounded-sm shadow-lg transition-transform duration-200 ease-in-out hover:scale-105 hover:shadow-2xl transform"
                    onMouseEnter={() => !isMobile && setHoveredNote(note.id)}
                    onMouseLeave={() => !isMobile && setHoveredNote(null)}
                    onClick={(e) => isMobile && toggleNoteOptions(note.id, e)}
                    style={{
                        minHeight: "250px",
                        maxWidth: "100%",
                        fontFamily: "'Patrick Hand', cursive, system-ui",
                        position: 'relative',
                        background: 'linear-gradient(to bottom right, #f7e697, #f5d76e)',
                        borderRadius: '12px',
                    }}
                >
                    {/* Top-right corner fold effect */}
                    <div
                        className="absolute top-0 right-0 w-0 h-0"
                        style={{
                            borderStyle: 'solid',
                            borderWidth: '0 30px 30px 0',
                            borderColor: 'transparent #e4c65c transparent transparent',
                            boxShadow: '-2px 2px 3px rgba(0,0,0,0.1)'
                        }}
                    />

                    {/* Pin Effect */}
                    <div className="absolute -top-2 left-1/2 transform -translate-x-1/2">
                        <div className="w-4 h-4 bg-red-500 rounded-full border-2 border-gray-900 shadow-md"></div>
                    </div>

                    {/* Note Content */}
                    <h3 className="text-lg font-bold text-black mb-2 truncate">
                        {note.name}
                    </h3>

                    {note.category_name && (
                        <div className="flex items-center mb-2">
                            <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-400 text-white">
                                {note.category_name}
                            </span>
                        </div>
                    )}

                    <p className="text-base text-black break-words line-clamp-5" style={{ fontFamily: "'Patrick Hand', cursive, system-ui" }}>
                        {note.encrypted_notes}
                    </p>

                    {/* Footer - Date and Actions */}
                    <div className="absolute bottom-3 left-4 right-4 flex justify-between items-center text-sm text-black">
                        <time dateTime={note.updated_at}>
                            {new Date(note.updated_at).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                            })}
                        </time>

                        <div className="flex space-x-2">
                            {note.favourite && (
                                <svg
                                    className="w-6 h-6 text-blue-400 hover:text-blue-500 transform hover:scale-110 transition duration-300 ease-in-out"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                                    />
                                </svg>
                            )}

                            {/* Mobile menu icon */}
                            {isMobile && (
                                <button
                                    onClick={(e) => toggleNoteOptions(note.id, e)}
                                    className="text-black p-1 rounded-full hover:bg-yellow-100 focus:outline-none"
                                >
                                    <EllipsisVerticalIcon className="h-5 w-5" />
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Desktop hover actions */}
                    {!isMobile && (
                        <>
                            {(note.permission_level && note.permission_level === 'read') ? (
                                <div className={`absolute inset-0 w-full h-full bg-gradient-to-br from-yellow-200 to-yellow-300 bg-opacity-90 flex flex-col items-center justify-center transition-all duration-300 rounded-lg ${hoveredNote === note.id ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                                    <button
                                        onClick={() => handleEdit(note.id, note.is_shared, note.shared_key)}
                                        className="w-3/4 py-2 px-4 bg-white/20 hover:bg-white/30 text-black rounded-lg transition-colors flex items-center justify-center space-x-2"
                                    >
                                        <EyeIcon className="h-5 w-5" />
                                        <span> View</span>
                                    </button>
                                </div>
                            )
                                :
                                <div className={`absolute inset-0 w-full h-full bg-gradient-to-br from-yellow-200 to-yellow-300 bg-opacity-90 flex flex-col items-center justify-center transition-all duration-300 rounded-lg ${hoveredNote === note.id ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                                    <button
                                        onClick={() => handleEdit(note.id, note.is_shared, note.shared_key)}
                                        className="w-3/4 py-2 px-4 bg-white/20 hover:bg-white/30 text-black rounded-lg transition-colors flex items-center justify-center space-x-2"
                                    >
                                        <PencilIcon className="h-5 w-5" />
                                        <span> Edit</span>
                                    </button>
                                    <button
                                        onClick={() => handleDelete(note.id)}
                                        className="w-3/4 py-2 px-4 bg-red-500/20 hover:bg-red-500/30 text-black rounded-lg transition-colors flex items-center justify-center space-x-2 mt-3"
                                    >
                                        <TrashIcon className="h-5 w-5" />
                                        <span>Delete</span>
                                    </button>
                                </div>
                            }
                        </>

                    )}

                    {/* Mobile action menu - replaces hover effect */}
                    {isMobile && activeNote === note.id && (
                        <div
                            className="absolute right-0 left-0 bottom-0 bg-yellow-100 backdrop-blur-sm rounded-b-lg z-10 p-3 shadow-lg"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex justify-between items-center mb-2">
                                <h4 className="font-medium">Note options</h4>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActiveNote(null);
                                    }}
                                    className="p-1 rounded-full hover:bg-yellow-200"
                                >
                                    <XMarkIcon className="h-5 w-5" />
                                </button>
                            </div>
                            {(note.permission_level && note.permission_level === 'read') ? (
                                <div className="grid grid-cols-2 gap-2">
                                <button
                                    onClick={() => handleEdit(note.id, note.is_shared, note.shared_key)}
                                    className="py-2 bg-yellow-300 hover:bg-yellow-400 text-black rounded-lg transition-colors flex items-center justify-center space-x-1"
                                >
                                    <EyeIcon className="h-4 w-4" />
                                    <span>View</span>
                                </button>
                                
                            </div>
                            )
                            :
                            <div className="grid grid-cols-2 gap-2">
                                <button
                                    onClick={() => handleEdit(note.id, note.is_shared, note.shared_key)}
                                    className="py-2 bg-yellow-300 hover:bg-yellow-400 text-black rounded-lg transition-colors flex items-center justify-center space-x-1"
                                >
                                    <PencilIcon className="h-4 w-4" />
                                    <span>Edit</span>
                                </button>
                                <button
                                    onClick={() => handleDelete(note.id)}
                                    className="py-2 bg-red-300 hover:bg-red-400 text-black rounded-lg transition-colors flex items-center justify-center space-x-1"
                                >
                                    <TrashIcon className="h-4 w-4" />
                                    <span>Delete</span>
                                </button>
                            </div>
                            }
                            
                        </div>
                    )}
                </div>
            ))}

            {/* No Notes Message */}
            {filteredNotes.length === 0 && (
                <div className="col-span-full flex flex-col items-center justify-center py-12 text-gray-500 dark:text-gray-400">
                    <DocumentTextIcon className="h-12 w-12 mb-4" />
                    <p className="text-lg">No notes found</p>
                    <p className="text-sm">Create a new note to get started</p>
                </div>
            )}
        </div>
    );
};

export default NotesList; 