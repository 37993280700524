import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VaultSidebar from '../../components/vault/VaultSidebar';
import VaultHeader from '../../components/vault/VaultHeader';
import { VaultService } from '../../services/vault.service';
import PasswordList from '../../components/vault/PasswordList';
import NotesList from '../../components/notes/NotesList';
import CardDetailsList from '../../components/cardDetails/CardDetailsList';
import PersonalDetailsList from '../../components/personalDetails/PersonalDetailsList';
import { IDecryptedPasswordEntry } from '../../types/vault.types';
import { Note } from '../../types/notes';
import { CardDetail } from '../../types/cardDetails';
import { PersonalDetail } from '../../types/personalDetails';
import { PlusIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { BankDetail } from '../../types/bankDetails';
import BankDetailsList from '../../components/bankDetails/BankDetailsList';
import CreateNoteModal from '../../components/notes/CreateNoteModal';
import BankDetailsModal from '../../components/bankDetails/BankDetailsModal';
import CardDetailsModal from '../../components/cardDetails/CardDetailsModal';
import PersonalDetailsModal from '../../components/personalDetails/PersonalDetailsModal';
import { useVault } from '../../contexts/VaultContext';

// Tab type definition
type TabType = 'passwords' | 'notes' | 'cardDetails' | 'bankDetails' | 'personalDetails';

const FavoritePage: React.FC = () => {
    const navigate = useNavigate();
    const { searchQuery } = useVault();

    // State variables for all items
    const [entries, setEntries] = useState<IDecryptedPasswordEntry[]>([]);
    const [notes, setNotes] = useState<Note[]>([]);
    const [cardDetails, setCardDetails] = useState<CardDetail[]>([]);
    const [personalDetails, setPersonalDetails] = useState<PersonalDetail[]>([]);
    const [bankDetails, setBankDetails] = useState<BankDetail[]>([]);

    // Loading state
    const [loading, setLoading] = useState<boolean>(true);

    // Active tab state
    const [activeTab, setActiveTab] = useState<TabType>('passwords');

    // Modal states
    const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
    const [selectedNote, setSelectedNote] = useState<Note | null>(null);
    const [isCardModalOpen, setIsCardModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState<CardDetail | null>(null);
    const [isBankModalOpen, setIsBankModalOpen] = useState(false);
    const [selectedBank, setSelectedBank] = useState<BankDetail | null>(null);
    const [isPersonalModalOpen, setIsPersonalModalOpen] = useState(false);
    const [selectedPersonal, setSelectedPersonal] = useState<PersonalDetail | null>(null);

    // Fetch all favorites data at once
    const fetchData = async () => {
        setLoading(true);
        try {
            const allItemsData = await VaultService.getFavouriteItemsData();
            if (allItemsData) {
                setEntries(allItemsData?.passwords);
                setNotes(allItemsData?.notes);
                setCardDetails(allItemsData?.cards);
                setPersonalDetails(allItemsData?.personals);
                setBankDetails(allItemsData?.banks);
            }
        } catch (error) {
            console.error('Error fetching favorites:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Filter items based on search query
    const filteredEntries = entries.filter(entry => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();
        return (
            entry.title?.toLowerCase().includes(query) ||
            entry.username?.toLowerCase().includes(query) ||
            entry.website_url?.toLowerCase().includes(query) ||
            entry.notes?.toLowerCase().includes(query)
        );
    });

    const filteredNotes = notes.filter(note => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();
        return (
            note.name?.toLowerCase().includes(query) ||
            note.encrypted_notes?.toLowerCase().includes(query)
        );
    });

    const filteredCardDetails = cardDetails.filter(card => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();
        return (
            card.name?.toLowerCase().includes(query) ||
            card.encrypted_card_holder_name?.toLowerCase().includes(query) ||
            card.card_type?.toLowerCase().includes(query)
        );
    });

    const filteredBankDetails = bankDetails.filter(bank => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();
        return (
            bank.name?.toLowerCase().includes(query) ||
            bank.encrypted_iban_number?.toLowerCase().includes(query) ||
            bank.encrypted_account_number?.toLowerCase().includes(query) ||
            bank.encrypted_routing_number?.toLowerCase().includes(query)
        );
    });

    const filteredPersonalDetails = personalDetails.filter(detail => {
        if (!searchQuery) return true;
        const query = searchQuery.toLowerCase();
        return (
            detail.name?.toLowerCase().includes(query) ||
            detail.encrypted_first_name?.toLowerCase().includes(query) ||
            detail.encrypted_last_name?.toLowerCase().includes(query) ||
            detail.encrypted_email_address?.toLowerCase().includes(query)
        );
    });

    // Tab configuration
    const tabs = [
        { id: 'passwords', label: 'Passwords', count: filteredEntries.length },
        // { id: 'notes', label: 'Notes', count: filteredNotes.length },
        { id: 'cardDetails', label: 'Cards', count: filteredCardDetails.length },
        { id: 'bankDetails', label: 'Bank Accounts', count: filteredBankDetails.length },
        { id: 'personalDetails', label: 'Personal Info', count: filteredPersonalDetails.length },
    ];

    const handleEditNote = (note: Note) => {
        setSelectedNote(note);
        setIsNoteModalOpen(true);
    };

    const handleNoteModalClose = () => {
        setIsNoteModalOpen(false);
        setSelectedNote(null);
    };

    const handleCardModalClose = () => {
        setIsCardModalOpen(false);
        setSelectedCard(null);
    };

    const handleBankModalClose = () => {
        setIsBankModalOpen(false);
        setSelectedBank(null);
    };

    const handlePersonalModalClose = () => {
        setIsPersonalModalOpen(false);
        setSelectedPersonal(null);
    };

    const handleViewMore = (section: string) => {
        // Redirect to the corresponding detailed page
        if (section === 'passwords') {
            navigate('/passwords');
        } else if (section === 'notes') {
            navigate('/notes');
        } else if (section === 'cardDetails') {
            navigate('/card-details');
        } else if (section === 'personalDetails') {
            navigate('/personal-details');
        } else if (section === 'bankDetails') {
            navigate('/bank-details');
        }
    };

    return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
            <VaultSidebar />
            <div className="flex flex-col flex-1 overflow-hidden">
                <VaultHeader />
                <main className="flex-1 overflow-y-auto bg-gray-50 dark:bg-gray-900">
                    <div className="container mx-auto px-4 py-6">
                        <div className="mb-6">
                            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Favorites</h1>
                            <p className="text-gray-600 dark:text-gray-400 text-sm">Manage all your favorite items</p>
                        </div>

                        {/* Tabs */}
                        <div className="border-b border-gray-200 dark:border-gray-700 mb-6">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {tabs.map((tab) => (
                                    <button
                                        key={tab.id}
                                        onClick={() => setActiveTab(tab.id as TabType)}
                                        className={`
                                                whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                                                ${activeTab === tab.id
                                                ? 'border-blue-500 text-blue-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                            }
                                            `}
                                    >
                                        {tab.label}
                                        
                                    </button>
                                ))}
                            </nav>
                        </div>

                        {/* Content */}
                        <div className="space-y-6">
                            {loading ? (
                                <div className="flex justify-center items-center py-12">
                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                                </div>
                            ) : (
                                <>
                                    <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                                        <div className="px-4 py-5">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Passwords</h3>
                                        </div>
                                        {/* Passwords Tab */}
                                        {activeTab === 'passwords' && (
                                            <PasswordList
                                                entries={filteredEntries}
                                                onSuccessfulUpdate={fetchData}
                                            />
                                        )}

                                        {/* Notes Tab */}
                                        {/* {activeTab === 'notes' && (
                                            <NotesList
                                                notes={filteredNotes}
                                                onUpdate={fetchData}
                                                onEdit={handleEditNote}
                                            />
                                        )} */}

                                        {/* Cards Tab */}
                                        {activeTab === 'cardDetails' && (
                                            <CardDetailsList
                                                details={filteredCardDetails}
                                                onUpdate={fetchData}
                                                onEdit={(card) => {
                                                    setSelectedCard(card);
                                                    setIsCardModalOpen(true);
                                                }}
                                            />
                                        )}

                                        {/* Bank Details Tab */}
                                        {activeTab === 'bankDetails' && (
                                            <BankDetailsList
                                                details={filteredBankDetails}
                                                onUpdate={fetchData}
                                                onEdit={(bank) => {
                                                    setSelectedBank(bank);
                                                    setIsBankModalOpen(true);
                                                }}
                                            />
                                        )}

                                        {/* Personal Details Tab */}
                                        {activeTab === 'personalDetails' && (
                                            <PersonalDetailsList
                                                details={filteredPersonalDetails}
                                                onUpdate={fetchData}
                                                onEdit={(personal) => {
                                                    setSelectedPersonal(personal);
                                                    setIsPersonalModalOpen(true);
                                                }}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </main>
            </div>
            <CreateNoteModal
                isOpen={isNoteModalOpen}
                onClose={handleNoteModalClose}
                onSuccess={fetchData}
                note={selectedNote || undefined}
            />
            <BankDetailsModal
                isOpen={isBankModalOpen}
                onClose={handleBankModalClose}
                onSuccess={() => {
                    handleBankModalClose();
                }}
                bank={selectedBank || undefined}
            />
            <CardDetailsModal
                isOpen={isCardModalOpen}
                onClose={handleCardModalClose}
                onSuccess={() => {
                    handleCardModalClose();
                }}
                card={selectedCard || undefined}
            />
            <PersonalDetailsModal
                isOpen={isPersonalModalOpen}
                onClose={handlePersonalModalClose}
                onSuccess={() => {
                    handlePersonalModalClose();
                }}
                personal={selectedPersonal || undefined}
            />
        </div>
    );
};

export default FavoritePage;