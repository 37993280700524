import { store } from '../store';
import { config } from '../extension/config';
import { VaultService } from './vault.service';
import { NotesService } from './notes.service';
import { BankDetailsService } from './bankDetails.service';
import { CardDetailsService } from './cardDetails.service';
import { PersonalDetailsService } from './personalDetails.service';
import { CategoryService } from './category.service';

const API_URL = config.API_URL;

export class ExportService {
    static async exportData(masterPassword: string): Promise<string> {
        try {
            // Fetch all data using existing services
            const [
                passwords,
                notes,
                bankDetails,
                cardDetails,
                personalDetails,
                categories
            ] = await Promise.all([
                VaultService.getAllEntries(),
                NotesService.getAllNotes(),
                BankDetailsService.getAllBankDetails(),
                CardDetailsService.getAllCardDetails(),
                PersonalDetailsService.getAllPersonalDetails(),
                CategoryService.getAllCategories()
            ]);

            // Create CSV header
            const header = [
                'type',
                'name',
                'folder',
                'username',
                'password',
                'website_url',
                'notes',
                'title',
                'first_name',
                'middle_name',
                'last_name',
                'gender',
                'birthday',
                'email_address',
                'phone',
                'address_1',
                'address_2',
                'city_town',
                'state',
                'country',
                'zip_postal_code',
                'timezone',
                'card_type',
                'card_holder_name',
                'card_number',
                'expiration_date',
                'cvv',
                'pin',
                'postal_code',
                'bank_name',
                'account_type',
                'routing_number',
                'account_number',
                'swift_code',
                'iban_number',
                'branch_address',
                'branch_phone'
            ].join(',');

            const rows: string[] = [header];

            // Process passwords
            passwords.forEach(pwd => {
                const category = categories.find(c => c.id === pwd.category_id);
                rows.push([
                    'password',
                    escapeCsvValue(pwd.title),
                    escapeCsvValue(category?.name || ''),
                    escapeCsvValue(pwd.username || ''),
                    escapeCsvValue(pwd.password || ''),
                    escapeCsvValue(pwd.website_url || ''),
                    escapeCsvValue(pwd.notes || ''),
                    // Fill empty values for other fields
                    ...new Array(29).fill('')
                ].join(','));
            });

            // Process notes
            notes.forEach(note => {
                const category = categories.find(c => c.id === note.category_id);
                rows.push([
                    'note',
                    escapeCsvValue(note.name),
                    escapeCsvValue(category?.name || ''),
                    '',
                    '',
                    '',
                    escapeCsvValue(note.encrypted_notes || ''),
                    // Fill empty values for other fields
                    ...new Array(29).fill('')
                ].join(','));
            });

            // Process personal details
            personalDetails.forEach(detail => {
                rows.push([
                    'personal_details',
                    escapeCsvValue(detail.name || ''),
                    '',
                    '',
                    '',
                    '',
                    escapeCsvValue(detail.encrypted_notes || ''),
                    escapeCsvValue(detail.title || ''),
                    escapeCsvValue(detail.encrypted_first_name || ''),
                    escapeCsvValue(detail.encrypted_middle_name || ''),
                    escapeCsvValue(detail.encrypted_last_name || ''),
                    escapeCsvValue(detail.gender || ''),
                    escapeCsvValue(detail.birthday || ''),
                    escapeCsvValue(detail.encrypted_email_address || ''),
                    escapeCsvValue(detail.encrypted_phone || ''),
                    escapeCsvValue(detail.encrypted_address_1 || ''),
                    escapeCsvValue(detail.encrypted_address_2 || ''),
                    escapeCsvValue(detail.encrypted_city_town || ''),
                    escapeCsvValue(detail.encrypted_state || ''),
                    escapeCsvValue(detail.encrypted_country || ''),
                    escapeCsvValue(detail.encrypted_zip_postal_code || ''),
                    escapeCsvValue(detail.encrypted_timezone || ''),
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    ''
                ].join(','));
            });

            // Process bank details
            bankDetails.forEach(bank => {
                const category = categories.find(c => c.id === bank.category_id);
                rows.push([
                    'bank_details',
                    escapeCsvValue(bank.name),
                    escapeCsvValue(category?.name || ''),
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    escapeCsvValue(bank.bank_name || ''),
                    escapeCsvValue(bank.account_type || ''),
                    escapeCsvValue(bank.encrypted_routing_number || ''),
                    escapeCsvValue(bank.encrypted_account_number || ''),
                    escapeCsvValue(bank.encrypted_swift_code || ''),
                    escapeCsvValue(bank.encrypted_iban_number || ''),
                    escapeCsvValue(bank.encrypted_branch_address || ''),
                    escapeCsvValue(bank.encrypted_branch_phone || '')
                ].join(','));
            });

            // Add similar processing for card_details

            return rows.join('\n');
        } catch (error) {
            console.error('Export error:', error);
            throw error;
        }
    }
}

function escapeCsvValue(value: string): string {
    if (!value) return '';
    if (value.includes(',') || value.includes('"') || value.includes('\n')) {
        return `"${value.replace(/"/g, '""')}"`;
    }
    return value;
} 