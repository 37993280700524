import React, { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import VaultSidebar from '../../components/vault/VaultSidebar';
import VaultHeader from '../../components/vault/VaultHeader';
import BankDetailsList from '../../components/bankDetails/BankDetailsList';
import BankDetailsModal from '../../components/bankDetails/BankDetailsModal';
import { BankDetail } from '../../types/bankDetails';
import BankDetailsService from '../../services/bankDetails.service';
import { toast } from 'react-hot-toast';

const BankDetailsPage: React.FC = () => {
    const [details, setDetails] = useState<BankDetail[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState<BankDetail | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchBankDetails();
    }, []);

    const fetchBankDetails = async () => {
        try {
            const fetchedDetails = await BankDetailsService.getAllBankDetails();
            setDetails(fetchedDetails);
        } catch (error) {
            toast.error('Failed to fetch bank accounts');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = (detail: BankDetail) => {
        setSelectedDetail(detail);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setSelectedDetail(null);
        setIsModalOpen(false);
    };

    const handleCreateClick = () => {
        setSelectedDetail(null);
        setIsModalOpen(true);
    };

    return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
            <VaultSidebar />
            <div className="flex flex-col flex-1 overflow-hidden">
                <VaultHeader />
                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="w-full px-4 sm:px-6 md:px-8">
                            <div className="flex justify-between items-center mb-6">
                                <div>
                                    <h1 className="text-2xl font-semibold text-gray-900">Bank Accounts</h1>
                                    <p className="mt-1 text-sm text-gray-500">
                                        Manage your bank accounts securely
                                    </p>
                                </div>
                            </div>

                            {loading ? (
                                <div className="flex justify-center items-center h-64">
                                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
                                </div>
                            ) : (
                                <BankDetailsList
                                    details={details}
                                    onUpdate={fetchBankDetails}
                                    onEdit={handleEdit}
                                />
                            )}
                        </div>

                        {/* Floating Action Button */}
                        <button
                            onClick={handleCreateClick}
                            className="fixed bottom-8 right-8 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                            title="Add new bank account"
                        >
                            <PlusIcon className="h-6 w-6" />
                        </button>
                    </div>
                </main>
            </div>

            <BankDetailsModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                onSuccess={() => {
                    fetchBankDetails();
                    handleModalClose();
                }}
                bank={selectedDetail || undefined}
            />
        </div>
    );
};

export default BankDetailsPage; 