import React, { useEffect, useState } from 'react';
import VaultSidebar from '../../components/vault/VaultSidebar';
import VaultHeader from '../../components/vault/VaultHeader';
import { TeamService } from '../../services/team.service';
import { TeamMember, TeamInvitation } from '../../types/team.types';
import ActiveUsersList from '../../components/team/ActiveUsersList';
import PendingUsersList from '../../components/team/PendingUsersList';
import { toast } from 'react-hot-toast';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Role } from '../../types/team.types';
import { useAppSelector } from '../../hooks/useRedux';
import InviteMemberModal from '../../components/team/InviteMemberModal';

const TeamMembersPage: React.FC = () => {
    const [activeTab, setActiveTab] = useState('active');
    const [activeMembers, setActiveMembers] = useState<TeamMember[]>([]);
    const [pendingInvitations, setPendingInvitations] = useState<TeamInvitation[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showInviteForm, setShowInviteForm] = useState(false);
    const [roles, setRoles] = useState<Role[]>([]);
    const { permissions, team_id } = useAppSelector((state) => state.auth);
    const hasInviteMembersPermission = permissions?.invite_members === true;
    const fetchTeamData = async () => {
        const teamId = team_id || '';

        try {
            setIsLoading(true);

            // Fetch team details
            const membersResponse = await TeamService.getTeamMembers(teamId);
            setActiveMembers(membersResponse.activeMembers || []);
            setPendingInvitations(membersResponse.pendingInvitations || []);
            setRoles(membersResponse.roles || []);
            setIsLoading(false);

        } catch (error) {
            console.error('Error fetching team data:', error);
            toast.error('Failed to load team data');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTeamData();
    }, [team_id]);

    const handleRoleChange = async (userId: string, newRoleId: number) => {
        const confirm = window.confirm('Are you sure you want to update the role?');
        if (!confirm) return;

        try {
            const response = await TeamService.updateUserRole(userId, newRoleId);
            if (response.success) {
                toast.success('Role updated successfully');
                fetchTeamData(); // Refresh the team members after a successful update
            } else {
                toast.error('Failed to update role: ' + response.message);
            }
        } catch (error) {
            console.error('Error updating role:', error);
            toast.error('Error updating role');
        }
    };

    const handleInviteMember = async (email: string, roleId: number) => {
        const teamId = team_id || '';
        
        try {
          await TeamService.inviteUser(email, roleId, teamId);
          fetchTeamData();
          setShowInviteForm(false);
          toast.success('Invitation sent successfully');
        } catch (error) {
          console.error('Error inviting member:', error);
          toast.error('Failed to send invitation');
        }
      };
    return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
            <VaultSidebar />
            <div className="flex flex-col  flex-1 overflow-hidden">
                <VaultHeader />
                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="w-full px-4 sm:px-6 md:px-8">
                            <div className="flex justify-between items-center">
                                <h1 className="text-2xl font-semibold text-gray-900">Team Members</h1>
                            </div>

                            <div className="mt-6">
                                <div className="border-b border-gray-200">
                                    <nav className="-mb-px flex space-x-8">
                                        <button
                                            onClick={() => setActiveTab('active')}
                                            className={`${activeTab === 'active'
                                                ? 'border-indigo-500 text-indigo-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                        >
                                            Active Members
                                        </button>
                                        <button
                                            onClick={() => setActiveTab('pending')}
                                            className={`${activeTab === 'pending'
                                                ? 'border-indigo-500 text-indigo-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                        >
                                            Pending Invitations
                                        </button>
                                    </nav>
                                </div>

                                {isLoading ? (
                                    <div className="mt-6 text-center">Loading...</div>
                                ) : (
                                    <div className="mt-6">
                                        {activeTab === 'active' ? (
                                            <ActiveUsersList members={activeMembers} roles={roles} onRoleChange={handleRoleChange} onRevoke={fetchTeamData} />
                                        ) : (
                                            <PendingUsersList
                                                invitations={pendingInvitations}
                                                onRevoke={fetchTeamData}
                                                onOpenInviteModal={() => setShowInviteForm(true)}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        {hasInviteMembersPermission && (
                            <button
                                onClick={() => setShowInviteForm(true)}
                                className="fixed bottom-8 right-8 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                                title="Add new role"
                            >
                                <PlusIcon className="h-6 w-6" />
                            </button>
                        )}
                    </div>
                </main>
            </div>
            {showInviteForm && (
            <InviteMemberModal
                teamId={team_id || ''}
                onClose={() => setShowInviteForm(false)}
                onInvite={handleInviteMember}
            />
            )}
        </div>
    );
};

export default TeamMembersPage;