import React from 'react';
import Modal from '../common/Modal';
import { KeyIcon, DocumentTextIcon, IdentificationIcon, CreditCardIcon, BuildingLibraryIcon } from '@heroicons/react/24/outline';

interface QuickAddItemModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (itemType: string) => void;
    selectedType: string | null;
    items: any[];
    onItemSelect: (id: string) => void;
    onSubmit: () => void;
}

const QuickAddItemModal: React.FC<QuickAddItemModalProps> = ({ 
    isOpen, 
    onClose, 
    onSelect, 
    selectedType,
    items,
    onItemSelect,
    onSubmit
}) => {
    const itemTypes = [
        { type: 'password', label: 'Password', icon: <KeyIcon className="h-8 w-8 text-blue-500" /> },
        // { type: 'notes', label: 'Notes', icon: <DocumentTextIcon className="h-8 w-8 text-green-500" /> },
        { type: 'personalDetails', label: 'Personal Details', icon: <IdentificationIcon className="h-8 w-8 text-purple-500" /> },
        { type: 'cardDetails', label: 'Card Details', icon: <CreditCardIcon className="h-8 w-8 text-red-500" /> },
        { type: 'bankDetails', label: 'Bank Details', icon: <BuildingLibraryIcon className="h-8 w-8 text-pink-500" /> },
    ];

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Add Existing Item">
            {!selectedType ? (
                <div className="grid grid-cols-4 gap-4 px-4">
                    {itemTypes.map((item) => (
                        <button
                            key={item.type}
                            onClick={() => onSelect(item.type)}
                            className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg hover:bg-gray-200 transition-all"
                        >
                            {item.icon}
                            <span className="mt-2 text-sm font-medium text-gray-700">{item.label}</span>
                        </button>
                    ))}
                </div>
            ) : (
                <div className="p-4">
                    <select
                        onChange={(e) => onItemSelect(e.target.value)}
                        className="w-full p-2 border rounded mb-4"
                    >
                        <option value="">Select an item</option>
                        {items.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name || item.title}
                            </option>
                        ))}
                    </select>
                    <div className="flex justify-end space-x-2">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 text-gray-600"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onSubmit}
                            className="px-4 py-2 bg-blue-600 text-white rounded"
                        >
                            Add
                        </button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default QuickAddItemModal; 