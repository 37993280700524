import React from 'react';
import { TeamInvitation } from '../../types/team.types';
import { toast } from 'react-hot-toast';
import { TeamService } from '../../services/team.service';
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useVault } from '../../contexts/VaultContext';
import { useAppSelector } from '../../hooks/useRedux';
import Button from '../common/Button';

interface PendingUsersListProps {
    invitations: TeamInvitation[];
    onRevoke: () => void;
    onOpenInviteModal: () => void;
}

const PendingUsersList: React.FC<PendingUsersListProps> = ({ invitations, onRevoke, onOpenInviteModal }) => {
    const { searchQuery } = useVault();
    const filteredInvitations = invitations.filter((invitation) => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        return (
            invitation.email?.toLowerCase().includes(searchLower) ||
            invitation.invited_by_email?.toLowerCase().includes(searchLower) ||
            invitation.role_name?.toLowerCase().includes(searchLower)
        );
    });
    const { permissions } = useAppSelector((state) => state.auth);
    const hasRevokeInvitationsPermission = permissions?.remove_members === true;

    const handleRevoke = async (invitationId: string) => {
        try {
            window.confirm('Are you sure you want to revoke this invitation?');
            await TeamService.revokeInvitation(invitationId);
            toast.success('Invitation revoked successfully');
            onRevoke();
        } catch (error) {
            toast.error('Failed to revoke invitation');
        }
    };

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            {filteredInvitations.length === 0 ? (
                <div className="flex items-center justify-center p-4 text-gray-500">
                    <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                    <span>No pending invitations. Click the button below to invite users.</span>
                </div>
            ) : (
                <>
                    {/* Desktop view */}
                    <div className="hidden md:block overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invited By</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Invited At</th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {filteredInvitations.map((invitation) => (
                                    <tr key={invitation.id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{invitation.email}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invitation.invited_by_email}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                {invitation.role_name}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {new Date(invitation.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                        </td>

                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            {hasRevokeInvitationsPermission && (
                                                <button
                                                    onClick={() => handleRevoke(invitation.id, )}
                                                    className="text-red-600 hover:text-red-900 focus:outline-none"
                                                >
                                                    Revoke
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Mobile view */}
                    <div className="block md:hidden">
                        <ul className="divide-y divide-gray-200">
                            {filteredInvitations.map((invitation) => (
                                <li key={invitation.id} className="py-4 px-4">
                                    <div className="flex justify-between items-start">
                                        <div className="space-y-2">
                                            <p className="text-sm font-medium text-gray-900">{invitation.email}</p>
                                            <div className="flex items-center text-xs text-gray-500">
                                                <span className="mr-2">Invited by:</span>
                                                <span>{invitation.invited_by_email}</span>
                                            </div>
                                            <div className="flex items-center text-xs text-gray-500">
                                                <span className="mr-2">Role:</span>
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    {invitation.role_name}
                                                </span>
                                            </div>
                                            <div className="flex items-center text-xs text-gray-500">
                                                <span className="mr-2">Date:</span>
                                                <span>{new Date(invitation.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</span>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => handleRevoke(invitation.id)}
                                            className="ml-2 flex items-center justify-center h-8 w-8 rounded-full bg-red-100 text-red-600 hover:bg-red-200"
                                            aria-label="Revoke invitation"
                                        >
                                            <XCircleIcon className="h-5 w-5" />
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

export default PendingUsersList; 