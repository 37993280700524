import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Team, TeamService } from '../../services/team.service';
import { UsersIcon, TrashIcon, PencilSquareIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '../../hooks/useRedux';
import toast from 'react-hot-toast';
import DeleteTeamModal from './DeleteTeamModal';
import CreateTeamModal from './CreateTeamModal';

interface TeamCardProps {
  team: Team;
  onDeleted: () => void;
  onUpdated: () => void;
  onRefresh: () => void;
  storageData?: any;
  isRefreshing: boolean;
}

const TeamCard: React.FC<TeamCardProps> = ({ team, onDeleted, onUpdated, onRefresh,storageData, isRefreshing   }) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { permissions } = useAppSelector(state => state.auth);
  const canManageTeams = permissions?.manage_teams;

  const handleCardClick = () => {
    navigate(`/team/${team.id}`);
  };

  const handleDeleteTeam = async () => {
    if (!window.confirm('Are you sure you want to delete this team?')) {
      return;
    }

    try {
      await TeamService.deleteTeam(team.id);
      onDeleted();
    } catch (error) {
      console.error('Error deleting team:', error);
      toast.error('Failed to delete team');
    }
  };

  const formatBytes = (bytes?: number): string => {
    if (!bytes || bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="p-5">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-lg font-medium text-gray-900 truncate">{team.name}</h3>
            <p className="text-sm text-gray-500 mt-1 truncate">{team.description || 'No description'}</p>
          </div>

        </div>

        <div className="mt-4 flex items-center text-sm text-gray-500">
          <UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" />
          <span>{team.member_count} {team.member_count === 1 ? 'member' : 'members'}</span>
        </div>
    
        {(team.storage_data || storageData) && (
            <div className="mt-3">
              <div className="flex justify-between text-sm">
                <span>Storage used:</span>
                <span className="font-medium">{formatBytes(team.storage_data?.totalBytes || 0)}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-1.5 mt-1">
                <div 
                  className="bg-blue-600 h-1.5 rounded-full" 
                  style={{ width: `${team.storage_data?.usagePercentage ? team.storage_data.usagePercentage : 0}%` }}
                ></div>
              </div>
              <div className="flex justify-between text-sm mt-1">
                <span>{team.storage_data?.usagePercentage ? team.storage_data.usagePercentage.toFixed(2) : '0.00'}%</span>
                <span className="font-medium">50 MB</span>
              </div>
            </div>
          )}
      </div>

      <div className="border-t border-gray-200 bg-gray-50 px-5 py-3 flex justify-between">
        <Link
          to={`/team/${team.id}`}
          className="text-sm font-medium text-blue-600 hover:text-blue-500"
        >
          View details
        </Link>

        {canManageTeams && (
          <div className="flex space-x-2">
            <button
              onClick={onRefresh}
              className="text-gray-400 hover:text-blue-500"
              disabled={isRefreshing}
              title="Refresh storage usage"
            >
              <ArrowPathIcon className={`h-5 w-5 ${isRefreshing ? 'animate-spin text-blue-500' : ''}`} />
            </button>
             <button
              onClick={(e) => {
                e.stopPropagation();
                setShowEditModal(true);
              }}
              className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
              title="Edit Team"
            >
              <PencilSquareIcon className="h-5 w-5" />
            </button>

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteModal(true);
                }} className="text-red-400 hover:text-red-500"
              >
                <TrashIcon className="h-5 w-5" />
              </button>
          </div>
        )}
      </div>

      <DeleteTeamModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        teamId={team.id}
        teamName={team.name}
        onDeleted={onDeleted}
      />
      <CreateTeamModal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        onTeamCreated={onUpdated}
        editMode={true}
        teamData={team}
      />
    </div>
  );
};

export default TeamCard;