import React, { useEffect, useState } from 'react';
import VaultSidebar from '../../components/vault/VaultSidebar';
import VaultHeader from '../../components/vault/VaultHeader';
import PasswordList from '../../components/vault/PasswordList';
import { useVault } from '../../contexts/VaultContext';
import PasswordModal from '../../components/vault/PasswordModal';
import { PlusIcon } from '@heroicons/react/24/outline';

const PasswordsPage: React.FC = () => {
    const { entries, searchQuery, refreshEntries } = useVault();

    const filteredEntries = entries.filter(entry => 
        entry.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
        entry.website_url?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCreateClick = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handlePasswordAdded = () => {
        // Close the modal
        handleModalClose();
        
        // No need to call refreshEntries() as the VaultContext already updated the state
    };

    useEffect(() => {
        refreshEntries();
    }, []);

    return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
            {/* Sidebar */}
            <VaultSidebar />

            {/* Main content */}
            <div className="flex flex-col flex-1 overflow-hidden">
                <VaultHeader />
                
                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="w-full px-4 sm:px-6 md:px-8">
                            <PasswordList entries={filteredEntries} />
                        </div>
                    </div>
                    
                </main>
            </div>

            <button
                onClick={handleCreateClick}
                className="fixed bottom-8 right-8 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                title="Add new password"
            >
                <PlusIcon className="h-6 w-6" />
            </button>

            <PasswordModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                onSuccess={handlePasswordAdded}
            />
        </div>
    );
};

export default PasswordsPage;