import React, { useState } from 'react';
import { ICreatePasswordEntry, IDecryptedPasswordEntry } from '../../types/vault.types';
import Modal from '../common/Modal';
import PasswordForm from './PasswordForm';
import { useVault } from '../../contexts/VaultContext';
import { toast } from 'react-hot-toast';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface PasswordModalProps {
    isOpen: boolean;
    onClose: () => void;
    entry?: IDecryptedPasswordEntry;
    onSuccess: () => void;
}

const PasswordModal: React.FC<PasswordModalProps> = ({
    isOpen,
    onClose,
    entry,
    onSuccess
}) => {
    const { addEntry } = useVault();
    const [loading, setLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const handleClose = () => {
        if (hasChanges) {
            if (window.confirm('You have unsaved changes. Are you sure you want to close?')) {
                onClose();
            }
        } else {
            onClose();
        }
    };

    const handleSubmit = async (data: ICreatePasswordEntry) => {
        try {
            setLoading(true);

            if (!data.title || !data.username || !data.password) {
                throw new Error('Please fill in all required fields');
            }

            await addEntry(data);
            setHasChanges(false);
            if (onSuccess) {
                await onSuccess();
            }
            onClose();
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to add password';
            console.error('Failed to add password:', error);
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

            <div className="flex min-h-full items-center justify-center p-4">
                <div className="relative bg-white rounded-lg w-full max-w-4xl mx-4">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h3 className="text-lg font-medium">
                            {entry ? 'Edit Password' : 'Add New Password'}
                        </h3>
                        <button
                            onClick={handleClose}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>
                    <PasswordForm
                        initialData={entry}
                        onSubmit={handleSubmit}
                        onCancel={handleClose}
                        isLoading={loading}
                    />
                </div>
            </div>
        </div>
    );
};

export default PasswordModal;