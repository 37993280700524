import { IRole, ICreateRole, IUpdateRole } from '../types/roles.types';
import { store } from '../store';

const API_URL = process.env.REACT_APP_API_URL;

export class RolesService {
    private static authToken: string | null = null;

    public static setAuthToken(token: string) {
        this.authToken = token;
    }

    private static async request<T>(
        endpoint: string,
        method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
        body?: any
    ): Promise<T> {
        const token = this.authToken || store.getState().auth.token;

        if (!token) {
            throw new Error('No authentication token found');
        }

        try {
            const response = await fetch(`${API_URL}/roles${endpoint}`, {
                method,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            return response.json();
        } catch (error) {
            console.error('API request failed:', error);
            throw new Error('An error occurred');
        }
    }

    static async getRoles(): Promise<IRole[]> {
        return await this.request<IRole[]>('');
    }

    static async createRole(role: ICreateRole): Promise<IRole> {
        return await this.request<IRole>('', 'POST', role);
    }

    static async updateRole(role: IUpdateRole): Promise<IRole> {
        return await this.request<IRole>(`/${role.id}`, 'PUT', role);
    }

    static async deleteRole(id: number): Promise<void> {
        await this.request<void>(`/${id}`, 'DELETE');
    }
}

export default RolesService; 