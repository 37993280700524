import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useRedux';
import ProtectedRoute from './ProtectedRoute';

interface PermissionProtectedRouteProps {
    children: React.ReactNode;
    requiredPermission: string;
}

const PermissionProtectedRoute: React.FC<PermissionProtectedRouteProps> = ({ 
    children, 
    requiredPermission 
}) => {
    const { permissions } = useAppSelector((state) => state.auth);
    
    // First use the regular ProtectedRoute to check authentication
    return (
        <ProtectedRoute>
            {permissions && permissions[requiredPermission as keyof typeof permissions] ? (
                children
            ) : (
                // If authenticated but doesn't have permission, show 404
                <Navigate to="/not-authorized" replace />
            )}
        </ProtectedRoute>
    );
};

export default PermissionProtectedRoute; 