import React, { useState, useEffect } from 'react';
import { BuildingLibraryIcon, PencilIcon, TrashIcon, CurrencyDollarIcon, EllipsisVerticalIcon, XMarkIcon, EyeIcon } from '@heroicons/react/24/outline';
import { BankDetail } from '../../types/bankDetails';
import { toast } from 'react-hot-toast';
import BankDetailsService from '../../services/bankDetails.service';
import { useVault } from '../../contexts/VaultContext';

interface BankDetailsListProps {
    details: BankDetail[];
    onUpdate: () => void;
    onEdit: (detail: BankDetail, isShared?: boolean, sharedKey?: string) => void;
}

const BankDetailsList: React.FC<BankDetailsListProps> = ({ details, onUpdate, onEdit }) => {
    const [hoveredWallet, setHoveredWallet] = useState<string | null>(null);
    const [activeWallet, setActiveWallet] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
    const { searchQuery } = useVault();

    // Detect screen size changes for responsive behavior
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDelete = async (id: string) => {
        try {
            if (window.confirm('Are you sure you want to delete this bank account?')) {
                await BankDetailsService.deleteBankDetail(id);
                toast.success('Bank account deleted successfully');
                onUpdate();
                setActiveWallet(null);
            }
        } catch (error) {
            toast.error('Failed to delete bank account');
        }
    };

    const handleEdit = async (entryId: string, isShared?: boolean, sharedKey?: string) => {
        try {
            const detail = await BankDetailsService.getBankDetailById(entryId, isShared, sharedKey);
            onEdit(detail, isShared ?? false, sharedKey ?? '');
            setActiveWallet(null);
        } catch (err) {
            console.error('Failed to fetch entry:', err);
            toast.error('Failed to fetch entry');
        }
    };

    // Toggle active wallet on mobile
    const toggleWalletOptions = (id: string, e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent bubbling
        if (isMobile) {
            setActiveWallet(activeWallet === id ? null : id);
        }
    };

    // Close wallet options when clicking outside
    useEffect(() => {
        const handleClickOutside = () => {
            if (activeWallet) {
                setActiveWallet(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [activeWallet]);

    // Get bank brand colors and backgrounds
    const getBankStyle = (bankName: string) => {
        const bankStyles: Record<string, { bg: string, color: string, logo: string }> = {
            'Chase': {
                bg: 'bg-blue-800',
                color: 'text-white',
                logo: 'linear-gradient(45deg, #117ACA, #00629B)'
            },
            'Bank of America': {
                bg: 'bg-red-700',
                color: 'text-white',
                logo: 'linear-gradient(45deg, #E31837, #CD1309)'
            },
            'Wells Fargo': {
                bg: 'bg-yellow-600',
                color: 'text-white',
                logo: 'linear-gradient(45deg, #D71E28, #FFCD12)'
            },
            'Citibank': {
                bg: 'bg-indigo-600',
                color: 'text-white',
                logo: 'linear-gradient(45deg, #004B93, #0077C8)'
            },
            'Capital One': {
                bg: 'bg-red-600',
                color: 'text-white',
                logo: 'linear-gradient(45deg, #C3002F, #D03027)'
            },
            'default': {
                bg: 'bg-teal-700',
                color: 'text-white',
                logo: 'linear-gradient(45deg, #00796B, #009688)'
            }
        };

        return bankStyles[bankName] || bankStyles['default'];
    };

    const filteredBankDetails = details.filter((detail: BankDetail) => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        return (
            detail.name?.toLowerCase().includes(searchLower) || 
            detail.bank_name?.toLowerCase().includes(searchLower) ||
            detail.account_type?.toLowerCase().includes(searchLower) ||
            detail.encrypted_account_number?.toLowerCase().includes(searchLower) ||
            detail.encrypted_iban_number?.toLowerCase().includes(searchLower)
        );
    });

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 p-4 sm:p-6">
            {filteredBankDetails.map((detail) => {
                const bankStyle = getBankStyle(detail.bank_name);

                return (
                    <div
                        key={detail.id}
                        className="relative overflow-hidden transition-transform duration-200 hover:scale-105"
                        onMouseEnter={() => !isMobile && setHoveredWallet(detail.id)}
                        onMouseLeave={() => !isMobile && setHoveredWallet(null)}
                        onClick={(e) => isMobile && toggleWalletOptions(detail.id, e)}
                    >
                        {/* Wallet front */}
                        <div className={`h-64 rounded-xl shadow-xl transition-all duration-300 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 ${hoveredWallet === detail.id && !isMobile ? 'opacity-0' : 'opacity-100'}`}>
                            {/* Bank header with logo */}
                            <div className={`h-20 ${bankStyle.bg} ${bankStyle.color} p-4 flex justify-between items-center rounded-t-xl`}>
                                <div>
                                    <h3 className="text-xl font-bold">
                                        {detail.bank_name}
                                    </h3>
                                    <p className="text-sm opacity-90">
                                        {detail.account_type || 'Checking Account'}
                                    </p>
                                </div>
                                <div className="flex items-center">
                                    <div className="rounded-full w-10 h-10 flex items-center justify-center mr-2"
                                        style={{ background: bankStyle.logo }}>
                                        <BuildingLibraryIcon className="h-6 w-6 text-white" />
                                    </div>

                                    {/* Mobile menu icon */}
                                    {isMobile && (
                                        <button
                                            onClick={(e) => toggleWalletOptions(detail.id, e)}
                                            className="text-white p-1 rounded-full hover:bg-white/10 focus:outline-none"
                                        >
                                            <EllipsisVerticalIcon className="h-5 w-5" />
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* Account balance section */}
                            <div className="px-4 py-3 border-b border-gray-200 dark:border-gray-700">
                                <div className="text-xs text-gray-500 dark:text-gray-400 font-medium">ACCOUNT HOLDER NAME</div>
                                <div className="flex items-baseline">
                                    <div className="text-gray-800 dark:text-gray-200 truncate">{detail.name}</div>
                                </div>
                            </div>

                            {/* Account details section */}
                            <div className="px-4 py-3">
                                <div className="grid grid-cols-2 gap-3">
                                    <div>
                                        <div className="text-xs text-gray-500 dark:text-gray-400 font-medium">ACCOUNT NUMBER</div>
                                        <div className="font-mono text-gray-800 dark:text-gray-200">
                                            •••• {detail.encrypted_account_number?.slice(-4) || '****'}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="text-xs text-gray-500 dark:text-gray-400 font-medium">IBAN NUMBER</div>
                                        <div className="text-gray-800 dark:text-gray-200 truncate">•••• {detail.encrypted_iban_number?.slice(-4) || '****'}</div>
                                    </div>
                                </div>
                            </div>

                            {/* Bottom actions section */}
                            <div className="absolute bottom-0 left-0 right-0 px-4 py-3 flex justify-between items-center">
                                <div className="flex items-center space-x-2">
                                    {detail.category_name && (
                                        <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                                            {detail.category_name}
                                        </span>
                                    )}
                                    <span className="text-xs text-gray-500 dark:text-gray-400">
                                        {new Date(detail.updated_at).toLocaleDateString()}
                                    </span>
                                </div>
                                {detail.favourite && (
                                    <div className="text-yellow-500">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Desktop hover controls */}
                        {!isMobile && (
                            <>
                                {(detail.permission_level && detail.permission_level === 'read') ? (

                                    <div className={`absolute inset-0 w-full h-full rounded-xl flex flex-col items-center justify-center transition-all duration-300 ${hoveredWallet === detail.id ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                                        style={{ background: bankStyle.logo }}
                                    >
                                        <div className="absolute top-0 left-0 right-0 px-4 py-3 text-white">
                                            <h3 className="text-lg font-semibold">{detail.bank_name}</h3>
                                            <p className="text-xs opacity-80">Account Management</p>
                                        </div>

                                        <button
                                            onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                            className="w-3/4 py-3 px-4 bg-white text-gray-800 hover:bg-gray-100 rounded-lg transition-colors flex items-center justify-center space-x-2 font-medium mb-3 shadow-md"
                                        >
                                            <EyeIcon className="h-5 w-5" />
                                            <span>View Account</span>
                                        </button>
                                        
                                    </div>
                                )
                                    :


                                    <div className={`absolute inset-0 w-full h-full rounded-xl flex flex-col items-center justify-center transition-all duration-300 ${hoveredWallet === detail.id ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                                        style={{ background: bankStyle.logo }}
                                    >
                                        <div className="absolute top-0 left-0 right-0 px-4 py-3 text-white">
                                            <h3 className="text-lg font-semibold">{detail.bank_name}</h3>
                                            <p className="text-xs opacity-80">Account Management</p>
                                        </div>

                                        <button
                                            onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                            className="w-3/4 py-3 px-4 bg-white text-gray-800 hover:bg-gray-100 rounded-lg transition-colors flex items-center justify-center space-x-2 font-medium mb-3 shadow-md"
                                        >
                                            <PencilIcon className="h-5 w-5" />
                                            <span>Edit Account</span>
                                        </button>
                                        <button
                                            onClick={() => handleDelete(detail.id)}
                                            className="w-3/4 py-3 px-4 bg-red-500 text-white hover:bg-red-600 rounded-lg transition-colors flex items-center justify-center space-x-2 font-medium shadow-md"
                                        >
                                            <TrashIcon className="h-5 w-5" />
                                            <span>Delete Account</span>
                                        </button>
                                    </div>
                                }
                            </>

                        )}

                        {/* Mobile action menu */}
                        {isMobile && activeWallet === detail.id && (
                            <div
                                className="absolute right-0 left-0 bottom-0 bg-white dark:bg-gray-800 backdrop-blur-sm rounded-b-lg z-10 p-3 shadow-lg"
                                onClick={(e) => e.stopPropagation()}
                                style={{ borderTop: '1px solid rgba(0,0,0,0.1)' }}
                            >
                                <div className="flex justify-between items-center mb-2">
                                    <h4 className="font-medium text-gray-800 dark:text-white">Account options</h4>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setActiveWallet(null);
                                        }}
                                        className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                                    >
                                        <XMarkIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                                    </button>
                                </div>
                                {(detail.permission_level && detail.permission_level === 'read') ? (

                                    <div className="grid grid-cols-2 gap-2">
                                        <button
                                            onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                            className="py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors flex items-center justify-center space-x-1"
                                        >
                                            <EyeIcon className="h-4 w-4" />
                                            <span>View</span>
                                        </button>
                                    </div>

                                ) : (
                                    <div className="grid grid-cols-2 gap-2">
                                        <button
                                            onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                            className="py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors flex items-center justify-center space-x-1"
                                        >
                                            <PencilIcon className="h-4 w-4" />
                                            <span>Edit</span>
                                        </button>
                                        <button
                                            onClick={() => handleDelete(detail.id)}
                                            className="py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors flex items-center justify-center space-x-1"
                                        >
                                            <TrashIcon className="h-4 w-4" />
                                            <span>Delete</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )
            })}

            {details.length === 0 && (
                <div className="col-span-full flex flex-col items-center justify-center py-12 text-gray-500 dark:text-gray-400">
                    <BuildingLibraryIcon className="h-24 w-24 mb-4" />
                    <h3 className="text-xl font-medium mb-2">No bank accounts yet</h3>
                    <p className="text-sm text-gray-400 dark:text-gray-500">Add your first bank account to get started</p>
                </div>
            )}
        </div>
    );
};

export default BankDetailsList; 