import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
    user: {
        id: string;
        email: string;
        two_factor_enabled: boolean;
    } | null;
    token: string | null;
    isAuthenticated: boolean;
    role_id: number | null;
    team_id: string | null;
    permissions: {
        manage_team: boolean;
        manage_roles: boolean;
        invite_members: boolean;
        remove_members: boolean;
        manage_teams: boolean;
    } | null;
    loading: boolean;
    error: string | null;
}

const initialState: AuthState = {
    user: null,
    token: null,
    isAuthenticated: false,
    role_id: null,
    team_id: null,
    permissions: null,
    loading: false,
    error: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<any>) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isAuthenticated = true;
            state.role_id = action.payload.role_id;
            state.permissions = action.payload.permissions;
            state.team_id = action.payload.team_id;
        },
        updateMfaStatus: (state, action: PayloadAction<boolean>) => {
            if (state.user) {
                state.user.two_factor_enabled = action.payload;
            }
        },
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.isAuthenticated = false;
            state.role_id = null;
            state.permissions = null;
            state.team_id = null;
        },
    }
});

export const { setCredentials, updateMfaStatus, logout } = authSlice.actions;
export default authSlice.reducer;