import React from 'react';
import { TeamMember, Role } from '../../types/team.types';
import { ExclamationCircleIcon, TrashIcon, UserCircleIcon, UserMinusIcon } from '@heroicons/react/24/outline';
import { useVault } from '../../contexts/VaultContext';
import { TeamService } from '../../services/team.service';
import { useAppSelector } from '../../hooks/useRedux';
import { toast } from 'react-hot-toast';
import { AuthService } from '../../services/auth.service';
import Button from '../common/Button';

interface ActiveUsersListProps {
    members: TeamMember[];
    roles: Role[];
    onRoleChange: (userId: string, newRoleId: number) => void;
    onRevoke: () => void;
}

const ActiveUsersList: React.FC<ActiveUsersListProps> = ({ members, roles, onRoleChange, onRevoke }) => {
    const { searchQuery } = useVault();
    const { permissions } = useAppSelector((state) => state.auth);
    const hasRemoveMembersPermission = permissions?.remove_members === true;
    const filteredMembers = members.filter((member) => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        return member.email?.toLowerCase().includes(searchLower) ||
            member.role_name?.toLowerCase().includes(searchLower);
    });
    const handleDeleteClick = async (userId: string) => {
        try {
            const confirmed = window.confirm('Are you sure you want to delete this member permanently? Once deleted, the all associated data will be permanently removed.');
            if (confirmed) {
                await TeamService.deleteUser(userId);
                toast.success('User and all associated data permanently removed');
                onRevoke();
            }
        } catch (error) {
            toast.error('Failed to delete member');
        }
    };
    const handleRemoveMFA = async (userId: string) => {
        try {
            const confirmed = window.confirm('Are you sure you want to remove MFA for this member?');
            if (confirmed) {
                await AuthService.removeMFA(userId);
                toast.success('MFA removed successfully');
                onRevoke();
            }
        } catch (error) {
            toast.error('Failed to remove MFA');
        }
    };
    const handleRemoveMember = async (memberId: string) => {
        try {
            const confirmed = window.confirm('Are you sure you want to remove this member from the team? They will lose access to shared items in Team Vault.');
            if (confirmed) {
                await TeamService.removeMemberFromTeam(memberId);
                toast.success('Member removed from team successfully');
                onRevoke();
            }
        } catch (error) {
            toast.error('Failed to remove member from team');
        }
    };
    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            {filteredMembers.length === 0 ? (
                <div className="flex items-center justify-center p-4 text-gray-500">
                    <ExclamationCircleIcon className="h-6 w-6 mr-2" />
                    <span>No active members found. Click the button below to invite users.</span>
                </div>
            ) : (
                <>
                    {/* Desktop view */}
                    <div className="hidden md:block overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Joined At</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                                    {hasRemoveMembersPermission && (
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                                    )}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {filteredMembers.map((member) => (
                                    <tr key={member.id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            <div className="flex items-center">{member.email}</div>
                                            {member.team_name && (
                                                <div className="flex items-center text-xs text-gray-700 mt-1">Team - {member.team_name}</div>
                                            )}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            {new Date(member.joined_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                            <select
                                                value={member.role_id}
                                                onChange={(e) => onRoleChange(member.user_id, Number(e.target.value))}
                                                className="text-sm border-gray-300 rounded-md shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                            >
                                                <option value={0}>Select Role</option>
                                                {roles.map((role) => (
                                                    <option key={role.id} value={role.id}>
                                                        {role.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        {(hasRemoveMembersPermission) && (
                                            <td className="px-6 py-4">
                                                <div className="flex items-center space-x-2">
                                                    <button
                                                        onClick={() => handleRemoveMember(member.id)}
                                                        className="py-3 px-4 bg-outlined-orange-500 text-orange-600 hover:bg-orange-600 hover:text-white rounded-lg transition-colors flex items-center justify-center space-x-2 font-medium shadow-md"
                                                    >
                                                        <UserMinusIcon className="h-5 w-5" />
                                                        <span>Remove</span>
                                                    </button>

                                                    <button
                                                        onClick={() => handleDeleteClick(member.user_id)}
                                                        className="py-3 px-4 bg-outlined-red-500 text-red-600 hover:bg-red-600 hover:text-white rounded-lg transition-colors flex items-center justify-center space-x-2 font-medium shadow-md"
                                                    >
                                                        <TrashIcon className="h-5 w-5" />
                                                        <span>Delete</span>
                                                    </button>

                                                    {/* Remove MFA button */}
                                                    {member?.two_factor_enabled && (
                                                        <button
                                                            onClick={() => handleRemoveMFA(member.user_id)}
                                                            className="py-3 px-4 bg-outlined-blue-500 text-blue-600 hover:bg-blue-600 hover:text-white rounded-lg transition-colors flex items-center justify-center space-x-2 font-medium shadow-md"
                                                        >
                                                            <span>Remove MFA</span>
                                                        </button>
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Mobile view */}
                    <div className="block md:hidden">
                        <ul className="divide-y divide-gray-200">
                            {filteredMembers.map((member) => (
                                <li key={member.id} className="py-4 px-4">
                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            <UserCircleIcon className="h-10 w-10 text-gray-400" />
                                        </div>
                                        <div className="ml-3 w-full">
                                            <div className="flex justify-between">
                                                <p className="text-sm font-medium text-gray-900">{member.email}</p>
                                                {hasRemoveMembersPermission && (
                                                    <div className="flex space-x-2">
                                                        <button
                                                            onClick={() => handleRemoveMember(member.id)}
                                                            className="text-orange-600 hover:text-orange-800 focus:outline-none"
                                                            title="Remove Member from Team"
                                                        >
                                                            <UserMinusIcon className="h-5 w-5" />
                                                        </button>
                                                        <button
                                                            onClick={() => handleDeleteClick(member.user_id)}
                                                            className="text-red-600 hover:text-red-800 focus:outline-none"
                                                            title="Delete Member Permanently"
                                                        >
                                                            <TrashIcon className="h-5 w-5" />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <p className="text-xs text-gray-500 mt-1">
                                                Joined: {new Date(member.joined_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                            </p>
                                            <div className="mt-2">
                                                <label className="block text-xs font-medium text-gray-700 mb-1">Role</label>
                                                <select
                                                    value={member.role_id || ''}
                                                    onChange={(e) => onRoleChange(member.user_id, Number(e.target.value))}
                                                    className="w-full text-sm mt-2 border-gray-300 rounded-md shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                                >
                                                    <option value="">Select Role</option>
                                                    {roles.map((role) => (
                                                        <option key={role.id} value={role.id}>
                                                            {role.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>

                                            {/* Add buttons in mobile view */}
                                            {hasRemoveMembersPermission && (
                                                <div className="mt-3 flex flex-wrap gap-2 justify-center">

                                                    {member?.two_factor_enabled && (
                                                        <button
                                                            onClick={() => handleRemoveMFA(member.user_id)}
                                                            className="py-2 px-3 bg-outlined-blue-500 text-blue-600 hover:bg-blue-600 hover:text-white rounded-lg transition-colors flex items-center justify-center space-x-1 text-sm font-medium shadow-sm"
                                                        >
                                                            <span>Remove MFA</span>
                                                        </button>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

export default ActiveUsersList; 