import React, { useState, useEffect } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { CardDetail } from '../../types/cardDetails';
import CardDetailsService from '../../services/cardDetails.service';
import { toast } from 'react-hot-toast';
import Button from '../common/Button';
import { useCategories } from '../../contexts/CategoryContext';

interface CardDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    card?: CardDetail;
    isSharedCard?: boolean;
    permissionLevel?: string;
    sharedKey?: string;
    onSuccessfulUpdate?: () => Promise<void> | void;
}

const CardDetailsModal: React.FC<CardDetailsModalProps> = ({
    isOpen,
    onClose,
    onSuccess,
    card,
    isSharedCard = false,
    permissionLevel = 'read',
    sharedKey,
    onSuccessfulUpdate
}) => {
    const [formData, setFormData] = useState({
        name: '',
        category_id: '',
        card_type: '',
        encrypted_card_holder_name: '',
        encrypted_card_number: '',
        encrypted_expiration_date: '',
        encrypted_cvv: '',
        encrypted_pin: '',
        encrypted_postal_code: '',
        encrypted_notes: '',
        favourite: false
    });

    const [loading, setLoading] = useState(false);
    const { categories, refreshCategories, createCategory } = useCategories();
    const [isCreatingCategory, setIsCreatingCategory] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [creatingCategoryLoading, setCreatingCategoryLoading] = useState(false);

    useEffect(() => {
        if (isOpen && card) {
            setFormData({
                ...formData,
                name: card.name || '',
                category_id: card.category_id || '',
                card_type: card.card_type || '',
                encrypted_card_holder_name: card.encrypted_card_holder_name || '',
                encrypted_card_number: card.encrypted_card_number || '',
                encrypted_expiration_date: card.encrypted_expiration_date || '',
                encrypted_cvv: card.encrypted_cvv || '',
                encrypted_pin: card.encrypted_pin || '',
                encrypted_postal_code: card.encrypted_postal_code || '',
                encrypted_notes: card.encrypted_notes || '',
                favourite: card.favourite || false
            });
        } else if (!isOpen) {
            setFormData({
                name: '',
                category_id: '',
                card_type: '',
                encrypted_card_holder_name: '',
                encrypted_card_number: '',
                encrypted_expiration_date: '',
                encrypted_cvv: '',
                encrypted_pin: '',
                encrypted_postal_code: '',
                encrypted_notes: '',
                favourite: false
            });
        }

        if (isOpen && !isSharedCard && card?.itemType !== 'category') {
            refreshCategories();
        }
    }, [card, isOpen]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleExpirationDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        // Remove any non-digit characters
        const cleanedValue = value.replace(/\D/g, '');
        let formattedValue = '';

        if (cleanedValue.length > 0) {
            formattedValue += cleanedValue.substring(0, 2); // MM
        }
        if (cleanedValue.length > 2) {
            formattedValue += '/' + cleanedValue.substring(2, 4); // YY
        }

        setFormData(prev => ({
            ...prev,
            encrypted_expiration_date: formattedValue
        }));
    };

    const handleCreateCategory = async () => {
        if (!newCategoryName.trim()) return;

        setCreatingCategoryLoading(true);
        try {
            await createCategory({ name: newCategoryName });
            await refreshCategories();

            // Find the newly created category and select it
            const newCategory = categories.find(cat => cat.name === newCategoryName);
            if (newCategory) {
                setFormData(prev => ({
                    ...prev,
                    category_id: newCategory.id
                }));
            }

            // Reset the new category UI state
            setNewCategoryName('');
            setIsCreatingCategory(false);
        } catch (error) {
            console.error('Error creating category:', error);
            toast.error('Failed to create category');
        } finally {
            setCreatingCategoryLoading(false);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);

        // Validate expiration date
        const [month, year] = formData.encrypted_expiration_date.split('/').map(Number);
        if (month < 1 || month > 12) {
            toast.error('Invalid Expiration Date Month');
            setLoading(false);
            return;
        }
        if (year < 0 || year > 99) {
            toast.error('Year must be a valid two-digit year');
            setLoading(false);
            return;
        }

        try {
            if (card) {
                await CardDetailsService.updateCardDetail(card.id, {
                    id: card.id,
                    ...formData,
                    favourite: formData.favourite,
                    isSharedUpdate: isSharedCard,
                    sharedKey,
                    itemType: card.itemType || 'card'
                });
                toast.success('Card updated successfully');
            } else {
                await CardDetailsService.createCardDetail({
                    ...formData,
                    favourite: formData.favourite
                });
                toast.success('Card created successfully');
            }
            if (onSuccessfulUpdate) {
                await onSuccessfulUpdate();
            } else {
                onSuccess();
            }
            onClose();
        } catch (error) {
            console.error('Error saving card:', error);
            toast.error(card ? 'Failed to update card' : 'Failed to create card');
        } finally {
            setLoading(false);
        }
    };

    if (!isOpen) return null;

    const isReadOnly = isSharedCard && permissionLevel === 'read' ;

    return (
        <div className="fixed inset-0 z-50 overflow-hidden">
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"></div>

            <div className="fixed inset-0 flex items-center justify-center p-4">
                <div className="relative bg-white rounded-lg w-full max-w-4xl max-h-[90vh] flex flex-col shadow-lg overflow-hidden">
                    <div className="flex items-center justify-between p-4 border-b">
                        <h3 className="text-lg font-medium">
                            {card ? 'Edit Card' : 'Add Card'}
                        </h3>
                        <button
                            onClick={onClose}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit} className="flex-1 overflow-auto p-4">
                        <div className="h-full flex flex-col md:flex-row md:divide-x">
                            {/* Left Column - Basic Info */}
                            <div className="w-full md:w-1/3 p-4 overflow-y-auto">
                                <div className="space-y-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Name on Card</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                            disabled={isReadOnly}
                                        />
                                    </div>
                                    {!isSharedCard && (
                                        <>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Vault</label>
                                                {!isCreatingCategory ? (
                                                    <select
                                                        name="category_id"
                                                        value={formData.category_id}
                                                        onChange={(e) => {
                                                            if (e.target.value === "create_new") {
                                                                setIsCreatingCategory(true);
                                                            } else {
                                                                handleChange(e);
                                                            }
                                                        }}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        disabled={isReadOnly}
                                                    >
                                                        <option value="">Select Vault</option>
                                                        {categories.map((category) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.name}
                                                            </option>
                                                        ))}
                                                        <option value="create_new">Create new Vault</option>
                                                    </select>
                                                ) : (
                                                    <div className="mt-1 space-x-2">
                                                        <input
                                                            type="text"
                                                            value={newCategoryName}
                                                            onChange={(e) => setNewCategoryName(e.target.value)}
                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                                            placeholder="Vault name"
                                                            autoFocus
                                                        />
                                                        <div className="flex items-center justify-between mt-4">
                                                            <button
                                                                type="button"
                                                                onClick={handleCreateCategory}
                                                                disabled={creatingCategoryLoading || !newCategoryName.trim()}
                                                                className={`px-3 py-2 rounded-md text-sm font-medium ${
                                                                    creatingCategoryLoading || !newCategoryName.trim()
                                                                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                                                                        : 'bg-blue-600 text-white hover:bg-blue-700'
                                                                }`}
                                                            >
                                                                {creatingCategoryLoading ? 'Creating...' : 'Create'}
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setIsCreatingCategory(false);
                                                                    setNewCategoryName('');
                                                                }}
                                                                className="px-3 py-2 rounded-md text-sm font-medium bg-gray-200 text-gray-700 hover:bg-gray-300"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex items-center mt-4">
                                                <input
                                                    type="checkbox"
                                                    id="favourite"
                                                    name="favourite"
                                                    checked={formData.favourite}
                                                    onChange={(e) => setFormData({ ...formData, favourite: e.target.checked })}
                                                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                    disabled={isReadOnly}
                                                />
                                                <label htmlFor="favourite" className="ml-2 block text-sm text-gray-900">
                                                    Add to favourites
                                                </label>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            {/* Right Column - Card Details */}
                            <div className="w-full md:w-2/3 p-4 overflow-y-auto">
                                <div className="space-y-6">
                                    {/* Card Information Section */}
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="text-sm font-medium text-gray-900 mb-4">Card Information</h4>
                                        <div className="space-y-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Card Type</label>
                                                <input
                                                    name="card_type"
                                                    value={formData.card_type}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    disabled={isReadOnly}
                                                />
                                            </div>
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Card Holder Name</label>
                                                <input
                                                    type="text"
                                                    name="encrypted_card_holder_name"
                                                    value={formData.encrypted_card_holder_name}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    required
                                                    disabled={isReadOnly}
                                                />
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Card Number</label>
                                                <input
                                                    type="text"
                                                    name="encrypted_card_number"
                                                    value={formData.encrypted_card_number}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    required
                                                    disabled={isReadOnly}
                                                />
                                            </div>

                                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Expiration Date (MM/YY)</label>
                                                    <input
                                                        type="text"
                                                        name="encrypted_expiration_date"
                                                        value={formData.encrypted_expiration_date}
                                                        onChange={handleExpirationDateChange}
                                                        placeholder="MM/YY"
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        required
                                                        disabled={isReadOnly}
                                                    />
                                                </div>

                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">CVV</label>
                                                    <input
                                                        type="password"
                                                        name="encrypted_cvv"
                                                        value={formData.encrypted_cvv}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        maxLength={5}
                                                        disabled={isReadOnly}
                                                    />
                                                </div>
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">Card PIN</label>
                                                    <input
                                                        type="password"
                                                        name="encrypted_pin"
                                                        value={formData.encrypted_pin}
                                                        onChange={handleChange}
                                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                        disabled={isReadOnly}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Additional Information Section */}
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <h4 className="text-sm font-medium text-gray-900 mb-4">Additional Information</h4>
                                        <div className="space-y-4">
                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Postal Code</label>
                                                <input
                                                    type="text"
                                                    name="encrypted_postal_code"
                                                    value={formData.encrypted_postal_code}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    disabled={isReadOnly}
                                                />
                                            </div>

                                            <div>
                                                <label className="block text-sm font-medium text-gray-700">Notes</label>
                                                <textarea
                                                    name="encrypted_notes"
                                                    value={formData.encrypted_notes}
                                                    onChange={handleChange}
                                                    rows={3}
                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                                                    disabled={isReadOnly}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Fixed Footer */}
                        <div className="sticky bottom-0 left-0 right-0 p-4 bg-white border-t flex justify-end space-x-2">
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            {!isReadOnly && (
                                <Button
                                    type="submit"
                                    loading={loading}
                                    disabled={loading}
                                >
                                    {card ? 'Update Card' : 'Save Card'}
                                </Button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CardDetailsModal; 