import React, { useEffect, useState } from 'react';
import VaultSidebar from '../../components/vault/VaultSidebar';
import VaultHeader from '../../components/vault/VaultHeader';
import RoleList from '../../components/roles/RoleList';
import RoleModal from '../../components/roles/RoleModal';
import { PlusIcon } from '@heroicons/react/24/outline';
import { IRole } from '../../types/roles.types';
import { RolesService } from '../../services/roles.service';

const RolesPage: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState<IRole | null>(null);
    const [roles, setRoles] = useState<IRole[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const data = await RolesService.getRoles();
                setRoles(data);
            } catch (err) {
                setError('Failed to fetch roles');
            } finally {
                setLoading(false);
            }
        };

        fetchRoles();
    }, []);

    const handleCreateClick = () => {
        setSelectedRole(null);
        setIsModalOpen(true);
    };

    const handleRoleChange = () => {
        const fetchRoles = async () => {
            try {
                const data = await RolesService.getRoles();
                setRoles(data);
            } catch (err) {
                setError('Failed to fetch roles');
            }
        };
        fetchRoles();
    };

    return (
        <div className="h-screen flex overflow-hidden bg-gray-100">
            <VaultSidebar />
            <div className="flex flex-col flex-1 overflow-hidden">
                <VaultHeader />
                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="w-full px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-semibold text-gray-900">Roles & Permissions</h1>
                        </div>
                        <div className="w-full px-4 sm:px-6 md:px-8">
                            <RoleList setSelectedRole={setSelectedRole} setIsModalOpen={setIsModalOpen} roles={roles} loading={loading} error={error} />
                        </div>
                        <button
                            onClick={handleCreateClick}
                            className="fixed bottom-8 right-8 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-4 shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                            title="Add new role"
                        >
                            <PlusIcon className="h-6 w-6" />
                        </button>
                    </div>
                </main>
            </div>

            <RoleModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                role={selectedRole}
                onRoleChange={handleRoleChange}
            />
        </div>
    );
};

export default RolesPage; 