import React, { useState, useEffect, useRef } from 'react';
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {
    BuildingLibraryIcon, // for bank
    KeyIcon, // for password
    DocumentTextIcon, // for notes
    CreditCardIcon, // for cards
    UserIcon, // for personal
    FolderIcon
} from '@heroicons/react/24/outline';
import { ItemTitle, SelectedItem } from '../../types/share.types';

interface MultiSelectProps {
    items: {
        passwords: ItemTitle[];
        notes: ItemTitle[];
        cards: ItemTitle[];
        banks: ItemTitle[];
        personal: ItemTitle[];
        categories: ItemTitle[];
    };
    selectedItems: SelectedItem[];
    onItemSelect: (item: ItemTitle) => void;
    onItemRemove: (item: ItemTitle) => void;
}

const getIconForType = (type: string) => {
    switch (type) {
        case 'password':
            return <KeyIcon className="h-5 w-5 text-gray-400" />;
        case 'note':
            return <DocumentTextIcon className="h-5 w-5 text-purple-400" />;
        case 'card':
            return <CreditCardIcon className="h-5 w-5 text-blue-400" />;
        case 'bank':
            return <BuildingLibraryIcon className="h-5 w-5 text-green-400" />;
        case 'personal':
            return <UserIcon className="h-5 w-5 text-orange-400" />;
        case 'category':
            return <FolderIcon className="h-5 w-5 text-red-400" />;
        default:
            return null;
    }
};

const MultiSelect: React.FC<MultiSelectProps> = ({
    items,
    selectedItems,
    onItemSelect,
    onItemRemove
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef<HTMLDivElement>(null);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Filter items based on search term
    const filteredItems = {
        passwords: items.passwords.filter(item => 
            item.name.toLowerCase().includes(searchTerm.toLowerCase())),
        // notes: items.notes.filter(item => 
        //     item.name.toLowerCase().includes(searchTerm.toLowerCase())),
        cards: items.cards.filter(item => 
            item.name.toLowerCase().includes(searchTerm.toLowerCase())),
        banks: items.banks.filter(item => 
            item.name.toLowerCase().includes(searchTerm.toLowerCase())),
        personal: items.personal.filter(item => 
            item.name.toLowerCase().includes(searchTerm.toLowerCase())),
        categories: items.categories.filter(item => 
            item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    };

    // Check if an item is already selected
    const isSelected = (item: ItemTitle) => {
        return selectedItems.some(selected => selected.id === item.id && selected.type === item.type);
    };

    const renderItem = (item: ItemTitle) => (
        <div
            key={`${item.type}-${item.id}`}
            className={`px-4 py-2 text-sm cursor-pointer hover:bg-gray-100 ${
                isSelected(item) ? 'bg-blue-50' : ''
            } flex items-center space-x-3`}
            onClick={() => onItemSelect(item)}
        >
            {getIconForType(item.type)}
            <span className="flex-1">{item.name}</span>
        </div>
    );

    return (
        <div className="relative" ref={dropdownRef}>
            {/* Main Input Container */}
            <div className="relative">
                <div className="border border-gray-300 rounded-md shadow-sm">
                    {/* Input Field */}
                    <div className="flex items-center px-3 py-2 bg-white rounded-t-md">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onFocus={() => setIsOpen(true)}
                            placeholder="Items to Share:"
                            className="w-full border-none focus:ring-0 p-0 text-sm"
                        />
                        <button
                            type="button"
                            onClick={() => setIsOpen(!isOpen)}
                            className="ml-2 text-gray-400"
                        >
                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path
                                    fillRule="evenodd"
                                    d={isOpen 
                                        ? "M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                        : "M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    }
                                />
                            </svg>
                        </button>
                    </div>

                    {/* Selected Items */}
                    {selectedItems.length > 0 && (
                        <div className="border-t border-gray-200">
                            {selectedItems.map(item => (
                                <div
                                    key={`${item.type}-${item.id}`}
                                    className="flex items-center justify-between px-3 py-2 hover:bg-gray-50"
                                >
                                    <div className="flex items-center space-x-2">
                                        {getIconForType(item.type)}
                                        <span className="text-sm">{item.name}</span>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => onItemRemove(item)}
                                        className="text-gray-400 hover:text-gray-500"
                                    >
                                        <XMarkIcon className="h-4 w-4" />
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* Dropdown */}
            {isOpen && (
                <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-[400px] overflow-auto">
                    {/* Search input in dropdown - optional, remove if not needed */}
                    <div className="sticky top-0 bg-white p-2 border-b border-gray-200">
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
                            </div>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Search items..."
                                className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    </div>

                    {/* Item Sections */}
                    {Object.entries({
                        'Passwords': filteredItems.passwords,
                        // 'Notes': filteredItems.notes,
                        'Cards': filteredItems.cards,
                        'Bank Accounts': filteredItems.banks,
                        'Personal Details': filteredItems.personal,
                        'Vault': filteredItems.categories
                    }).map(([sectionTitle, sectionItems]) => 
                        sectionItems.length > 0 && (
                            <div key={sectionTitle}>
                                <div className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-50 flex items-center space-x-2">
                                    {getIconForType(sectionTitle.toLowerCase().split(' ')[0])}
                                    <span>{sectionTitle}</span>
                                </div>
                                {sectionItems.map(renderItem)}
                            </div>
                        )
                    )}

                    {/* No results */}
                    {Object.values(filteredItems).every(group => group.length === 0) && (
                        <div className="px-4 py-2 text-sm text-gray-500 text-center">
                            No items found
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default MultiSelect; 