import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import api from '../../services/api';
import Button from '../common/Button';
import VaultSidebar from '../vault/VaultSidebar';
import VaultHeader from '../vault/VaultHeader';
import { updateMfaStatus } from '../../store/slices/authSlice';
import { ShieldCheckIcon, QrCodeIcon, DevicePhoneMobileIcon } from '@heroicons/react/24/outline';

const MfaSetup: React.FC = () => {
    const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
    const [secret, setSecret] = useState<string | null>(null);
    const [verificationCode, setVerificationCode] = useState<string>('');
    const dispatch = useDispatch();

    const isEnabled = useSelector((state: any) => state.auth.user?.two_factor_enabled);


    const setupMfa = async () => {
        try {

            const response = await api.post('/users/2fa/setup', { secret: secret });
            setQrCodeUrl(response.qrCodeUrl);
            setSecret(response.secret);
        } catch (error) {
            console.error('Error during MFA setup:', error);
            toast.error('Failed to setup MFA');
        }
    };

    const verifyMfa = async () => {
        try {
            const response = await api.post('/users/2fa/verify', { code: verificationCode });
            if (response.success) {
                dispatch(updateMfaStatus(true));
                toast.success('MFA enabled successfully');
            } else {
                toast.error('Invalid verification code');
            }
        } catch (error) {
            toast.error('Failed to verify MFA code');
        }
    };

    const disableMfa = async () => {
        try {
            if (!window.confirm('Are you sure you want to disable MFA?')) {
                return;
            }
            const response = await api.post('/users/2fa/disable', { code: verificationCode });
            if (response.success) {
                dispatch(updateMfaStatus(false));
                toast.success('MFA disabled successfully');
            } else {
                toast.error('Invalid verification code');
            }
        } catch (error) {
            toast.error('Failed to disable MFA');
        }
    };

    return (
        <div className="flex h-screen bg-gray-100">
            <VaultSidebar />
            <div className="flex-1 flex flex-col overflow-hidden">
                <VaultHeader />
                <main className="flex-1 overflow-y-auto bg-gray-50 px-6">
                    <div className="max-w-3xl mx-auto py-8">
                        <div className="bg-white shadow rounded-lg p-6">
                            <div className="flex items-center mb-6">
                                <ShieldCheckIcon className="h-8 w-8 text-blue-500 mr-3" />
                                <h2 className="text-2xl font-bold text-gray-900">
                                    Multi-Factor Authentication (MFA)
                                </h2>
                            </div>

                            <div className="mb-8 bg-blue-50 p-4 rounded-md">
                                <h3 className="font-semibold text-blue-900 mb-2">
                                    Why use MFA?
                                </h3>
                                <p className="text-blue-800">
                                    MFA adds an extra layer of security to your account by requiring a second form of verification beyond your password.
                                </p>
                            </div>

                            {!isEnabled ? (
                                <div className="space-y-6">
                                    <div className="border-b pb-6">
                                        <h3 className="text-lg font-medium mb-4">
                                            Setup Instructions
                                        </h3>
                                        <ol className="list-decimal list-inside space-y-3 text-gray-600">
                                            <li>Download an authenticator app:
                                                <ul className="pl-6 mt-2 space-y-1">
                                                    <li>• Google Authenticator (Android/iOS)</li>
                                                    <li>• Microsoft Authenticator (Android/iOS)</li>
                                                    <li>• Authy (Desktop/Mobile)</li>
                                                </ul>
                                            </li>
                                            <li>Click "Setup MFA" below to generate your unique QR code</li>
                                            <li>Scan the QR code with your authenticator app</li>
                                            <li>Enter the 6-digit code shown in your app to verify</li>
                                        </ol>
                                    </div>

                                    {!qrCodeUrl ? (
                                        <Button 
                                            onClick={setupMfa}
                                        
                                        >
                                            <QrCodeIcon className="h-5 w-5 mr-2" />
                                            Setup MFA
                                        </Button>
                                    ) : (
                                        <div className="space-y-4">
                                            <div className="flex flex-col items-center p-6 border rounded-lg">
                                                <p className="mb-4 text-gray-600">
                                                    Scan this QR code with your authenticator app:
                                                </p>
                                                <img 
                                                    src={qrCodeUrl} 
                                                    alt="QR Code" 
                                                    className="mb-4 border p-2 rounded"
                                                />
                                                <div className="w-full max-w-xs">
                                                    <input
                                                        type="text"
                                                        value={verificationCode}
                                                        onChange={(e) => {
                                                            const value = e.target.value.replace(/\D/g, '').slice(0, 6);
                                                            setVerificationCode(value);
                                                        }}
                                                        placeholder="Enter 6-digit code"
                                                        className="w-full px-4 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
                                                        maxLength={6}
                                                    />
                                                </div>
                                            </div>
                                            <Button 
                                                onClick={verifyMfa}
                                            >
                                                Verify Code
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="space-y-4">
                                    <div className="bg-green-50 p-4 rounded-md flex items-center">
                                        <DevicePhoneMobileIcon className="h-5 w-5 text-green-500 mr-2" />
                                        <p className="text-green-700">MFA is currently enabled and protecting your account.</p>
                                    </div>
                                    <div className="border-t pt-4">
                                        <h3 className="text-lg font-medium mb-4">Disable MFA</h3>
                                        <p className="text-gray-600 mb-4">
                                            To disable MFA, enter a verification code from your authenticator app:
                                        </p>
                                        <div className="flex space-x-4">
                                            <input
                                                type="text"
                                                value={verificationCode}
                                                onChange={(e) => {
                                                    const value = e.target.value.replace(/\D/g, '').slice(0, 6);
                                                    setVerificationCode(value);
                                                }}
                                                placeholder="Enter verification code"
                                                className="flex-1 px-4 py-2 border rounded focus:ring-blue-500 focus:border-blue-500"
                                                maxLength={6}
                                            />
                                            <Button 
                                                onClick={disableMfa}
                                                variant="outline"
                                            >
                                                Disable MFA
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default MfaSetup;