import React, { useState, useEffect } from 'react';
import { FolderIcon, StarIcon, KeyIcon, DocumentTextIcon, CreditCardIcon, ChevronDownIcon, WrenchIcon, ArrowDownTrayIcon, UsersIcon, ShieldCheckIcon, ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ExportModal from '../export/ExportModal';
import { useAppSelector } from '../../hooks/useRedux';
import { useSidebar } from '../../contexts/SidebarContext';

// Helper function to check permissions
const hasPermission = (permissions: string[] | undefined, permission: string): boolean => {
    if (!permissions) return false;
    return permissions.includes(permission) || permissions.includes('admin');
};

const VaultSidebar: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [isTeamManagementOpen, setIsTeamManagementOpen] = useState(false);
    const logo = require('../../assets/logo.png');

    const { isOpen, closeSidebar } = useSidebar();

    // Access permissions from the Redux store
    const { permissions } = useAppSelector((state) => state.auth);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // Close sidebar when clicking on a link on mobile
    const handleLinkClick = () => {
        if (window.innerWidth < 1024) {
            closeSidebar();
        }
    };

    // Handle ESC key to close sidebar
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                closeSidebar();
            }
        };

        document.addEventListener('keydown', handleEsc);
        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, [closeSidebar]);

    // Add debugging log to verify isOpen state
    useEffect(() => {
        console.log("Sidebar isOpen:", isOpen);
    }, [isOpen]);

    return (
        <>
            {/* Mobile sidebar backdrop */}
            {isOpen && (
                <div
                    className="fixed inset-0 bg-gray-600 bg-opacity-75 z-20 lg:hidden"
                    onClick={closeSidebar}
                ></div>
            )}

            {/* Sidebar for desktop (always visible) and mobile (shown when isOpen is true) */}
            <div
                className={`fixed inset-y-0 left-0 z-30 w-[280px] transform transition-transform duration-300 ease-in-out lg:static lg:translate-x-0 ${isOpen ? 'translate-x-0' : '-translate-x-full'
                    } lg:flex lg:flex-shrink-0`}
            >
                <div className="h-full flex flex-col border-r border-gray-200 dark:border-gray-700 bg-[#1e293b] overflow-hidden">
                    {/* Add mobile close button */}
                    <div className="lg:hidden flex justify-end p-2">
                        <button
                            onClick={closeSidebar}
                            className="text-gray-300 hover:text-white"
                            aria-label="Close sidebar"
                        >
                            <XMarkIcon className="h-6 w-6" />
                        </button>
                    </div>

                    <div className="flex flex-col h-full overflow-y-auto scrollbar-hide">
                        <div>
                            <div className="flex items-center h-16 flex-shrink-0 px-4 border-b border-gray-700">
                                <img src={logo} alt="LockdownPass" className="h-8 w-auto" />
                                <span className="text-white text-lg font-bold ml-2">LockdownPass</span>
                            </div>

                            {/* Main Navigation */}
                            <nav className="mt-5 flex-1 px-2 space-y-1">

                                <Link
                                    to="/vaults"
                                    className={`group flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/vaults'
                                        ? 'bg-[#252e3e] text-white font-medium'
                                        : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                        }`}
                                    onClick={handleLinkClick}
                                >
                                    {location.pathname === '/vaults' ?

                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-3 h-6 w-6">
                                            <path fill-rule="evenodd" d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                                            <path d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z" />
                                        </svg>

                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 h-6 w-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                                        </svg>


                                    }
                                    <span className="ml-3 font-medium">Vaults</span>
                                </Link>



                                <Link
                                    to="/all-items"
                                    className={`group flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/all-items'
                                        ? 'bg-[#252e3e] text-white font-medium'
                                        : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                        }`}
                                    onClick={handleLinkClick}
                                >
                                    {location.pathname === '/all-items' ?
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-3 h-6 w-6">
                                            <path d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z" />
                                            <path d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z" />
                                        </svg>



                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="mr-3 h-6 w-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                        </svg>

                                    }
                                    <span className="ml-3 font-medium">All Items</span>
                                </Link>

                                <Link
                                    to="/favorites"
                                    className={`group flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/favorites'
                                        ? 'bg-[#252e3e] text-white font-medium'
                                        : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                        }`}
                                    onClick={handleLinkClick}
                                >
                                    {location.pathname === '/favorites' ?
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-3 h-6 w-6 text-white">
                                            <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                        </svg>

                                        :
                                        <StarIcon className="mr-3 h-6 w-6" />
                                    }
                                    <span className="ml-3 font-medium">Favorites</span>
                                </Link>

                                {/* <div className="px-3 py-2.5 text-sm font-medium text-gray-300">
                                    Identities
                                </div>
                                <div className="ml-4 space-y-1">
                                    <Link
                                        to="/passwords"
                                        className={`group flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/passwords'
                                            ? 'bg-[#252e3e] text-white font-medium'
                                            : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                            }`}
                                        onClick={handleLinkClick}
                                    >
                                        <KeyIcon className="mr-3 h-6 w-6" />
                                        <span className="ml-3 font-medium">Passwords</span>
                                    </Link>
                                    <Link
                                        to="/notes"
                                        className={`group flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/notes'
                                            ? 'bg-[#252e3e] text-white font-medium'
                                            : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                            }`}
                                        onClick={handleLinkClick}
                                    >
                                        <DocumentTextIcon className="mr-3 h-6 w-6" />
                                        <span className="ml-3 font-medium">Notes</span>
                                    </Link>
                                    <Link
                                        to="/bank-details"
                                        className={`group flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/bank-details'
                                            ? 'bg-[#252e3e] text-white font-medium'
                                            : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                            }`}
                                        onClick={handleLinkClick}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-3 h-6 w-6">
                                            <path d="M11.584 2.376a.75.75 0 0 1 .832 0l9 6a.75.75 0 1 1-.832 1.248L12 3.901 3.416 9.624a.75.75 0 0 1-.832-1.248l9-6Z" />
                                            <path fill-rule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75v-9.918a.75.75 0 0 1 .634-.74A49.109 49.109 0 0 1 12 9c2.59 0 5.134.202 7.616.592a.75.75 0 0 1 .634.74Zm-7.5 2.418a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Zm3-.75a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0v-6.75a.75.75 0 0 1 .75-.75ZM9 12.75a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Z" clip-rule="evenodd" />
                                            <path d="M12 7.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
                                        </svg>
                                        <span className="ml-3 font-medium">Bank Details</span>
                                    </Link>
                                    <Link
                                        to="/card-details"
                                        className={`group flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/card-details'
                                            ? 'bg-[#252e3e] text-white font-medium'
                                            : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                            }`}
                                        onClick={handleLinkClick}
                                    >
                                        <CreditCardIcon className="mr-3 h-6 w-6" />
                                        <span className="ml-3 font-medium">Card Details</span>
                                    </Link>
                                    <Link
                                        to="/personal-details"
                                        className={`group flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/personal-details'
                                            ? 'bg-[#252e3e] text-white font-medium'
                                            : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                            }`}
                                        onClick={handleLinkClick}
                                    >
                                        <UsersIcon className="mr-3 h-6 w-6" />
                                        <span className="ml-3 font-medium">Personal Details</span>
                                    </Link>
                                </div> */}


                                <div className="px-3 py-2.5 text-sm font-medium text-gray-300">
                                    Advanced Features
                                </div>
                                <div className="ml-4 space-y-1">
                                    <Link
                                        to="/import"
                                        className={`group flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/import'
                                            ? 'bg-[#252e3e] text-white font-medium'
                                            : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                            }`}
                                        onClick={handleLinkClick}
                                    >
                                        <ArrowDownTrayIcon className="mr-3 h-6 w-6" />
                                        <span className="ml-3 font-medium">Import File</span>
                                    </Link>
                                    <Link
                                        to="#"
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevents navigation
                                            setShowExportModal(true);
                                        }}
                                        className={`group flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/export'
                                            ? 'bg-[#252e3e] text-white font-medium'
                                            : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                            }`}
                                    >
                                        <ArrowUpTrayIcon className="mr-3 h-6 w-6" />
                                        <span className="ml-3 font-medium">Export File</span>
                                    </Link>

                                </div>


                                {permissions?.manage_team && (
                                    <>

                                        <div className="px-3 py-2.5 text-sm font-medium text-gray-300">
                                            Team Management
                                        </div>
                                        {permissions?.manage_teams && (
                                            <div className="ml-4 mt-1">
                                                <Link
                                                    to="/teams"
                                                    className={`group flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/team'
                                                        ? 'bg-[#252e3e] text-white font-medium'
                                                        : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                                        }`}                                        >
                                                    <UsersIcon className="mr-3 h-5 w-5" />
                                                    Teams
                                                </Link>
                                            </div>
                                        )}

                                        {permissions?.manage_roles && (
                                            <div className="ml-4 mt-1">
                                                <Link
                                                    to="/roles"
                                                    className={`group flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/team'
                                                        ? 'bg-[#252e3e] text-white font-medium'
                                                        : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                                        }`}                                        >
                                                    <ShieldCheckIcon className="mr-3 h-5 w-5" />
                                                    Roles & Permissions
                                                </Link>
                                            </div>
                                        )}

                                        {permissions?.manage_team && (
                                            <div className="ml-4 mt-1">
                                                <Link
                                                    to="/manage-members"
                                                    className={`group flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/team'
                                                        ? 'bg-[#252e3e] text-white font-medium'
                                                        : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                                        }`}                                        >
                                                    <UsersIcon className="mr-3 h-5 w-5" />
                                                    Manage Members
                                                </Link>
                                            </div>
                                        )}
                                    </>
                                )}
                            </nav>
                        </div>

                        {/* This div ensures the sharing center stays at bottom */}
                        <div className="mt-auto sticky bottom-0 bg-[#1e293b]">
                            <div className="px-6 py-4 border-t border-gray-700">
                                <Link
                                    to="/sharing-center"
                                    className={`group flex items-center px-3 py-2.5 text-sm rounded-lg transition-colors duration-200 ${location.pathname === '/sharing-center'
                                        ? 'bg-[#252e3e] text-white font-medium'
                                        : 'text-gray-300 hover:bg-[#252e3e] hover:text-white'
                                        }`}
                                    onClick={handleLinkClick}
                                >
                                    {location.pathname === '/sharing-center' ?
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="mr-3 h-6 w-6">
                                            <path fillRule="evenodd" d="M15.75 4.5a3 3 0 11.825 2.066l-8.421 4.679a3.002 3.002 0 010 1.51l8.421 4.679a3 3 0 11-.729 1.31l-8.421-4.678a3 3 0 110-4.132l8.421-4.679a3 3 0 01-.096-.755z" clipRule="evenodd" />
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 h-6 w-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 4.5a3 3 0 11.825 2.066l-8.421 4.679a3.002 3.002 0 010 1.51l8.421 4.679a3 3 0 11-.729 1.31l-8.421-4.678a3 3 0 110-4.132l8.421-4.679a3 3 0 01-.096-.755z" />
                                        </svg>
                                    }
                                    <span className="ml-3 font-medium">Sharing Center</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showExportModal && (
                <ExportModal isOpen={showExportModal} onClose={() => setShowExportModal(false)} />
            )}
        </>
    );
};

export default VaultSidebar;
