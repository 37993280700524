import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TeamService } from '../services/team.service';
import { toast } from 'react-hot-toast';

const AcceptInvitation: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const logo = require('../assets/logo.png');
    const invitationProcessed = useRef(false);
    
    useEffect(() => {
        // Use a ref to ensure the invitation is only processed once
        if (invitationProcessed.current) return;
        
        const acceptInvitation = async () => {
            try {
                invitationProcessed.current = true;
                // Get token from URL
                const queryParams = new URLSearchParams(location.search);
                const token = queryParams.get('token');
                
                if (!token) {
                    setMessage('Invalid invitation link');
                    setSuccess(false);
                    setLoading(false);
                    return;
                }
                
                // Decode token to check type
                const decodedData = JSON.parse(atob(token));
                
                // // If it's not for existing users, redirect to register
                // if (decodedData.type !== 'existing') {
                //     navigate(`/register?token=${token}`);
                //     return;
                // }
                
                console.log('Accepting invitation with token');
                // Accept invitation
                const response = await TeamService.acceptInvitation(token);
                
                if (response.success) {
                    setSuccess(true);
                    setMessage(response.message);
                } else {
                    setSuccess(false);
                    setMessage(response.message || 'Failed to accept invitation');
                }
            } catch (error: any) {
                console.error('Error accepting invitation:', error);
                setSuccess(false);
                setMessage(error.message || 'An error occurred while accepting the invitation');
            } finally {
                setLoading(false);
            }
        };
        
        acceptInvitation();
        
    }, []); // Empty dependency array to run only once
    
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
            <div className="w-full max-w-md p-8 space-y-8 bg-white rounded-lg shadow">
                <div className="text-center">
                    <img src={logo} alt="LockdownPass" className="mx-auto h-12 w-auto" />
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Team Invitation</h2>
                </div>
                
                {loading ? (
                    <div className="flex justify-center items-center py-8">
                        <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
                        <span className="ml-3 text-gray-700">Processing invitation...</span>
                    </div>
                ) : (
                    <div className={`text-center p-4 rounded-md ${success ? 'bg-green-50' : 'bg-red-50'}`}>
                        <p className={`text-lg ${success ? 'text-green-800' : 'text-red-800'}`}>
                            {message}
                        </p>
                        
                        {success && (
                            <div className="mt-6">
                                <button
                                    onClick={() => navigate('/login')}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Go to Login
                                </button>
                            </div>
                        )}
                        
                        {!success && (
                            <div className="mt-6">
                                <button
                                    onClick={() => navigate('/')}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Return to Homepage
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AcceptInvitation; 