import React, { useState, useEffect } from 'react';
import { CreditCardIcon, PencilIcon, TrashIcon, StarIcon, EllipsisVerticalIcon, XMarkIcon, EyeIcon } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { CardDetail } from '../../types/cardDetails';
import { toast } from 'react-hot-toast';
import CardDetailsService from '../../services/cardDetails.service';
import { useVault } from '../../contexts/VaultContext';

interface CardDetailsListProps {
    details: CardDetail[];
    onUpdate: () => void;
    onEdit: (detail: CardDetail, isShared: boolean, sharedKey: string) => void;
}

const CardDetailsList: React.FC<CardDetailsListProps> = ({ details, onUpdate, onEdit }) => {
    const [hoveredCard, setHoveredCard] = useState<string | null>(null);
    const [activeCard, setActiveCard] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
    const { searchQuery } = useVault();

    // Detect screen size changes for responsive behavior
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDelete = async (id: string) => {
        try {
            if (window.confirm('Are you sure you want to delete this card?')) {
                await CardDetailsService.deleteCardDetail(id);
                toast.success('Card deleted successfully');
                onUpdate();
                setActiveCard(null);
            }
        } catch (error) {
            toast.error('Failed to delete card');
        }
    };

    const handleEdit = async (entryId: string, isShared?: boolean, sharedKey?: string) => {
        try {
            const detail = await CardDetailsService.getCardDetailById(entryId, isShared, sharedKey);
            onEdit(detail, isShared ?? false, sharedKey ?? '');
            setActiveCard(null);
        } catch (err) {
            console.error('Failed to fetch entry:', err);
            toast.error('Failed to fetch entry');
        }
    };

    // Toggle active card on mobile
    const toggleCardOptions = (id: string, e: React.MouseEvent) => {
        e.stopPropagation(); // Prevent bubbling
        if (isMobile) {
            setActiveCard(activeCard === id ? null : id);
        }
    };

    // Close card options when clicking outside
    useEffect(() => {
        const handleClickOutside = () => {
            if (activeCard) {
                setActiveCard(null);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [activeCard]);

    const filteredCards = details.filter((detail: CardDetail) => {
        if (!searchQuery) return true;
        const searchLower = searchQuery.toLowerCase();
        return (
          detail.name?.toLowerCase().includes(searchLower) || 
          detail.encrypted_card_holder_name?.toLowerCase().includes(searchLower) ||
          detail.encrypted_card_number?.toLowerCase().includes(searchLower) ||
          detail.encrypted_expiration_date?.toLowerCase().includes(searchLower)
        );
      });
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-6 p-4 sm:p-6">
            {filteredCards.map((detail) => (
                <div
                    key={detail.id}
                    className="relative h-52 rounded-xl shadow-lg overflow-hidden"
                    onMouseEnter={() => !isMobile && setHoveredCard(detail.id)}
                    onMouseLeave={() => !isMobile && setHoveredCard(null)}
                    onClick={(e) => isMobile && toggleCardOptions(detail.id, e)}
                >
                    {/* Front of Card */}
                    <div className={`absolute inset-0 w-full h-full bg-gradient-to-br from-[#4364F7] to-[#6FB1FC] p-6 transition-all duration-300 ${hoveredCard === detail.id && !isMobile ? 'opacity-0' : 'opacity-100'}`}>
                        {/* Top right section for favorite icon and mobile menu */}
                        <div className="absolute top-2 right-2 flex items-center">
                            {detail.favourite && (
                                <StarIconSolid className="h-6 w-6 text-yellow-400 mr-2" />
                            )}

                            {/* Mobile menu icon */}
                            {isMobile && (
                                <button
                                    onClick={(e) => toggleCardOptions(detail.id, e)}
                                    className="text-white p-1 rounded-full hover:bg-white/10 focus:outline-none"
                                >
                                    <EllipsisVerticalIcon className="h-5 w-5" />
                                </button>
                            )}
                        </div>

                        {/* Top Section - Card Name & Chip */}
                        <div className="flex justify-between items-start mb-6 mt-3">
                            <h3 className="text-xl font-bold text-white">
                                {detail.name || "Unnamed Card"}
                            </h3>

                            {/* Card Chip */}
                            <div className="w-12 h-10 bg-white/20 rounded-md shadow-sm flex items-center justify-center overflow-hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-6 h-6 text-white">
                                    <g fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round">
                                        <path d="M16.2,16.2c2.5-2.5,2.5-6.6,0-9.2" />
                                        <path d="M19,19c3.8-3.8,3.8-10.2,0-14" />
                                        <path d="M13.4,13.4c1.2-1.2,1.2-3.1,0-4.2" />
                                        <circle cx="9.5" cy="11.3" r="1.5" fill="currentColor" stroke="none" />
                                    </g>
                                </svg>
                            </div>
                        </div>

                        {/* Card Holder & Expiry */}
                        <div className="flex justify-between items-end">
                            <div>
                                <p className="text-xs text-white/70 uppercase tracking-wider mb-1">Card Holder</p>
                                <p className="text-sm text-white font-medium tracking-wide">
                                    {detail.encrypted_card_holder_name || "CARD HOLDER"}
                                </p>
                            </div>
                            <div className="text-right">
                                <p className="text-xs text-white/70 uppercase tracking-wider mb-1">Valid Thru</p>
                                <p className="text-sm text-white font-medium">
                                    {detail.encrypted_expiration_date || "MM/YY"}
                                </p>
                            </div>
                        </div>

                        {/* Footer - Account Number & Card Network */}
                        <div className="absolute bottom-6 left-6 flex justify-between w-full pr-10">
                            <p className="text-lg tracking-wider mb-2 text-white">
                                **** **** **** {detail.encrypted_card_number?.slice(-4) || '****'}
                            </p>
                            <div className="flex space-x-1">
                                <div className="w-8 h-8 rounded-full bg-white/30" />
                                <div className="w-8 h-8 rounded-full bg-white/20" />
                            </div>
                        </div>
                    </div>

                    {/* Desktop hover controls */}
                    {!isMobile && (
                        <>
                            {(detail.permission_level && detail.permission_level === 'read') ? (
                                <div className={`absolute inset-0 w-full h-full bg-gradient-to-br from-[#4364F7] to-[#6FB1FC] flex flex-col items-center justify-center transition-all duration-300 ${hoveredCard === detail.id ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                                    <button
                                        onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                        className="w-3/4 py-2 px-4 bg-white/20 hover:bg-white/30 text-white rounded-lg transition-colors flex items-center justify-center space-x-2"
                                    >
                                        <EyeIcon className="h-5 w-5" />
                                        <span>View</span>
                                    </button>

                                </div>)
                                :
                                <div className={`absolute inset-0 w-full h-full bg-gradient-to-br from-[#4364F7] to-[#6FB1FC] flex flex-col items-center justify-center transition-all duration-300 ${hoveredCard === detail.id ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                                    <button
                                        onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                        className="w-3/4 py-2 px-4 bg-white/20 hover:bg-white/30 text-white rounded-lg transition-colors flex items-center justify-center space-x-2"
                                    >
                                        <PencilIcon className="h-5 w-5" />
                                        <span>Edit</span>
                                    </button>
                                    <button
                                        onClick={() => handleDelete(detail.id)}
                                        className="w-3/4 py-2 px-4 bg-red-500/20 hover:bg-red-500/30 text-white rounded-lg transition-colors flex items-center justify-center space-x-2 mt-3"
                                    >
                                        <TrashIcon className="h-5 w-5" />
                                        <span>Delete</span>
                                    </button>
                                </div>
                            }
                        </>
                    )}

                    {/* Mobile action menu */}
                    {isMobile && activeCard === detail.id && (
                        <div
                            className="absolute right-0 left-0 bottom-0 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm rounded-b-lg z-10 p-3 shadow-lg"
                            onClick={(e) => e.stopPropagation()}
                            style={{ borderTop: '1px solid rgba(255,255,255,0.2)' }}
                        >
                            <div className="flex justify-between items-center mb-2">
                                <h4 className="font-medium text-gray-800 dark:text-white">Card options</h4>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setActiveCard(null);
                                    }}
                                    className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
                                >
                                    <XMarkIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                                </button>
                            </div>
                            {(detail.permission_level && detail.permission_level === 'read') ?
                                <div className="grid grid-cols-2 gap-2">
                                    <button
                                        onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                        className="py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors flex items-center justify-center space-x-1"
                                    >
                                        <EyeIcon className="h-4 w-4" />
                                        <span>View</span>
                                    </button>
                                </div>
                                :
                                <div className="grid grid-cols-2 gap-2">
                                    <button
                                        onClick={() => handleEdit(detail.id, detail.is_shared, detail.shared_key)}
                                        className="py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors flex items-center justify-center space-x-1"
                                    >
                                        <PencilIcon className="h-4 w-4" />
                                        <span>Edit</span>
                                    </button>
                                    <button
                                        onClick={() => handleDelete(detail.id)}
                                        className="py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors flex items-center justify-center space-x-1"
                                    >
                                        <TrashIcon className="h-4 w-4" />
                                        <span>Delete</span>
                                    </button>
                                </div>
                            }
                        </div>
                    )}
                </div>
            ))}

            {details.length === 0 && (
                <div className="col-span-full flex flex-col items-center justify-center py-12 text-gray-500 dark:text-gray-400">
                    <CreditCardIcon className="h-24 w-24 mb-4" />
                    <h3 className="text-xl font-medium mb-2">No cards yet</h3>
                    <p className="text-sm text-gray-400 dark:text-gray-500">Add your first card to get started</p>
                </div>
            )}
        </div>
    );
};

export default CardDetailsList; 